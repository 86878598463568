import { useCallback, useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { useAppDispatch } from '../../store/hooks';
import { selectContent } from '../../store/slices/contentSlice';
import { focusedItemName, setCropImageUrl } from '../../store/slices/editorSlice';
import { changeHeaderLogo } from '../../store/slices/locationSlice';
import { ACCEPTABLE_IMAGE_MIME_TYPES, HEADER_LOGO } from '../../util/constants';
import { DroppableLogo } from './DroppableLogo';

export type EditLogoProps = {
	src?: string;
};

export const EditLogo = (props: EditLogoProps) => {
	const { src } = { ...props };
	const [previewImage, setPreviewImage] = useState(src);
	const [isDrag, setIsDrag] = useState(false);
	const [isValidType, setIsValidType] = useState(false);

	useEffect(() => {
		setPreviewImage(src);
	}, [src, setPreviewImage]);

	const dispatch = useAppDispatch();
	const actions = bindActionCreators(
		{
			onSetCropImageUrl: setCropImageUrl,
			saveHeaderLogo: changeHeaderLogo,
			onFocusedItemName: focusedItemName,
			onSelectContent: selectContent,
		},
		dispatch
	);

	const handleDragEnter = (e) => {
		const fileType = e.dataTransfer.items[0]?.type;
		if (ACCEPTABLE_IMAGE_MIME_TYPES.includes(fileType)) {
			setIsValidType(true);
		} else {
			setIsValidType(false);
		}
	};

	const handleFocus = useCallback(
		(name: string) => {
			actions.onFocusedItemName(name);
		},
		[actions]
	);

	const handleDragStart = () => {
		if (!isDrag) setIsDrag(true);
	};

	const handleDragStop = () => {
		if (isDrag) setIsDrag(false);
	};

	window.addEventListener('dragenter', handleDragEnter, false);
	window.addEventListener('dragover', handleDragStart, false);
	window.addEventListener('dragleave', handleDragStop, false);
	window.addEventListener('drop', handleDragStop, false);

	return (
		<div style={{ animation: 'fade 1s ease .5s both' }}>
			<DroppableLogo
				imageUrl={previewImage}
				setImagePreview={(file: File) => {
					const url = URL.createObjectURL(file);
					setPreviewImage(url);
					actions.onSetCropImageUrl(url);
					handleFocus(HEADER_LOGO);
					actions.saveHeaderLogo({
						name: file.name,
						url: url,
					});
				}}
				isDrag={isDrag}
				isValidType={isValidType}
			/>
		</div>
	);
};
