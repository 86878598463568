/**
 * TinyMCE Grid Layout Plugin
 *
 * This plugin adds Bootstrap grid functionality to TinyMCE editor.
 * It allows users to:
 * - Insert responsive grid layouts
 * - Customize column widths using Bootstrap's 12-column system
 * - Create flexible content sections with equal or proportional widths
 */

// =================
// Global Constants
// =================

// Plugin identifiers for registration with TinyMCE
const PLUGIN_IDENTIFIER = 'gridlayout';
const ICON_IDENTIFIER = 'grid-layout';

// Bootstrap grid system uses 12 columns as its base
const MAX_COLUMNS = 12;

// Custom SVG icon for the grid layout button in the toolbar
const GRID_ICON = `
  <svg width="24" height="24" viewBox="0 0 24 24">
    <path d="M3 3h18v18H3V3zm16 16V5H5v14h14zM7 7h4v4H7V7zm0 6h4v4H7v-4zm6-6h4v4h-4V7zm0 6h4v4h-4v-4z" 
          fill="currentColor"/>
  </svg>
`;

// =================
// Helper Functions
// =================

/**
 * Generates options for column width selection
 * Includes 'Auto' option and numerical values from 1 to MAX_COLUMNS
 * @returns {Array<{text: string, value: string}>} Array of option objects
 */
const generateColumnOptions = () => {
	const options = [{ text: 'Auto', value: '' }];

	for (let i = 1; i <= MAX_COLUMNS; i++) {
		options.push({ text: `${i}`, value: `${i}` });
	}

	return options;
};

/**
 * Determines the appropriate label for a column based on its position
 * @param {number} index - The zero-based index of the column
 * @param {number} totalColumns - Total number of columns in the grid
 * @returns {string} Appropriate label for the column
 */
const getColumnLabel = (index, totalColumns) => {
	if (index === 0) return 'Left Column';
	if (index + 1 === totalColumns) return 'Right Column';
	return `Middle Column ${index}`;
};

/**
 * Generates the HTML structure for the Bootstrap grid
 * @param {number} columns - Number of columns to generate
 * @param {Object} columnWidths - Object containing width values for each column
 * @returns {string} Complete HTML string for the grid structure
 */
const generateGridHTML = (columns, columnWidths) => {
	const columnElements = Array.from({ length: columns }, (_, index) => {
		const colClass = columnWidths[`col${index}`] ? `col-${columnWidths[`col${index}`]}` : 'col';

		return `    <div class="${colClass} p-0 m-0">\n      <div>Content goes here</div>\n    </div>`;
	}).join('\n');

	return `  <div class="row g-0 p-0 m-0">\n${columnElements}\n  </div>\n`;
};

/**
 * Main plugin function that initializes the grid layout functionality
 * @param {Object} editor - TinyMCE editor instance
 */
const GridLayoutPlugin = (editor) => {
	// Register the custom grid icon with TinyMCE
	editor.ui.registry.addIcon(ICON_IDENTIFIER, GRID_ICON);

	// HTML template for instructions shown in the column width dialog
	const instructionsHTML = `
    <div class="mb-3">
      <ol>
        <li>Column widths: Auto = equal width</li>
        <li>Or choose 1-12 (total must = 12)</li>
        <li>Example: 2 Columns
          <ul>
            <li>Left = 4</li>
            <li>Right = 8</li>
            <li>This makes Right column twice as wide</li>
          </ul>
        </li>
      </ol>
    </div>
  `;

	/**
	 * Shows the dialog for setting individual column widths
	 * @param {number} columns - Number of columns to configure
	 */
	const showColumnWidthDialog = (columns) => {
		editor.windowManager.open({
			title: 'Set Column Widths',
			body: {
				type: 'panel',
				items: [
					// Display instructions for column width configuration
					{
						type: 'htmlpanel',
						html: instructionsHTML,
					},
					// Generate width selection boxes for each column
					...Array.from({ length: columns }, (_, index) => ({
						type: 'selectbox',
						name: `col${index}`,
						label: getColumnLabel(index, columns),
						items: generateColumnOptions(),
					})),
				],
			},
			buttons: [
				{ type: 'cancel', text: 'Cancel' },
				{ type: 'submit', text: 'Insert', primary: true },
			],
			onSubmit: (api) => {
				const columnWidths = api.getData();
				const gridHTML = generateGridHTML(columns, columnWidths);
				editor.insertContent(gridHTML);
				api.close();
			},
		});
	};

	// Register the grid layout button in the TinyMCE toolbar
	editor.ui.registry.addButton(PLUGIN_IDENTIFIER, {
		icon: ICON_IDENTIFIER,
		tooltip: 'Insert Columns',
		onAction: () => {
			// Show initial dialog for selecting number of columns
			editor.windowManager.open({
				title: 'Insert Columns',
				size: 'small',
				body: {
					type: 'panel',
					items: [
						{
							type: 'htmlpanel',
							html: '<div class="mb-3"><p>Select number of columns</p></div>',
						},
						{
							type: 'input',
							name: 'columns',
							label: 'Number of Columns',
							inputMode: 'numeric',
							placeholder: 'Enter number of columns',
						},
					],
				},
				buttons: [
					{ type: 'cancel', text: 'Cancel' },
					{ type: 'submit', text: 'Next', primary: true },
				],
				onSubmit: (api) => {
					const { columns } = api.getData();
					api.close();
					// Show the column width configuration dialog
					showColumnWidthDialog(parseInt(columns));
				},
			});
		},
	});
};

export default GridLayoutPlugin;
