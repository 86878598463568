import { useAsyncEffect } from 'ahooks';
import { useState } from 'react';
import * as api from '../store/api-client';

export const useVersion = () => {
	const [version, setVersion] = useState<string | null>(null);

	useAsyncEffect(async () => {
		let newestVersion = await api.getUIVersion();
		setVersion(newestVersion);
		let localVersion = localStorage.getItem('version');
		if (newestVersion !== localVersion) {
			localStorage.setItem('version', newestVersion);
			window.location.reload();
		}
	});

	return version;
};
