import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
import TreeItem from '@material-ui/lab/TreeItem';
import TreeView from '@material-ui/lab/TreeView';
import { ChangeEvent } from 'react';
import { LANGUAGE_MAP } from '../store/models/location/Language';

const useStyles = makeStyles((theme) => ({
	'& .MuiTypography-root .MuiTreeItem-label .MuiTypography-body1': {
		backgroundColor: '#FFFFFF',
	},
	leaf: {
		backgroundColor: '#FFFFFF',
		paddingLeft: 0,
		display: 'block',
	},
	tree: {
		backgroundColor: '#FFFFFF',
		height: '300px',
		'& .MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label': {
			backgroundColor: '#FFFFFF',
		},
		'& .MuiTreeItem-content': {
			backgroundColor: '#FFFFFF',
			display: 'block',
		},
		'& .MuiTreeItem-label': {
			backgroundColor: '#FFFFFF',
			display: 'block',
		},
	},
}));

export type TranslationTreeProps = {
	languages: any[];
	handleSelectAll: (event: ChangeEvent<HTMLInputElement>) => void;
	handleSelectLanguage: (event: ChangeEvent<HTMLInputElement>) => void;
	selectedLanguages: any[];
};

export const TranslationTree = (props: TranslationTreeProps) => {
	const { languages, selectedLanguages, handleSelectAll, handleSelectLanguage } = props;
	const classes = useStyles();

	return (
		<TreeView
			className={classes.tree}
			defaultCollapseIcon={<></>}
			defaultExpandIcon={<></>}
			defaultEndIcon={<></>}
			defaultSelected={[]}
			multiSelect
			expanded={['all']}
			selected={languages}
		>
			<TreeItem
				nodeId="all"
				label={
					<div className={classes.leaf}>
						<Checkbox
							checked={selectedLanguages.length === languages.length}
							indeterminate={selectedLanguages.length > 0 && selectedLanguages.length < languages.length}
							onChange={handleSelectAll}
							color="primary"
						/>
						{'All'}
					</div>
				}
				className={classes.leaf}
			>
				{languages.map((language) => (
					<TreeItem
						key={language}
						nodeId={language}
						label={
							<div className={classes.leaf}>
								<Checkbox
									checked={selectedLanguages.includes(language)}
									value={language}
									onChange={handleSelectLanguage}
									color="primary"
								/>
								{LANGUAGE_MAP[language]}
							</div>
						}
						className={classes.leaf}
					/>
				))}
			</TreeItem>
		</TreeView>
	);
};
