// TinyMCE plugin for importing Bootstrap HTML
const CustomHtmlPlugin = (editor) => {
	// Add Bootstrap CSS to the editor if not already included
	editor.on('init', function () {
		if (!editor.dom.styleSheetLoader) {
			editor.dom.loadCSS('https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/css/bootstrap.min.css');
		}
	});

	// Register a custom icon for the plugin
	editor.ui.registry.addIcon(
		'bootstrap-icon',
		`<svg width="24" height="24" viewBox="0 0 24 24">
			<path d="M5 6l-2 6 2 6m14-12l2 6-2 6" stroke="currentColor" fill="none" stroke-width="2" stroke-linecap="round"/>
			<path d="M9 4L15 20" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
		</svg>`
	);

	// Add a button to the toolbar
	editor.ui.registry.addButton('customhtml', {
		icon: 'bootstrap-icon',
		tooltip: 'Custom HTML With Bootstrap',
		onAction: function () {
			openImportDialog();
		},
	});

	function openImportDialog() {
		editor.windowManager.open({
			title: 'Custom HTML With Bootstrap',
			size: 'large',
			body: {
				type: 'panel',
				items: [
					{
						type: 'textarea',
						name: 'html',
						label: 'Paste your Custom HTML With Bootstrap Here',
						placeholder: '<div class="container">...</div>',
						maximized: true,
					},
				],
			},
			buttons: [
				{
					type: 'cancel',
					text: 'Cancel',
				},
				{
					type: 'submit',
					text: 'Import',
					primary: true,
				},
			],
			onSubmit: function (api) {
				var data = api.getData();
				var html = data.html;

				// Sanitize the HTML
				var sanitizedHtml = sanitizeHtml(html);

				// Insert the sanitized HTML into the editor
				editor.insertContent(sanitizedHtml);

				// Initialize any Bootstrap JavaScript components
				initializeBootstrapComponents();

				api.close();
			},
		});
	}

	function sanitizeHtml(html) {
		// This is a placeholder for a more robust sanitization function
		// In a real implementation, you'd use a library like DOMPurify
		// For now, we'll do some basic sanitization
		var tempDiv = document.createElement('div');
		tempDiv.innerHTML = html;

		// Remove any script tags
		var scripts = tempDiv.getElementsByTagName('script');
		for (var i = scripts.length - 1; i >= 0; i--) {
			scripts[i].parentNode.removeChild(scripts[i]);
		}

		return tempDiv.innerHTML;
	}

	function initializeBootstrapComponents() {
		// Instead of directly initializing Bootstrap components,
		// we'll add attributes that our custom handler will use
		editor.dom.select('[data-bs-toggle="tooltip"]').forEach(function (el) {
			el.setAttribute('data-mce-bootstrap-tooltip', 'true');
		});

		// Add more attribute setting for other Bootstrap components as needed
	}

	// Add custom CSS to style Bootstrap components within the editor
	editor.on('init', function () {
		var customCSS = `
      .mce-content-body .row { border: 1px dashed #ccc; padding: 5px; }
      .mce-content-body [class^="col-"] { border: 1px solid #ddd; padding: 5px; }
    `;
		editor.dom.addStyle(customCSS);
	});

	// Handle Bootstrap responsive classes in different TinyMCE view modes
	editor.on('ResizeEditor', function () {
		// This is where you'd adjust how Bootstrap responsive classes are displayed
		// based on the current editor size
	});

	// Add a custom handler for Bootstrap components
	editor.on('mouseenter', function (e) {
		var target = e.target;
		if (target.getAttribute('data-mce-bootstrap-tooltip') === 'true') {
			// Here you would implement custom tooltip behavior
			// For example, you could show a TinyMCE tooltip:
			editor.execCommand('mceShowTooltip', false, {
				content: target.getAttribute('title') || 'Tooltip',
				target: target,
			});
		}
		// Add more handlers for other Bootstrap components as needed
	});

	editor.on('mouseleave', function (e) {
		var target = e.target;
		if (target.getAttribute('data-mce-bootstrap-tooltip') === 'true') {
			// Hide the tooltip
			editor.execCommand('mceHideTooltip');
		}
		// Add more handlers for other Bootstrap components as needed
	});

	return {
		getMetadata: function () {
			return {
				name: 'Custom HTML',
				url: 'https://liiingo.com/tinymce_plugins/customHTML',
			};
		},
	};
};

export default CustomHtmlPlugin;
