import { useImageUploadField } from '../../hooks/useImageEditorUploadField';
import { checkMultipleValidationRules, recommendHighSolution } from '../../util/imageValidators';
import { MAX_IMAGE_UPLOAD_SIZE } from '../../util/constants';

export const PreviewMinHeight = 183;

export type DroppableImageProps = {
	isDrag?: boolean;
	isOver?: boolean;
	imageUrl?: string;
	setImagePreview: (file: File) => void;
	imageType?: string;
	isValidType?: boolean;
};

export const DroppableImage = (props: DroppableImageProps) => {
	const { imageUrl, isDrag, setImagePreview, imageType, isOver, isValidType } = { ...props };

	const [imageUploadFieldProps, ImageUploadField] = useImageUploadField({
		fieldName: `customLogoImageFile`,
		maxFileSizeBytes: MAX_IMAGE_UPLOAD_SIZE,
		initialValue: imageUrl,
		validateImage: checkMultipleValidationRules([recommendHighSolution]),
		setImagePreview: setImagePreview,
		isDrag: isDrag,
		isOver: isOver,
		imageType: imageType,
		isValidType: isValidType,
	});

	return <ImageUploadField {...imageUploadFieldProps} />;
};
