import { useCustomImageUploadingHook } from '../../../hooks/useCustomImageUploading';
import { checkMultipleValidationRules, recommendHighSolution } from '../../../util/imageValidators';

export const PreviewMinHeight = 133;

export type CustomImageUploadingProps = {
	isDrag?: boolean;
	imageUrl?: string;
	setImagePreview: (file: File) => void;
};

export const CustomImageUploading = (props: CustomImageUploadingProps) => {
	const { imageUrl, isDrag, setImagePreview } = { ...props };
	const [uploadCustomImageUploadingHookProps, CustomImageUploadingHook] = useCustomImageUploadingHook({
		fieldName: `customQrBackgroundImageFile`,
		maxFileSizeBytes: 524288,
		initialValue: imageUrl,
		validateImage: checkMultipleValidationRules([recommendHighSolution]),
		setImagePreview: setImagePreview,
		isDrag: isDrag,
	});

	return <CustomImageUploadingHook {...uploadCustomImageUploadingHookProps} />;
};
