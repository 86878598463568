import { Box, Typography } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Theme } from '@material-ui/core/styles/createTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import { colors } from '../../../../theme/palette';
import { ChartContent } from './ChartContent';
import Highcharts, { Options } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useEffect, useState } from 'react';
import { sessionDurationType, SessionType } from '../../../../store/models/analytics/Session';
import { Empty } from './Empty';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		cardLabel: {
			display: 'flex',
			justifyContent: 'space-between',
			margin: '10px 0px 20px',
		},
		cardLabelTitle: {
			color: colors.neutralDark,
			fontSize: '14px',
			lineHeight: '22px',
			display: 'flex',
			alignItems: 'center',
		},
		cardLabelValue: {
			color: colors.purpleAccent,
			fontSize: '16px',
			lineHeight: '24px',
			fontWeight: 700,
			marginLeft: '5px',
		},
	})
);

export type UserCardProps = {
	session: SessionType[];
	sessionDuration: sessionDurationType;
};

export const UserCard = (props: UserCardProps) => {
	const { session, sessionDuration } = props;
	const [newUserData, setNewUserData] = useState(0);
	const [returnUserData, setReturnUserData] = useState(0);
	const classes = useStyles();

	useEffect(() => {
		session.forEach((data) => {
			if (data.name === 'Returning User') {
				setReturnUserData(data.value);
			}
			if (data.name === 'New User') {
				setNewUserData(data.value);
			}
		});
	}, [session]);
	const allUserData = newUserData + returnUserData;
	const chartOptions: Options = {
		chart: {
			height: 118,
			type: 'column',
			zoomType: 'xy',
			panning: {
				enabled: true,
				type: 'xy',
			},
			panKey: 'shift',
		},
		title: {
			text: '',
		},
		series: [
			{
				type: 'column',
				data: [allUserData, returnUserData, newUserData],
			},
		],
		xAxis: {
			categories: ['All', 'Returning', 'New'],
			labels: {
				enabled: true,
				useHTML: true,
				formatter: function () {
					return `${this.value}`;
				},
				style: {
					color: colors.grayDark,
					fontSize: '12px',
					lineHeight: '20px',
				},
			},
		},
		yAxis: {
			title: { text: null },
			labels: {
				style: {
					fontSize: '12px',
					lineHeight: '20px',
				},
			},
		},
		credits: {
			enabled: false, //removing watermark
		},
		tooltip: {
			useHTML: true,
			backgroundColor: colors.grayDark,
			borderWidth: 0,
			borderRadius: 4,
			shadow: false,
			style: {
				padding: '8px 12px',
				color: colors.pureWhite,
				fontSize: '16px',
				fontWeight: '700',
				whiteSpace: 'nowrap',
			},
			formatter: function () {
				return `${this.y}`;
			},
		},
		legend: { enabled: false },
		plotOptions: {
			series: {
				cursor: 'pointer',
				color: colors.purpleAccent,
			},
		},
	};

	return (
		<ChartContent
			name={'USERS'}
			toolTipMsg={
				'The number of users that have visited your Liiingo. Bounce rate refers to users who spent less than 3 seconds in your Liiingo.'
			}
		>
			<Box className={classes.cardLabel}>
				<Typography className={classes.cardLabelTitle}>
					Average Session:
					<span className={classes.cardLabelValue}>{sessionDuration.averageSession}</span>
				</Typography>
				<Typography className={classes.cardLabelTitle}>
					Bounce Rate:
					<span className={classes.cardLabelValue}>{sessionDuration.bounceRate}</span>
				</Typography>
			</Box>
			{newUserData === 0 && returnUserData === 0 ? (
				<Empty />
			) : (
				<HighchartsReact highcharts={Highcharts} options={chartOptions} />
			)}
		</ChartContent>
	);
};
