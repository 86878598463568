import { SessionResponse } from './Analytics';

export interface SessionType {
	name: string;
	value: number;
}

export interface sessionDurationType {
	averageSession: string;
	bounceRate: string;
}

export interface SessionPayload {
	session: SessionType[];
	sessionDuration: sessionDurationType;
}

export const formatTime = (seconds: number) => {
	// calculate the days
	let days = Math.trunc(seconds / (24 * 3600)); // Seconds divided by 24 hours, divided by 3600 seconds(1 Hour), truncated to whole number

	// calculate the hours
	seconds = seconds % (24 * 3600); // Total seconds divided by 24 hours, divided by 3600 seconds(1 Hour), remainder
	let hours = Math.trunc(seconds / 3600); // Remaining seconds divided by 3600 seconds (1 Hour), truncated to whole number

	seconds %= 3600; // Remaining seconds divided by 3600 seconds (1 Hour), remainder
	let minutes = Math.trunc(seconds / 60); // Remaining seconds divided by 60 seconds (1 Minute)

	seconds = Math.trunc(seconds % 60); // Remaining seconds divided by 60 seconds (1 Minute), remainder

	return `${days > 0 ? days + 'd ' : ''}${hours > 0 ? hours + 'h ' : ''}${minutes > 0 ? minutes + 'm ' : ''}${
		seconds > 0 ? seconds + 's' : ''
	}`;
};

/**
 * This function aggregates session data from the raw data.
 *
 * @param rawData - SessionResponse[] data from the API
 * @returns userChartData
 */
export const aggregateSession = (rawData: SessionResponse[]) => {
	let userChartData: SessionType[] = [];
	let duration = 0;
	let bounceCount = 0;
	let uniqueViews = {};
	let returningCount = 0;
	let newCount = 0;

	rawData.forEach((item) => {
		// initialize the key/value to 0 if it doesn't exist
		uniqueViews[item.udid] = uniqueViews[item.udid] || 0;
		// increment the key/value
		uniqueViews[item.udid]++;
	});

	Object.keys(uniqueViews).forEach(function (key, index) {
		if (uniqueViews[key] > 1) {
			returningCount++;
		} else if (uniqueViews[key] === 1) {
			newCount++;
		}
	});

	userChartData.push({
		name: 'Returning User',
		value: returningCount,
	});

	userChartData.push({
		name: 'New User',
		value: newCount,
	});

	rawData.forEach((item: any) => {
		let itemDuration;
		//use 4 seconds as default duration if closeTime is not available
		if (!item.metadata.closeTime) {
			itemDuration = 4;
		} else {
			// otherwise calculate the duration
			itemDuration =
				(item.metadata.closeTime.$date.$numberLong - item.metadata.startTime.$date.$numberLong) / 1000;
		}
		// add to the total duration
		duration += itemDuration;
		// if the duration is less than 3 seconds, increment the bounce count
		if (itemDuration < 3) {
			++bounceCount;
		}
	});

	const averageSession = rawData.length === 0 ? '0s' : formatTime(duration / rawData.length).toString();
	const bounceRate = rawData.length === 0 ? '0%' : Math.round((bounceCount / rawData.length) * 100).toString() + '%';

	return {
		session: userChartData,
		sessionDuration: { averageSession: averageSession, bounceRate: bounceRate },
	} as SessionPayload;
};
