import Container from '@material-ui/core/Container';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useState } from 'react';
import { useHydrateStore } from '../../hooks/useHydrateStore';
import { useIsDev } from '../../hooks/useIsDev';
import { useAppSelector } from '../../store/hooks';
import { _organization } from '../../store/slices/organizationSlice';
import { colors } from '../../theme/palette';
import { CLOSED_ACCOUNT } from '../../util/constants';
import { FlatButton } from '../Buttons';
import { AccountSecurityCard } from './AccountSecurityCard';
import { BillingInformationCard } from './BillingInformationCard';
import { CancelAtChanger } from './CancelAtChanger';
import { FreeTrialChanger } from './FreeTrialChanger';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		containerWidth: {
			width: '100%',
		},
		dashboardTitle: {
			padding: 8,
		},
		closeBtn: {
			borderRadius: 10,
			color: colors.redDark,
			background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), #DC4C4C',

			'&:hover': {
				borderRadius: 10,
				color: colors.pureWhite,
				background: colors.redDark,
			},
		},
		btnRow: {
			padding: 20,
		},
	})
);

export const AccountSettings = () => {
	// const { handleError } = useContext(ErrorContext);
	const [closeAccountDialogOpen, setCloseAccountDialogOpen] = useState(false);
	const isDev = useIsDev();
	const classes = useStyles();
	useHydrateStore(); //this will only hydrate if we access this page with an empty store

	const organization = useAppSelector(_organization);

	const handleCancel = () => {
		setCloseAccountDialogOpen(false);
	};

	const handleConfirm = () => {
		localStorage.setItem(CLOSED_ACCOUNT, 'true');
		setCloseAccountDialogOpen(false);
	};

	return (
		<Container className={classes.containerWidth}>
			<Dialog open={closeAccountDialogOpen} maxWidth="sm" PaperProps={{ elevation: 0 }}>
				<DialogTitle>Close Your Account?</DialogTitle>
				<DialogContent>
					<DialogContentText>
						By closing your account, all published apps will be deleted permanently once the remaining
						billing cycle has ended. Are you sure you want to close your account?
					</DialogContentText>
				</DialogContent>
				<DialogActions className={classes.btnRow}>
					<FlatButton onClick={handleCancel} color="primary">
						Keep My Account
					</FlatButton>
					<FlatButton
						onClick={handleConfirm}
						color="primary"
						className={classes.closeBtn}
						autoFocus
						data-cy={'confirmCloseAccount'}
					>
						Close My Account
					</FlatButton>
				</DialogActions>
			</Dialog>
			<Typography variant="h5" className={classes.dashboardTitle}>
				Account Settings
			</Typography>
			<Grid container spacing={2} justifyContent="center">
				{/* <Grid item xs={6}>
          <UserAccountForm onError={handleError} submitButtonText="Update" />
        </Grid> */}
				<Grid container item xs={12} spacing={2}>
					{!organization?.sponsored && (
						<Grid item xs={6}>
							<BillingInformationCard />
						</Grid>
					)}
					<Grid item xs={6}>
						<AccountSecurityCard onCloseAccountDialogOpen={setCloseAccountDialogOpen} />
					</Grid>
				</Grid>
				{isDev && (
					<Grid item xs={6}>
						<FreeTrialChanger />
						<CancelAtChanger />
					</Grid>
				)}
			</Grid>
		</Container>
	);
};
