import Bugsnag from '@bugsnag/js';
import axios from 'axios';
import { Stream } from 'stream';
import { liiingoFailure } from '../../components/LiiingoFailureSnackbar';
import { PHP_ACCESS_TOKEN } from '../../util/constants';
import { checkPHPToken } from '../../util/refreshTokens';
import { toAbridgedAxiosError } from './axiosErrorHelpers';

// For network requests that don't need these header-params, just create another axios instance
const axiosInstance = axios.create({
	baseURL: process.env.REACT_APP_LIIINGO_URL,
});

// Intercept requests and append current PHP Token to header
axiosInstance.interceptors.request.use(async function (config) {
	// let accessToken = (await refreshJwt()).accessToken;
	let phpToken = localStorage.getItem(PHP_ACCESS_TOKEN);
	phpToken = await checkPHPToken(phpToken);
	// if we don't have, and can't get, a valid token... route to sign in page
	if (!phpToken) {
		routeToSignInPage();

		// const controller = new AbortController();
		// const abortedConfig = {
		//   ...config,
		//   signal: controller.signal,
		// };
		// controller.abort();
		// return abortedConfig;
	}

	config.headers.Authorization = `Bearer ${phpToken}`;
	return config;
});

// Intercept all responses, for handling errors in one place
axiosInstance.interceptors.response.use(
	(response) => response,
	(error) => {
		// if (error.response?.status === '401') {
		//   // if we get a 401 reponse, refresh the JWT
		//   //TODO: try the network call again if the token is refreshed saveSuccessfully
		//   let phpToken = localStorage.getItem(PHP_ACCESS_TOKEN);
		//   phpToken = await checkPHPToken(phpToken);
		//   // if we don't have, and can't get, a valid token... route to sign in page
		//   if (!phpToken) {
		//     routeToSignInPage()

		//   }
		// }

		// if (error.message !== 'canceled') {
		console.error(`AXIOS ERROR: ${error} \n RESPONSE FROM SERVER: ${error.response?.data}`);
		liiingoFailure(`An Error Occured`);
		Bugsnag.notify(error);
		// }
		return Promise.reject(error);
	}
);

const routeToSignInPage = () => {
	window.location.href = `${window.location.href.split('/').slice(0, 4).join('/')}/sign-in?sessionExpired=true`;
};

export function stripQuotes(res): string {
	return res.replace(/['"]+/g, '');
}

export async function getFileFromUrl(url: string | Object | undefined): Promise<any> {
	if (typeof url !== 'string') return;
	try {
		// const response = await axios.get(url, {
		//   responseType: 'stream',
		// });
		// return await streamToString(response.data);
		const blobVila = await fetch(url).then((r) => r.blob());
		return blobVila;
	} catch (error) {
		throw toAbridgedAxiosError(error);
	}
}

export const streamToString = (stream: Stream): Promise<Buffer> => {
	const chunks: any[] = [];
	return new Promise((resolve, reject) => {
		stream.on('data', (chunk) => chunks.push(chunk));
		stream.on('error', reject);
		stream.on('end', () => resolve(Buffer.concat(chunks)));
	});
};

export default axiosInstance;
export * from './analytics';
export * from './auth';
export * from './device';
export * from './file';
export * from './invitation';
export * from './location';
export * from './ms';
export * from './organization';
export * from './overlay';
export * from './plan';
export * from './push-notifications';
export * from './redirect';
export * from './section';
export * from './stripe';
export * from './topics';
export * from './user';
