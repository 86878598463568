import axios from 'axios';

// call endpoint getMSPlanByToken
export const getMSPlanByToken = (token: string) => {
	return axios
		.post(process.env.REACT_APP_LIIINGO_URL + '/organization/getMsPlanByToken', { token })
		.then((res) => res.data);
};

// call endpoint to activate Ms Saas subscription
export const activateMsSaasSubscription = (subscriptionId: string) => {
	return axios
		.post(process.env.REACT_APP_LIIINGO_URL + '/organization/activateMsSaas', { subscriptionId })
		.then((res) => console.log('activation status: ' + res.data));
};
