import { useState, useRef } from 'react';
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Theme } from '@material-ui/core/styles/createTheme';
import { EmailItem } from './EmailItem';
import { colors } from '../../../theme/palette';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		listField: {
			display: 'flex',
			flexDirection: 'row',
			padding: '8px 10px',
			background: colors.pureWhite,
			border: '1px solid #59C9E6',
			minHeight: 98,
			borderRadius: 10,
			position: 'static',
			flex: 'none',
			order: 1,
			alignSelf: 'stretch',
			flexGrow: 1,
			boxSizing: 'border-box',
			flexWrap: 'wrap',
		},
		inputField: {
			position: 'static',
			top: 'calc(50% - 82px/2)',
			width: '100%',
			height: 'unset',
		},
		input: {
			color: 'inherit',
			background: 0,
			opacity: 1,
			width: '100%',
			height: '100%',
			paddingTop: '0.125rem',
			paddingBottom: '50px',
			gridArea: '1/2',
			font: 'inherit',
			minWidth: 2,
			border: 0,
			margin: 0,
			outline: '2px solid transparent',
			outlineOffset: 2,
		},
	})
);

interface EmailInputFieldProps {
	emailList: any;
	onCancel: () => void;
	onSelect: (number) => void;
	onAdd: (string) => void;
}
export const EmailInputField = (props: EmailInputFieldProps) => {
	const classes = useStyles();
	const { emailList, onCancel, onAdd, onSelect } = props;
	const [curEmail, setCurEmail] = useState({
		email: '',
		selected: false,
	});
	const [selectedIndex, setSeletectedIndex] = useState(-1);
	const [backCnt, setBackCnt] = useState(0);
	const [isVisible, setIsVisible] = useState(true);
	const editorRef = useRef(null);

	return (
		<div className={classes.listField}>
			{emailList.map((item, index) => {
				return (
					<EmailItem key={`email${index}`} email={item.email} selected={item.selected} backCnt={backCnt} />
				);
			})}
			<div className={classes.inputField} onClick={() => setIsVisible(true)}>
				<input
					type="text"
					placeholder={
						isVisible ? (emailList.length === 0 ? 'Email, comma separated' : 'Add another member') : ''
					}
					className={classes.input}
					style={isVisible ? { caretColor: '#000' } : { caretColor: 'transparent' }}
					onKeyDown={(e) => {
						// seperate emails with 'enter', 'tab', 'comma' key pressing
						if (e.key === 'Enter' || e.key === 'Tab' || e.key === ',') {
							if (
								editorRef.current.value !== '' &&
								!emailList.some((item) => item.email === curEmail.email)
							) {
								onAdd(curEmail);
							}
							setSeletectedIndex(emailList.length);
							setCurEmail({
								email: '',
								selected: false,
							});
						}
						// prevent default actions of 'tab', 'comma' key pressing
						if (e.key === ',' || e.key === 'Tab') {
							e.preventDefault();
							return false;
						}
						if (e.key === 'Backspace') {
							if (editorRef?.current?.value === '') {
								setBackCnt(backCnt + 1);
								if (selectedIndex === emailList.length && backCnt % 2 === 0) {
									// frist time backspace press is for selection, second time backspace press is for delete
									onSelect(emailList.length - 1);
									setSeletectedIndex(emailList.length - 1);
									setIsVisible(false);
								} else if (selectedIndex !== -1 && backCnt % 2 === 1) {
									onCancel();
									selectedIndex === 0 ? setSeletectedIndex(1) : setSeletectedIndex(selectedIndex - 1);
									setIsVisible(false);
								}
								if (emailList.length === 0 && !isVisible) setIsVisible(true);
							}
						}
						if (e.keyCode === 37) {
							// left arrow key press
							if (selectedIndex > 0) {
								onSelect(selectedIndex - 1);
								setSeletectedIndex(selectedIndex - 1);
								setBackCnt(0);
								setIsVisible(false);
							}
						}
						if (e.keyCode === 39) {
							// right arrow key press
							if (selectedIndex < emailList.length - 1) {
								onSelect(selectedIndex + 1);
								setSeletectedIndex(selectedIndex + 1);
								setBackCnt(0);
								setIsVisible(false);
							} else if (selectedIndex === emailList.length - 1) setIsVisible(true);
						}
					}}
					ref={editorRef}
					onClick={() => {
						editorRef.current.focus();
					}}
					onBlur={() => {
						if (
							editorRef.current.value !== '' &&
							!emailList.some((item) => item.email === curEmail.email)
						) {
							onAdd(curEmail);
						}
						setSeletectedIndex(emailList.length);
						setCurEmail({
							email: '',
							selected: false,
						});
					}}
					value={curEmail.email}
					onChange={(e) => {
						if (isVisible)
							setCurEmail({
								email: e.target.value,
								selected: false,
							});
					}}
					onPaste={(event) => {
						const delimiters = ['\t', '\r\n', '\n'];
						let tempEmailList = event.clipboardData
							.getData('text')
							.split(new RegExp(delimiters.join('|'), 'g'));
						tempEmailList = tempEmailList.filter((item) => item !== '');
						tempEmailList.forEach((tempEmail) => {
							if (!emailList.some((item) => item.email === tempEmail)) {
								onAdd({
									email: tempEmail,
									selected: false,
								});
							}
							setSeletectedIndex(emailList.length);
							setCurEmail({
								email: '',
								selected: false,
							});
						});
						event.preventDefault();
					}}
				/>
			</div>
		</div>
	);
};
