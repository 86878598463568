import React from 'react';

const PaymentCanceled = () => {
	return (
		<div>
			Your payment has been canceled. Don't worry! Your account is here for when you are ready to complete your
			registration
		</div>
	);
};

export default PaymentCanceled;
