import { useEffect, useState } from 'react';
import { useAppSelector } from '../../store/hooks';
import { _plan } from '../../store/slices/editorSlice';
import { LIIINGO_HELP_URL } from '../../util/constants';
import { AppBar } from './AppBar';
import { LeftNavProps } from './LeftNav';

export const Nav = (props: LeftNavProps) => {
	const plan = useAppSelector(_plan);
	const { isHidden } = props;
	const [items, setItems] = useState([]);

	// useEffect to set the appBarItems based on the plan
	useEffect(() => {
		let baseItems = [{ route: 'dashboard', displayName: 'Dashboard' }];
		let withHelp = [...baseItems, { route: LIIINGO_HELP_URL, displayName: 'Help' }];

		if (plan?.name === null || plan?.name === undefined || plan?.name === 'Lite') {
			setItems(baseItems);
		} else {
			setItems(withHelp);
		}
	}, [plan]);

	if (isHidden) {
		return null;
	}

	return (
		<div>
			<AppBar isHidden={isHidden} appBarItems={items} />
		</div>
	);
};
