import { LiiingoWalkthroughStep } from './type';

export const InitialContentDesignerSteps: LiiingoWalkthroughStep[] = [
	{
		key: 'start',
		selector: '[data-tour="start"]',
		title: 'Welcome to Liiingo!',
		message: 'In a few minutes, we can help you walk through creating your app without writing any code!',
		buttonText: 'Get Started',
		position: [],
	},
	{
		key: 'mobile-preview',
		selector: '[data-tour="mobile-preview"]',
		title: 'Mobile Preview',
		message: "This is where you'll build your app. We've created a template for you to get started.",
		buttonText: 'Next',
		position: 'left',
		width: 366,
		arrowPosition: 'right',
		arrowPlacement: 15,
		disableInteraction: true,
	},
	{
		key: 'rename',
		selector: '[data-tour="rename"]',
		title: 'Rename Your App',
		message:
			'To start renaming, double-click on your app name. Then press Enter on your keyboard to save the new name. Give it a try!',
		buttonText: 'Skip',
		skipTo: 4,
		position: 'bottom',
		width: 483,
		arrowPosition: 'top',
		arrowPlacement: 5,
	},
	{
		key: 'rename-complete',
		selector: '[data-tour="rename"]',
		title: 'Nailed it!',
		message: 'Your app name is updated! To rename your app, you can double-click on your app name at any time',
		buttonText: 'Next',
		position: 'bottom',
		width: 425,
		arrowPosition: 'top',
		arrowPlacement: 10,
	},
	{
		key: 'left-tab',
		selector: '[data-tour="left-tab"]',
		title: 'Customize Your App',
		message: 'The left tab bar includes tools to help create and manage your app content.',
		buttonText: 'Next',
		position: 'right',
		width: 343,
		arrowPosition: 'left',
		arrowPlacement: 5,
		disableInteraction: true,
	},
	{
		key: 'element-menu',
		selector: '[data-tour="element-menu"]',
		title: 'Customize Your App',
		message:
			'To further customize your content, click and drag elements onto your Mobile Preview. Try adding a new image to your app now!',
		buttonText: 'Skip',
		position: 'right',
		width: 383,
		skipTo: 9,
		arrowPosition: 'left',
	},
	{
		key: 'element-menu-drag-start',
		selector: '[data-tour="mobile-preview"]',
		title: 'Customize Your App',
		message:
			'To further customize your content, click and drag elements onto your Mobile Preview. Try adding a new image to your app now!',
		buttonText: 'Skip',
		position: 'left',
		width: 383,
		skipTo: 9,
		arrowPosition: 'right',
		arrowPlacement: 15,
	},
	{
		key: 'right-drawer',
		selector: '[data-tour="right-drawer"]',
		title: 'Design Toolbox',
		message:
			'When an element in your Mobile Preview is selected, the Design Toolbox appears with tools to help you customize it further.',
		buttonText: 'Next',
		position: 'left',
		width: 376,
		arrowPosition: 'right',
		arrowPlacement: 10,
	},
	{
		key: 'organize',
		selector: '[data-tour="mobile-preview"]',
		title: 'Organizing Your Elements',
		message: 'Click & drag content in your Mobile Preview to rearrange the layout. Give it a try!',
		buttonText: 'Skip',
		position: 'left',
		width: 374,
		skipTo: 9,
		arrowPosition: 'right',
		arrowPlacement: 15,
	},
	{
		key: 'end',
		selector: '[data-tour="end"]',
		title: 'Nice Work!',
		message:
			"You're ready to start designing on your own, but don't worry. We'll pop in to help as you discover new features.",
		buttonText: 'Done',
		position: [],
	},
];
