import { Box, Card, CardContent, Theme, Typography, createStyles, makeStyles } from '@material-ui/core';
import { useCallback, useState } from 'react';
import { colors } from '../../theme/palette';
import { FlatButton } from '../Buttons';
import { LiteQuiiikModal } from './LiteQuiiikModal';
import { QuiiikPageCardTable } from './QuiiikPageCardTable';
import * as api from '../../store/api-client';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		card: {
			marginBottom: 20,
			width: 450,
			backgroundColor: theme.palette.background.paper,
		},
		titleBox: {
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'baseline',
			marginBottom: 40,
		},
		headerText: {
			color: colors.grayDark,
			display: 'grid',
			fontSize: 20,
		},
		descriptionText: {
			fontSize: 16,
			color: colors.grayLight,
			justifyContent: 'center',
			paddingTop: 12,
			marginTop: 8,
		},
	})
);

const QuiiikPageCardWrapper = () => {
	const [open, setOpen] = useState(false);
	const classes = useStyles();

	// Methods, functions, useEffects, useState ect go here

	const handleOpen = useCallback(() => {
		setOpen(true);
		api.logAction('open_create_quiikpage_modal');
	}, []);

	return (
		<Card variant="outlined" className={classes.card}>
			<CardContent>
				<Box className={classes.titleBox}>
					<Typography className={classes.headerText} variant="h5">
						{'Quiiik Pages'}
						<div className={classes.descriptionText}>
							{'Create custom pages, each with a dynamic QR code—no coding needed!'}
						</div>
					</Typography>
					<FlatButton variant="contained" onClick={handleOpen} color="secondary">
						{'Add'}
					</FlatButton>
				</Box>
				{open && <LiteQuiiikModal handleClose={() => setOpen(false)} open={open} />}
				<QuiiikPageCardTable />
			</CardContent>
		</Card>
	);
};

export default QuiiikPageCardWrapper;
