import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs, { Dayjs } from 'dayjs';
import { useState } from 'react';
import { useStripeState } from '../../hooks/useStripeState';
import { FlatButton } from '../Buttons/FlatButton';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		formActions: {
			display: 'flex',
			justifyContent: 'space-between',
			[theme.breakpoints.up('md')]: {
				flexDirection: 'row-reverse',
			},
			[theme.breakpoints.down('sm')]: {
				marginTop: theme.spacing(1),
				flexDirection: 'row',
			},
		},
		formCard: {
			marginBottom: 20,
			backgroundColor: theme.palette.background.paper,
		},
	})
);

export const CancelAtChanger = () => {
	const classes = useStyles();
	const [value, setValue] = useState<Dayjs | null>(dayjs(Date.now()));
	const { changeCancelAtDate } = useStripeState();

	return (
		<Card variant="outlined" className={classes.formCard}>
			<CardHeader title="Development Helper - CANCEL_AT" />
			<CardContent>
				<Grid container>
					<Grid container item xs={12} md={12}>
						<Grid item xs container direction="row" spacing={2}>
							<Grid item xs={12} md={12}>
								<Typography gutterBottom variant="subtitle1">
									Choose a new date for your subscription to cancel at.
								</Typography>
							</Grid>
							<Grid item xs={12} sm={12} className={classes.formActions}>
								<LocalizationProvider dateAdapter={AdapterDayjs}>
									<DateTimePicker
										renderInput={(props) => <TextField {...props} />}
										label="DateTimePicker"
										value={value}
										onChange={(newValue) => {
											setValue(newValue);
										}}
									/>
								</LocalizationProvider>
								<FlatButton
									onClick={() => changeCancelAtDate(value.unix())}
									variant="contained"
									color="secondary"
								>
									Save
								</FlatButton>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
};
