import axios from 'axios';
import axiosInstance from '.';
import { Redirect, newRedirect } from '../models/redirect/Redirect';

// create new Redirect
export const createRedirect = (redirect: Redirect) => {
	return axiosInstance.post('/lite/new', redirect).then((res) => newRedirect(res.data));
};

// get all Redirects
export const getRedirects = () => {
	return axiosInstance
		.post('/lite/get', {}) // send a POST request to the Liiingo API
		.then((res: any) => res.data.map((r) => newRedirect(r))); // convert the response data to an array of Redirect objects
};

// get Redirect by ID
export const getRedirectById = (id: string) => {
	return axios.post('/lite/getById', { id }).then((res) => newRedirect(res.data));
};

// update Redirect
export const updateRedirect = (redirect: Partial<Redirect>) => {
	return axiosInstance.post('/lite/save', redirect).then((res) => newRedirect(res.data));
};

// delete Redirect
export const deleteRedirect = (id: string) => {
	return axiosInstance.post('/lite/delete', { id }); // we dont need to return anything here
};

// log analytic for opening the quiiik modal.
export const logAction = (action: string) => {
	return axiosInstance.post('/lite/log', { action: action }); // we dont need to return anything here
};
// submit feedback
export const submitFeedback = (reaction: String, feedback: string) => {
	return axiosInstance.post('/lite/feedback', { reaction, feedback }).then((res) => res.data);
	// the last part of (the .this) is the response that would be used to show a success/failure message in the future if we wanted to and is optional
};
