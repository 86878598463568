import React, { useContext } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { OnboardingFlowContext } from '../context/OnboardingFlowContext';
import { Page, PageProps } from '../pages/Page';
import { PHP_ACCESS_TOKEN } from './constants';

/**
 * Show the component only when the user is logged in
 * Otherwise, redirect the user to /sign-in page
 */
interface PrivatePageRouteProps {
	children: React.ReactNode;
	path?: string | string[];
	menuItems?: any;
}

export const PrivatePageRoute: React.FC<PrivatePageRouteProps> = (props) => {
	const phpAccessToken = localStorage.getItem(PHP_ACCESS_TOKEN);
	const { route } = useContext(OnboardingFlowContext);
	const { children, path } = props;
	if (!phpAccessToken) {
		return <Redirect to={`/${route}/sign-in`} />;
	}

	if (path === '') {
		return <>{React.Children.only(props)}</>;
	} else {
		return <PageWithNavRoute {...props}>{children}</PageWithNavRoute>;
	}
};

// NOTE: This all sucks and makes my eyes bleed when I try to understand it. I'm going to try to refactor it when we have some spare time. -Jared 6/14/1998

/**
 * Renders the Route inside a <Page> component, which means the LeftNav will be available and
 * the <children> passed to the Route will be rendered inside the content panel of the app layout.
 * This is intended to be used via the <PageWithNavRoute> and <PageWithoutNavRoute> components,
 * but could be used directly if we find a need in the future.
 */
const PageRoute: React.FC<RouteProps & PageProps> = (props) => {
	const { menuItems, bottomMenuItems, hideLeftNav = false, ...routeProps } = props;
	return (
		<Page menuItems={menuItems} bottomMenuItems={bottomMenuItems} hideLeftNav={hideLeftNav}>
			<Route {...routeProps} />
		</Page>
	);
};

/**
 * Renders the Route inside a <Page> component with LeftNav visible
 */
export const PageWithNavRoute: React.FC<RouteProps & PageProps> = (props) => {
	const { hideLeftNav, ...rest } = props; // We're going to throw away the 'hideLeftNav' prop
	return <PageRoute {...rest} hideLeftNav={false} />;
};

/**
 * Renders the Route inside a <Page> component with LeftNav HIDDEN
 */
export const PageWithoutNavRoute: React.FC<RouteProps & PageProps> = (props) => {
	const { hideLeftNav, ...rest } = props; // We're going to throw away the 'hideLeftNav' prop
	return <PageRoute {...rest} hideLeftNav={true} />;
};
