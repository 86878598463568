import { ReactNode } from 'react';
import { OptionGroup } from './OptionGroup';

export type TextMenuProps = {
	children: ReactNode;
};

export const TextMenu = (props: TextMenuProps) => {
	const { children } = {
		...props,
	};

	return (
		<>
			<OptionGroup title="Actions">{children}</OptionGroup>
		</>
	);
};
