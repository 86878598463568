import { createStyles, makeStyles, Theme } from '@material-ui/core';
import Image from '@material-ui/icons/Image';
import PlayCircleFilled from '@material-ui/icons/PlayCircleFilled';
import { Box } from '@mui/material';
import React from 'react';
import { bindActionCreators } from 'redux';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { FileType } from '../../store/models';
import { _activeLanguageCode, _leftDrawerMenu } from '../../store/slices/editorSlice';
import { _sectionOrder, _selectedLocation, reorderSection } from '../../store/slices/locationSlice';
import { _sections, _selectedSection, _topicOrders, addSection, selectSection } from '../../store/slices/sectionSlice';
import { _selectedTopic, _topics, reorderTopic, selectTopic } from '../../store/slices/topicSlice';
import { ButtonOption, ElementMenu, TypographyOption } from '../ElementMenu/ElementMenu';
import { EnumTags } from '../ElementMenu/InsertTypographyOption';
import { ImageButtonIcon } from '../SvgComponents/SvgImageButton';
import { AppMenu } from './AppMenu';
import { LiiingoDrawer } from './LiiingoDrawer';
import { QrMenu } from './QrMenu';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		drawerBox: {
			display: 'flex',
			flexDirection: 'column',
			height: '100%',
		},
		contentBox: {
			display: 'flex',
			flexDirection: 'column',
			flexGrow: 1,
		},
	})
);

export type LeftDrawerProps = {
	isExpanded?: boolean;
	component?: React.ReactNode;
	highlightedImage?: boolean;
};

const ButtonOptions: ButtonOption[] = [
	{
		contentType: FileType.webview,
		buttonText: 'Button',
		hoverText: 'Button',
	},
];

const MediaOptions = (highlightedImage: boolean = false) => [
	{
		contentType: FileType.image,
		icon: Image,
		hoverText: 'Image',
		highlighted: highlightedImage,
	},
	{
		contentType: FileType.video,
		icon: PlayCircleFilled,
		hoverText: 'Video',
	},
	{
		contentType: FileType.imageBtn,
		icon: ImageButtonIcon,
		hoverText: 'Image Button',
		highlighted: false,
	},
];

const TypographyOptions: TypographyOption[] = [
	{
		displayName: 'Heading 1',
		displayVariant: 'h4',
		desc: 'Open Sans, 24px',
		tag: EnumTags['h1'],
	},
	{
		displayName: 'Heading 2',
		displayVariant: 'h5',
		desc: 'Open Sans, 20px',
		tag: EnumTags['h2'],
	},
	{
		displayName: 'Heading 3',
		displayVariant: 'subtitle1',
		desc: 'Open Sans, 16px',
		tag: EnumTags['h3'],
	},
	{
		displayName: 'Heading 4',
		displayVariant: 'subtitle2',
		desc: 'Open Sans, 14px',
		tag: EnumTags['h4'],
	},
	{
		displayName: 'Paragraph 1',
		displayVariant: 'body1',
		desc: 'Roboto, 16px',
		tag: EnumTags['p'],
	},
];

const defaultProps = {
	isExpanded: true,
};

export const LeftDrawer = (props: LeftDrawerProps) => {
	const classes = useStyles();

	const { isExpanded, component } = {
		...defaultProps,
		...props,
	};
	return (
		<LiiingoDrawer anchor="left" isExpanded={isExpanded}>
			<Box className={classes.drawerBox}>
				<Box className={classes.contentBox}>{component}</Box>
			</Box>
		</LiiingoDrawer>
	);
};

const LeftDrawerContainer = (props: LeftDrawerProps) => {
	const { highlightedImage } = { ...props };

	const dispatch = useAppDispatch();

	const actions = bindActionCreators(
		{
			addSection,
			selectSection,
			selectTopic,
			reorderSection,
			reorderTopic,
		},
		dispatch
	);

	const leftDrawerMenuOption = useAppSelector(_leftDrawerMenu);
	const sections = useAppSelector(_sections);
	const sectionOrder = useAppSelector(_sectionOrder);
	const selectedSection = useAppSelector(_selectedSection);
	const topics = useAppSelector(_topics);
	const activeLanguageCode = useAppSelector(_activeLanguageCode);
	const topicOrder = useAppSelector(_topicOrders);
	const selectedTopic = useAppSelector(_selectedTopic);
	const location = useAppSelector(_selectedLocation);

	const appMenuState = {
		language: activeLanguageCode,
		location,
		sections,
		selectedSection,
		topics,
		sectionOrder,
		topicOrder,
		selectedTopic,
	};

	let component: React.ReactNode;

	switch (leftDrawerMenuOption) {
		case 'page':
			component = <AppMenu {...actions} {...appMenuState} />;
			break;
		case 'element':
			component = (
				<ElementMenu
					mediaOptions={MediaOptions(highlightedImage)}
					typographyOptions={TypographyOptions}
					buttonOptions={ButtonOptions}
				/>
			);
			break;
		case 'qr':
			component = <QrMenu />;
	}

	const state = { component };

	return <LeftDrawer {...props} {...state} />;
};

export default LeftDrawerContainer;
