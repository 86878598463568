import axios from 'axios';
import axiosInstance from '.';
import { Invitation } from '../models';

export const createInvitation = async (invitation: Invitation) => {
	const formData = new FormData();
	formData.append(`invitation`, JSON.stringify(invitation));
	formData.append(
		'hostUrl',
		process.env.REACT_APP_CLIENT_URL + '/' + invitation.route + '/invite-accept?invitationId='
	);
	return axiosInstance.post<{ invitationId: string }>('/invitation/save', formData).then((res: any) => res.data);
};

export const verifyInvitation = async (invitationId: String) => {
	return axios
		.post<Invitation>(`${process.env.REACT_APP_LIIINGO_URL}/invitation/verify`, { invitationId: invitationId })
		.then((res: any) => res.data);
};

export const acceptInvitation = async (invitationId: string, liiingoOrgId: string) => {
	return axios
		.post<Invitation>(`${process.env.REACT_APP_LIIINGO_URL}/invitation/accept`, {
			invitationId: invitationId,
			liiingoOrgId: liiingoOrgId,
		})
		.then((res: any) => res.data);
};
