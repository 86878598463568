import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { decode } from 'jsonwebtoken';
import React from 'react';
import store from '../store/store';
import { PHP_ACCESS_TOKEN } from '../util/constants';

const initialize = () => {
	let phpToken: any = localStorage.getItem(PHP_ACCESS_TOKEN);
	phpToken = decode(phpToken);

	Bugsnag.start({
		apiKey: `${process.env.REACT_APP_BUGSNAG_API_KEY}`,
		plugins: [new BugsnagPluginReact(React)],
		releaseStage: `${process.env.REACT_APP_BUGSNAG_RELEASE_STAGE}`,
		enabledReleaseStages: ['development', 'production'], // Prevent the 'local' environment from reporting to BugSnag
		maxEvents: 100,
		maxBreadcrumbs: 100,
		onError: function (event) {
			console.log('Bugsnag event', event);
			event.setUser(phpToken?.id, phpToken?.email);
			event.addMetadata('phpToken', phpToken);
			event.addMetadata('reduxState', store.getState());
		},
	});
};

initialize();

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

export { ErrorBoundary };
