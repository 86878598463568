import Bugsnag from '@bugsnag/js';
import {
	Backdrop,
	Card,
	CardContent,
	CardHeader,
	CircularProgress,
	createStyles,
	Grid,
	makeStyles,
	Theme,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { useInterval } from '@react-hooks-library/core';
import { useEffect, useState } from 'react';
import { useQueryStringParams } from '../../hooks/useQueryStringParams';
import { useStripeState } from '../../hooks/useStripeState';
import { BlockingModal } from '../BlockingModal';
import { FlatButton } from '../Buttons';
import { liiingoSuccess } from '../LiiingoSuccessSnackbar';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		blockingModal: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'flex-start',
			padding: 10,
			width: 447,
			height: 'auto',
			background: '#FFFFFF',
			boxShadow: '0px 6px 24px rgba(24, 27, 32, 0.2)',
			borderRadius: 10,
			flex: 'none',
		},
		buttons: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'flex-end',
			gap: 20,
			width: '100%',
			marginTop: 20,
		},
	})
);

export type TimeBombProps = {};

// checks the subscription status every 20 minutes
// displays a blocking modal if the subscription is invalid or trial has expired
// not used directly in conjunction with the TrialDaysWidget
export const TimeBomb = (props: TimeBombProps) => {
	const urlParams = useQueryStringParams();
	const billingAdded = urlParams.get('billingAdded');
	const [buttonText, setButtonText] = useState('');
	const [titleText, setTitleText] = useState('');
	const [bodyText, setBodyText] = useState('');
	const [isActive, setIsActive] = useState(false); // eslint-disable-line @typescript-eslint/no-unused-vars
	const [visible, setVisible] = useState(false); // eslint-disable-line @typescript-eslint/no-unused-vars
	const classes = useStyles();
	const {
		stripeSubscription,
		stripeCustomer,
		openCustomerPortal,
		fetchStripeData,
		billingInfo,
		loadingStripeData,
		redirecting,
	} = useStripeState();

	// start heartbeat monitor for stripe subscription
	const twentyMinutes = 20 * 60 * 1000;
	useInterval(fetchStripeData, twentyMinutes, { immediate: false, paused: isActive });

	// effect for displaying success toast upon billing information being added
	useEffect(() => {
		// 'billingAdded' url param indicates we entered the stripe portal without a default payment method
		// if we have billingInfo after returning from stripe, display success toast
		if (billingAdded && billingInfo) {
			liiingoSuccess('Your billing is updated!');
		}
	}, [billingAdded, billingInfo]);

	// effect for setting the copy of the blocking modal
	useEffect(() => {
		if (!loadingStripeData) {
			if (stripeSubscription === null && stripeCustomer) {
				// fetching stripe data is not in flight, and we have a customer but no subscription, like when a subscription is manually cancelled, or ?
				setTitleText('No Subscription Found');
				setBodyText(
					'A subscription was not found for your account. Please contact support or check the Stripe Customer Portal.'
				);
				setButtonText('Manage Subscription');
				Bugsnag.notify(new Error(`No subscription found for customer ${stripeCustomer.email}`));
				setVisible(true);
			} else if (stripeSubscription?.status === 'paused') {
				//paused and no payment method added yet (trial ended w/o billing)
				setTitleText('Your Free Trial Is Over');
				setBodyText('Your free trial has ended, but you can upgrade now to continue using the Liiingo Portal.');
				setButtonText('Upgrade Now');
				setVisible(true);
			} else if (
				stripeSubscription?.status === 'incomplete' ||
				stripeSubscription?.status === 'incomplete-expired' ||
				stripeSubscription?.status === 'past_due'
			) {
				//bad billing info, expired card, etc.
				setTitleText('Update Your Billing');
				setBodyText(
					`We hope you're enjoying the Liiingo Portal. Unfortunately, we're having trouble processing your payment. Please update your billing information to continue using the Portal.`
				);
				setButtonText('Update Billing');
				setVisible(true);
			} else if (stripeSubscription?.status === 'canceled') {
				//subscription was cancelled, possibly because of unpaid invoices
				setTitleText('Your Subscription Was Canceled');
				setBodyText(`Your subscription was canceled. Click below to get up and running again.`);
				setButtonText('Reactivate Subscription');
				setVisible(true);
			} else {
				setVisible(false);
			}
		}
	}, [billingInfo, loadingStripeData, stripeCustomer, stripeSubscription]);

	return (
		<>
			{redirecting ? (
				<Backdrop style={{ color: '#fff', zIndex: 1000 }} open={redirecting} onClick={() => {}} in={true}>
					<CircularProgress thickness={5} color="inherit" />
				</Backdrop>
			) : (
				<BlockingModal open={visible} fullPage={false}>
					<Card className={classes.blockingModal}>
						<CardHeader title={titleText} />
						<CardContent>
							<Grid container>
								<Typography variant="body1">{bodyText}</Typography>
							</Grid>

							<Grid item xs={12} md={12} className={classes.buttons}>
								{/* <FlatButton role="button" type="submit" onClick={handleClick}>
                Close Account
              </FlatButton> */}

								<FlatButton
									color="primary"
									role="button"
									type="submit"
									variant="contained"
									onClick={() => openCustomerPortal()}
								>
									{buttonText}
								</FlatButton>
							</Grid>
						</CardContent>
					</Card>
				</BlockingModal>
			)}
		</>
	);
};
