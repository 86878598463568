import { ListItemSecondaryAction } from '@material-ui/core';
import InputBase from '@material-ui/core/InputBase';
import ListItem from '@material-ui/core/ListItem';
import { Theme } from '@material-ui/core/styles/createTheme';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Check from '@material-ui/icons/Check';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import { Box } from '@mui/material';
import { useHover } from 'ahooks';
import { useRef, useState } from 'react';
import { bindActionCreators } from 'redux';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { Status, Title } from '../../store/models';
import { _activeLanguageCode, _newLanguageIds, _plan, removeNewLanguageIds } from '../../store/slices/editorSlice';
import { _topics, deleteTopic, saveTopic, selectTopic } from '../../store/slices/topicSlice';
import { colors } from '../../theme/palette';
import { DISMISSED_PAGE_DELETE, STARTER_PLAN } from '../../util/constants';
import { DraggableMenuWrapper } from '../ElementMenu/DraggableMenuWrapper';
import { LiiingoSuccessSnackbar } from '../LiiingoSuccessSnackbar';
import { LiiingoTooltip } from '../LiiingoTooltip';
import LiiingoPageStateToggle from './LiiingoPageStateToggle';
import { PageDeleteDialog } from './PageDeleteDialog';
import { PageContextMenu } from './PageMenuActions';
import ShareButton from './ShareButton';

const useStyles = makeStyles<Theme, { dim: boolean }>({
	item: {
		'&.Mui-selected': {
			backgroundColor: colors.grayLight5,
		},
	},
	pageName: (props) => ({
		width: 150,
		opacity: props.dim ? 0.5 : 1,
		color: colors.pureBlack,
	}),
	pageNameEditable: (props) => ({
		width: 150,
		opacity: props.dim ? 0.5 : 1,
		borderRadius: 5,
		borderStyle: 'solid',
		borderColor: colors.blueAccent20,
		borderWidth: 0.5,
	}),
	icon: {
		paddingRight: '5px',
		paddingTop: 13,
	},
	hidden: {
		visibility: 'hidden',
	},
	secondaryActions: {
		display: 'flex',
	},
	rightTooltip: {
		textAlign: 'center',
		paddingRight: '75px',
		zIndex: 0,
	},
});

export type PageMenuItemProps = {
	locationId: string;
	status: number;
	enableSharing: boolean;
	topicId: string;
	topicLink: string;
	index: number;
	last: number;
	selected: boolean;
	displayName?: string;
	disableDelete?: boolean;
	renamePage: (topicId: string, title: Title) => void;
	moveUp: () => void;
	moveDown: () => void;
	socialSharing: () => void;
};

export const PageMenuItem = (props: PageMenuItemProps) => {
	const {
		status,
		enableSharing,
		topicId,
		topicLink,
		index,
		last,
		selected,
		disableDelete,
		renamePage,
		moveUp,
		moveDown,
		socialSharing,
	} = {
		...props,
	};
	const [showDialog, setShowDialog] = useState(false);
	const [showSecondary, setShowSecondary] = useState(false);
	const [showOptions, setShowOptions] = useState(false);
	const [readOnly, setReadOnly] = useState(true);
	const [copySuccess, setCopySuccess] = useState(false);
	const pageName = useRef(null);
	const activeLanguageCode = useAppSelector(_activeLanguageCode);
	const topics = useAppSelector(_topics);
	const topic = topics[topicId];
	const dispatch = useAppDispatch();
	const deletePage = bindActionCreators(deleteTopic, dispatch);
	const saveTopicName = bindActionCreators(saveTopic, dispatch);
	const removeLanguageId = bindActionCreators(removeNewLanguageIds, dispatch);
	const newLanguageIds = useAppSelector(_newLanguageIds);
	const plan = useAppSelector(_plan);
	const thisIsNew = newLanguageIds[activeLanguageCode]?.includes(topicId);
	// Addresses icons not hiding onMouseLeave. Still get stuck after they're clicked, but this is better
	// TODO: finish fixing this
	const secondActionRef = useRef<HTMLDivElement | null>(null);
	const isHovered = useHover(secondActionRef);
	const selectPage = bindActionCreators(selectTopic, dispatch);

	const classes = useStyles({ dim: thisIsNew });

	const copyPageLink = () => {
		navigator.clipboard.writeText(topicLink);
		setCopySuccess(true);
	};

	const editPageName = () => {
		setReadOnly(false);
		setTimeout(() => {
			pageName.current.focus();
			pageName.current.select();
		}, 1);
	};

	const handleDeletePage = () => {
		if (!localStorage.getItem(DISMISSED_PAGE_DELETE)) {
			setShowDialog(true);
		} else {
			deletePage(topicId);
		}
	};

	return (
		<DraggableMenuWrapper id={topicId} index={index} showSnapshot={false} key={topicId + '-page-menu-wrapper'}>
			<PageDeleteDialog
				open={showDialog}
				topicId={topicId}
				handleClose={() => {
					setShowDialog(false);
					setShowOptions(false);
				}}
				key={topicId + '-page-menu-item-dialog'}
			/>

			<PageContextMenu
				topicId={topicId}
				disableDelete={disableDelete}
				disableUp={index === 0}
				disableDown={last === index}
				editPageName={editPageName}
				deletePage={handleDeletePage}
				copyPageLink={copyPageLink}
				moveUp={moveUp}
				moveDown={moveDown}
				socialSharing={socialSharing}
				key={topicId + 'page-menu-context-menu'}
			>
				<ListItem
					button
					onMouseEnter={() => setShowOptions(true && plan?.name !== STARTER_PLAN)}
					onMouseLeave={() => setShowOptions(false)}
					classes={{ root: classes.item }}
					onClick={() => {
						if (!showSecondary) {
							selectPage(topicId);
						}
					}}
					selected={selected}
					key={topicId + '-page-menu-list-item'}
					component="span"
				>
					<LiiingoTooltip
						message={topic.name.find((n) => n.language === activeLanguageCode)?.name}
						placement="right"
						className={classes.rightTooltip}
					>
						<>
							<LiiingoSuccessSnackbar
								open={copySuccess}
								text="Link copied successfully"
								onClose={() => setCopySuccess(false)}
							/>
							{showOptions ? (
								<DragIndicatorIcon fontSize="medium" className={classes.icon} />
							) : selected ? (
								<Check fontSize="medium" className={classes.icon} />
							) : (
								<Check className={classes.hidden} />
							)}
							<InputBase
								placeholder="Page Name"
								onDoubleClick={editPageName}
								readOnly={readOnly}
								inputRef={pageName}
								fullWidth={false}
								className={readOnly ? classes.pageName : classes.pageNameEditable}
								inputProps={{
									style: {
										textOverflow: 'ellipsis',
										fontSize: 12,
										cursor: readOnly ? 'pointer' : 'text',
									},
								}}
								value={topic.name.find((n) => n.language === activeLanguageCode)?.name}
								onFocus={() => {
									thisIsNew && removeLanguageId({ language: activeLanguageCode, ids: [topicId] });
								}}
								onChange={(e) =>
									renamePage(topicId, { language: activeLanguageCode, name: e.target.value })
								}
								onBlur={(e) => {
									setReadOnly(true);
									saveTopicName({ topicId });
								}}
								onKeyDown={(e) => {
									if (e.key === 'Enter') {
										setReadOnly(true);
										saveTopicName({ topicId });
									}
								}}
							/>
						</>
					</LiiingoTooltip>
					{(showOptions || showSecondary || status !== Status.Live || !enableSharing) && (
						<div ref={secondActionRef}>
							<ListItemSecondaryAction
								onMouseEnter={() => setShowSecondary(true)}
								onMouseLeave={() => setShowSecondary(false)}
								key={topicId + '-page-menu-item-child-secondary'}
							>
								<Box className={classes.secondaryActions}>
									{(isHovered || showOptions || showSecondary || !enableSharing) && (
										<ShareButton initialShareStatus={enableSharing} topicId={topicId} />
									)}
									{(isHovered || showOptions || showSecondary || status !== Status.Live) && (
										<LiiingoPageStateToggle topicId={topicId} status={status} />
									)}
								</Box>
							</ListItemSecondaryAction>
						</div>
					)}
				</ListItem>
			</PageContextMenu>
		</DraggableMenuWrapper>
	);
};
