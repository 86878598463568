import Box from '@mui/material/Box';
import ButtonBase from '@material-ui/core/ButtonBase';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import InputBase from '@material-ui/core/InputBase';
import createStyles from '@material-ui/core/styles/createStyles';
import { Theme } from '@material-ui/core/styles/createTheme';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ImageIcon from '@material-ui/icons/Image';
import { Skeleton } from '@mui/material';
import { bindActionCreators } from '@reduxjs/toolkit';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import parse from 'date-fns/parse';
import { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAppDispatch } from '../../store/hooks';
import { Location, Topic } from '../../store/models';
import { changeLocationName, saveLocation } from '../../store/slices/locationSlice';
import { colors } from '../../theme/palette';
import { LiiingoTooltip } from '../LiiingoTooltip';
import { TemplatePreview } from '../TemplatePreview';
import { EditContextMenu } from './CardComponents/Menu/EditContextMenu';
import { EditMenu } from './CardComponents/Menu/EditMenu';
import { clearFocusItem } from '../../store/slices/editorSlice';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		skeletonText: {
			width: '90%',
		},
		skeleton: {
			width: '100%',
		},
		formCard: {
			backgroundColor: theme.palette.background.paper,
			width: 500,
			height: 100,
			alignItems: 'start',
			'&:hover': {
				borderStyle: 'solid',
				borderColor: colors.tealAccent,
				borderWidth: 1,
				cursor: 'pointer',
			},
		},
		appButton: {
			height: '100%',
			display: 'flex',
		},
		imageBox: {
			width: 100,
			height: 100,
			display: 'flex',
			backgroundColor: colors.grayLight5,
			color: colors.grayMedium,
			alignItems: 'center',
			justifyContent: 'center',
		},
		image: {
			width: 37.5,
			height: 37.5,
		},
		content: {
			flex: '1 0 auto',
			textAlign: 'start',
			cursor: 'pointer',
			outline: 'none',
		},
		appName: {
			fontSize: '1.5rem',
			fontWeight: 400,
			lineHeight: '2rem',
			color: colors.grayLight,
			'&:hover': {
				cursor: 'pointer',
			},
		},
		menu: {
			position: 'relative',
			top: -65,
			left: 465,
		},
		spinnerBox: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			height: 100,
		},
		arrow: {
			color: colors.grayDark,
		},
		tooltip: {
			backgroundColor: colors.grayDark,
			textAlign: 'center',
		},
	})
);

export type EditCardProps = {
	loading: boolean;
	location: Location;
	topic: Topic;
	orgName: string;
};

export const EditCard = (props: EditCardProps) => {
	const { loading, location, topic, orgName } = props;
	const classes = useStyles();
	const appName = useRef(null);
	const [readOnly, setReadOnly] = useState(true);
	const [isOverText, setIsOverText] = useState(false);
	const [isChanged, setIsChanged] = useState(false);
	const history = useHistory();

	const dispatch = useAppDispatch();
	const updateLocationName = bindActionCreators(changeLocationName, dispatch);
	const clearFocusedItem = bindActionCreators(clearFocusItem, dispatch);
	const saveAppName = bindActionCreators(saveLocation, dispatch);

	const navigateToApp = () => {
		if (location !== null && topic !== null) {
			history.push(`app-editor`);
		}
	};

	const lastUpdated = (timestamp) => {
		if (timestamp) {
			const timeframe = formatDistanceToNow(parse(timestamp, 'T', new Date()), { addSuffix: true });
			return `Last updated ${timeframe}`;
		} else {
			return <Skeleton animation="wave" variant="text" className={classes.skeletonText} />;
		}
	};

	// TODO: refactor all the onBlur, onClick, onmouseover, onmouseout, etc. too much code, too convoluted

	// e.detail = A Number, representing the number of clicks that happened in a short amount of time.
	// Note: The returned value for a ondblclick event is always "2"
	// Note: The returned value for a onmouseover or onmouseout event is always "0"
	// "renameApp" is passed to the onClick event of the appName input,
	// this makes it behave like a link on single click, or an editable field on doubleclick, I think
	let timer;
	const renameApp = (e) => {
		clearTimeout(timer);
		if (e.detail === 1 && readOnly) {
			timer = setTimeout(navigateToApp, 200);
		} else if (e.detail === 2) {
			setReadOnly(false);
			setTimeout(() => {
				appName.current.focus();
			}, 1);
		}
	};

	const AppName = (
		<InputBase
			value={location?.name}
			placeholder="Name"
			readOnly={readOnly}
			inputRef={appName}
			fullWidth={false}
			className={classes.appName}
			inputProps={{ className: classes.content }}
			defaultValue={location?.name}
			onMouseOver={(e) => {
				setIsOverText(true);
			}}
			onMouseOut={(e) => {
				setIsOverText(false);
			}}
			onClick={(e) => {
				if (isOverText) renameApp(e);
			}}
			onBlur={(e) => {
				setReadOnly(true);
				setIsChanged(false);

				if (!e?.target?.value) {
					updateLocationName('My Liingo');
				} else {
					updateLocationName(e?.target?.value);
				}

				clearFocusedItem();
				saveAppName();
			}}
			onChange={(e) => {
				updateLocationName(e?.target?.value);
			}}
			onKeyPress={(e) => {
				setIsChanged(true);
				if (e.key === 'Enter') {
					appName.current.blur();
				}
			}}
		/>
	);

	return (
		<>
			<LiiingoTooltip placement="top" message={'Click to edit.'}>
				<Card variant="outlined" className={classes.formCard}>
					<EditContextMenu navigateToApp={navigateToApp} appName={appName} setReadOnly={setReadOnly}>
						<Box className={classes.appButton}>
							<ButtonBase disabled={!readOnly} className={classes.appButton} onClick={navigateToApp}>
								<Box className={classes.imageBox}>
									{loading ? (
										<Skeleton
											animation="wave"
											height={100}
											variant="rectangular"
											className={classes.skeleton}
										/>
									) : (
										<>
											{topic?.exhibitImage ? (
												<TemplatePreview
													company={orgName}
													name={location?.name}
													previewUrl={topic?.exhibitImage}
												/>
											) : (
												<ImageIcon className={classes.image} />
											)}
										</>
									)}
								</Box>
							</ButtonBase>
							<CardHeader
								className={classes.content}
								title={AppName}
								subheader={lastUpdated(location?.modifiedDate?.$date?.$numberLong)}
								onClick={(e) => {
									if (!isOverText && !isChanged) navigateToApp();
								}}
							/>
						</Box>
					</EditContextMenu>
				</Card>
			</LiiingoTooltip>
			<Box className={classes.menu}>
				<LiiingoTooltip placement="left" message={'Options'}>
					<div>
						<EditMenu navigateToApp={navigateToApp} appName={appName} setReadOnly={setReadOnly} />
					</div>
				</LiiingoTooltip>
			</Box>
		</>
	);
};
