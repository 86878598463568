// This will hold the MUI table with the QR codes and functionality
import { LinearProgress } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Theme, createStyles, makeStyles, withStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';
import { useEffect, useState } from 'react';
import { useAppSelector } from '../../store/hooks';
import { _isCreating, _redirects } from '../../store/slices/redirectSlice';
import QRGeneratorTableIcons from './QRGeneratorTableIcons';

const useStyles = makeStyles((theme) =>
	createStyles({
		root: {
			display: 'flex',
			justifyContent: 'end',
			alignItems: 'end',
			width: '100%',
			'& > *': {
				marginTop: theme.spacing(2),
				marginBottom: theme.spacing(2),
			},
		},
		table: {
			maxWidth: '100%',
		},
		tableHeadText: {
			textTransform: 'uppercase',
		},
		tableText: {
			maxWidth: '200px',
			whiteSpace: 'nowrap',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
		},
	})
);

// This and StyledTableRow are the styling for the table and the color of the rows
const StyledTableCell = withStyles((theme: Theme) =>
	createStyles({
		head: {
			backgroundColor: '#142648',
			color: theme.palette.common.white,
			paddingRight: 5,
		},
		body: {
			fontSize: 14,
		},
	})
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
	createStyles({
		root: {
			height: 70,
			'&:nth-of-type(odd)': {
				backgroundColor: theme.palette.action.hover,
			},
		},
	})
)(TableRow);

export type TableRowData = {
	createdDate: string;
	name: string;
	visits?: { [IP: string]: number };
	url: string;
	id: string;
};

// Props
export type QRGeneratorTableProps = {};

const QRGeneratorTable = () => {
	const tableData = useAppSelector(_redirects);
	const isCreating = useAppSelector(_isCreating);
	const [data, setData] = useState<TableRowData[]>([]);
	const [emptyRows, setEmptyRows] = useState<TableRowData[]>([]); // This is to make sure that the table has at least 8 rows at all times
	const classes = useStyles();
	// User is currently on this page
	const [currentPage, setCurrentPage] = useState(1);
	// No of Redirects to be displayed on each page
	const redirectsPerPage = 7;
	const indexOfLastRedirect = currentPage * redirectsPerPage;
	const indexOfFirstRedirect = indexOfLastRedirect - redirectsPerPage;
	const currentRedirects = data?.slice(indexOfFirstRedirect, indexOfLastRedirect);
	// const nPages = Math.ceil(data.length / redirectsPerPage) || 1;
	const [nPages, setNPages] = useState(1);
	const isLastPage = currentPage === nPages;

	useEffect(() => {
		// if there is tableData then convert tableData to an array of objects and use it to set the data state
		if (tableData) {
			let tempData: TableRowData[] = Object.values(tableData);
			// sort the temp data by createdDate fom newest to oldest
			tempData.sort((a, b) => parseInt(b.createdDate) - parseInt(a.createdDate));
			setData(tempData);

			if (tempData.length > redirectsPerPage) {
				setNPages(Math.ceil(tempData.length / redirectsPerPage));
			}

			let minRows = 7; // define minimum table size
			if (isCreating) {
				minRows = 6;
			} // if the user is creating a new link then subtract one so we can add a loading bar

			// if currentRedirects.length is less than minRows add empty objects to the array until it is minRows
			if (currentRedirects?.length < minRows) {
				let emptyArray = new Array(minRows - currentRedirects.length).fill({ name: '' });
				setEmptyRows(emptyArray);
			} else {
				setEmptyRows([]);
			}
			if (isCreating) {
				setCurrentPage(1);
			}
		}
		// eslint-disable-next-line
	}, [data?.length, isCreating, tableData, isLastPage]);

	// Change Page

	// const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
	// 	setCurrentPage(value);
	// };

	return (
		<TableContainer component={Paper}>
			<Table className={classes.table} aria-label="customized table">
				<TableHead>
					<TableRow>
						<StyledTableCell className={classes.tableHeadText}>CREATED ON</StyledTableCell>
						<StyledTableCell className={classes.tableHeadText} align="left">
							Link Name
						</StyledTableCell>
						<StyledTableCell className={classes.tableHeadText} align="left">
							Link
						</StyledTableCell>
						<StyledTableCell className={classes.tableHeadText} align="left">
							Scans
						</StyledTableCell>
						<StyledTableCell className={classes.tableHeadText} align="left"></StyledTableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{/* loader row */}
					{isCreating && (
						<StyledTableRow>
							<StyledTableCell colSpan={5}>
								<LinearProgress />
							</StyledTableCell>
						</StyledTableRow>
					)}
					{/* data */}
					{currentRedirects?.map((row, index) => (
						<StyledTableRow key={row.id ? row.id : index}>
							<StyledTableCell>
								{row.createdDate && new Date(parseInt(row.createdDate)).toLocaleDateString()}
							</StyledTableCell>
							<StyledTableCell className={classes.tableText} align="left">
								{row.name}
							</StyledTableCell>
							<StyledTableCell className={classes.tableText} align="left">
								<a href={row.url} target="_blank" rel="noreferrer">
									{row.url}
								</a>
							</StyledTableCell>
							<StyledTableCell className={classes.tableText} align="left">
								{row?.visits
									? Object.values(row.visits).reduce((total, current) => total + current, 0)
									: 0}
							</StyledTableCell>
							<StyledTableCell align="left">
								{/* icons */}
								{row.id ? <QRGeneratorTableIcons rowData={row} /> : ''}
							</StyledTableCell>
						</StyledTableRow>
					))}
					{/* emoty rows */}
					{emptyRows?.map((row, index) => (
						<StyledTableRow key={index}>
							<StyledTableCell colSpan={5} />
						</StyledTableRow>
					))}
				</TableBody>
			</Table>
			<div className={classes.root}>
				<Pagination count={nPages} page={currentPage} onChange={(event, value) => setCurrentPage(value)} />
			</div>
		</TableContainer>
	);
};

export default QRGeneratorTable;
