import { OrganizationType } from './OrganizationType';
import { BSONConverter } from 'mongodb-bson-types';

export interface Organization extends ReturnType<typeof newOrganization> {}

export const newOrganization = (fetchedOrganization: Partial<OrganizationConstructorParams>) => {
	const _org = new _Organization(fetchedOrganization); //for data conversions (_id -> id, etc.)
	const newOrg = JSON.parse(JSON.stringify(_org)); // serialized-converted Section
	return newOrg;
};

interface OrganizationConstructorParams {
	id: string;
	_id: any;
	name: string;
	email: string;
	type: OrganizationType;
	parentId: string;
	parentLocationId: string;
	sponsored: boolean;
	groupLeadId: string;
	coupon: string;
	subscription_active: boolean;
}

export class _Organization {
	public id: string = '';
	public name: string = '';
	public email: string = '';
	public type: OrganizationType = OrganizationType.Subscriber;
	public parentId: string = '';
	public parentLocationId: string = '';
	public sponsored: boolean = false;
	public groupLeadId: string = '';
	public coupon: string = '';
	public subscription_active: boolean = false;

	constructor(data?: Partial<OrganizationConstructorParams>) {
		if (!!data) {
			if (data._id) {
				this.id = BSONConverter.objectId(data._id);
			}
			this.id = data.id || this.id;
			this.name = data.name || this.name;
			this.email = data.email || this.email;
			this.type = data.type || this.type;
			this.parentId = data.parentId || this.parentId;
			this.parentLocationId = data.parentLocationId || this.parentLocationId;
			this.sponsored = data.sponsored || this.sponsored;
			this.groupLeadId = data.groupLeadId || this.groupLeadId;
			this.coupon = data.coupon || this.coupon;
			this.subscription_active = data.subscription_active || this.subscription_active;
		}
	}
}
