import axiosInstance from '../../store/api-client';

const ImageUploaderPlugin = (editor) => {
	// Declare a variable to store the file input element
	let imageFileInput;

	// Register a custom icon for the file upload button
	editor.ui.registry.addIcon(
		'image-upload-icon',
		`<svg width="24px" height="24px" viewBox="0 0 1024 1024" class="icon"  version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M77.312 286.208h503.808v559.104H77.312z" fill="#000" /><path d="M133.632 342.016h391.68v335.36H133.632z" fill="#FFFFFF" /><path d="M189.44 621.568h93.184L236.032 537.6zM375.808 453.632l-93.184 167.936h186.88z" fill="#000" /><path d="M637.44 621.568v83.456l337.408-165.376-211.456-432.64-252.928 122.88h110.08l120.32-58.368 127.488 259.584-230.912 113.152z" fill="#000" /></svg>`
	);

	// Add a custom button to the editor's toolbar
	editor.ui.registry.addButton('insertimage', {
		icon: 'image-upload-icon',
		tooltip: 'Insert Image',
		onAction: showUploadDialog,
	});

	// Function to display the upload dialog
	function showUploadDialog() {
		let selectedFile = null;
		let dropArea = null;
		let customWidth = null;
		let customHeight = null;
		let customAltText = null;

		// Configure the dialog
		const dialogConfig = {
			title: 'Insert Image',
			body: {
				type: 'panel',
				items: [
					{
						type: 'htmlpanel',
						html: `
                        <div class="file-upload-dialog" style="padding: 20px; background: #fff;">
                            <div id="drop-area" style="border: 2px dashed #ccc; border-radius: 20px; width: 100%; min-height: 200px; display: flex; align-items: center; justify-content: center; flex-direction: column; cursor: pointer;">
                                <p>Drag & Drop files here or click to select</p>
                                <div id="preview" style="width: 100%; height: 150px; margin-top: 10px; display: flex; justify-content: center; align-items: center; overflow: hidden;"></div>
                            </div>
                            <div class="form-group" style="margin-top: 20px;">
                                <label for="alt-description" style="display: block; margin-bottom: 8px; color: #666;">Alternative description</label>
                                <input type="text" id="insertimageAltDescription" style="width: 100%; padding: 8px; border: 1px solid #ddd; border-radius: 4px; box-sizing: border-box;">
                            </div>

                            <div   class="dimensions" style="display: flex; gap: 20px; margin-top: 20px;">
                                <div class="form-group" style="flex: 1;">
                                    <label style="display: block; margin-bottom: 8px; color: #666;">Width</label>
                                    <input id="insertimageWidth" type="number" style="width: 100%; padding: 8px; border: 1px solid #ddd; border-radius: 4px; box-sizing: border-box;">
                                </div>
                                <div class="form-group" style="flex: 1;">
                                    <label style="display: block; margin-bottom: 8px; color: #666;">Height</label>
                                    <div style="position: relative;">
                                        <input id="insertimageHeight" type="number" style="width: 100%; padding: 8px; border: 1px solid #ddd; border-radius: 4px; box-sizing: border-box;">
                                    </div>
                                </div>
                            </div>
                        `,
					},
				],
			},
			buttons: [
				{
					type: 'cancel',
					text: 'Cancel',
				},
				{
					type: 'submit',
					text: 'Upload',
					primary: true,
				},
			],
			onSubmit: (api) => {
				if (selectedFile) {
					handleImageFileUpload(
						selectedFile,
						customWidth.value !== '' ? customWidth.value : '100%',
						customHeight.value,
						customAltText.value
					);
					api.close();
				} else {
					editor.notificationManager.open({
						text: 'Please select a file to upload',
						type: 'warning',
					});
				}
			},
			onClose: () => {
				if (dropArea) {
					dropArea.removeEventListener('dragover', handleDragOver);
					dropArea.removeEventListener('drop', handleDrop);
					dropArea.removeEventListener('click', handleClick);
				}
			},
		};

		// Open the dialog
		editor.windowManager.open(dialogConfig);

		// Set up event listeners for the drop area
		setTimeout(() => {
			dropArea = document.getElementById('drop-area');
			if (dropArea) {
				dropArea.addEventListener('dragover', handleDragOver);
				dropArea.addEventListener('drop', handleDrop);
				dropArea.addEventListener('click', handleClick);
			}
		}, 100);

		// Event handler functions
		function handleDragOver(e) {
			e.preventDefault();
			e.stopPropagation();
		}

		function handleDrop(e) {
			e.preventDefault();
			e.stopPropagation();
			const files = e.dataTransfer.files;
			if (files.length) {
				handleFiles(files);
			}
		}

		function handleClick() {
			imageFileInput.click();
		}

		function handleFiles(files) {
			selectedFile = files[0];
			updatePreview(selectedFile);
		}

		// Function to update the preview of the selected file
		function updatePreview(file) {
			customWidth = document.getElementById('insertimageWidth') as HTMLInputElement;
			customHeight = document.getElementById('insertimageHeight') as HTMLInputElement;
			customAltText = document.getElementById('insertimageAltDescription') as HTMLInputElement;
			const preview = document.getElementById('preview');
			if (preview) {
				preview.innerHTML = '';
				if (file.type.startsWith('image/')) {
					const img = document.createElement('img');
					img.style.maxWidth = '100%';
					img.style.maxHeight = '100%';
					img.style.objectFit = 'cover';
					img.onload = () => {
						customWidth.value = img.naturalWidth;
						customHeight.value = img.naturalHeight;
						customAltText.value = file.name;
					};
					preview.appendChild(img);

					const reader = new FileReader();
					reader.onload = (e) => {
						if (typeof e.target.result === 'string') {
							img.src = e.target.result;
						} else {
							editor.notificationManager.open({
								text: 'Unexpected result type from FileReader',
								type: 'error',
							});
						}
					};
					reader.readAsDataURL(file);
				}
			}
		}

		// Set up file input change event
		imageFileInput.onchange = (event) => {
			const imgFiles = event.target.files;
			if (imgFiles.length) {
				handleFiles(imgFiles);
			}
		};
	}

	async function handleImageFileUpload(file, width, height, altText) {
		try {
			const formData = new FormData();
			formData.append('file', file);
			const response = await axiosInstance.post('/content/uploadFile', formData);

			if (response.status === 200) {
				const { url, name } = response.data;
				const fileExtension = name.split('.').pop().toLowerCase();

				let content = '';
				if (['jpg', 'jpeg', 'png', 'gif', 'svg'].includes(fileExtension)) {
					content = `<img src="${url}" style="width: ${width !== '100%' ? width + 'px' : width}; ${
						width !== '100%' ? ' height:' + height + 'px;' : ''
					} ${width === '100%' ? 'object-fit: contain' : ''}" alt="${altText.trim() || name}" />`;
				}

				editor.insertContent(content);
				editor.notificationManager.open({
					text: 'Image uploaded successfully',
					type: 'success',
				});
			} else {
				throw new Error('Image Upload failed');
			}
		} catch (error) {
			editor.notificationManager.open({
				text: 'Error uploading Image',
				type: 'error',
			});
		}
	}

	// Initialize the file input element when the editor is ready
	editor.on('init', () => {
		imageFileInput = document.createElement('input');
		imageFileInput.type = 'file';
		imageFileInput.style.display = 'none';
		imageFileInput.accept = 'image/*';
		editor.getContainer().appendChild(imageFileInput);
	});

	// Return metadata for the plugin
	return {
		getMetadata: () => ({
			name: 'Image Upload Plugin',
			url: 'https://liiingo.com/plugins/image-upload-plugin',
		}),
	};
};

export default ImageUploaderPlugin;
