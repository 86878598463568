import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import createStyles from '@material-ui/core/styles/createStyles';
import { Theme } from '@material-ui/core/styles/createTheme';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import React, { useState } from 'react';
// import { bindActionCreators } from 'redux';
// import { useAppDispatch, useAppSelector } from '../../store/hooks';
// import { removeNotification, _notifications } from '../../store/slices/notificationSlice';
import { FlatButton } from '../Buttons/FlatButton';
import { NotifyEditor } from '../Dashboard/NotifyEditor/NotifyEditor';
// import { NotificationsList } from '../NotificationsList';
import { CardTitle } from './CardComponents/CardTitle';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		formCard: {
			marginBottom: 20,
			width: 500,
			height: 'auto',
			backgroundColor: theme.palette.background.paper,
		},
		titleBox: {
			display: 'flex',
			alignItems: 'center',
		},
		actionsContainer: {
			display: 'flex',
			flexDirection: 'row-reverse',
			textAlign: 'right',
		},
		formActionButton: {
			height: 32,
			width: 175,
			marginLeft: 8,
			whiteSpace: 'nowrap',
		},
	})
);

export type NotifyCardProps = {
	setSnackBarSuccessMessage: (options: string) => void;
};

export const NotifyCard = (props: NotifyCardProps) => {
	// const { setSnackBarSuccessMessage } = props;
	const [openEditor, setOpenEditor] = useState(false);
	const classes = useStyles();
	// const dispatch = useAppDispatch();
	// const deletePushNotification = bindActionCreators(removeNotification, dispatch);
	// const notifications = useAppSelector(_notifications);

	// const deleteNotification = async (id: string) => {
	//   deletePushNotification(id);
	//   setSnackBarSuccessMessage(`Notification Successfully Canceled`); //lies
	// };

	const handleOpen = () => {
		setOpenEditor(true);
	};

	return (
		<Card variant="outlined" className={classes.formCard}>
			<CardContent>
				<CardTitle title="Notify Your Audience" />
				<Typography variant="body2" paragraph>
					Use push notifications to generate business and to re-engage your base by sending announcements or
					updates.
				</Typography>
				<Grid container spacing={2} className={classes.actionsContainer}>
					<Grid item xs={6}>
						<FlatButton
							variant="contained"
							onClick={handleOpen}
							color="secondary"
							className={classes.formActionButton}
						>
							Create Notification
						</FlatButton>
						<NotifyEditor open={openEditor} setOpen={(open: boolean) => setOpenEditor(open)} />
					</Grid>
				</Grid>
			</CardContent>

			{/* <CardContent>
        <Typography variant="h5">Scheduled Notifications</Typography>
        <NotificationsList notifications={notifications} removeNotification={deleteNotification} />
      </CardContent> */}
		</Card>
	);
};
