import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { OnboardingFlowContext } from '../context/OnboardingFlowContext';

export const useChangeRoute = (path) => {
	const history = useHistory();
	const { route } = useContext(OnboardingFlowContext);

	const changeRoute: () => void = async () => {
		history.push(`/${route}/${path}`);
	};

	return changeRoute;
};
