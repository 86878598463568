import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Typography from '@material-ui/core/Typography';
import React, { useState } from 'react';
import { phoneNumberPattern, smsNumberPattern } from '../../../../util/patternValidators';
import { LinkInput } from './LinkInput';

const prepareInitialValue = (prepended: string, value: string) => {
	return value.split(`${prepended}:`).pop();
};

export type PhoneScheme = 'tel' | 'sms';

export type PhoneOption = {
	scheme: PhoneScheme;
	number: string;
};

export type PhoneProps = {
	value?: string;
	scheme: PhoneScheme;
	onLinkChange: (number: string, scheme: PhoneScheme) => void;
	setButtonLinkInvalid: (invalid: boolean) => void;
};

export const Phone = (props: PhoneProps) => {
	const { value, scheme, onLinkChange, setButtonLinkInvalid } = { ...props };
	const preparedValue = prepareInitialValue(scheme, value);
	const [text, setText] = useState(preparedValue);
	const [selectedScheme, setSelectedScheme] = useState(scheme);
	const [errorState, setErrorState] = useState(false);

	// when the text changes, we need to update the state
	const handleChange = (e) => {
		const scheme = e.target.value;
		let phoneRegex = new RegExp(phoneNumberPattern);
		let smsRegex = new RegExp(smsNumberPattern);

		// depending on the scheme, we need to change the regex
		if (scheme === 'tel') {
			// if the text is a valid phone number, then we can set the button to be valid
			if (phoneRegex.test(text) === true) {
				setButtonLinkInvalid(false);
				// remove all spaces from the text
				let trimmed = text.split(' ').join('');
				// call the onLinkChange function from the parent component
				onLinkChange(`${scheme}:${trimmed}`, scheme);
				//set the error state to false so that the text field is not red
				setErrorState(false);
			} else {
				// otherwise, we need to set the button to be invalid
				setButtonLinkInvalid(true);
				//set the error state to true so that the text field will be red
				setErrorState(true);
			}
		} else if (scheme === 'sms') {
			// if the text is a valid sms number, then we can set the button to be valid
			if (smsRegex.test(text) === true) {
				setButtonLinkInvalid(false);
				// remove all spaces from the text
				let trimmed = text.split(' ').join('');
				// call the onLinkChange function from the parent component
				onLinkChange(`${scheme}:${trimmed}`, scheme);
				//set the error state to false so that the text field is not red
				setErrorState(false);
			} else {
				// otherwise, we need to set the button to be invalid
				setButtonLinkInvalid(true);
				//set the error state to true so that the text field will be red
				setErrorState(true);
			}
		}
		// set the scheme to match the clicked radio button
		setSelectedScheme(scheme);
	};

	return (
		<>
			<RadioGroup row>
				<FormControlLabel
					key={'tel'}
					control={
						<Radio color="primary" checked={selectedScheme === 'tel'} onChange={handleChange} value="tel" />
					}
					label={<Typography variant="body2">Call</Typography>}
					labelPlacement="end"
				/>
				<FormControlLabel
					key={'sms'}
					control={
						<Radio color="primary" checked={selectedScheme === 'sms'} onChange={handleChange} value="sms" />
					}
					label={<Typography variant="body2">Text</Typography>}
					labelPlacement="end"
				/>
			</RadioGroup>
			<LinkInput
				value={text}
				phoneScheme={selectedScheme}
				pattern={selectedScheme === 'tel' ? phoneNumberPattern : smsNumberPattern}
				label="(123) 456-7890"
				title="Phone Number"
				proc={`${selectedScheme}:`}
				errorMsg="Please input a valid phone number"
				onLinkChange={onLinkChange}
				onTextChange={setText}
				setButtonLinkInvalid={setButtonLinkInvalid}
				errorState={errorState}
				setErrorState={setErrorState}
			/>
		</>
	);
};
