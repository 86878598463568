import { makeStyles } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { Content } from '../../store/models';
import { toString } from '../../store/models/file/FileType';
import { removeNewLanguageIds, _activeLanguageCode, _newLanguageIds } from '../../store/slices/editorSlice';
import { EditButton } from './EditButton';
import { EditImage } from './EditImage';
import { EditImageButton } from './EditImageButton';
import { EditText } from './EditText';
import { EditVideo } from './EditVideo';
import { Focusable, FocusableProps } from './Focusable';

const useStyles = makeStyles((theme) => ({
	new: {
		// border: '1px solid red',
		opacity: 0.5,
	},
}));
export interface EditorFieldProps {
	content: Content;
	isFocused: boolean;
	newElement?: boolean; //TODO: I don't believe this is still used
}

export const EditorField = ({ content, isFocused, newElement }: EditorFieldProps) => {
	const [translation, setTranslation] = useState(null);
	const activeLanguageCode = useAppSelector(_activeLanguageCode);

	useEffect(() => {
		setTranslation(content?.languages.find((item) => item.language === activeLanguageCode));
	}, [activeLanguageCode, content, setTranslation]);

	if (content.type === 'text') {
		return <EditText {...translation} isFocused={isFocused} newElement={newElement} contentId={content._id} />;
	} else if (content.type === 'image') {
		return <EditImage src={translation?.fileUrl} contentId={content._id} />;
	} else if (content.type === 'imageBtn') {
		return <EditImageButton src={translation?.fileUrl} contentId={content._id} />;
	} else if (content.type === 'webview') {
		return translation?.fileUrl ? (
			<EditImageButton src={translation?.fileUrl} contentId={content._id} />
		) : (
			<EditButton isFocused={isFocused} contentId={content._id} />
		);
	} else if (content.type === 'video') {
		return <EditVideo contentId={content._id} />;
	} else {
		return null;
	}
};

export const FocusableEditorField = (props: Omit<FocusableProps, 'label'> & EditorFieldProps) => {
	const { isFocused, newElement, ...rest } = props;
	const { content } = rest;
	const activeLanguageCode = useAppSelector(_activeLanguageCode);

	const classes = useStyles();

	let badgeLabel = toString(content.type);
	const newLanguageIds = useAppSelector(_newLanguageIds);
	const dispatch = useAppDispatch();
	const removeNewLangIds = bindActionCreators(removeNewLanguageIds, dispatch);
	const isNewTranslation = newLanguageIds[activeLanguageCode]?.includes(content._id);
	const [translation, setTranslation] = useState(null);

	useEffect(() => {
		setTranslation(content?.languages.find((item) => item.language === activeLanguageCode));
	}, [activeLanguageCode, content, setTranslation]);

	if (translation?.fileUrl && badgeLabel === 'Button') {
		badgeLabel = 'Image Button';
	}

	return (
		// Dimming was broken so I moved the opacity to this parent div. Then I discovered some places that use the focusable element without this wrapper
		// So i left the dimming in the focusable component too. It works for those cases, but not here. Spent too much time messing witht his so leaving it as is for now.
		<div className={`${isNewTranslation ? classes.new : ''}`}>
			<Focusable
				id={content._id}
				onFocus={() => {
					isNewTranslation && removeNewLangIds({ language: activeLanguageCode, ids: [content._id] });
				}}
				isFocused={isFocused}
				label={badgeLabel}
				// dim={isNewTranslation}
			>
				<EditorField {...rest} isFocused={isFocused} newElement={newElement} />
			</Focusable>
		</div>
	);
};
