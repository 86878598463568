import ListItem from '@material-ui/core/ListItem';
import { useVideoOptionField } from '../../hooks/useVideoUploadField';
import { VIDEO_CONTENT } from '../../util/constants';

export type DroppableVideoProps = {
	isDrag?: boolean;
	isOver?: boolean;
	videoUrl?: string;
	setVideo: (file: File) => void;
	videoType?: string;
	isValidType?: boolean;
};

export const DroppableVideo = (props: DroppableVideoProps) => {
	const { videoUrl, isDrag, setVideo, isOver, isValidType } = { ...props };

	const [VideoOptionField, videoFieldProps] = useVideoOptionField({
		fieldName: `video`,
		initialValue: videoUrl,
		setVideo: setVideo,
		videoType: VIDEO_CONTENT,
		isDrag: isDrag,
		isOver: isOver,
		isValidType: isValidType,
	});

	return (
		<ListItem key={`video_${videoUrl}`} disableGutters={true}>
			<VideoOptionField {...videoFieldProps} />
		</ListItem>
	);
};
