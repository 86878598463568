import Box from '@mui/material/Box';
import createStyles from '@material-ui/core/styles/createStyles';
import { Theme } from '@material-ui/core/styles/createTheme';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import { bindActionCreators } from '@reduxjs/toolkit';
import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { Language } from '../../store/models';
import {
	setNewDefaultLanguage,
	setNewSupportedLanguages,
	_newDefaultLanguage,
	_newSupportedLanguages,
} from '../../store/slices/editorSlice';
import { _defaultLanguage, _supportedLanguages } from '../../store/slices/locationSlice';
import { MultilanguageList } from './MLList';
import { MultilanguageSearchBar } from './MLSearchBar';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		message: {
			marginBottom: 10,
		},
		box: {
			marginTop: 40,
			display: 'flex',
			flexDirection: 'column',
			height: '100%',
		},
	})
);

// Disclaimer: this used to modify the Location object directly (supportedLanguages, defaultLanguage) and then store a copy od the location  to restore if the user decided to back out.
// This wasn't fitting well with the new architecture, so now I store the languages to be added/removed, and any change to the default language, in temp varaiables in the editorSlice.
// When the user clicks "save", these variables are referenced in order to determine which actions to take in Redux.
// addLanguages, removeLanguages, and newDefaultLanguage are the variables. They contain language codes ('en', 'es', 'fr'...).

export const MultilanguageSearch = () => {
	const classes = useStyles();
	const dispatch = useAppDispatch();
	const locationSupportedLanguages = useAppSelector(_supportedLanguages);
	const locationDefaultLanguage = useAppSelector(_defaultLanguage);
	const primary = useAppSelector(_newDefaultLanguage);
	const setPrimary = bindActionCreators(setNewDefaultLanguage, dispatch);
	const supported = useAppSelector(_newSupportedLanguages);
	const setSupported = bindActionCreators(setNewSupportedLanguages, dispatch);

	useEffect(() => {
		// this is meant to only run once, when initially rendered and these temp variables are both null
		if (primary === null && supported.length === 0) {
			setSupported(locationSupportedLanguages);
			setPrimary(locationDefaultLanguage);
		}
	}, [locationDefaultLanguage, locationSupportedLanguages, primary, setPrimary, setSupported, supported.length]);

	const onRemoveLanguage = (language: Language) => {
		const newSupported = supported.filter((code) => code !== language);
		setSupported(newSupported);
	};

	const onAddLanguage = (language: Language) => {
		const newSupported = [...supported, language];
		setSupported(newSupported);
	};

	return (
		<Box className={classes.box}>
			<Typography variant="body2">MY LANGUAGES</Typography>
			<Typography className={classes.message} variant="body2">
				Add the languages you would like to display your content in.
			</Typography>
			<MultilanguageSearchBar
				supported={supported}
				addLanguage={onAddLanguage}
				removeLanguage={onRemoveLanguage}
			/>
			<MultilanguageList
				primary={primary}
				supported={supported}
				setSupported={setSupported}
				removeLanguage={onRemoveLanguage}
				changePrimary={setPrimary}
			/>
		</Box>
	);
};
