import { Box, createStyles, Typography } from '@material-ui/core';
import makeStyles from '@material-ui/styles/makeStyles';
import { useEffect, useState } from 'react';
import Tour from 'reactour';
import { colors } from '../../theme/palette';
import { SvgTour } from '../SvgComponents/SvgTour';
import { LiiingoWalkthroughArrow } from './LiiingoWalkthroughArrow';
import { LiiingoWalkthroughStep } from './Steps';

const DEFAULT_WIDTH = 400;

const useStyles = makeStyles(() =>
	createStyles({
		tour: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'flex-end',
		},
		title: {
			fontWeight: 'bold',
		},
		content: {
			display: 'flex',
			width: '100%',
			justifyContent: 'space-between',
			alignItems: 'center',
		},
		textContainer: {
			marginLeft: 10,
		},
		svg: {
			transform: 'scale(2.5)',
			marginRight: 20,
		},
		next: {
			border: 'none',
			color: colors.purpleAccent,
			padding: '5px 10px 5px 10px',
			borderRadius: 10,
			'&:hover': {
				backgroundColor: colors.purpleAccent20,
			},
			'&:active': {
				color: colors.pureWhite,
				backgroundColor: colors.purpleAccent,
			},
		},
		skip: {
			border: 'none',
			color: colors.grayLight,
			padding: '5px 10px 5px 10px',
			borderRadius: 10,
			'&:hover': {
				backgroundColor: colors.purpleAccent20,
			},
			'&:active': {
				color: colors.pureWhite,
				backgroundColor: colors.purpleAccent,
			},
		},
	})
);

export type LiiingoWalkthroughProps = {
	local: string;
	open: boolean;
	steps: LiiingoWalkthroughStep[];
	stepKey: string;
	onClose?: () => void;
};

const defaultProps = {
	onClose: () => {},
};

export const LiiingoWalkthrough = (props: LiiingoWalkthroughProps) => {
	const { local, open, steps, stepKey, onClose } = { ...defaultProps, ...props };
	const [isOpen, setIsOpen] = useState(open);
	const [stepNumber, setStepNumber] = useState(0);
	const classes = useStyles();

	useEffect(() => {
		localStorage.setItem(local, 'hide');
	}, [local]);

	useEffect(
		() =>
			setStepNumber(
				steps.map((step) => step.key).indexOf(stepKey) >= 0 ? steps.map((step) => step.key).indexOf(stepKey) : 0
			),
		[stepKey, steps]
	);

	useEffect(() => setIsOpen(open), [open]);

	const handleClose = () => {
		onClose();
		localStorage.setItem(local, 'hide');
		setIsOpen(false);
	};

	const preparedSteps = steps.map((step, index) => ({
		selector: step.selector,
		content: (
			<>
				{step.arrowPosition && (
					<LiiingoWalkthroughArrow position={step.arrowPosition} placement={step.arrowPlacement} />
				)}
				<Box className={classes.content}>
					<SvgTour fontSize="large" className={classes.svg} />
					<Box className={classes.textContainer}>
						<Typography variant="body2" className={classes.title}>
							{step.title}
						</Typography>
						<Typography variant="caption">{step.message}</Typography>
					</Box>
				</Box>
			</>
		),
		position: step.position,
		style: {
			minWidth: step.width ?? DEFAULT_WIDTH,
			maxWidth: step.width ?? DEFAULT_WIDTH,
		},
	}));

	const buttonText = steps.map((step) => step.buttonText);
	const disableInteraction = steps.map((step) => step?.disableInteraction);
	const destination = steps.map((step, index) => step?.skipTo ?? index + 1);

	return (
		<Tour
			className={classes.tour}
			closeWithMask={false}
			steps={preparedSteps}
			disableFocusLock={true}
			showNumber={false}
			showNavigation={false}
			isOpen={isOpen}
			onRequestClose={handleClose}
			rounded={4}
			goToStep={stepNumber}
			disableKeyboardNavigation={true}
			disableInteraction={disableInteraction[stepNumber]}
			prevButton={<></>}
			nextButton={
				<Typography
					className={steps[stepNumber]?.skipTo ? classes.skip : classes.next}
					onClick={() => setStepNumber(destination[stepNumber])}
				>
					{buttonText[stepNumber]}
				</Typography>
			}
			lastStepNextButton={
				<Typography className={classes.next} onClick={() => setStepNumber(stepNumber + 1)}>
					{buttonText[stepNumber]}
				</Typography>
			}
		/>
	);
};
