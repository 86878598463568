import List from '@material-ui/core/List';
import { Theme } from '@material-ui/core/styles/createTheme';

import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import React from 'react';
import { MultilanguageListItem } from './MLListItem';
import { MLContextMenu } from './MLContextMenu';
import { Language } from '../../store/models';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		list: {
			//height: 368,
			display: 'flex',
			flexDirection: 'column',
			//height: '100%',
			flexGrow: 1,
			overflow: 'auto',
		},
	})
);

export type MultilanguageListProps = {
	primary: Language;
	supported: Language[];
	setSupported: (supported: Language[]) => void;
	removeLanguage: (language: Language) => void;
	changePrimary: (language: Language) => void;
};

export const MultilanguageList = (props: MultilanguageListProps) => {
	const { primary, supported, setSupported, changePrimary, removeLanguage } = { ...props };
	const classes = useStyles();

	return (
		<>
			<List className={classes.list}>
				{supported.map((code, index) => (
					<MLContextMenu
						index={index.toString()}
						primary={code === primary}
						language={code}
						supported={supported}
						setSupported={setSupported}
						removeLanguage={removeLanguage}
						changePrimary={changePrimary}
						key={code + index + 'mlcontextmenu'}
					>
						<MultilanguageListItem
							primary={code === primary}
							supported={supported}
							setSupported={setSupported}
							language={code}
							count={supported.length}
							index={index}
							removeLanguage={removeLanguage}
							changePrimary={changePrimary}
							key={code + index + 'multilanguagelistitem'}
						/>
					</MLContextMenu>
				))}
			</List>
		</>
	);
};
