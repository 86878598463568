import React, { useEffect } from 'react';
import Container from '@material-ui/core/Container';
import { useAuth } from '../hooks/useAuth';

const SignOut = () => {
	const { logout } = useAuth();
	useEffect(logout);
	return <Container>You have been successfully logged out</Container>;
};

export default SignOut;
