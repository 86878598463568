import { makeStyles } from '@material-ui/core';
import { createStyles } from '@material-ui/styles';
import { Theme } from 'tinymce';
import { Keyframes } from '../../theme/Keyframes';
import { useHydrateStore } from '../../hooks/useHydrateStore';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		//adding a brief spinner to the page while it loads
		loader: {
			content: "''",
			padding: '150px',
			width: '48px',
			height: '48px',
			borderRadius: '50%',
			display: 'inline-block',
			borderTop: '4px solid #D0D4DA',
			borderRight: '4px solid transparent',
			boxSizing: 'border-box',
			animation: 'rotation 1s linear infinite',
			'&:after': {
				content: "''",
				padding: '150px',
				boxSizing: 'border-box',
				position: 'absolute',
				left: 0,
				top: 0,
				width: '48px',
				height: '48px',
				borderRadius: '50%',
				borderLeft: '4px solid #181B20',
				borderBottom: '4px solid transparent',
				animation: 'rotation 0.5s linear infinite reverse',
			},
		},
	})
);

const LoadingComponent = () => {
	useHydrateStore();

	const classes = useStyles();
	return (
		<>
			<Keyframes name="rotation" _0={{ transform: 'rotate(0deg)' }} _100={{ transform: 'rotate(360deg)' }} />
			<span style={{ color: '#181B20BB', margin: '0px', position: 'absolute', left: '50vw', top: '20vh' }}>
				please wait...
				<span style={{ position: 'relative', left: -200, top: 150 }} className={classes.loader}></span>
			</span>
		</>
	);
};

export default LoadingComponent;
