/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, CSSProperties, useCallback } from 'react';
import axiosInstance from '../../../store/api-client';

interface QRCodeSettings {
	type: string;
	url?: string;
	text?: string;
	email?: string;
	email_subject?: string;
	email_body?: string;
	phone?: string;
	sms?: string;
	sms_body?: string;
	wifi?: {
		ssid: string;
		encryption: string;
		password: string;
	};
	vcard?: {
		vcard_first_name: string;
		vcard_last_name: string;
		vcard_organization: string;
		vcard_title: string;
		vcard_email: string;
		vcard_phone: string;
		vcard_website: string;
		vcard_address: string;
	};
	whatsapp?: string;
	whatsapp_message?: string;
	facetime?: string;
	location?: {
		location_latitude: string;
		location_longitude: string;
	};
	event?: {
		event: string;
		event_location: string;
		event_start_date: string;
		event_end_date: string;
		event_url: string;
		event_note: string;
	};
	crypto?: {
		crypto_type: string;
		crypto_address: string;
		crypto_amount?: string;
	};
	paypal?: {
		paypal_email: string;
		paypal_amount?: string;
		paypal_currency?: string;
		paypal_description?: string;
	};
	upi?: {
		upi_payee_id: string;
		upi_payee_name: string;
		upi_amount?: string;
		upi_note?: string;
	};
	epc?: {
		name: string;
		iban: string;
		amount?: string;
		reference?: string;
	};
	style: {
		style: 'square' | 'rounded' | 'dots';
		inner_eye_style: 'square' | 'circle' | 'rounded';
		outer_eye_style: 'square' | 'circle' | 'rounded';
		foreground_type: 'color' | 'gradient';
		foreground_color: string;
		foreground_gradient_style: 'vertical' | 'horizontal' | 'diagonal' | 'radial';
		foreground_gradient_one: string;
		foreground_gradient_two: string;
		background_color: string;
		background_color_transparency: number;
		custom_eyes_color: string;
		eyes_inner_color: string;
		eyes_outer_color: string;
		qr_code_logo_size?: number;
		qr_code_background_transparency?: number;
		frame?: string;
		frame_text?: string;
		frame_text_size?: number;
		frame_text_font?: 'times_new_roman' | 'arial' | 'verdana' | 'helvetica';
		frame_custom_colors?: boolean;
		frame_color?: string;
		frame_text_color?: string;
		size: number;
		margin: number;
		ecc: 'L' | 'M' | 'Q' | 'H';
		qr_code_background_activate: boolean;
		qr_code_logo_activate: boolean;
	};
}

type FlattenedObject = { [key: string]: string | number | boolean };

const QRCodeCreatorCustom: React.FC<{ setQrImage: (qrImage: string) => void }> = ({ setQrImage }) => {
	const [settings, setSettings] = useState<QRCodeSettings>({
		type: 'url',
		style: {
			style: 'square',
			inner_eye_style: 'square',
			outer_eye_style: 'square',
			foreground_type: 'color',
			foreground_color: '#000000',
			foreground_gradient_style: 'vertical',
			foreground_gradient_one: '#000000',
			foreground_gradient_two: '#FFFFFF',
			background_color: '#FFFFFF',
			background_color_transparency: 0,
			custom_eyes_color: 'not active',
			eyes_inner_color: '#000000',
			eyes_outer_color: '#000000',
			qr_code_logo_size: 25,
			qr_code_background_transparency: 0,
			size: 500,
			margin: 0,
			ecc: 'L',
			qr_code_background_activate: false,
			qr_code_logo_activate: false,
		},
	});
	const [qrCodeImage, setQRCodeImage] = useState('');
	const [openSnackbar, setOpenSnackbar] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState('');

	function flattenObject(obj: any): FlattenedObject {
		return Object.keys(obj).reduce((acc: FlattenedObject, k: string) => {
			// const pre = prefix.length ? `${prefix}.` : "";, prefix = ""
			const pre = '';
			if (typeof obj[k] === 'object' && obj[k] !== null && !Array.isArray(obj[k])) {
				Object.assign(acc, flattenObject(obj[k]));
			} else {
				acc[pre + k] = obj[k];
			}
			return acc;
		}, {});
	}

	const handleChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
		const { name, value } = event.target;

		if (name === 'type') {
			// If the type is changing, reset all settings except for style
			setSettings((prevSettings) => ({
				type: value as string,
				style: prevSettings.style,
				[value as string]: '',
			}));
		} else {
			// For all other changes, update normally
			setSettings((prevSettings) => ({
				...prevSettings,
				[name as string]: value,
			}));
		}
	};

	const handleNestedChange = (nestedField: string, event: any) => {
		const { name, value } = event.target;

		setSettings((prevSettings) => ({
			...prevSettings,
			[nestedField]: {
				...(prevSettings[nestedField] as object),
				[name]: value,
			},
		}));
	};

	const renderTypeSpecificFields = () => {
		const formStyles = {
			formControl: {
				marginBottom: '1.5rem',
				width: '100%',
			},
			label: {
				display: 'block',
				marginBottom: '0.5rem',
				fontSize: '0.875rem',
				fontWeight: '500',
				color: '#374151',
			},
			input: {
				width: '100%',
				padding: '0.625rem',
				borderRadius: '0.375rem',
				border: '1px solid #D1D5DB',
				backgroundColor: '#F9FAFB',
				fontSize: '0.875rem',
				transition: 'all 0.2s ease-in-out',
				outline: 'none',
			},
			inputFocus: {
				borderColor: '#3B82F6',
				boxShadow: '0 0 0 3px rgba(59, 130, 246, 0.1)',
				backgroundColor: '#FFFFFF',
			},
			select: {
				width: '100%',
				padding: '0.625rem',
				borderRadius: '0.375rem',
				border: '1px solid #D1D5DB',
				backgroundColor: '#F9FAFB',
				fontSize: '0.875rem',
				cursor: 'pointer',
				appearance: 'none',
				backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3E%3Cpath stroke='%236B7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3E%3C/svg%3E")`,
				backgroundRepeat: 'no-repeat',
				backgroundPosition: 'right 0.5rem center',
				backgroundSize: '1.5em 1.5em',
			} as React.CSSProperties,
			textarea: {
				width: '100%',
				padding: '0.625rem',
				borderRadius: '0.375rem',
				border: '1px solid #D1D5DB',
				backgroundColor: '#F9FAFB',
				fontSize: '0.875rem',
				minHeight: '6rem',
				resize: 'vertical' as const,
				WebkitTransition: 'all 0.2s ease-in-out',
				MozTransition: 'all 0.2s ease-in-out',
				OTransition: 'all 0.2s ease-in-out',
				transition: 'all 0.2s ease-in-out',
				outline: 'none',
				fontFamily: 'inherit',
				lineHeight: 1.5,
				boxSizing: 'border-box' as const,
			} as React.CSSProperties,
		};

		const handleFocus = (e) => {
			e.target.style.borderColor = '#3B82F6';
			e.target.style.boxShadow = '0 0 0 3px rgba(59, 130, 246, 0.1)';
			e.target.style.backgroundColor = '#FFFFFF';
		};

		const handleBlur = (e) => {
			e.target.style.borderColor = '#D1D5DB';
			e.target.style.boxShadow = 'none';
			e.target.style.backgroundColor = '#F9FAFB';
		};

		switch (settings.type) {
			case 'url':
				return (
					<div style={formStyles.formControl}>
						<label style={formStyles.label}>URL</label>
						<input
							style={formStyles.input}
							type="url"
							name="url"
							value={settings.url || ''}
							onChange={handleChange}
							onFocus={handleFocus}
							onBlur={handleBlur}
							placeholder="Enter URL"
						/>
					</div>
				);

			case 'text':
				return (
					<div style={formStyles.formControl}>
						<label style={formStyles.label}>Text</label>
						<textarea
							style={formStyles.textarea}
							name="text"
							value={settings.text || ''}
							onChange={handleChange}
							onFocus={handleFocus}
							onBlur={handleBlur}
							placeholder="Enter text content"
							rows={4}
						/>
					</div>
				);

			case 'email':
				return (
					<>
						<div style={formStyles.formControl}>
							<label style={formStyles.label}>Email Address</label>
							<input
								style={formStyles.input}
								type="email"
								name="email"
								value={settings.email || ''}
								onChange={handleChange}
								onFocus={handleFocus}
								onBlur={handleBlur}
								placeholder="Enter email address"
							/>
						</div>
						<div style={formStyles.formControl}>
							<label style={formStyles.label}>Subject</label>
							<input
								style={formStyles.input}
								type="text"
								name="email_subject"
								value={settings.email_subject || ''}
								onChange={handleChange}
								onFocus={handleFocus}
								onBlur={handleBlur}
								placeholder="Enter email subject"
							/>
						</div>
						<div style={formStyles.formControl}>
							<label style={formStyles.label}>Body</label>
							<textarea
								style={formStyles.textarea}
								name="email_body"
								value={settings.email_body || ''}
								onChange={handleChange}
								onFocus={handleFocus}
								onBlur={handleBlur}
								placeholder="Enter email body"
								rows={4}
							/>
						</div>
					</>
				);

			case 'phone':
				return (
					<div style={formStyles.formControl}>
						<label style={formStyles.label}>Phone Number</label>
						<input
							style={formStyles.input}
							type="tel"
							name="phone"
							value={settings.phone || ''}
							onChange={handleChange}
							onFocus={handleFocus}
							onBlur={handleBlur}
							placeholder="Enter phone number"
						/>
					</div>
				);

			case 'sms':
				return (
					<>
						<div style={formStyles.formControl}>
							<label style={formStyles.label}>Phone Number</label>
							<input
								style={formStyles.input}
								type="tel"
								name="sms"
								value={settings.sms || ''}
								onChange={handleChange}
								onFocus={handleFocus}
								onBlur={handleBlur}
								placeholder="Enter phone number"
							/>
						</div>
						<div style={formStyles.formControl}>
							<label style={formStyles.label}>Message</label>
							<textarea
								style={formStyles.textarea}
								name="sms_body"
								value={settings.sms_body || ''}
								onChange={handleChange}
								onFocus={handleFocus}
								onBlur={handleBlur}
								placeholder="Enter SMS message"
								rows={4}
							/>
						</div>
					</>
				);

			case 'wifi':
				return (
					<>
						<div style={formStyles.formControl}>
							<label style={formStyles.label}>Network Name (SSID)</label>
							<input
								style={formStyles.input}
								type="text"
								name="ssid"
								value={settings.wifi?.ssid || ''}
								onChange={(e) => handleNestedChange('wifi', e)}
								onFocus={handleFocus}
								onBlur={handleBlur}
								placeholder="Enter network name"
							/>
						</div>
						<div style={formStyles.formControl}>
							<label style={formStyles.label}>Encryption</label>
							<select
								style={formStyles.select}
								name="encryption"
								value={settings.wifi?.encryption || ''}
								onChange={(e) => handleNestedChange('wifi', e)}
								onFocus={handleFocus}
								onBlur={handleBlur}
							>
								<option value="">Select encryption type</option>
								<option value="WEP">WEP</option>
								<option value="WPA/WPA2">WPA/WPA2</option>
								<option value="None">None</option>
							</select>
						</div>
						<div style={formStyles.formControl}>
							<label style={formStyles.label}>Password</label>
							<input
								style={formStyles.input}
								type="password"
								name="password"
								value={settings.wifi?.password || ''}
								onChange={(e) => handleNestedChange('wifi', e)}
								onFocus={handleFocus}
								onBlur={handleBlur}
								placeholder="Enter network password"
							/>
						</div>
					</>
				);

			case 'vcard':
				return (
					<>
						<div style={formStyles.formControl}>
							<label style={formStyles.label}>First Name</label>
							<input
								style={formStyles.input}
								type="text"
								name="vcard_first_name"
								value={settings.vcard?.vcard_first_name || ''}
								onChange={(e) => handleNestedChange('vcard', e)}
								onFocus={handleFocus}
								onBlur={handleBlur}
								placeholder="Enter first name"
							/>
						</div>
						<div style={formStyles.formControl}>
							<label style={formStyles.label}>Last Name</label>
							<input
								style={formStyles.input}
								type="text"
								name="vcard_last_name"
								value={settings.vcard?.vcard_last_name || ''}
								onChange={(e) => handleNestedChange('vcard', e)}
								onFocus={handleFocus}
								onBlur={handleBlur}
								placeholder="Enter last name"
							/>
						</div>
						<div style={formStyles.formControl}>
							<label style={formStyles.label}>Organization</label>
							<input
								style={formStyles.input}
								type="text"
								name="vcard_organization"
								value={settings.vcard?.vcard_organization || ''}
								onChange={(e) => handleNestedChange('vcard', e)}
								onFocus={handleFocus}
								onBlur={handleBlur}
								placeholder="Enter organization"
							/>
						</div>
						<div style={formStyles.formControl}>
							<label style={formStyles.label}>Title</label>
							<input
								style={formStyles.input}
								type="text"
								name="vcard_title"
								value={settings.vcard?.vcard_title || ''}
								onChange={(e) => handleNestedChange('vcard', e)}
								onFocus={handleFocus}
								onBlur={handleBlur}
								placeholder="Enter title"
							/>
						</div>
						<div style={formStyles.formControl}>
							<label style={formStyles.label}>Email</label>
							<input
								style={formStyles.input}
								type="email"
								name="vcard_email"
								value={settings.vcard?.vcard_email || ''}
								onChange={(e) => handleNestedChange('vcard', e)}
								onFocus={handleFocus}
								onBlur={handleBlur}
								placeholder="Enter email"
							/>
						</div>
						<div style={formStyles.formControl}>
							<label style={formStyles.label}>Phone</label>
							<input
								style={formStyles.input}
								type="tel"
								name="vcard_phone"
								value={settings.vcard?.vcard_phone || ''}
								onChange={(e) => handleNestedChange('vcard', e)}
								onFocus={handleFocus}
								onBlur={handleBlur}
								placeholder="Enter phone number"
							/>
						</div>
						<div style={formStyles.formControl}>
							<label style={formStyles.label}>Website</label>
							<input
								style={formStyles.input}
								type="url"
								name="vcard_website"
								value={settings.vcard?.vcard_website || ''}
								onChange={(e) => handleNestedChange('vcard', e)}
								onFocus={handleFocus}
								onBlur={handleBlur}
								placeholder="Enter website URL"
							/>
						</div>
						<div style={formStyles.formControl}>
							<label style={formStyles.label}>Address</label>
							<textarea
								style={formStyles.textarea}
								name="vcard_address"
								value={settings.vcard?.vcard_address || ''}
								onChange={(e) => handleNestedChange('vcard', e)}
								onFocus={handleFocus}
								onBlur={handleBlur}
								placeholder="Enter address"
								rows={3}
							/>
						</div>
					</>
				);

			case 'whatsapp':
				return (
					<>
						<div style={formStyles.formControl}>
							<label style={formStyles.label}>WhatsApp Number</label>
							<input
								style={formStyles.input}
								type="tel"
								name="whatsapp"
								value={settings.whatsapp || ''}
								onChange={handleChange}
								onFocus={handleFocus}
								onBlur={handleBlur}
								placeholder="Enter WhatsApp number"
							/>
						</div>
						<div style={formStyles.formControl}>
							<label style={formStyles.label}>Pre-filled Message</label>
							<textarea
								style={formStyles.textarea}
								name="whatsapp_message"
								value={settings.whatsapp_message || ''}
								onChange={handleChange}
								onFocus={handleFocus}
								onBlur={handleBlur}
								placeholder="Enter message"
								rows={4}
							/>
						</div>
					</>
				);

			case 'facetime':
				return (
					<div style={formStyles.formControl}>
						<label style={formStyles.label}>FaceTime Address</label>
						<input
							style={formStyles.input}
							type="text"
							name="facetime"
							value={settings.facetime || ''}
							onChange={handleChange}
							onFocus={handleFocus}
							onBlur={handleBlur}
							placeholder="Enter phone number or email"
						/>
					</div>
				);

			case 'location':
				return (
					<>
						<div style={formStyles.formControl}>
							<label style={formStyles.label}>Latitude</label>
							<input
								style={formStyles.input}
								type="number"
								step="any"
								name="location_latitude"
								value={settings.location?.location_latitude || ''}
								onChange={(e) => handleNestedChange('location', e)}
								onFocus={handleFocus}
								onBlur={handleBlur}
								placeholder="Enter latitude"
							/>
						</div>
						<div style={formStyles.formControl}>
							<label style={formStyles.label}>Longitude</label>
							<input
								style={formStyles.input}
								type="number"
								step="any"
								name="location_longitude"
								value={settings.location?.location_longitude || ''}
								onChange={(e) => handleNestedChange('location', e)}
								onFocus={handleFocus}
								onBlur={handleBlur}
								placeholder="Enter longitude"
							/>
						</div>
					</>
				);

			case 'event':
				return (
					<>
						<div style={formStyles.formControl}>
							<label style={formStyles.label}>Event Title</label>
							<input
								style={formStyles.input}
								type="text"
								name="event"
								value={settings.event?.event || ''}
								onChange={(e) => handleNestedChange('event', e)}
								onFocus={handleFocus}
								onBlur={handleBlur}
								placeholder="Enter event title"
							/>
						</div>
						<div style={formStyles.formControl}>
							<label style={formStyles.label}>Location</label>
							<input
								style={formStyles.input}
								type="text"
								name="event_location"
								value={settings.event?.event_location || ''}
								onChange={(e) => handleNestedChange('event', e)}
								onFocus={handleFocus}
								onBlur={handleBlur}
								placeholder="Enter event location"
							/>
						</div>
						<div style={formStyles.formControl}>
							<label style={formStyles.label}>Start Date & Time</label>
							<input
								style={formStyles.input}
								type="datetime-local"
								name="event_start_date"
								value={settings.event?.event_start_date || ''}
								onChange={(e) => handleNestedChange('event', e)}
								onFocus={handleFocus}
								onBlur={handleBlur}
							/>
						</div>
						<div style={formStyles.formControl}>
							<label style={formStyles.label}>End Date & Time</label>
							<input
								style={formStyles.input}
								type="datetime-local"
								name="event_end_date"
								value={settings.event?.event_end_date || ''}
								onChange={(e) => handleNestedChange('event', e)}
								onFocus={handleFocus}
								onBlur={handleBlur}
							/>
						</div>
						<div style={formStyles.formControl}>
							<label style={formStyles.label}>Event URL</label>
							<input
								style={formStyles.input}
								type="url"
								name="event_url"
								value={settings.event?.event_url || ''}
								onChange={(e) => handleNestedChange('event', e)}
								onFocus={handleFocus}
								onBlur={handleBlur}
								placeholder="Enter event website URL"
							/>
						</div>
						<div style={formStyles.formControl}>
							<label style={formStyles.label}>Description</label>
							<textarea
								style={formStyles.textarea}
								name="event_note"
								value={settings.event?.event_note || ''}
								onChange={(e) => handleNestedChange('event', e)}
								onFocus={handleFocus}
								onBlur={handleBlur}
								placeholder="Enter event description"
								rows={4}
							/>
						</div>
					</>
				);

			case 'crypto':
				return (
					<>
						<div style={formStyles.formControl}>
							<label style={formStyles.label}>Cryptocurrency Type</label>
							<select
								// style={formStyles.select}
								name="crypto_type"
								value={settings.crypto?.crypto_type || ''}
								onChange={(e) => handleNestedChange('crypto', e)}
								onFocus={handleFocus}
								onBlur={handleBlur}
							>
								<option value="">Select cryptocurrency</option>
								<option value="bitcoin">Bitcoin</option>
								<option value="ethereum">Ethereum</option>
								<option value="litecoin">Litecoin</option>
								<option value="bitcoin-cash">Bitcoin Cash</option>
								<option value="ripple">Ripple (XRP)</option>
								<option value="dogecoin">Dogecoin</option>
							</select>
						</div>
						<div style={formStyles.formControl}>
							<label style={formStyles.label}>Wallet Address</label>
							<input
								style={formStyles.input}
								type="text"
								name="crypto_address"
								value={settings.crypto?.crypto_address || ''}
								onChange={(e) => handleNestedChange('crypto', e)}
								onFocus={handleFocus}
								onBlur={handleBlur}
								placeholder="Enter wallet address"
							/>
						</div>
						<div style={formStyles.formControl}>
							<label style={formStyles.label}>Amount (Optional)</label>
							<input
								style={formStyles.input}
								type="number"
								step="any"
								name="crypto_amount"
								value={settings.crypto?.crypto_amount || ''}
								onChange={(e) => handleNestedChange('crypto', e)}
								onFocus={handleFocus}
								onBlur={handleBlur}
								placeholder="Enter amount"
							/>
						</div>
					</>
				);

			case 'paypal':
				return (
					<>
						<div style={formStyles.formControl}>
							<label style={formStyles.label}>PayPal Email or Username</label>
							<input
								style={formStyles.input}
								type="text"
								name="paypal_email"
								value={settings.paypal?.paypal_email || ''}
								onChange={(e) => handleNestedChange('paypal', e)}
								onFocus={handleFocus}
								onBlur={handleBlur}
								placeholder="Enter PayPal email or username"
							/>
						</div>
						<div style={formStyles.formControl}>
							<label style={formStyles.label}>Amount (Optional)</label>
							<input
								style={formStyles.input}
								type="number"
								step="0.01"
								name="paypal_amount"
								value={settings.paypal?.paypal_amount || ''}
								onChange={(e) => handleNestedChange('paypal', e)}
								onFocus={handleFocus}
								onBlur={handleBlur}
								placeholder="Enter amount"
							/>
						</div>
						<div style={formStyles.formControl}>
							<label style={formStyles.label}>Currency</label>
							<select
								// style={formStyles.select}
								name="paypal_currency"
								value={settings.paypal?.paypal_currency || ''}
								onChange={(e) => handleNestedChange('paypal', e)}
								onFocus={handleFocus}
								onBlur={handleBlur}
							>
								<option value="">Select currency</option>
								<option value="USD">US Dollar (USD)</option>
								<option value="EUR">Euro (EUR)</option>
								<option value="GBP">British Pound (GBP)</option>
								<option value="CAD">Canadian Dollar (CAD)</option>
								<option value="AUD">Australian Dollar (AUD)</option>
								<option value="JPY">Japanese Yen (JPY)</option>
							</select>
						</div>
						<div style={formStyles.formControl}>
							<label style={formStyles.label}>Description (Optional)</label>
							<textarea
								style={formStyles.textarea}
								name="paypal_description"
								value={settings.paypal?.paypal_description || ''}
								onChange={(e) => handleNestedChange('paypal', e)}
								onFocus={handleFocus}
								onBlur={handleBlur}
								placeholder="Enter payment description"
								rows={3}
							/>
						</div>
					</>
				);

			case 'upi':
				return (
					<>
						<div style={formStyles.formControl}>
							<label style={formStyles.label}>UPI ID</label>
							<input
								style={formStyles.input}
								type="text"
								name="upi_payee_id"
								value={settings.upi?.upi_payee_id || ''}
								onChange={(e) => handleNestedChange('upi', e)}
								onFocus={handleFocus}
								onBlur={handleBlur}
								placeholder="Enter UPI ID (e.g., name@bank)"
							/>
						</div>
						<div style={formStyles.formControl}>
							<label style={formStyles.label}>Payee Name</label>
							<input
								style={formStyles.input}
								type="text"
								name="upi_payee_name"
								value={settings.upi?.upi_payee_name || ''}
								onChange={(e) => handleNestedChange('upi', e)}
								onFocus={handleFocus}
								onBlur={handleBlur}
								placeholder="Enter payee name"
							/>
						</div>
						<div style={formStyles.formControl}>
							<label style={formStyles.label}>Amount (Optional)</label>
							<input
								style={formStyles.input}
								type="number"
								step="0.01"
								name="upi_amount"
								value={settings.upi?.upi_amount || ''}
								onChange={(e) => handleNestedChange('upi', e)}
								onFocus={handleFocus}
								onBlur={handleBlur}
								placeholder="Enter amount"
							/>
						</div>
						<div style={formStyles.formControl}>
							<label style={formStyles.label}>Note (Optional)</label>
							<textarea
								style={formStyles.textarea}
								name="upi_note"
								value={settings.upi?.upi_note || ''}
								onChange={(e) => handleNestedChange('upi', e)}
								onFocus={handleFocus}
								onBlur={handleBlur}
								placeholder="Enter payment note"
								rows={3}
							/>
						</div>
					</>
				);

			case 'epc':
				return (
					<>
						<div style={formStyles.formControl}>
							<label style={formStyles.label}>Beneficiary Name</label>
							<input
								style={formStyles.input}
								type="text"
								name="name"
								value={settings.epc?.name || ''}
								onChange={(e) => handleNestedChange('epc', e)}
								onFocus={handleFocus}
								onBlur={handleBlur}
								placeholder="Enter beneficiary name"
							/>
						</div>
						<div style={formStyles.formControl}>
							<label style={formStyles.label}>IBAN</label>
							<input
								style={formStyles.input}
								type="text"
								name="iban"
								value={settings.epc?.iban || ''}
								onChange={(e) => handleNestedChange('epc', e)}
								onFocus={handleFocus}
								onBlur={handleBlur}
								placeholder="Enter IBAN"
							/>
						</div>
						<div style={formStyles.formControl}>
							<label style={formStyles.label}>Amount (Optional)</label>
							<input
								style={formStyles.input}
								type="number"
								step="0.01"
								name="amount"
								value={settings.epc?.amount || ''}
								onChange={(e) => handleNestedChange('epc', e)}
								onFocus={handleFocus}
								onBlur={handleBlur}
								placeholder="Enter amount"
							/>
						</div>
						<div style={formStyles.formControl}>
							<label style={formStyles.label}>Reference (Optional)</label>
							<input
								style={formStyles.input}
								type="text"
								name="reference"
								value={settings.epc?.reference || ''}
								onChange={(e) => handleNestedChange('epc', e)}
								onFocus={handleFocus}
								onBlur={handleBlur}
								placeholder="Enter payment reference"
							/>
						</div>
					</>
				);

			default:
				return null;
		}
	};

	const optionsCSS: { [key: string]: CSSProperties } = {
		styleOptions: {
			display: 'flex',
			flexDirection: 'column',
			gap: '2rem',
			padding: '24px',
			backgroundColor: '#ffffff',
			borderRadius: '12px',
			boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
			maxWidth: '600px',
			margin: '0 auto',
		},
		styleSection: {
			display: 'flex',
			flexDirection: 'column',
			gap: '1rem',
			padding: '16px',
			backgroundColor: '#f8fafc',
			borderRadius: '8px',
			border: '1px solid #e2e8f0',
		},
		styleSubtitle: {
			fontSize: '0.875rem',
			fontWeight: '600',
			color: '#334155',
			textTransform: 'uppercase',
			letterSpacing: '0.05em',
			marginBottom: '0.75rem',
		},
		radioGroup: {
			display: 'flex',
			flexWrap: 'wrap',
			gap: '0.75rem',
		},
		radioLabel: {
			display: 'flex',
			alignItems: 'center',
			gap: '0.5rem',
			padding: '6px 12px',
			backgroundColor: '#ffffff',
			borderRadius: '6px',
			border: '1px solid #e2e8f0',
			cursor: 'pointer',
			transition: 'all 0.2s ease',
			fontSize: '0.875rem',
			color: '#475569',
		},
		radioInput: {
			margin: 0,
			cursor: 'pointer',
			accentColor: '#3b82f6',
		},
		colorInputWrapper: {
			position: 'relative',
			// height: '40px',
			width: '100%',
			marginBottom: '1rem',
		},
		colorInput: {
			width: '100%',
			height: '40px',
			padding: '4px',
			border: '1px solid #e2e8f0',
			borderRadius: '6px',
			cursor: 'pointer',
			backgroundColor: '#ffffff',
		},
		select: {
			width: '100%',
			padding: '8px 12px',
			borderRadius: '6px',
			border: '1px solid #e2e8f0',
			backgroundColor: '#ffffff',
			color: '#475569',
			fontSize: '0.875rem',
			cursor: 'pointer',
			outline: 'none',
			transition: 'border-color 0.2s ease',
		},
		textInput: {
			width: '100%',
			padding: '8px 12px',
			borderRadius: '6px',
			border: '1px solid #e2e8f0',
			backgroundColor: '#ffffff',
			color: '#475569',
			fontSize: '0.875rem',
			transition: 'border-color 0.2s ease',
		},
		sliderContainer: {
			display: 'flex',
			flexDirection: 'column',
			gap: '0.5rem',
			marginBottom: '1rem',
		},
		sliderWrapper: {
			display: 'flex',
			alignItems: 'center',
			gap: '1rem',
			backgroundColor: '#ffffff',
			padding: '8px 12px',
			borderRadius: '6px',
			border: '1px solid #e2e8f0',
		},
		slider: {
			flexGrow: 1,
			accentColor: '#3b82f6',
			height: '2px',
		},
		sliderValue: {
			minWidth: '3rem',
			textAlign: 'right',
			fontSize: '0.875rem',
			color: '#475569',
			fontWeight: '500',
		},
		checkboxLabel: {
			display: 'flex',
			alignItems: 'center',
			gap: '0.5rem',
			cursor: 'pointer',
			fontSize: '0.875rem',
			color: '#475569',
			padding: '8px 12px',
			backgroundColor: '#ffffff',
			borderRadius: '6px',
			border: '1px solid #e2e8f0',
			transition: 'all 0.2s ease',
		},
		inputLabel: {
			fontSize: '0.75rem',
			color: '#64748b',
			fontWeight: '500',
			marginBottom: '0.25rem',
		},
		formControl: {
			marginBottom: '1rem',
		},
	};

	const renderStyleOptions = () => (
		<div style={optionsCSS.styleOptions}>
			{/* QR Code Style */}
			<div style={optionsCSS.styleSection}>
				<span style={optionsCSS.styleSubtitle}>QR Code Style</span>
				<div style={optionsCSS.radioGroup}>
					{['Square', 'Round', 'Dot', 'Heart', 'Diamond'].map((style) => (
						<label
							key={style}
							style={{
								...optionsCSS.radioLabel,
								backgroundColor: settings.style.style === style.toLowerCase() ? '#eff6ff' : '#ffffff',
								borderColor: settings.style.style === style.toLowerCase() ? '#3b82f6' : '#e2e8f0',
							}}
						>
							<input
								type="radio"
								name="style"
								value={style.toLowerCase()}
								checked={settings.style.style === style.toLowerCase()}
								onChange={(e) => handleStyleChange('style', e.target.value)}
								style={optionsCSS.radioInput}
							/>
							{style}
						</label>
					))}
				</div>
			</div>

			{/* Inner Eye Shape */}
			<div style={optionsCSS.styleSection}>
				<span style={optionsCSS.styleSubtitle}>Inner Eye Shape</span>
				<div style={optionsCSS.radioGroup}>
					{['Square', 'Circle', 'Rounded', 'Flower', 'Leaf'].map((style) => (
						<label
							key={style}
							style={{
								...optionsCSS.radioLabel,
								backgroundColor:
									settings.style.inner_eye_style === style.toLowerCase() ? '#eff6ff' : '#ffffff',
								borderColor:
									settings.style.inner_eye_style === style.toLowerCase() ? '#3b82f6' : '#e2e8f0',
							}}
						>
							<input
								type="radio"
								name="inner_eye_style"
								value={style.toLowerCase()}
								checked={settings.style.inner_eye_style === style.toLowerCase()}
								onChange={(e) => handleStyleChange('inner_eye_style', e.target.value)}
								style={optionsCSS.radioInput}
							/>
							{style}
						</label>
					))}
				</div>
			</div>

			{/* Outer Eye Shape */}
			<div style={optionsCSS.styleSection}>
				<span style={optionsCSS.styleSubtitle}>Outer Eye Shape</span>
				<div style={optionsCSS.radioGroup}>
					{['Square', 'Circle', 'Rounded', 'Flower', 'Leaf'].map((style) => (
						<label
							key={style}
							style={{
								...optionsCSS.radioLabel,
								backgroundColor:
									settings.style.outer_eye_style === style.toLowerCase() ? '#eff6ff' : '#ffffff',
								borderColor:
									settings.style.outer_eye_style === style.toLowerCase() ? '#3b82f6' : '#e2e8f0',
							}}
						>
							<input
								type="radio"
								name="outer_eye_style"
								value={style.toLowerCase()}
								checked={settings.style.outer_eye_style === style.toLowerCase()}
								onChange={(e) => handleStyleChange('outer_eye_style', e.target.value)}
								style={optionsCSS.radioInput}
							/>
							{style}
						</label>
					))}
				</div>
			</div>

			{/* Foreground Style */}
			<div style={optionsCSS.styleSection}>
				<span style={optionsCSS.styleSubtitle}>Foreground Style</span>
				<div style={optionsCSS.radioGroup}>
					<label
						style={{
							...optionsCSS.radioLabel,
							backgroundColor: settings.style.foreground_type === 'color' ? '#eff6ff' : '#ffffff',
							borderColor: settings.style.foreground_type === 'color' ? '#3b82f6' : '#e2e8f0',
						}}
					>
						<input
							type="radio"
							name="foreground_type"
							value="color"
							checked={settings.style.foreground_type === 'color'}
							onChange={(e) => handleStyleChange('foreground_type', e.target.value)}
							style={optionsCSS.radioInput}
						/>
						Solid Color
					</label>
					<label
						style={{
							...optionsCSS.radioLabel,
							backgroundColor: settings.style.foreground_type === 'gradient' ? '#eff6ff' : '#ffffff',
							borderColor: settings.style.foreground_type === 'gradient' ? '#3b82f6' : '#e2e8f0',
						}}
					>
						<input
							type="radio"
							name="foreground_type"
							value="gradient"
							checked={settings.style.foreground_type === 'gradient'}
							onChange={(e) => handleStyleChange('foreground_type', e.target.value)}
							style={optionsCSS.radioInput}
						/>
						Gradient
					</label>
				</div>

				{settings.style.foreground_type === 'color' ? (
					<>
						<div style={optionsCSS.colorInputWrapper}>
							<label style={optionsCSS.inputLabel}>Foreground Color</label>
							<input
								type="color"
								style={optionsCSS.colorInput}
								value={settings.style.foreground_color}
								onChange={(e) => handleStyleChange('foreground_color', e.target.value)}
							/>
						</div>
					</>
				) : (
					<div style={{ ...optionsCSS.styleSection, backgroundColor: '#ffffff' }}>
						<div style={optionsCSS.formControl}>
							<label style={optionsCSS.inputLabel}>Gradient Style</label>
							<select
								style={optionsCSS.select}
								value={settings.style.foreground_gradient_style}
								onChange={(e) => handleStyleChange('foreground_gradient_style', e.target.value)}
							>
								<option value="vertical">Vertical</option>
								<option value="horizontal">Horizontal</option>
								<option value="diagonal">Diagonal</option>
								<option value="radial">Radial</option>
							</select>
						</div>
						<div style={optionsCSS.colorInputWrapper}>
							<label style={optionsCSS.inputLabel}>Gradient Color 1</label>
							<input
								type="color"
								style={optionsCSS.colorInput}
								value={settings.style.foreground_gradient_one}
								onChange={(e) => handleStyleChange('foreground_gradient_one', e.target.value)}
							/>
						</div>
						<div style={optionsCSS.colorInputWrapper}>
							<label style={optionsCSS.inputLabel}>Gradient Color 2</label>
							<input
								type="color"
								style={optionsCSS.colorInput}
								value={settings.style.foreground_gradient_two}
								onChange={(e) => handleStyleChange('foreground_gradient_two', e.target.value)}
							/>
						</div>
					</div>
				)}
			</div>

			{/* Eyes Color */}
			<div style={optionsCSS.styleSection}>
				<span style={optionsCSS.styleSubtitle}>Eyes Color</span>
				<label
					style={{
						...optionsCSS.checkboxLabel,
						backgroundColor: settings.style.custom_eyes_color === 'active' ? '#eff6ff' : '#ffffff',
						borderColor: settings.style.custom_eyes_color === 'active' ? '#3b82f6' : '#e2e8f0',
					}}
				>
					<input
						type="checkbox"
						checked={settings.style.custom_eyes_color === 'active'}
						onChange={(e) =>
							handleStyleChange('custom_eyes_color', e.target.checked ? 'active' : 'not Active')
						}
						style={optionsCSS.radioInput}
					/>
					Custom Eyes Color
				</label>

				{settings.style.custom_eyes_color === 'active' && (
					<>
						<div style={optionsCSS.colorInputWrapper}>
							<label style={optionsCSS.inputLabel}>Inner Eyes Color</label>
							<input
								type="color"
								style={optionsCSS.colorInput}
								value={settings.style.eyes_inner_color}
								onChange={(e) => handleStyleChange('eyes_inner_color', e.target.value)}
							/>
						</div>
						<div style={optionsCSS.colorInputWrapper}>
							<label style={optionsCSS.inputLabel}>Outer Eyes Color</label>
							<input
								type="color"
								style={optionsCSS.colorInput}
								value={settings.style.eyes_outer_color}
								onChange={(e) => handleStyleChange('eyes_outer_color', e.target.value)}
							/>
						</div>
					</>
				)}
			</div>

			{/* Background */}
			<div style={optionsCSS.styleSection}>
				<span style={optionsCSS.styleSubtitle}>Background</span>
				<div style={optionsCSS.colorInputWrapper}>
					<label style={optionsCSS.inputLabel}>Background Color</label>
					<input
						type="color"
						style={optionsCSS.colorInput}
						value={settings.style.background_color}
						onChange={(e) => handleStyleChange('background_color', e.target.value)}
					/>
				</div>
				<label
					style={{
						...optionsCSS.checkboxLabel,
						backgroundColor: settings.style.qr_code_background_activate ? '#eff6ff' : '#ffffff',
						borderColor: settings.style.qr_code_background_activate ? '#3b82f6' : '#e2e8f0',
					}}
				>
					<input
						type="checkbox"
						checked={settings.style.qr_code_background_activate}
						onChange={(e) => handleStyleChange('qr_code_background_activate', e.target.checked)}
						style={optionsCSS.radioInput}
					/>
					Background Image
				</label>

				{settings.style.qr_code_background_activate && (
					<div style={optionsCSS.sliderContainer}>
						<label style={optionsCSS.inputLabel}>Background Transparency</label>
						<div style={optionsCSS.sliderWrapper}>
							<input
								type="range"
								style={optionsCSS.slider}
								min={0}
								max={100}
								value={settings.style.background_color_transparency}
								onChange={(e) =>
									handleStyleChange('background_color_transparency', Number(e.target.value))
								}
							/>
							<span style={optionsCSS.sliderValue}>{settings.style.background_color_transparency}%</span>
						</div>
					</div>
				)}
			</div>

			{/* Logo */}
			<div style={optionsCSS.styleSection}>
				<span style={optionsCSS.styleSubtitle}>Logo </span>
				<label
					style={{
						...optionsCSS.checkboxLabel,
					}}
				>
					<input
						type="checkbox"
						checked={settings.style.qr_code_background_activate}
						onChange={(e) => handleStyleChange('qr_code_background_activate', e.target.checked)}
						style={optionsCSS.radioInput}
					/>
					Logo Image
				</label>

				{settings.style.qr_code_background_activate && (
					<div style={optionsCSS.sliderContainer}>
						<label style={optionsCSS.inputLabel}>Background Transparency</label>
						<div style={optionsCSS.sliderWrapper}>
							<input
								type="range"
								style={optionsCSS.slider}
								min={0}
								max={100}
								value={settings.style.background_color_transparency}
								onChange={(e) =>
									handleStyleChange('background_color_transparency', Number(e.target.value))
								}
							/>
							<span style={optionsCSS.sliderValue}>{settings.style.background_color_transparency}%</span>
						</div>
					</div>
				)}
			</div>

			{/* Frame */}
			<div style={optionsCSS.styleSection}>
				<span style={optionsCSS.styleSubtitle}>Frame</span>
				<div style={optionsCSS.formControl}>
					<select
						style={optionsCSS.select}
						value={settings.style.frame || ''}
						onChange={(e) => {
							if (e.target.value === '') {
								delete settings.style.frame;
								setSettings(settings);
							} else {
								handleStyleChange('frame', e.target.value);
							}
						}}
					>
						<option value="">None</option>
						<option value="round_bottom_text">Round Bottom Text</option>
						<option value="round_top_text">Round Top Text</option>
						<option value="tooltip_bottom_text">Tooltip Bottom Text</option>
						<option value="tooltip_top_text">Tooltip Top Text</option>
						<option value="ribbon_bottom_text">Ribbon Bottom Text</option>
						<option value="ribbon_top_text">Ribbon Top Text</option>
						<option value="tooltip_snap_top_text">Tooltip Snap Top Text</option>
						<option value="tooltip_snap_bottom_text">Tooltip Snap Bottom Text</option>
					</select>
				</div>

				{settings.style.frame && (
					<>
						<div style={optionsCSS.formControl}>
							<label style={optionsCSS.inputLabel}>Frame Text</label>
							<input
								type="text"
								style={optionsCSS.textInput}
								value={settings.style.frame_text}
								onChange={(e) => handleStyleChange('frame_text', e.target.value)}
								placeholder="Enter frame text"
							/>
						</div>

						<div style={optionsCSS.formControl}>
							<label style={optionsCSS.inputLabel}>Frame Text Font</label>
							<select
								style={optionsCSS.select}
								value={settings.style.frame_text_font}
								onChange={(e) => handleStyleChange('frame_text_font', e.target.value)}
							>
								<option value="times_new_roman">Times New Roman</option>
								<option value="arial">Arial</option>
								<option value="verdana">Verdana</option>
								<option value="helvetica">Helvetica</option>
							</select>
						</div>

						<div style={optionsCSS.sliderContainer}>
							<label style={optionsCSS.inputLabel}>Frame Text Size</label>
							<div style={optionsCSS.sliderWrapper}>
								<input
									type="range"
									style={optionsCSS.slider}
									min={0}
									max={100}
									value={settings.style.frame_text_size}
									onChange={(e) => handleStyleChange('frame_text_size', Number(e.target.value))}
								/>
								<span style={optionsCSS.sliderValue}>{settings.style.frame_text_size}</span>
							</div>
						</div>

						<label
							style={{
								...optionsCSS.checkboxLabel,
								backgroundColor: settings.style.frame_custom_colors ? '#eff6ff' : '#ffffff',
								borderColor: settings.style.frame_custom_colors ? '#3b82f6' : '#e2e8f0',
								marginBottom: '1rem',
							}}
						>
							<input
								type="checkbox"
								checked={settings.style.frame_custom_colors}
								onChange={(e) => handleStyleChange('frame_custom_colors', e.target.checked)}
								style={optionsCSS.radioInput}
							/>
							Custom Frame Colors
						</label>

						{settings.style.frame_custom_colors && (
							<>
								<div style={optionsCSS.colorInputWrapper}>
									<label style={optionsCSS.inputLabel}>Frame Color</label>
									<input
										type="color"
										style={optionsCSS.colorInput}
										value={settings.style.frame_color}
										onChange={(e) => handleStyleChange('frame_color', e.target.value)}
									/>
								</div>
								<div style={optionsCSS.colorInputWrapper}>
									<label style={optionsCSS.inputLabel}>Frame Text Color</label>
									<input
										type="color"
										style={optionsCSS.colorInput}
										value={settings.style.frame_text_color}
										onChange={(e) => handleStyleChange('frame_text_color', e.target.value)}
									/>
								</div>
							</>
						)}
					</>
				)}
			</div>

			{/* Size and Margin Controls */}
			<div style={optionsCSS.styleSection}>
				<span style={optionsCSS.styleSubtitle}>QR Size, Margin & ECC</span>
				<div style={optionsCSS.sliderContainer}>
					<label style={optionsCSS.inputLabel}>QR Code Size (px)</label>
					<div style={optionsCSS.sliderWrapper}>
						<input
							type="range"
							style={optionsCSS.slider}
							min={100}
							max={1000}
							step={10}
							value={settings.style.size}
							onChange={(e) => handleStyleChange('size', Number(e.target.value))}
						/>
						<span style={optionsCSS.sliderValue}>{settings.style.size}px</span>
					</div>
				</div>

				<div style={optionsCSS.sliderContainer}>
					<label style={optionsCSS.inputLabel}>Margin (px)</label>
					<div style={optionsCSS.sliderWrapper}>
						<input
							type="range"
							style={optionsCSS.slider}
							min={0}
							max={50}
							value={settings.style.margin}
							onChange={(e) => handleStyleChange('margin', Number(e.target.value))}
						/>
						<span style={optionsCSS.sliderValue}>{settings.style.margin}px</span>
					</div>
				</div>

				{/* Error Correction Level */}
				<div style={optionsCSS.formControl}>
					<label style={optionsCSS.inputLabel}>Error Correction Level</label>
					<select
						style={optionsCSS.select}
						value={settings.style.ecc}
						onChange={(e) => handleStyleChange('ecc', e.target.value)}
					>
						<option value="L">Low (7%)</option>
						<option value="M">Medium (15%)</option>
						<option value="Q">Quartile (25%)</option>
						<option value="H">High (30%)</option>
					</select>
				</div>
			</div>
		</div>
	);

	const handleStyleChange = (name: string, value: any) => {
		setSettings((prevSettings) => ({
			...prevSettings,
			style: {
				...prevSettings.style,
				[name]: value,
			},
		}));
	};

	const validateQRCodeSettings = (settings: QRCodeSettings): { isValid: boolean; message: string } => {
		const urlRegex = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		const phoneRegex = /^\+?[\d\s-()]{8,}$/;
		const ibanRegex = /^[A-Z]{2}\d{2}[A-Z\d]{4}\d{7}([A-Z\d]?){0,16}$/;
		const cryptoAddressRegex = {
			bitcoin: /^[13][a-km-zA-HJ-NP-Z1-9]{25,34}$/,
			ethereum: /^0x[a-fA-F0-9]{40}$/,
			litecoin: /^[LM3][a-km-zA-HJ-NP-Z1-9]{26,33}$/,
			'bitcoin-cash': /^[13][a-km-zA-HJ-NP-Z1-9]{25,34}$/,
			ripple: /^r[0-9a-zA-Z]{24,34}$/,
			dogecoin: /^D{1}[5-9A-HJ-NP-U]{1}[1-9A-HJ-NP-Za-km-z]{32}$/,
		};

		switch (settings.type) {
			case 'url':
				if (!settings.url?.trim()) {
					return { isValid: false, message: 'Please enter a URL' };
				}
				if (!urlRegex.test(settings.url)) {
					return { isValid: false, message: 'Please enter a valid URL' };
				}
				break;

			case 'text':
				if (!settings.text?.trim()) {
					return { isValid: false, message: 'Please enter some text' };
				}
				break;

			case 'email':
				if (!settings.email?.trim()) {
					return { isValid: false, message: 'Please enter an email address' };
				}
				if (!emailRegex.test(settings.email)) {
					return { isValid: false, message: 'Please enter a valid email address' };
				}
				break;

			case 'phone':
				if (!settings.phone?.trim()) {
					return { isValid: false, message: 'Please enter a phone number' };
				}
				if (!phoneRegex.test(settings.phone)) {
					return { isValid: false, message: 'Please enter a valid phone number' };
				}
				break;

			case 'sms':
				if (!settings.sms?.trim()) {
					return { isValid: false, message: 'Please enter a phone number for SMS' };
				}
				if (!phoneRegex.test(settings.sms)) {
					return { isValid: false, message: 'Please enter a valid phone number for SMS' };
				}
				break;

			case 'wifi':
				if (!settings.wifi?.ssid?.trim()) {
					return { isValid: false, message: 'Please enter the WiFi network name (SSID)' };
				}
				if (settings.wifi.encryption === 'WEP' || settings.wifi.encryption === 'WPA/WPA2') {
					if (!settings.wifi.password?.trim()) {
						return { isValid: false, message: 'Please enter the WiFi password' };
					}
				}
				break;

			case 'vcard':
				if (!settings.vcard?.vcard_first_name?.trim() || !settings.vcard?.vcard_last_name?.trim()) {
					return { isValid: false, message: 'Please enter both first and last name' };
				}
				if (settings.vcard.vcard_email && !emailRegex.test(settings.vcard.vcard_email)) {
					return { isValid: false, message: 'Please enter a valid email address in vCard' };
				}
				if (settings.vcard.vcard_phone && !phoneRegex.test(settings.vcard.vcard_phone)) {
					return { isValid: false, message: 'Please enter a valid phone number in vCard' };
				}
				break;

			case 'whatsapp':
				if (!settings.whatsapp?.trim()) {
					return { isValid: false, message: 'Please enter a WhatsApp number' };
				}
				if (!phoneRegex.test(settings.whatsapp)) {
					return { isValid: false, message: 'Please enter a valid WhatsApp number' };
				}
				break;

			case 'facetime':
				if (!settings.facetime?.trim()) {
					return { isValid: false, message: 'Please enter a FaceTime address' };
				}
				if (!emailRegex.test(settings.facetime) && !phoneRegex.test(settings.facetime)) {
					return { isValid: false, message: 'Please enter a valid email or phone number for FaceTime' };
				}
				break;

			case 'location':
				if (!settings.location?.location_latitude || !settings.location?.location_longitude) {
					return { isValid: false, message: 'Please enter both latitude and longitude' };
				}
				const lat = parseFloat(settings.location.location_latitude);
				const lon = parseFloat(settings.location.location_longitude);
				if (isNaN(lat) || lat < -90 || lat > 90) {
					return { isValid: false, message: 'Please enter a valid latitude (-90 to 90)' };
				}
				if (isNaN(lon) || lon < -180 || lon > 180) {
					return { isValid: false, message: 'Please enter a valid longitude (-180 to 180)' };
				}
				break;

			case 'event':
				if (!settings.event?.event?.trim()) {
					return { isValid: false, message: 'Please enter an event title' };
				}
				if (!settings.event?.event_start_date) {
					return { isValid: false, message: 'Please enter an event start date' };
				}
				if (!settings.event?.event_end_date) {
					return { isValid: false, message: 'Please enter an event end date' };
				}
				if (new Date(settings.event.event_end_date) <= new Date(settings.event.event_start_date)) {
					return { isValid: false, message: 'End date must be after start date' };
				}
				break;

			case 'crypto':
				if (!settings.crypto?.crypto_type) {
					return { isValid: false, message: 'Please select a cryptocurrency type' };
				}
				if (!settings.crypto?.crypto_address?.trim()) {
					return { isValid: false, message: 'Please enter a wallet address' };
				}
				const addressRegex = cryptoAddressRegex[settings.crypto.crypto_type];
				if (addressRegex && !addressRegex.test(settings.crypto.crypto_address)) {
					return { isValid: false, message: 'Please enter a valid wallet address' };
				}
				if (settings.crypto?.crypto_amount && isNaN(parseFloat(settings.crypto.crypto_amount))) {
					return { isValid: false, message: 'Please enter a valid amount' };
				}
				break;

			case 'paypal':
				if (!settings.paypal?.paypal_email?.trim()) {
					return { isValid: false, message: 'Please enter a PayPal email' };
				}
				if (!emailRegex.test(settings.paypal.paypal_email)) {
					return { isValid: false, message: 'Please enter a valid PayPal email' };
				}
				if (settings.paypal?.paypal_amount && isNaN(parseFloat(settings.paypal.paypal_amount))) {
					return { isValid: false, message: 'Please enter a valid amount' };
				}
				break;

			case 'upi':
				if (!settings.upi?.upi_payee_id?.trim()) {
					return { isValid: false, message: 'Please enter a UPI ID' };
				}
				if (!settings.upi?.upi_payee_name?.trim()) {
					return { isValid: false, message: 'Please enter a payee name' };
				}
				if (settings.upi?.upi_amount && isNaN(parseFloat(settings.upi.upi_amount))) {
					return { isValid: false, message: 'Please enter a valid amount' };
				}
				break;

			case 'epc':
				if (!settings.epc?.name?.trim()) {
					return { isValid: false, message: 'Please enter a beneficiary name' };
				}
				if (!settings.epc?.iban?.trim()) {
					return { isValid: false, message: 'Please enter an IBAN' };
				}
				if (!ibanRegex.test(settings.epc.iban)) {
					return { isValid: false, message: 'Please enter a valid IBAN' };
				}
				if (settings.epc?.amount && isNaN(parseFloat(settings.epc.amount))) {
					return { isValid: false, message: 'Please enter a valid amount' };
				}
				break;

			default:
				return { isValid: false, message: 'Invalid QR code type' };
		}

		return { isValid: true, message: 'Validation successful' };
	};

	const generateQRCode = useCallback(
		(settings) => {
			const validation = validateQRCodeSettings(settings);

			if (!validation.isValid) {
				setSnackbarMessage(validation.message);
				setOpenSnackbar(true);
				return;
			}

			// Prepare the data to be sent to the backend
			const qrCodeData = flattenObject(settings);

			try {
				// Replace this with your actual API endpoint
				axiosInstance.post('http://localhost/api/exhibit/qr', qrCodeData).then((data) => {
					setSnackbarMessage('QR code created successfully!');
					setOpenSnackbar(true);
					console.log('Created QR code:', data.data);
					setQRCodeImage(data.data.svg);
					setQrImage(data.data.svg);
				});
			} catch (error) {
				console.error('Error creating QR code:', error);
				setSnackbarMessage('Failed to create QR code. Please try again.');
				setOpenSnackbar(true);
			}
		},
		[flattenObject, setQrImage]
	);

	useEffect(() => {
		generateQRCode(settings);
	}, [generateQRCode, settings]);

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		generateQRCode(settings);
	};

	const mainStyle: { [key: string]: React.CSSProperties } = {
		qrCreator: {
			fontFamily: "'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif",
			overflow: 'hidden',
			height: '500px',
		},
		container: {
			margin: '0 auto',
			height: '100%',
			overflow: 'hidden',
		},
		header: {
			textAlign: 'center',
			background: 'white',
			zIndex: 10,
		},
		headerH1: {
			fontSize: '2.5rem',
			fontWeight: 800,
			color: 'var(--text-primary)',
			margin: '0 0 0.75rem 0',
			background: 'linear-gradient(135deg, var(--primary-color), var(--primary-hover))',
			WebkitBackgroundClip: 'text',
			WebkitTextFillColor: 'transparent',
		},
		headerP: {
			color: 'var(--text-secondary)',
			fontSize: '1.125rem',
			margin: 0,
		},
		grid: {
			display: 'grid',
			gridTemplateColumns: '1fr',
			gap: '2rem',
			animation: 'slideUp 0.6s ease-out',
			width: '100%',
			// Add height constraint for the grid
			height: '100%',
			maxHeight: 'calc(80vh - 2rem)', // Account for dialog padding
		},

		gridDesktop: {
			gridTemplateColumns: '1.2fr 0.8fr',
		},

		// Fixed left column styles
		leftColumn: {
			flex: 1,
			flexDirection: 'column',
			overflowY: 'auto',
			height: '100%',
			minWidth: 0,
			paddingBottom: 50,
		},
		// Fixed content wrapper styles
		contentWrapper: {
			flex: 1,
			overflowY: 'auto',
			minHeight: 0,
		},

		// Right column styles remain the same
		rightColumn: {
			display: 'flex',
			flexDirection: 'column',
			gap: '1.5rem',
		},

		// Sticky footer styles remain the same
		stickyFooter: {
			position: 'fixed',
			bottom: 0,
			left: 0,
			right: 0,
			background: '#fff',
			padding: '1rem',
			// borderTop: '1px solid #e2e8f0',
			zIndex: 10,
			marginTop: 'auto',
		},
		shadows: {
			boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
		},
		card: {
			borderRadius: '16px',
			padding: '2rem',
			transition: 'transform 0.2s ease, box-shadow 0.2s ease',
		},
		cardHover: {
			transform: 'translateY(-2px)',
			boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
		},
		formGroup: {
			marginBottom: '1.75rem',
		},
		formLabel: {
			display: 'block',
			fontSize: '0.875rem',
			fontWeight: 600,
			color: 'var(--text-primary)',
			marginBottom: '0.5rem',
			letterSpacing: '0.025em',
		},
		formInput: {
			width: '100%',
			padding: '0.75rem 1rem',
			fontSize: '0.875rem',
			border: '2px solid var(--border-color)',
			borderRadius: '8px',
			transition: 'all 0.2s ease',
			backgroundColor: '#f8fafc',
		},
		formInputFocus: {
			outline: 'none',
			borderColor: 'var(--primary-color)',
			boxShadow: '0 0 0 3px rgba(99, 102, 241, 0.1)',
			backgroundColor: 'white',
		},
		select: {
			width: '100%',
			padding: '0.75rem 2.5rem 0.75rem 1rem',
			fontSize: '0.875rem',
			border: '2px solid var(--border-color)',
			borderRadius: '8px',
			backgroundImage:
				"url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%236366f1' viewBox='0 0 16 16'%3E%3Cpath d='M8 10.5l4-4H4l4 4z'/%3E%3C/svg%3E\")",
			backgroundRepeat: 'no-repeat',
			backgroundPosition: 'right 0.75rem center',
			backgroundSize: '16px',
			appearance: 'none',
			transition: 'all 0.2s ease',
		},
		button: {
			display: 'inline-flex',
			alignItems: 'center',
			justifyContent: 'center',
			padding: '0.75rem 1.5rem',
			fontSize: '0.875rem',
			fontWeight: 600,
			borderRadius: '8px',
			transition: 'all 0.2s ease',
			cursor: 'pointer',
			letterSpacing: '0.025em',
		},
		buttonPrimary: {
			background: 'linear-gradient(135deg, var(--primary-color), var(--primary-hover))',
			color: 'white',
			border: 'none',
			width: '100%',
		},
		buttonPrimaryHover: {
			transform: 'translateY(-1px)',
			boxShadow: '0 4px 6px -1px rgba(99, 102, 241, 0.2)',
		},
		buttonSecondary: {
			background: 'white',
			color: 'var(--text-primary)',
			border: '2px solid var(--border-color)',
			width: '100%',
			marginTop: '1rem',
		},
		buttonSecondaryHover: {
			backgroundColor: 'var(--background-color)',
			borderColor: 'var(--primary-color)',
			color: 'var(--primary-color)',
		},
		previewContainer: {
			aspectRatio: '1',
			background: 'linear-gradient(135deg, #f8fafc 0%, #e2e8f0 100%)',
			borderRadius: '12px',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			marginBottom: '1.5rem',
			border: '2px dashed var(--border-color)',
			transition: 'all 0.2s ease',
		},
		previewContainerHover: {
			borderColor: 'var(--primary-color)',
			background: 'linear-gradient(135deg, #f8fafc 0%, #e5e7ff 100%)',
		},
		qrImage: {
			maxWidth: '100%',
			height: 'auto',
		},
		snackbar: {
			position: 'fixed',
			bottom: '24px',
			left: '50%',
			transform: 'translateX(-50%)',
			padding: '1rem 2rem',
			backgroundColor: 'var(--primary-color)',
			color: 'white',
			borderRadius: '8px',
			fontWeight: 500,
			boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1)',
			animation: 'slideUp 0.3s ease-out',
		},
		accordion: {
			marginBottom: '1.5rem',
			border: '1px solid var(--border-color)',
			borderRadius: '8px',
			overflow: 'hidden',
		},
		accordionSummary: {
			display: 'flex',
			justifyContent: 'space-between',
			padding: '1rem',
			backgroundColor: 'var(--background-color)',
			cursor: 'pointer',
			fontWeight: 500,
		},
		accordionDetails: {
			padding: '1rem',
			borderTop: '1px solid var(--border-color)',
		},
	};

	const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 1024);

	useEffect(() => {
		const handleResize = () => {
			setIsDesktop(window.innerWidth >= 1024);
		};

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);
	return (
		<div style={mainStyle.qrCreator}>
			<div style={mainStyle.container}>
				<div
					style={{
						...mainStyle.header,
						...{
							position: 'sticky',
							top: 0,
							backgroundColor: '#fff',
							padding: '20px',
							display: 'flex',
							justifyContent: 'center',
							flexDirection: 'column',
						},
					}}
				>
					<div>
						<h1>QR Code Generator</h1>
						<p>Create customized QR codes for your needs</p>
					</div>
				</div>

				<div style={{ ...mainStyle.grid, ...(isDesktop ? mainStyle.gridDesktop : {}) }}>
					<div style={mainStyle.leftColumn}>
						<div style={mainStyle.card}>
							<form onSubmit={handleSubmit} style={{ padding: ' 10 10' }}>
								<div style={mainStyle.formGroup}>
									<label style={mainStyle.formLabel}>Type</label>
									<select
										style={mainStyle.select}
										name="type"
										value={settings.type}
										onChange={handleChange}
									>
										<option value="url">URL</option>
										<option value="text">Text</option>
										<option value="email">Email</option>
										<option value="phone">Phone</option>
										<option value="sms">SMS</option>
										<option value="wifi">Wi-Fi</option>
										<option value="vcard">vCard</option>
										<option value="whatsapp">WhatsApp</option>
										<option value="facetime">FaceTime</option>
										<option value="location">Location</option>
										<option value="event">Event</option>
										<option value="crypto">Cryptocurrency</option>
										<option value="paypal">PayPal</option>
										<option value="upi">UPI</option>
										<option value="epc">EPC (SEPA)</option>
									</select>
								</div>

								{renderTypeSpecificFields()}

								<div style={mainStyle.accordion}>
									<div style={mainStyle.contentWrapper}>
										{/* Style options rendered with new CSS classes */}
										{renderStyleOptions()}
									</div>
								</div>

								{/* <!-- Submit button -->
								<div
									style={{
										position: 'sticky',
										bottom: 0,
										margin: '0 -10',
										backgroundColor: '#fff',
										padding: '20px',
										display: 'flex',
										justifyContent: 'center',
									}}
								>
									<button
										type="submit"
										style={{
											padding: '10px 20px',
											fontSize: '16px',
											backgroundColor: '#007BFF',
											color: '#fff',
											border: 'none',
											borderRadius: '5px',
											cursor: 'pointer',
											transition: 'background-color 0.3s',
										}}
									>
										Generate QR Code
									</button>
								</div> */}
							</form>
						</div>
					</div>

					{/* Right Column */}
					<div style={mainStyle.rightColumn}>
						<div
							style={{
								position: 'sticky',
								top: 150,
							}}
						>
							<div style={mainStyle.card}>
								<div style={mainStyle.previewContainer}>
									{qrCodeImage ? (
										<img src={qrCodeImage} alt="Generated QR Code" style={mainStyle.qrImage} />
									) : (
										<div style={mainStyle.previewPlaceholder}>QR Code Preview</div>
									)}
								</div>
								{qrCodeImage && <button style={{ ...mainStyle.button }}>Download QR Code</button>}
							</div>
						</div>
					</div>
				</div>
			</div>
			{openSnackbar && <div style={mainStyle.snackbar}>{snackbarMessage}</div>}
		</div>
	);
};

export default QRCodeCreatorCustom;
