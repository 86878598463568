import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FlatButton } from '../Buttons/FlatButton';
import { LiteModal } from './LiteModal';

// useStyles
const useStyles = makeStyles({
	text: {
		//TODO: style this
	},
});

// props
export type LiteQuiiikModalProps = {
	handleClose: () => void;
	open: boolean;
};

export const LiteQuiiikModal = (props: LiteQuiiikModalProps) => {
	const { handleClose, open } = { ...props };
	// consts, methods, functions, useEffects, useState ect go here
	const classes = useStyles();

	return (
		<LiteModal
			title="Thanks for your interest"
			titleVariant="light"
			handleClose={handleClose}
			open={open}
			modalSize="small"
			actions={
				<FlatButton onClick={handleClose} variant="contained" color="primary">
					Done
				</FlatButton>
			}
		>
			<Typography className={classes.text} variant="body2">
				We're thrilled that you're interested in adding additional Quiiik Pages. Your curiosity and support mean
				the world to us! We’ll be sure to update you as soon as more Quiiik Pages become available.
			</Typography>
		</LiteModal>
	);
};
