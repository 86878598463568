import { format } from 'date-fns';
import { AnalyticsDate, AnalyticsId } from './Analytics';

export interface DownloadMetadata {
	exhibitId: string;
	areaId: string;
	locationId: string;
	organizationId: string;
	channel?: string;
	feature?: string;
	platform?: string;
	browser?: string;
}

export interface DownloadResponse {
	_id: AnalyticsId;
	udid: string;
	type: string;
	os: string;
	createdDate: AnalyticsDate;
	modifiedDate: AnalyticsDate;
	metadata: DownloadMetadata;
}

/**
 * This function aggregates download data from the raw data.
 *
 * @param rawData - DownloadResponse[] data from the API
 * @returns downloadsChartData
 */
export const aggregateDownloads = (rawData: DownloadResponse[]) => {
	// create a map to store the aggregated data
	let downloadsChartData = new Map<string, number>();
	// iterate over the raw data
	rawData.forEach((item) => {
		// convert the item.createdDate to MM/DD/YYYY format using the date-fns library
		let date = format(new Date(parseInt(item.createdDate.$date.$numberLong)), 'MM/dd/yy');
		date = `${date}`;
		// set the key/value in the map
		downloadsChartData.set(date, (downloadsChartData.get(date) || 0) + 1);
	});

	// convert the map to an array of objects, make consumable for our charts
	let retVal = [];
	downloadsChartData.forEach((value, key) => {
		return retVal.unshift([key, value]);
	});

	return retVal;
};
