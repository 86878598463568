import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { subDays } from 'date-fns';
import {
	PageViewsType,
	SessionPayload,
	TopContentType,
	TrafficDailyPayload,
	UserTraffic,
	ViewsByPayload,
} from '../models';
import { TrafficPayload } from '../models/analytics/Traffic';

export type DateRange = {
	startDate: string;
	endDate: string;
};
export interface AnalyticsState {
	topContent: TopContentType[];
	userTraffic: UserTraffic[];
	// newTraffic: UserTraffic[];
	// returningTraffic: UserTraffic[];
	pageViews: PageViewsType[];
	dateRange: DateRange;
	isLoading: boolean;
	sessionData: SessionPayload;
	viewsBy: ViewsByPayload;
	downloadsData: [date: string, downloads: number][];
	trafficDaily: TrafficDailyPayload;
	hourly: [key: number, value: number][];
	rawData: any[];
}

const initAnalyticsState: AnalyticsState = {
	topContent: [],
	userTraffic: [],
	// newTraffic: [],
	// returningTraffic: [],
	pageViews: [],
	dateRange: {
		startDate: subDays(new Date().setHours(23, 59, 59, 999), 30).toISOString(),
		endDate: new Date(new Date().setHours(23, 59, 59, 999)).toISOString(),
	},
	isLoading: false,
	sessionData: { session: [], sessionDuration: { averageSession: '0', bounceRate: '0' } },
	viewsBy: { app: 0, history: 0, link: 0, scan: 0, share: 0 },
	downloadsData: [],
	trafficDaily: { Sun: 0, Mon: 0, Tue: 0, Wed: 0, Thu: 0, Fri: 0, Sat: 0 },
	hourly: [],
	rawData: [],
};

const analyticsSlice = createSlice({
	name: 'analytics',
	initialState: initAnalyticsState,
	reducers: {
		newDateRange: (state, action: PayloadAction<DateRange>) => {
			let { startDate, endDate } = action.payload;
			//force a consistent format for the date range stored in Redux
			endDate = new Date(endDate).toISOString();
			startDate = new Date(startDate).toISOString();
			state.dateRange = { startDate, endDate };
			state.isLoading = true;
		},
		getPageViews: (state, action: PayloadAction<PageViewsType[]>) => {
			state.pageViews = action.payload;
		},
		getTraffic: (state, action: PayloadAction<TrafficPayload>) => {
			// state.newTraffic = action.payload.newTraffic;
			// state.returningTraffic = action.payload.returningTraffic;
			state.userTraffic = action.payload.userTraffic;
		},
		getTopContent: (state, action: PayloadAction<TopContentType[]>) => {
			state.topContent = action.payload;
		},
		getSession: (state, action: PayloadAction<SessionPayload>) => {
			state.sessionData = action.payload;
		},
		getViewsBy: (state, action: PayloadAction<ViewsByPayload>) => {
			state.viewsBy = action.payload;
		},
		getDownloads: (state, action: PayloadAction<[date: string, downloads: number][]>) => {
			state.downloadsData = action.payload;
		},
		getTrafficDaily: (state, action: PayloadAction<TrafficDailyPayload>) => {
			state.trafficDaily = action.payload;
		},
		getHourly: (state, action: PayloadAction<[key: number, value: number][]>) => {
			state.hourly = action.payload;
		},
		setIsLoading: (state, action: PayloadAction<boolean>) => {
			state.isLoading = action.payload;
		},
		getAnalyticsData: (state, action: PayloadAction<any[]>) => {
			//LiiingoMiddleware will catch this action and fire the API call, returning the data to this reducer
			state.rawData = action.payload;
		},
	},
	extraReducers: (builder) => {},
});

// Export reducer
export default analyticsSlice.reducer;

// Export actions
export const {
	newDateRange,
	getPageViews,
	setIsLoading,
	getTopContent,
	getTraffic,
	getSession,
	getViewsBy,
	getDownloads,
	getTrafficDaily,
	getHourly,
	getAnalyticsData,
} = analyticsSlice.actions;

// Export selectors
export const _topContent = (state) => state.analytics.topContent;
export const _userTraffic = (state) => state.analytics.userTraffic;
// export const _newTraffic = (state) => state.analytics.newTraffic;
// export const _returningTraffic = (state) => state.analytics.returningTraffic;
export const _pageViews = (state) => state.analytics.pageViews;
export const _sessionData = (state) => state.analytics.sessionData;
export const _downloadsData = (state) => state.analytics.downloadsData;
export const _hourly = (state) => state.analytics.hourly;
export const _dateRange = (state) => state.analytics.dateRange;
export const _isLoading = (state) => state.analytics.isLoading;
export const _viewsBy = (state) => state.analytics.viewsBy;
export const _trafficDaily = (state) => state.analytics.trafficDaily;
export const _rawData = (state) => state.analytics.rawData;
