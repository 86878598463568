import Box from '@mui/material/Box';
import ListItem from '@material-ui/core/ListItem';
import { Theme } from '@material-ui/core/styles/createTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useVideoOptionField } from '../../hooks/useVideoUploadField';
import { colors } from '../../theme/palette';
import { VIDEO_PREVIEW } from '../../util/constants';
import { PreviewMinHeight } from './ImageOption';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		preview: {
			padding: 0,
		},
		previewBox: {
			minWidth: '100%',
			minHeight: PreviewMinHeight,
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			backgroundColor: colors.grayLight5,
		},
	})
);

export type VideoOptionProps = {
	videoUrl?: string;
	setVideo: (file: File) => void;
	isDrag?: boolean;
	isOver?: boolean;
	isValidType?: boolean;
};

export const VideoOption = (props: VideoOptionProps) => {
	const { videoUrl, setVideo, isDrag, isOver, isValidType } = { ...props };
	const classes = useStyles();

	const [VideoOptionField, videoFieldProps] = useVideoOptionField({
		fieldName: `video`,
		initialValue: videoUrl,
		setVideo: setVideo,
		videoType: VIDEO_PREVIEW,
		isDrag: isDrag,
		isOver: isOver,
		isValidType: isValidType,
	});

	return (
		<ListItem key={`video_${videoUrl}`} disableGutters={true} className={classes.preview}>
			<Box className={classes.previewBox}>
				<VideoOptionField {...videoFieldProps} />
			</Box>
		</ListItem>
	);
};
