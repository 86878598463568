import { useContext } from 'react';
import { Redirect } from 'react-router-dom';
import { OnboardingFlowContext } from '../context/OnboardingFlowContext';

// const useStyles = makeStyles((theme: Theme) =>
//   createStyles({
//     root: {
//       display: 'flex',
//       alignItems: 'center',
//       justifyContent: 'center',
//       paddingTop: theme.spacing(1), // Don't let content overlap the floating logo button
//     },
//     card: {
//       backgroundColor: theme.palette.background.paper,
//       padding: theme.spacing(16),
//     },
//     spinner: {
//       display: 'flex',
//       flex: 1,
//       justifyContent: 'center',
//       alignItems: 'center',
//       flexDirection: 'column',
//     },
//   })
// );

const PaymentConfirmation = () => {
	// const sendDataToGTM = useGTMDispatch();
	// const params = useQueryStringParams();
	// const classes = useStyles();
	const onboardingFlowContext = useContext(OnboardingFlowContext);
	// const productCode = onboardingFlowContext.essentialTemplate?.stripeProductCode;
	// const sessionId = params.get('sessionId');
	// const { handleError } = useContext(ErrorContext);
	// const [mutationInProgress, setMutationInProgress] = useState(true);
	// window.localStorage.setItem(`stripeCheckout${productCode}`, 'success');
	// const onCompleted = async () => {
	//   sendDataToGTM({ event: 'payment_received', username: 'hidden', route: onboardingFlowContext.route });
	//   setMutationInProgress(false);
	// };

	// const [verifyPaymentMutation, { called }] = useMutation(VERIFY_PAYMENT_MUTATION, {
	//   onCompleted,
	//   onError: handleError,
	// });

	// useEffect(() => {
	//   if (!called) {
	//     verifyPaymentMutation({
	//       variables: {
	//         checkoutSessionId: sessionId,
	//         isUpgrade: params.get('isUpgrade') === 'true',
	//       },
	//     });
	//   }
	// }, [called, params, sessionId, verifyPaymentMutation]);

	// no longer a neeed to verify payments
	// if (false) {
	//   return (
	//     <Container className={classes.root}>
	//       <Card className={classes.card}>
	//         <CardContent className={classes.spinner}>
	//           <Throbber />
	//           <Typography variant="h2" align="center" paragraph>
	//             Please wait, we're verifying your payment
	//           </Typography>
	//         </CardContent>
	//       </Card>
	//     </Container>
	//   );
	// }
	return <Redirect to={`/${onboardingFlowContext.route}/app-editor?emailVerified=1`} />;
};

export default PaymentConfirmation;
