import Box from '@mui/material/Box';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { EditLogo } from '../../components/Editable/EditLogo';
import { colors } from '../../theme/palette';
import { HEADER_LOGO } from '../../util/constants';
import { Focusable } from '../Editable/Focusable';
import Bottom from './IphoneStatusbarBottom';
import Top from './IphoneStatusbarTop';
import { IPHONE_RADIUS } from './IPhoneViewer';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		logo: {
			height: 50,
			width: 50,
			borderStyle: 'solid',
			borderColor: colors.grayMedium,
		},
		box: {
			alignItems: 'center',
			width: 375,
			height: 144,
			display: 'flex',
			flexDirection: 'column',
		},
		headerTop: {
			minWidth: '100%',
			borderTopLeftRadius: IPHONE_RADIUS,
			borderTopRightRadius: IPHONE_RADIUS,
		},
		headerBottom: {
			minWidth: '100%',
		},
	})
);

export type IphoneStatusbarProps = {
	onFocusItemName?: (id: string) => void;
	isFocused?: boolean;
	logoUrl: string;
};

const IphoneStatusbar = (props: IphoneStatusbarProps) => {
	const { isFocused, logoUrl } = { ...props };
	const classes = useStyles();
	return (
		<Box className={classes.box}>
			<Top className={classes.headerTop} />
			<Focusable id={HEADER_LOGO} isFocused={isFocused} onFocus={() => {}} label="Logo">
				<EditLogo src={logoUrl} />
			</Focusable>
			<Bottom className={classes.headerBottom} />
		</Box>
	);
};

export default IphoneStatusbar;
