import { createSvgIcon } from '@material-ui/core';
import * as React from 'react';

const SvgTemporary = (props) => (
	<svg width={12} height={12} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M7.45866 7.28335L9.15032 8.27502L8.68366 9.03335L6.58366 7.75002V4.83335H7.45866V7.28335ZM11.8337 7.16669C11.8337 9.73919 9.73949 11.8334 7.16699 11.8334C5.98866 11.8334 4.91533 11.39 4.09283 10.6667H1.33366C0.662826 10.6667 0.166992 10.1709 0.166992 9.50002V4.25002C0.166992 3.59669 0.686159 3.10669 1.33366 3.08335V2.79169C1.33366 1.33919 2.50616 0.166687 3.95866 0.166687C5.32366 0.166687 6.43199 1.21085 6.56033 2.54669C6.75866 2.51752 6.96283 2.50002 7.16699 2.50002C9.73949 2.50002 11.8337 4.59419 11.8337 7.16669ZM2.50033 3.08335H5.41699V2.65169C5.34699 1.91085 4.71699 1.33335 3.95866 1.33335C3.15366 1.33335 2.50033 1.98669 2.50033 2.79169V3.08335ZM10.667 7.16669C10.667 5.23585 9.09783 3.66669 7.16699 3.66669C5.23616 3.66669 3.66699 5.23585 3.66699 7.16669C3.66699 9.09752 5.23616 10.6667 7.16699 10.6667C9.09783 10.6667 10.667 9.09752 10.667 7.16669Z"
			fill="#92A0AC"
		/>
	</svg>
);

const Icon = createSvgIcon(
	<path d="M7.45866 7.28335L9.15032 8.27502L8.68366 9.03335L6.58366 7.75002V4.83335H7.45866V7.28335ZM11.8337 7.16669C11.8337 9.73919 9.73949 11.8334 7.16699 11.8334C5.98866 11.8334 4.91533 11.39 4.09283 10.6667H1.33366C0.662826 10.6667 0.166992 10.1709 0.166992 9.50002V4.25002C0.166992 3.59669 0.686159 3.10669 1.33366 3.08335V2.79169C1.33366 1.33919 2.50616 0.166687 3.95866 0.166687C5.32366 0.166687 6.43199 1.21085 6.56033 2.54669C6.75866 2.51752 6.96283 2.50002 7.16699 2.50002C9.73949 2.50002 11.8337 4.59419 11.8337 7.16669ZM2.50033 3.08335H5.41699V2.65169C5.34699 1.91085 4.71699 1.33335 3.95866 1.33335C3.15366 1.33335 2.50033 1.98669 2.50033 2.79169V3.08335ZM10.667 7.16669C10.667 5.23585 9.09783 3.66669 7.16699 3.66669C5.23616 3.66669 3.66699 5.23585 3.66699 7.16669C3.66699 9.09752 5.23616 10.6667 7.16699 10.6667C9.09783 10.6667 10.667 9.09752 10.667 7.16669Z" />,
	'Temporary'
);

export const TemporaryIcon = (props) => <Icon {...props} viewBox="-6 -6 24 24" />;

export default SvgTemporary;
