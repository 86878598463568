import Container from '@material-ui/core/Container';
import createStyles from '@material-ui/core/styles/createStyles';
import { Theme } from '@material-ui/core/styles/createTheme';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Route, Switch } from 'react-router';
import { LeftNavMenuItem } from '../components/Navigation/LeftNav';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: 'flex',
			paddingTop: theme.spacing(1), // Don't let content overlap the floating logo button
		},
		content: {
			backgroundColor: theme.palette.background.default,
		},
		backdropCard: {
			maxWidth: 704,
			marginLeft: theme.spacing(2),
			marginRight: theme.spacing(2),
		},
	})
);

export type ProfilePageProps = {
	menuItems: LeftNavMenuItem[];
	appRoute: string;
};

const Profile = (props: ProfilePageProps) => {
	const { appRoute, menuItems } = props;
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<Container component="main" className={classes.content} maxWidth="md">
				<Switch>
					{menuItems.map((menu) => (
						// If an array of 'locationsThatMatch' is defined for this menu item, attempt to match those first.
						// Otherwise, just match the route that the leftNav item links to.
						// This allows pages with children to perform their own subrouting.
						<Route exact path={menu.locationsThatMatch ?? menu.route} key={menu.route}>
							<menu.component />
						</Route>
					))}
					<Route exact path={appRoute} key={appRoute}></Route>
				</Switch>
			</Container>
		</div>
	);
};

export default Profile;
