import * as React from 'react';

function SvgIphoneStatusbarTop(props) {
	return (
		<svg width={375} height={44} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path fill="#fff" d="M0 0h375v44H0z" />
			<path
				d="M78-2h219a5.047 5.047 0 00-3.655 4.684l-.028.878c0 13.497-10.942 24.438-24.439 24.438H106.122C92.625 28 81.683 17.059 81.683 3.562l-.029-.878A5.045 5.045 0 0078-2z"
				fill="#181B20"
			/>
			<path
				opacity={0.35}
				d="M336.5 20c0-1.197.97-2.167 2.167-2.167h16.666c1.197 0 2.167.97 2.167 2.167v6c0 1.196-.97 2.166-2.167 2.166h-16.666A2.167 2.167 0 01336.5 26v-6z"
				stroke="#181B20"
			/>
			<path opacity={0.4} d="M359 21v4a2.17 2.17 0 000-4z" fill="#181B20" />
			<path
				d="M338 20.666c0-.736.597-1.333 1.333-1.333h15.334c.736 0 1.333.597 1.333 1.333v4.667c0 .736-.597 1.333-1.333 1.333h-15.334A1.333 1.333 0 01338 25.333v-4.667z"
				fill="#181B20"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M323.33 19.608a8.64 8.64 0 015.953 2.378.308.308 0 00.433-.003l1.156-1.167a.321.321 0 00-.002-.456 10.897 10.897 0 00-15.08 0 .321.321 0 00-.003.456l1.156 1.167c.119.12.312.121.433.003a8.642 8.642 0 015.954-2.378zm0 3.796c1.217 0 2.391.452 3.294 1.27a.31.31 0 00.433-.006l1.155-1.167a.321.321 0 00-.005-.46 7.159 7.159 0 00-9.752 0 .321.321 0 00-.004.459l1.154 1.168a.31.31 0 00.433.005 4.908 4.908 0 013.292-1.27zm2.313 2.553a.312.312 0 01-.094.23l-1.997 2.017a.314.314 0 01-.444 0l-1.997-2.016a.312.312 0 01.009-.457 3.423 3.423 0 014.42 0c.064.058.101.14.103.226zM309.667 17.666h-1a1 1 0 00-1 1v8.667a1 1 0 001 1h1a1 1 0 001-1v-8.666a1 1 0 00-1-1zM304 20h1a1 1 0 011 1v6.333a1 1 0 01-1 1h-1a1 1 0 01-1-1V21a1 1 0 011-1zm-3.667 2.333h-1a1 1 0 00-1 1v4a1 1 0 001 1h1a1 1 0 001-1v-4a1 1 0 00-1-1zm-4.666 2h-1a1 1 0 00-1 1v2a1 1 0 001 1h1a1 1 0 001-1v-2a1 1 0 00-1-1z"
				fill="#181B20"
			/>
			<path
				d="M37.32 28.256c2.689 0 4.285-2.102 4.285-5.661 0-1.34-.256-2.468-.747-3.34-.71-1.355-1.933-2.087-3.479-2.087-2.3 0-3.925 1.545-3.925 3.713 0 2.036 1.465 3.515 3.479 3.515 1.237 0 2.24-.578 2.739-1.582h.124c0 2.417-.915 3.86-2.46 3.86-.909 0-1.612-.498-1.854-1.296H33.6c.315 1.736 1.787 2.878 3.72 2.878zm.067-5.361c-1.216 0-2.08-.864-2.08-2.073 0-1.179.908-2.08 2.087-2.08 1.18 0 2.087.916 2.087 2.11 0 1.179-.886 2.043-2.094 2.043zM44.697 28.154c.695 0 1.171-.498 1.171-1.157 0-.667-.476-1.158-1.171-1.158-.689 0-1.172.491-1.172 1.158 0 .659.483 1.157 1.172 1.157zm0-5.493c.695 0 1.171-.491 1.171-1.15 0-.667-.476-1.157-1.171-1.157-.689 0-1.172.49-1.172 1.157 0 .659.483 1.15 1.172 1.15zM52.724 28h1.81v-1.97h1.428v-1.597h-1.429v-7.002h-2.666c-1.867 2.813-3.354 5.164-4.306 6.914v1.685h5.163V28zm-3.413-3.633a79.594 79.594 0 013.34-5.398h.102v5.508h-3.442v-.11zM59.99 28h1.89V17.431h-1.883l-2.76 1.934v1.816l2.629-1.846h.124V28z"
				fill="#000"
			/>
		</svg>
	);
}

export default SvgIphoneStatusbarTop;
