import createStyles from '@material-ui/core/styles/createStyles';
import { Theme } from '@material-ui/core/styles/createTheme';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Tooltip, { TooltipProps } from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import React, { useState } from 'react';
import { colors } from '../theme/palette';
const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		icon: {
			color: colors.redDark,
		},
		'& .MuiTooltip-popper': {
			backgroundColor: colors.grayDark,
			opacity: 0,
			transition: 'opacity 0.5s',
			'&:hover': {
				opacity: 1,
			},
			'&:focus-within': {
				opacity: 0,
			},
		},
		arrow: {
			color: colors.grayDark,
		},
		tooltip: {
			backgroundColor: colors.grayDark,
			textAlign: 'center',
		},
		msg: {
			color: colors.pureWhite,
		},
	})
);
export type LiiingoTooltipPosition =
	| 'bottom'
	| 'left'
	| 'right'
	| 'top'
	| 'bottom-end'
	| 'bottom-start'
	| 'left-end'
	| 'left-start'
	| 'right-end'
	| 'right-start'
	| 'top-end'
	| 'top-start';
export type LiiingoTooltipProps = {
	message: string;
	placement?: LiiingoTooltipPosition;
	delay?: number;
	show?: boolean;
};

const defaultProps: LiiingoTooltipProps = {
	message: 'Warning',
	placement: 'bottom-start',
	delay: 100, //milliseconds 1000 ms = 1 sec
	show: true,
};

export const LiiingoTooltip: React.FC<LiiingoTooltipProps & Partial<TooltipProps>> = (props) => {
	const classes = useStyles();

	const { children, message, placement, show, ...rest } = { ...defaultProps, ...props };
	const [open, setOpen] = useState(false);

	const prepargeMessage = (
		<Typography variant="body2" className={classes.msg}>
			{message}
		</Typography>
	);

	return show ? (
		<Tooltip
			arrow
			open={open}
			placement={placement}
			title={prepargeMessage}
			classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
			onClose={() => setOpen(false)}
			onOpen={() => setOpen(true)}
			onClick={() => setOpen(false)}
			onKeyPress={(e) => {
				if (e.key === 'Enter') {
					setOpen(false);
				}
			}}
			{...rest}
		>
			<div>{children}</div>
		</Tooltip>
	) : (
		<div>{children}</div>
	);
};
