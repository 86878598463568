import { ReactNode, useCallback, useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { _topicIsLoading, duplicateTopic, updateQr } from '../../../store/slices/topicSlice';
import { LiiingoContextMenu } from '../../Dashboard/CardComponents/Menu/LiiingoContextMenu';
import { getZip } from '../../../util/getZip';

export type PageContextMenuProps = {
	children: ReactNode;
	topicId: string;
	disableDelete: boolean;
	disableDown: boolean;
	disableUp: boolean;
	editPageName: () => void;
	deletePage: () => void;
	copyPageLink: () => void;
	moveUp: () => void;
	moveDown: () => void;
	socialSharing: () => void;
};

export const PageContextMenu = (props: PageContextMenuProps) => {
	const {
		children,
		topicId,
		disableDelete,
		disableDown,
		disableUp,
		editPageName,
		copyPageLink,
		deletePage,
		moveUp,
		moveDown,
		socialSharing,
	} = {
		...props,
	};

	const dispatch = useAppDispatch();
	const checkQrStatus = bindActionCreators(updateQr, dispatch);
	const duplicateTopicBinder = bindActionCreators(duplicateTopic, dispatch);
	const topicIsLoading = useAppSelector(_topicIsLoading);
	const [options, setOptions] = useState([]);
	const index = 3;
	useEffect(() => {
		let baseOptions = [
			{
				text: 'Copy Page Link',
				handleClick: copyPageLink,
			},
			{
				text: 'Download Page QR Code',
				handleClick: () => getZip(topicId),
			},
			{
				text: 'Sharing',
				handleClick: socialSharing,
			},
			{ text: 'Rename', handleClick: editPageName },
			{
				text: 'Delete',
				disabled: disableDelete,
				handleClick: deletePage,
			},
			{
				text: 'Move Up',
				disabled: disableUp,
				handleClick: moveUp,
			},
			{
				text: 'Move Down',
				disabled: disableDown,
				handleClick: moveDown,
			},
		];
		if (topicIsLoading) {
			setOptions([
				...baseOptions.slice(0, index),
				{
					text: 'Loading...',
					disabled: true,
					handleClick: () => {},
				},
				...baseOptions.slice(index),
			]);
		} else {
			setOptions([
				...baseOptions.slice(0, index),
				{ text: 'Duplicate', handleClick: () => duplicateTopicBinder(topicId) },
				...baseOptions.slice(index),
			]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [topicIsLoading]);

	return (
		<LiiingoContextMenu
			id={topicId}
			onOpen={() => {
				checkQrStatus({ id: topicId });
			}}
			options={options}
		>
			{children}
		</LiiingoContextMenu>
	);
};
