// Parent component is QuiiikPageCardWrapper
// this is the table that holds the edit button & the list of Quiiik pages

import { Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAppSelector } from '../../store/hooks';
import { TopicMap, _topics } from '../../store/slices/topicSlice';
import { FlatButton } from '../Buttons/FlatButton';

// styling for the table
const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			flexGrow: 1,
			maxWidth: 752,
		},
		demo: {
			backgroundColor: theme.palette.background.paper,
		},
		title: {
			margin: theme.spacing(4, 0, 2),
		},
		table: {
			maxWidth: '100%',
		},
		row: {
			height: 70,
			'&:nth-of-type(odd)': {
				backgroundColor: theme.palette.action.hover,
			},
		},
		cell: {
			fontSize: 14,
		},
	})
);

// Props set up here
export type QuiiikPageCardTableProps = {};

export const QuiiikPageCardTable = (props: QuiiikPageCardTableProps) => {
	// All consts & methods, functions, useEffects, useState ect go here
	const classes = useStyles();
	const history = useHistory();
	const topics: TopicMap = useAppSelector(_topics);
	const [data, setData] = useState([]);

	// Methods, functions, useEffects, useState ect go here

	// convert topics to an array of objects and use it to set the data state
	useEffect(() => {
		let tempData = Object.values(topics);
		setData(tempData);
	}, [topics]);

	// This is to make sure that the table has 8 rows at all times
	if (data?.length < 8) {
		let emptyArray = new Array(8 - data.length).fill({ name: '' });
		data.push(...emptyArray);
	}

	return (
		<TableContainer component={Paper}>
			<Table className={classes.table} aria-label="customized table">
				<TableBody component="tbody">
					{data?.map((topic, index) => (
						<TableRow key={topic.id ? topic.id : index} className={classes.row}>
							<TableCell>{topic.name[0]?.language === 'en' ? topic.name[0]?.name : ''}</TableCell>
							<TableCell align="right">
								{topic.id ? (
									<FlatButton
										variant="text"
										onClick={() => history.push(`app-editor?id=${topic.id}`)}
										color="secondary"
									>
										Edit
									</FlatButton>
								) : (
									''
								)}
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
};
