// This is the "landing page" that displays all the cards for the user to interact with on the lite dashboard
import { Container, Grid, Typography } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import QRGeneratorCardWrapper from '../components/LiteDashboardComponents/QRGeneratorCardWrapper';
import QuiiikPageCardWrapper from '../components/LiteDashboardComponents/QuiiikPageCardWrapper';
import { Keyframes } from '../theme/Keyframes';
import { useHydrateStore } from '../hooks/useHydrateStore';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		containerWidth: {
			width: '100%',
		},
		topGrid: {
			margin: 0,
		},
		dashboardTitle: {
			padding: 8,
		},
		fade: {
			animation: 'fade 1s ease .5s both',
		},
	})
);

export type LiteDashboardProps = {
	// Props go here
};

//Below is the component itself which is the parent to all the cards
// Pass props into the component so they are accessible in the return statement

export const LiteDashboard = (props: LiteDashboardProps) => {
	// All consts & methods, functions, useEffects, useState ect go here

	const classes = useStyles();
	useHydrateStore();

	// Methods, functions, useEffects, useState ect go here

	return (
		<Container className={classes.containerWidth}>
			<Keyframes name="fade" _0={{ opacity: 0 }} _100={{ opacity: 1 }} />
			<Typography variant="h5" className={classes.dashboardTitle}>
				Dashboard
			</Typography>
			<Grid container spacing={2} className={classes.topGrid}>
				<Grid item lg={7} className={classes.fade}>
					<Grid item>
						<QRGeneratorCardWrapper />
					</Grid>
				</Grid>
				<Grid item lg={5} className={classes.fade}>
					<QuiiikPageCardWrapper />
				</Grid>
			</Grid>
		</Container>
	);
};
