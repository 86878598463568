import { default as React, Suspense, useContext, useEffect } from 'react';
import { Route, Switch, useHistory } from 'react-router';
import { Redirect } from 'react-router-dom';
import { Throbber } from './components';
import LoadingComponent from './components/LiteDashboardComponents/LoadingComponent';
import { LeftNavMenuItem } from './components/Navigation/LeftNav';
import { AccountSettings, Dashboard } from './components/ProfileMenus';
import { TimeBomb } from './components/TrialDays/TimeBomb';
import { OnboardingFlowContext } from './context/OnboardingFlowContext';
import { Analytics } from './pages';
import { LiteDashboard } from './pages/LiteDashboard';
import { useAppSelector } from './store/hooks';
import { _plan } from './store/slices/editorSlice';
import { PageWithNavRoute, PageWithoutNavRoute, PrivatePageRoute } from './util/SpecialRoutes';
import { TITLE_MAP } from './util/constants';
const PaymentCanceled = React.lazy(() => import('./pages/PaymentCanceled'));
const PaymentConfirmation = React.lazy(() => import('./pages/PaymentConfirmation'));
const InviteAccept = React.lazy(() => import('./pages/InviteAccept'));
const NotFound = React.lazy(() => import('./pages/NotFound'));
const SignIn = React.lazy(() => import('./pages/SignIn'));
const SignOut = React.lazy(() => import('./pages/SignOut'));
const SignUp = React.lazy(() => import('./pages/SignUp'));
const ForgotPassword = React.lazy(() => import('./pages/ForgotPassword'));
const AppEditor = React.lazy(() => import('./pages/AppEditor'));

const menuItems: LeftNavMenuItem[] = [];

const bottomMenuItems: LeftNavMenuItem[] = [];

export default function App() {
	const onboardingFlowContext = useContext(OnboardingFlowContext);
	const history = useHistory();
	const plan = useAppSelector(_plan);

	// useEffect to detect if the url has double slashes (mistake) and redirect to a url containing only single slashes
	useEffect(() => {
		if (window.location.pathname.includes('//')) {
			const newPath = window.location.pathname.replace(/\/\//g, '/');
			window.location.assign(newPath);
		}
	});

	// set page Title on location change, mostly for analytics readabilty
	useEffect(() => {
		const path = history.location.pathname.split('/');
		const title = path
			.map((subpath) => {
				// mutate path names with lookup object
				return TITLE_MAP[subpath] ?? subpath;
			})
			// remove any items that are undefined
			.filter((pageTitle) => pageTitle)
			.join(' | ');
		window.document.title = title;
	}, [history.location]);

	const menuItemsWithNestedPathPrepended = menuItems.map((menuItem) => {
		return {
			...menuItem,
			route: `/${onboardingFlowContext.route}${menuItem.route}`,
			locationsThatMatch: menuItem.locationsThatMatch?.map(
				(locationToMatch) => `/${onboardingFlowContext.route}${locationToMatch}`
			),
		};
	});

	return (
		<Suspense fallback={<Throbber withOverlay />}>
			<TimeBomb />
			<Switch>
				<Route exact path={`/${onboardingFlowContext.route}/sign-up`} component={SignUp} />
				<Route exact path={`/${onboardingFlowContext.route}/sign-in`} component={SignIn} />
				<PageWithoutNavRoute exact path={`/${onboardingFlowContext.route}/sign-out`} component={SignOut} />
				<Route exact path={`/${onboardingFlowContext.route}/forgot-password`} component={ForgotPassword} />
				<PageWithoutNavRoute
					exact
					path={`/${onboardingFlowContext.route}/payment-canceled`}
					component={PaymentCanceled}
				/>
				<PageWithoutNavRoute
					exact
					path={`/${onboardingFlowContext.route}/payment-confirmation`}
					component={PaymentConfirmation}
				/>
				<PageWithoutNavRoute
					exact
					path={`/${onboardingFlowContext.route}/invite-accept`}
					component={InviteAccept}
				/>

				{/* APP EDITOR */}
				<PrivatePageRoute path={`/${onboardingFlowContext.route}/app-editor`}>
					<AppEditor />
				</PrivatePageRoute>

				{/* OTHER PAGES WITH TOP AND LEFT NAVE BARS SHOWN, USER LOGGGED IN */}
				<PrivatePageRoute path={`/${onboardingFlowContext.route}/dashboard`}>
					<PageWithNavRoute menuItems={menuItemsWithNestedPathPrepended} bottomMenuItems={bottomMenuItems}>
						{/* if plan.name is null render the LoadingComponent, or if plan.name is Lite render 
						LiteDashboard, otherwise load Dashboard */}
						{plan?.name === null || plan?.name === undefined ? (
							<LoadingComponent />
						) : plan?.name === 'Lite' ? (
							<LiteDashboard />
						) : (
							<Dashboard />
						)}
					</PageWithNavRoute>
				</PrivatePageRoute>

				<PrivatePageRoute path={`/${onboardingFlowContext.route}/analytics`}>
					<PageWithNavRoute menuItems={menuItemsWithNestedPathPrepended} bottomMenuItems={bottomMenuItems}>
						<Analytics />
					</PageWithNavRoute>
				</PrivatePageRoute>

				<PrivatePageRoute path={`/${onboardingFlowContext.route}/billing`}>
					<PageWithNavRoute menuItems={menuItemsWithNestedPathPrepended} bottomMenuItems={bottomMenuItems}>
						<AccountSettings />
					</PageWithNavRoute>
				</PrivatePageRoute>

				{/* if the path is malformed, like /<AppRoute>/profile, or /<AppRoute>, or '/', or '//', then redirect to the dashboard page */}
				<PrivatePageRoute path={`/${onboardingFlowContext.route}/`}>
					<Redirect to={`/${onboardingFlowContext.route}/dashboard`} />
				</PrivatePageRoute>
				<PrivatePageRoute path={`/${onboardingFlowContext.route}`}>
					<Redirect to={`/${onboardingFlowContext.route}/dashboard`} />
				</PrivatePageRoute>
				<PrivatePageRoute path="/">
					<Redirect to={`/${onboardingFlowContext.route}/dashboard`} />
				</PrivatePageRoute>
				<PrivatePageRoute path="//">
					<Redirect to={`/${onboardingFlowContext.route}/dashboard`} />
				</PrivatePageRoute>

				{/* No need to show 404 if you're not logged in yet */}
				<PrivatePageRoute path="*" menuItems={menuItemsWithNestedPathPrepended}>
					<NotFound />
				</PrivatePageRoute>
			</Switch>
		</Suspense>
	);
}
