import ReactDOM from 'react-dom';
import QRCodeCreatorCustom from './QRCodeCustom';
import axiosInstance from '../../../store/api-client';

const QRCodeGeneratorPlugin = (editor) => {
	editor.ui.registry.addIcon(
		'qrcode',
		'<svg width="24" height="24" viewBox="0 0 24 24"><path d="M3 3h6v6H3zm2 2v2h2V5H5zm8-2h6v6h-6zm2 2v2h2V5h-2zM3 13h6v6H3zm2 2v2h2v-2H5zm13-2h3v2h-3zm-3 0h2v3h-2zm3 3h3v3h-3zm-3 0h2v3h-2z"/></svg>'
	);

	editor.ui.registry.addButton('qrcodegenerator', {
		icon: 'qrcode',
		tooltip: 'QR Code Generator',
		onAction: showUploadDialog,
	});

	function showUploadDialog() {
		// Important: Add MUI styles to the TinyMCE dialog
		let qrcode = null;

		const dialogConfig = {
			title: '',
			size: 'medium',
			body: {
				type: 'panel',
				items: [
					{
						type: 'htmlpanel',
						html: '<div id="qrcode-generator-root"></div>',
					},
				],
			},

			buttons: [
				{
					type: 'submit',
					text: 'Save',
					primary: true,
				},
				{
					type: 'cancel',
					text: 'Close',
				},
			],
			onSubmit: (api) => {
				if(qrcode){
					uploadGenQRCode(qrcode);
				}
				
				const root = document.getElementById('qrcode-generator-root');
				if (root) {
					ReactDOM.unmountComponentAtNode(root);
				}
				api.close();
			},
			onClose: () => {
				const root = document.getElementById('qrcode-generator-root');
				if (root) {
					ReactDOM.unmountComponentAtNode(root);
				}
			},
		};

		editor.windowManager.open(dialogConfig);

		// Ensure MUI styles are properly initialized
		setTimeout(() => {
			const reactRoot = document.getElementById('qrcode-generator-root');
			if (reactRoot) {
				ReactDOM.render(
					<div>
						<QRCodeCreatorCustom  setQrImage={(qr)=>{qrcode = qr}} />
					</div>,
					reactRoot
				);
			}
		}, 100);

		const uploadGenQRCode = (base64QRData: string)=>{
			const base64Content = base64QRData.split(',')[1];
			const svgContent = atob(base64Content);

			// Convert the SVG string to a Blob
			const blob = new Blob([svgContent], { type: 'image/svg+xml' });

			// Create a File object from the Blob
			const file = new File([blob], 'image.svg', { type: 'image/svg+xml' });

			// Create FormData and append the file
			const formData = new FormData();
			formData.append('image', file);

			axiosInstance.post('/content/uploadFile', formData).then(response=>{
				const {url} = response.data;

				const content = `<img src="${url}" style="width:100%; object-fit: contain" />`;
				editor.insertContent(content);
				editor.notificationManager.open({
					text: 'QRCode Generated successfully',
					type: 'success',
				});
			});
			
		}
	}

	return {
		getMetadata: () => ({
			name: 'QR Code Generator Plugin',
			url: 'https://example.com',
		}),
	};
};

export default QRCodeGeneratorPlugin;
