import { createSvgIcon } from '@material-ui/core';
import * as React from 'react';

const SvgIncognito = (props) => (
	<svg width={12} height={12} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M8.95297 6.5834C7.86797 6.5834 6.95797 7.35923 6.72464 8.39173C6.17047 8.15257 5.66297 8.21673 5.27797 8.3859C5.0388 7.34757 4.1288 6.5834 3.04964 6.5834C1.7838 6.5834 0.751302 7.62757 0.751302 8.91673C0.751302 10.2059 1.7838 11.2501 3.04964 11.2501C4.2513 11.2501 5.2313 10.3051 5.32464 9.1034C5.52297 8.9634 6.04214 8.7009 6.67797 9.11507C6.78297 10.3109 7.7513 11.2501 8.95297 11.2501C10.2188 11.2501 11.2513 10.2059 11.2513 8.91673C11.2513 7.62757 10.2188 6.5834 8.95297 6.5834ZM3.04964 10.5851C2.13964 10.5851 1.41047 9.8384 1.41047 8.91673C1.41047 7.99507 2.14547 7.2484 3.04964 7.2484C3.95964 7.2484 4.6888 7.99507 4.6888 8.91673C4.6888 9.8384 3.95964 10.5851 3.04964 10.5851ZM8.95297 10.5851C8.04297 10.5851 7.3138 9.8384 7.3138 8.91673C7.3138 7.99507 8.04297 7.2484 8.95297 7.2484C9.86297 7.2484 10.598 7.99507 10.598 8.91673C10.598 9.8384 9.85714 10.5851 8.95297 10.5851ZM11.8346 5.12507H0.167969V6.00007H11.8346V5.12507ZM8.06047 0.534235C7.93214 0.248401 7.60547 0.0967344 7.2963 0.195901L6.0013 0.627568L4.70047 0.195901L4.6713 0.190068C4.36214 0.102568 4.03547 0.265901 3.9188 0.563401L2.5013 4.25007H9.5013L8.07797 0.563401L8.06047 0.534235Z"
			fill="#92A0AC"
		/>
	</svg>
);

const Icon = createSvgIcon(
	<path d="M8.95297 6.5834C7.86797 6.5834 6.95797 7.35923 6.72464 8.39173C6.17047 8.15257 5.66297 8.21673 5.27797 8.3859C5.0388 7.34757 4.1288 6.5834 3.04964 6.5834C1.7838 6.5834 0.751302 7.62757 0.751302 8.91673C0.751302 10.2059 1.7838 11.2501 3.04964 11.2501C4.2513 11.2501 5.2313 10.3051 5.32464 9.1034C5.52297 8.9634 6.04214 8.7009 6.67797 9.11507C6.78297 10.3109 7.7513 11.2501 8.95297 11.2501C10.2188 11.2501 11.2513 10.2059 11.2513 8.91673C11.2513 7.62757 10.2188 6.5834 8.95297 6.5834ZM3.04964 10.5851C2.13964 10.5851 1.41047 9.8384 1.41047 8.91673C1.41047 7.99507 2.14547 7.2484 3.04964 7.2484C3.95964 7.2484 4.6888 7.99507 4.6888 8.91673C4.6888 9.8384 3.95964 10.5851 3.04964 10.5851ZM8.95297 10.5851C8.04297 10.5851 7.3138 9.8384 7.3138 8.91673C7.3138 7.99507 8.04297 7.2484 8.95297 7.2484C9.86297 7.2484 10.598 7.99507 10.598 8.91673C10.598 9.8384 9.85714 10.5851 8.95297 10.5851ZM11.8346 5.12507H0.167969V6.00007H11.8346V5.12507ZM8.06047 0.534235C7.93214 0.248401 7.60547 0.0967344 7.2963 0.195901L6.0013 0.627568L4.70047 0.195901L4.6713 0.190068C4.36214 0.102568 4.03547 0.265901 3.9188 0.563401L2.5013 4.25007H9.5013L8.07797 0.563401L8.06047 0.534235Z" />,
	'Incognito'
);

export const IncognitoIcon = (props) => <Icon {...props} viewBox="-6 -6 24 24" />;

export default SvgIncognito;
