import axios from 'axios';
import axiosInstance from '.';
import { Content, Folder, newContent, newLocation, newOrganization, newSection, newTopic, Status } from '../models';

export const urlToFile = async (url: string, name: string) => {
	const file = await fetch(url)
		.then((r) => r.blob())
		.then((blobFile) => new File([blobFile], name, { type: blobFile.type }));
	return file;
};

export const getContentByIdList = (ids: string[]) => {
	return axiosInstance
		.post('/content/getByIdList', { ids, status: [Status.Live, Status.Hidden] })
		.then((res) => res.data.map((content) => newContent(content)));
};

// this will return the location, areas, topics, and content
export const getEverythingAllAtOnce = () => {
	return axiosInstance.post('/content/getEverythingAllAtOnce', {}).then((res) => {
		let org = res.data[0];
		let location = res.data[1];
		let areas = res.data[2];
		let topics = res.data[3];
		let content = res.data[4];
		org = newOrganization(org);
		location = newLocation(location);
		areas = areas.map((area: any) => newSection(area));
		topics = topics.map((topic: any) => newTopic(topic));
		content = content.map((content: any) => newContent(content));
		return { org, location, areas, topics, content };
	});
};

// This will return all content for the org, even content that is not attached to a page.
export const getAllContent = () => {
	return axiosInstance.post('/content/getAll').then((res) => res.data.map((content) => newContent(content)));
};

// This will only return content found in topic.content[] for all the location topics.
export const getContentByLocationId = (locationId: string) => {
	return axiosInstance
		.post('/content/getByLocationId', { id: locationId })
		.then((res) => res.data.map((content) => newContent(content)));
};

export const saveContent = async (content: Content) => {
	const formData = new FormData();
	let contentClone = structuredClone(content);
	if (contentClone.type === 'imageBtn') {
		contentClone.type = 'webview'; //TODO: Remove need to change the content type - right before saving. This isn't solid.
	}
	for (let i = 0; i < contentClone.languages.length; i++) {
		if (contentClone.languages[i].fileUrl.includes('blob')) {
			const file = await urlToFile(contentClone.languages[i].fileUrl, contentClone.languages[i].fileName);
			if (contentClone.type === 'video') {
				const uploadUrl = await getVideoUploadUrl();
				const videoKey = await uploadVideoToJWPlayer(uploadUrl, file);
				contentClone.languages[i].fileUrl = ''; //neccessary to clear out the fileUrl so it doesn't get saved to the database
				contentClone.languages[i].content = videoKey;
			} else {
				//image
				contentClone.languages[i].fileUrl = '';
				contentClone.languages[i].fileUrlThumb = '';
				formData.append(`file[${i}]`, file);
			}
		} else if (contentClone.languages[i].fileData) {
			formData.append(`file[${i}]`, contentClone.languages[i].fileData);
			delete contentClone.languages[i].fileData;
		}
	}
	formData.append(`content`, JSON.stringify(contentClone));
	return axiosInstance.post('/content/save', formData).then((res: any) => newContent(res.data));
};

export const saveClonedContent = async (content: Content) => {
	return axiosInstance.post('/content/save', content).then((res: any) => newContent(res.data));
};

export const uploadVideoToJWPlayer = (url: string, file: File) => {
	const formData = new FormData();
	formData.append('file', file);
	//use axios directly so we don't get overridden by our axiosInstance's default headers and baseurl
	return axios.post(url, formData).then((res: any) => res.data.media.key);
};

export const getVideoUploadUrl = () => {
	return axiosInstance
		.post<string>('/content/getJWPUploadLink', {})
		.then(
			(res: any) =>
				`https://${res.data.link.address}${res.data.link.path}?api_format=json&key=${res.data.link.query.key}&token=${res.data.link.query.token}`
		);
};

export const getContentById = (fileId: string) => {
	return axiosInstance
		.post<Content>('/content/getById', {
			id: fileId,
		})
		.then((res) => newContent(res.data));
};

export const searchFiles = (searchTerm: string, sortBy?: string, sortOrder?: number) => {
	return axiosInstance
		.post<Content[]>('/content/search', {
			searchTerm: searchTerm,
			sortBy: sortBy,
			sortOrder: sortOrder,
		})
		.then((res: any) => res.data.map((r) => newContent(r)));
};

export const duplicateContent = (id: string) => {
	return axiosInstance
		.post<string>('/content/duplicate', { id })
		.then((res) => res.data);
};

export const deleteContent = (contentId: string) => {
	return axiosInstance.post('/content/delete', { id: contentId }).then((res) => res.data);
};

export const toggleShareContent = (contentId: string) => {
	return axiosInstance.post('/content/share', { id: contentId }).then((res) => res.data);
};

export const getRootFolder = (sortBy?: string, sortOrder?: number) => {
	return axiosInstance
		.post<Folder>('/folder/root', {
			sortBy: sortBy,
			sortOrder: sortOrder,
		})
		.then((res) => new Folder(res.data));
};

export const getFolderById = (folderId: string, sortBy?: string, sortOrder?: number) => {
	return axiosInstance
		.post<Folder>('/folder/get', {
			id: folderId,
			sortBy: sortBy,
			sortOrder: sortOrder,
		})
		.then((res) => new Folder(res.data));
};

export const createNewFolder = (folderName: string, parentFolderId: string) => {
	return axiosInstance
		.post('/folder/create', {
			name: folderName,
			parentFolderId: parentFolderId,
		})
		.then((res) => res.data['folderId']);
};

export const updateFolder = (folderId: string, folderName: string, parentFolderId: string) => {
	return axiosInstance
		.post('/folder/update', {
			id: folderId,
			name: folderName,
			parentFolderId: parentFolderId,
		})
		.then((res) => res.data);
};

export const getImageConent = (
	id: string | number,
	searchTerm: string = '',
	page: number = 1,
	pageSize: number = 10
) => {
	return axiosInstance
		.post(`/content/getOrganizationContent`, { id, page, searchTerm, pageSize })
		.then((res) => res.data);
};

// export const getContentByExhibitId = (topicId: string) => {
//   return axiosInstance
//     .post<Content[]>('/content/getByExhibitId', { id: topicId })
//     .then((res: any) => {
//       return res.data.content.map((r) => newContent(r));
//     });
// };

// export const getContentByTopicId = (topicId: string) => {
//   return axiosInstance
//     .post('/exhibit/getExhibitById', { exhibitId: topicId })
//     .then((res: any) => res.data.exhibit.contentExpanded.map((r) => newContent(r)));
// };

// Can't get content for hidden pages (weird Liiingo API legacy behavior..) so we need to get contentExpanded from /getExhibitById endpoint
// export const getContentByTopicId = (topicId: string) => {
//   return axiosInstance
//     .post<Content[]>('/exhibit/getExhibitById', { exhibitId: topicId })
//     .then((res: any) => res.data.exhibit.contentExpanded.map((r) => newContent(r)));
// };

export const getTopicBackground = async (topicId: string) => {
	console.log('FIX THIS, LATENCY PROBLEM');
	return axiosInstance
		.post<string>('/exhibit/getExhibitById', { exhibitId: topicId })
		.then((res: any) => res.data.exhibit.exhibitImage);
};
