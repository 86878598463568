import { makeStyles } from '@material-ui/core/styles';
import { FlatButton } from '../Buttons/FlatButton';
import { LiteModal } from './LiteModal';
import { TableRowData } from './QRGeneratorTable';

// useStyles

const useStyles = makeStyles({
	iconWrapper: {
		display: 'inline-flex',
		justifyContent: 'space-between',
	},
	imageBox: {
		display: 'flex',
		flexDirection: 'column',
		padding: 15,
	},
	image: {
		width: '100%',
		height: '100%',
	},
});

// props
export type LiteQRViewModalProps = {
	handleClose?: () => void;
	open: boolean;
	rowData: TableRowData;
};

const LiteQRViewModal = (props: LiteQRViewModalProps) => {
	const { handleClose, open, rowData } = { ...props };
	// consts, methods, functions, useEffects, useState ect go here
	const classes = useStyles();

	// downloads QR codes as a zip file
	const handleDownload = () => {
		window.location.href = `${process.env.REACT_APP_LIIINGO_URL}/lite/getZip?id=${rowData.id}`;
		handleClose();
	};

	return (
		<div className={classes.iconWrapper}>
			<LiteModal
				title={rowData.name}
				titleVariant="dark"
				handleClose={handleClose}
				open={open}
				actions={
					<FlatButton variant="contained" color="primary" onClick={handleDownload}>
						Download
					</FlatButton>
				}
			>
				{/* modals content */}
				{/* Display QR code based on ID of clicked QR code */}
				<div className={classes.imageBox}>
					<img
						className={classes.image}
						src={`${process.env.REACT_APP_LIIINGO_URL}/lite/png?id=${rowData.id}`}
						alt={rowData.name}
					></img>
				</div>
			</LiteModal>
		</div>
	);
};

export default LiteQRViewModal;
