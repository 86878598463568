export enum Language {
	Arabic = 'ar',
	English = 'en',
	Persian = 'fa',
	French = 'fr',
	German = 'de',
	Greek = 'el',
	Italian = 'it',
	Japanese = 'ja',
	Kinyarwanda = 'rw',
	Korean = 'ko',
	Norwegian = 'no',
	Portuguese = 'pt',
	Russian = 'ru',
	SimplifiedChinese = 'zh-Hans',
	Somali = 'so',
	Spanish = 'es',
	Swahili = 'sw',
	Turkish = 'tr',
	Vietnamese = 'vi',
}

export const LANGUAGE_MAP = {
	ar: 'Arabic',
	en: 'English',
	fa: 'Persian',
	fr: 'French',
	de: 'German',
	el: 'Greek',
	it: 'Italian',
	ja: 'Japanese',
	ko: 'Korean',
	no: 'Norwegian',
	pt: 'Portuguese',
	ru: 'Russian',
	rw: 'Kinyarwanda',
	so: 'Somali',
	'zh-Hans': 'Simplified Chinese',
	es: 'Spanish',
	sw: 'Swahili',
	tr: 'Turkish',
	vi: 'Vietnamese',
};

// export a type for Language
export const REVERSED_LANGUAGE_MAP = Object.entries(LANGUAGE_MAP).reduce((acc, [key, value]) => {
	acc[value] = key;
	return acc;
}, {});
