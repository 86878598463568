import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { bindActionCreators } from 'redux';
import { useAppDispatch } from '../../store/hooks';
import { deleteRedirect } from '../../store/slices/redirectSlice';
import { FlatButton } from '../Buttons/FlatButton';
import { LiteModal } from './LiteModal';
import { TableRowData } from './QRGeneratorTable';

// useStyles

const useStyles = makeStyles({
	iconWrapper: {
		display: 'inline-flex',
		justifyContent: 'space-between',
	},
	box: {
		display: 'flex',
		flexDirection: 'column',
		padding: 15,
	},
	text: {
		fontSize: 16,
		paddingTop: 8,
		width: '95%',
	},
});

// props
export type LiteDeleteQRModalProps = {
	handleClose?: () => void;
	open: boolean;
	rowData: TableRowData;
};

const LiteDeleteQRModal = (props: LiteDeleteQRModalProps) => {
	const { handleClose, open, rowData } = { ...props };
	// consts, methods, functions, useEffects, useState ect go here
	const classes = useStyles();
	const dispatch = useAppDispatch();
	const deleteData = bindActionCreators(deleteRedirect, dispatch);

	const handleDelete = () => {
		deleteData(rowData.id);
		handleClose();
	};

	return (
		<div className={classes.iconWrapper}>
			<LiteModal
				title="Delete QR Code"
				titleVariant="light"
				handleClose={handleClose}
				open={open}
				modalSize="small"
				actions={
					<>
						<FlatButton onClick={handleClose}>Cancel</FlatButton>
						<FlatButton
							variant="contained"
							type="submit"
							onClick={handleDelete}
							styles={{ background: 'red', color: 'white' }}
						>
							Delete
						</FlatButton>
					</>
				}
			>
				{/* modals content */}
				<Box className={classes.box}>
					<Typography className={classes.text} variant="body2">
						{'Are you sure you want to delete your dynamic QR code? This action cannot be undone.'}
					</Typography>
				</Box>
			</LiteModal>
		</div>
	);
};

export default LiteDeleteQRModal;
