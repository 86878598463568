import InputBase from '@material-ui/core/InputBase';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Skeleton } from '@mui/material';
import Box from '@mui/material/Box';
import React, { useRef, useState } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { bindActionCreators } from 'redux';
import { LiiingoTooltip } from '../components/LiiingoTooltip';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { Location } from '../store/models';
import { _contentIsLoading, selectContent } from '../store/slices/contentSlice';
import {
	_activeLanguageCode,
	_newLanguageIds,
	clearFocusItem,
	removeNewLanguageIds,
} from '../store/slices/editorSlice';
import { _appName, _locationIsLoading, changeLocationName, saveLocation } from '../store/slices/locationSlice';
import { _organization, saveOrganization, updateOrganization } from '../store/slices/organizationSlice';
import { _sections, _selectedSectionId, saveSection, updateSectionName } from '../store/slices/sectionSlice';
import { _topicIsLoading } from '../store/slices/topicSlice';
import { colors } from '../theme/palette';
import { APP_NAME, ORG_NAME, SECTION_NAME_ID, TOPIC_BACKGROUND } from '../util/constants';
import { EditPageImage } from './Editable/EditPageImage';
import { Focusable } from './Editable/Focusable';
import { IPhoneViewer } from './PhoneViewer/IPhoneViewer';

export type TemplateEditorProps = {
	location: Location;
	fields: React.ReactNode[];
	topicBackgroundImageUrl: string;
	onSelect: (contentId: string) => void;
	isFocusedHeaderLogo: boolean;
	isFocusedBackgroundImage: boolean;
	isFocusedAppName: boolean;
	isFocusedOrgName: boolean;
	isFocusedSectionName: boolean;
	headerLogo: string;
};

const defaultProps = {};
export const PreviewMinHeight = 183;

const useStyles = makeStyles<Theme, { dim: boolean }>((theme: Theme) =>
	createStyles({
		container: {
			position: 'relative',
		},
		header: {
			minHeight: PreviewMinHeight,
			height: 'auto',
			backgroundColor: '#a9a9a9',
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'flex-end',
			boxSizing: 'border-box',
			position: 'relative',
		},
		sectionArea: {
			width: 'auto',
			backgroundColor: '#F6F6F7',
			paddingBottom: 16,
		},
		sectionTab: {
			borderBottom: '3px solid #49525D',
			maxWidth: 'calc(100% - 10px)',
			marginLeft: 5,
			marginRight: 5,
		},
		orgNameInHeader: {
			fontFamily: theme.typography.fontFamily,
			fontSize: theme.typography.fontSize,
			color: 'white',
			position: 'absolute',
			bottom: 60,
			width: '100%',
			padding: '0px 10px',
		},
		appNameInHeader: {
			fontFamily: theme.typography.fontFamily,
			fontSize: theme.typography.fontSize,
			color: 'white',
			position: 'absolute',
			bottom: 20,
			width: '100%',
			padding: '0px 10px',
		},
		orgNameInputText: {
			display: 'flex',
			fontFamily: theme.typography.fontFamily,
			fontSize: theme.typography.fontSize - 1,
			color: 'white',
			fontWeight: 'bold',
		},
		appNameInputText: {
			display: 'flex',
			fontFamily: theme.typography.fontFamily,
			fontSize: theme.typography.fontSize - 1,
			color: 'white',
		},
		sectionNameInputText: (props) => ({
			display: 'flex',
			fontFamily: theme.typography.fontFamily,
			fontSize: 12,
			fontWeight: 'bold',
			color: colors.grayDark,
			opacity: props.dim ? 0.5 : 1,
			width: '100%',
			padding: '0px 10px',
		}),
		horizontalMenu: {
			display: 'flex',
			overflow: 'hidden',
			borderBottom: '2px solid #dedede',
			padding: '8px 0px',
		},
		horizontalMenuItem: {
			whiteSpace: 'nowrap',
			padding: '0 5px',
		},
		horizontalMenuItemText: {
			margin: 0,
		},
		horizontalMenuItemTypography: {
			fontSize: 9,
			color: '#8c8c8c',
		},
		contentAreaBelowHorizontalMenu: {
			padding: '0 5px',
		},
		topicImageRoot: {
			display: 'flex',
			flex: 1,
			marginBottom: 8,
		},
		topicDotContainer: {
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			justifyContent: 'center',
			height: '2em',
		},
		topicDot: {
			border: '3px solid #555555',
			width: 5,
			height: 5,
			borderRadius: 2,
		},
		topicImageImage: {
			width: '100%',
			objectFit: 'contain',
		},
		address: {
			textAlign: 'center',
			paddingBottom: 16,
		},
		hoursOfService: {
			textAlign: 'center',
			paddingBottom: 16,
		},
		jwPlayerRoot: {
			display: 'flex',
			marginTop: 10,
			width: '100%',
			height: 130,
			alignItems: 'center',
			justifyContent: 'center',
			flexDirection: 'column',
			overflow: 'hidden',
		},
		buttonName: {
			fontSize: 14,
			fontWeight: 'bold',
			color: '#ffffff',
			padding: 20,
		},
		buttonImage: {
			alignItems: 'center',
			width: '100%',
			height: '100%',
			borderRadius: 5,
		},
		placeholder: {
			height: 188,
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		},
		orgName: {
			fontFamily: 'Open Sans, sans-serif',
			fontSize: 12,
			lineHeight: 32,
			color: colors.grayDark,
			border: 0,
		},
		hiddenSpan: {
			visibility: 'hidden',
			whiteSpace: 'nowrap',
		},
		skeleton: {
			width: '100%',
		},
	})
);

export const TemplateEditor = (props: TemplateEditorProps) => {
	const {
		fields,
		topicBackgroundImageUrl,
		onSelect,
		isFocusedHeaderLogo,
		isFocusedBackgroundImage,
		isFocusedAppName,
		isFocusedOrgName,
		isFocusedSectionName,
		headerLogo,
	} = {
		...defaultProps,
		...props,
	};
	const classes = useStyles({ dim: false });
	const dispatch = useAppDispatch();
	const selectedSectionId = useAppSelector(_selectedSectionId);
	const sections = useAppSelector(_sections);
	// skip checking for loading sections because it happens in parallel with loading topics
	const locationLoading = useAppSelector(_locationIsLoading);
	const topicLoading = useAppSelector(_topicIsLoading);
	const contentLoading = useAppSelector(_contentIsLoading);
	const newLanguageIds = useAppSelector(_newLanguageIds);
	const activeLanguageCode = useAppSelector(_activeLanguageCode);
	const topicBackgroundRef = useRef<HTMLDivElement | null>(null);
	const [isSectionFocus, setIsSectionFocus] = useState(false);
	const isLoading = locationLoading || topicLoading || contentLoading;
	const selectedSection = sections[selectedSectionId];
	const isNewLanguageSectionName = newLanguageIds[activeLanguageCode]?.includes(selectedSectionId);
	const onUpdateSectionName = bindActionCreators(updateSectionName, dispatch);
	const saveSectionName = bindActionCreators(saveSection, dispatch);
	const onRemoveNewLanguage = bindActionCreators(removeNewLanguageIds, dispatch);
	const clearFocusedItem = bindActionCreators(clearFocusItem, dispatch);
	const setSelectedContentId = bindActionCreators(selectContent, dispatch);

	return (
		<IPhoneViewer onFocusItemName={onSelect} isFocused={isFocusedHeaderLogo} logoUrl={headerLogo}>
			<Box className={classes.container}>
				<div ref={topicBackgroundRef}>
					<Focusable
						id={TOPIC_BACKGROUND}
						isFocused={isFocusedBackgroundImage}
						onFocus={() => {
							setSelectedContentId(null); // need to do this to allow right drawer to update properly. Dont ask.
							// onFocusedItemName(TOPIC_BACKGROUND);
						}}
						label={'Banner Image'}
					>
						<Box className={classes.header}>
							<EditPageImage src={topicBackgroundImageUrl} />
						</Box>
					</Focusable>
				</div>
				<PageHeader isFocusedAppName={isFocusedAppName} isFocusedOrgName={isFocusedOrgName} />
			</Box>
			<Box className={classes.sectionArea}>
				<Box className={classes.sectionTab}>
					<Focusable
						id={SECTION_NAME_ID}
						isFocused={isFocusedSectionName}
						onFocus={() => {
							setSelectedContentId(null);
							onRemoveNewLanguage({ language: activeLanguageCode, ids: [SECTION_NAME_ID] });
						}}
						label="Section Name"
						badgeVertical="top"
						dim={isNewLanguageSectionName}
					>
						<InputBase
							value={selectedSection?.name.find((n) => n.language === activeLanguageCode)?.name || ''}
							placeholder="Section Name"
							fullWidth
							inputProps={{
								className: classes.sectionNameInputText,
								style: {
									cursor: isSectionFocus ? 'text' : 'pointer',
									color: colors.grayDark,
									textOverflow: 'ellipsis',
								},
							}}
							onClick={(e) => {
								setIsSectionFocus(true);
							}}
							onChange={(e) => {
								onUpdateSectionName({
									sectionId: selectedSectionId,
									name: e.target.value,
									language: activeLanguageCode,
								});
							}}
							onBlur={(e) => {
								saveSectionName({ sectionId: selectedSectionId });
								setIsSectionFocus(false);
								clearFocusedItem();
							}}
							onKeyPress={(e) => {
								if (e.key === 'Enter') {
									saveSectionName({ sectionId: selectedSectionId });
									setIsSectionFocus(false);
									clearFocusedItem();
								}
							}}
						/>
					</Focusable>
				</Box>
			</Box>

			<Box className={classes.contentAreaBelowHorizontalMenu}>
				<Droppable droppableId="phoneView">
					{(provided, snapshot) => (
						<div {...provided.droppableProps} ref={provided.innerRef}>
							{fields?.length ? (
								fields
							) : (
								<Box className={classes.placeholder}>
									{isLoading ? <Skeleton animation="wave" width="100%" height="100%" /> : ''}
								</Box>
							)}
							{provided.placeholder}
						</div>
					)}
				</Droppable>
			</Box>
		</IPhoneViewer>
	);
};

//for displaying org name and app name
const PageHeader = ({ isFocusedAppName, isFocusedOrgName }) => {
	const classes = useStyles({ dim: false });
	const dispatch = useAppDispatch();
	const changeAppName = bindActionCreators(changeLocationName, dispatch);
	const saveAppName = bindActionCreators(saveLocation, dispatch);
	const updateOrg = bindActionCreators(updateOrganization, dispatch);
	const saveOrg = bindActionCreators(saveOrganization, dispatch);
	const clearFocusedItem = bindActionCreators(clearFocusItem, dispatch);
	const org = useAppSelector(_organization);
	const appName = useAppSelector(_appName);

	return (
		<>
			{/* ORG NAME - (comapnayName will be mirrored via liiingoMiddleware) */}
			<Box className={classes.orgNameInHeader}>
				<Focusable
					id={ORG_NAME}
					isFocused={isFocusedOrgName}
					onFocus={() => {}}
					label="Organization Name"
					badgeVertical="top"
				>
					<InputBase
						value={org?.name || ''}
						placeholder="Organization Name"
						className={classes.orgNameInputText}
						inputProps={{
							style: {
								color: colors.pureWhite,
								textOverflow: 'ellipsis',
								textShadow:
									'-0.3px -0.3px 0 #000, 0.3px -0.3px 0 #000, -0.3px 0.3px 0 #000, 0.3px 0.3px 0 #000',
							},
						}}
						onChange={(e) => {
							updateOrg({ name: e.target.value });
						}}
						onBlur={(e) => {
							clearFocusedItem();
							saveOrg();
						}}
						onKeyPress={(e) => {
							if (e.key === 'Enter') {
								clearFocusedItem();
								saveOrg();
							}
						}}
					/>
				</Focusable>
			</Box>
			{/* APP NAME */}
			<Box className={classes.appNameInHeader}>
				<Focusable
					id={APP_NAME}
					isFocused={isFocusedAppName}
					onFocus={() => {}}
					label="Tagline"
					badgeVertical="top"
				>
					<LiiingoTooltip message="Click to rename" placement="bottom">
						<InputBase
							value={appName}
							placeholder="Tagline"
							className={classes.appNameInputText}
							inputProps={{
								style: {
									color: colors.pureWhite,
									textOverflow: 'ellipsis',
									textShadow:
										'-0.3px -0.3px 0 #000, 0.3px -0.3px 0 #000, -0.3px 0.3px 0 #000, 0.3px 0.3px 0 #000',
								},
							}}
							onChange={(e) => {
								changeAppName(e.target.value);
							}}
							onBlur={(e) => {
								clearFocusedItem();
								saveAppName();
							}}
							onKeyPress={(e) => {
								if (e.key === 'Enter') {
									clearFocusedItem();
									saveAppName();
								}
							}}
						/>
					</LiiingoTooltip>
				</Focusable>
			</Box>
		</>
	);
};
