import { SessionResponse } from './Analytics';

export interface TrafficDailyPayload {
	Sun: number;
	Mon: number;
	Tue: number;
	Wed: number;
	Thu: number;
	Fri: number;
	Sat: number;
}

/**
 * This function aggregates session data from the raw data.
 *
 * @param rawData - trafficDailyResponse[] data from the API
 * @returns userChartData
 */
export const aggregateTrafficDaily = (rawData: SessionResponse[]) => {
	const trafficDailyData = { Sun: 0, Mon: 0, Tue: 0, Wed: 0, Thu: 0, Fri: 0, Sat: 0 };
	const WEEKDAYS = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

	rawData.forEach((data) => {
		const date = new Date(parseInt(data.createdDate.$date.$numberLong, 10));
		const index = date.getDay();
		trafficDailyData[WEEKDAYS[index]] = trafficDailyData[WEEKDAYS[index]] || 0;
		trafficDailyData[WEEKDAYS[index]]++;
	});

	return trafficDailyData as TrafficDailyPayload;
};
