import { useEffect, useState } from 'react';
import { PageViewsType } from '../../../../store/models';
import { TopicMap } from '../../../../store/slices/topicSlice';
import { colors } from '../../../../theme/palette';
import { ChartContent } from './ChartContent';
import { LineGraph } from './LineGraph';

export type PageViewsProps = {
	data: PageViewsType[];
	topics: TopicMap;
};

// a function to format ms to a readable string indicating days, hours, minutes, seconds
const formatTime = (ms: number) => {
	let seconds = Math.floor(ms / 1000);
	let minutes = Math.floor(seconds / 60);
	let hours = Math.floor(minutes / 60);
	let days = Math.floor(hours / 24);

	hours = hours % 24;
	minutes = minutes % 60;
	seconds = seconds % 60;

	let result = '';
	if (days > 0) {
		result += `${days}d `;
	}
	if (hours > 0) {
		result += `${hours}h `;
	}
	if (minutes > 0) {
		result += `${minutes}m `;
	}
	if (seconds > 0) {
		result += `${seconds}s `;
	}
	return result;
};

// a component named PageViews should be able to fetch its own data

export const PageViews = (props: PageViewsProps) => {
	const { data, topics } = { ...props };
	const [selected, setSelected] = useState(Object.keys(topics)[0]);
	const [views, setViews] = useState([]);
	const [durations, setDurations] = useState([]);
	const [total, setTotal] = useState(0);
	const [avgSession, setAvgSession] = useState('');
	const [pageNames, setPageNames] = useState({});

	useEffect(() => {
		// if pageNames object isnt populated yet, but the topics object is, then populate pageNames
		if (!Object.keys(pageNames).length && Object.keys(topics).length) {
			const ids = Object.keys(topics);
			let tempPageNames = {};
			ids.forEach((id) => {
				// get the english name, or the first name if no english name
				tempPageNames[id] =
					topics[id].name.find((lang) => lang.language === 'en')?.name || topics[id].name[0].name;
			});
			setPageNames(tempPageNames);
		}
	}, [topics, pageNames]);

	useEffect(() => {
		// if pageNames object is populated, then set the views, total, and avgSession
		if (Object.keys(pageNames).length > 0) {
			setViews(data.find((obj) => obj.exhibitId === selected)?.views);
			let tempTotal = views?.reduce((acc, obj) => acc + obj.value, 0) || 0;
			setTotal(tempTotal);
			// sesssion duration calculation
			setDurations(data.find((obj) => obj.exhibitId === selected)?.sessionLengths);
			let tempAvgSession = 0;
			durations?.forEach((val) => (tempAvgSession += val));
			if (tempAvgSession > 0) {
				tempAvgSession = tempAvgSession / durations.length;
				// tempAvgSession = tempAvgSession / 1000;
				tempAvgSession = Math.ceil(tempAvgSession);
				setAvgSession(formatTime(tempAvgSession));
			}
		}
	}, [selected, data, pageNames, durations, views, avgSession]);

	return (
		<ChartContent
			name={'PAGE VIEWS'}
			selected={selected}
			pageNames={pageNames}
			setSelected={setSelected}
			total={total}
			avgSession={avgSession}
			toolTipMsg={
				'The number of views for the selected page. Change pages using the page selector in the top right of the Page Views card.'
			}
		>
			<LineGraph data={views} name="Views" color={colors.tealAccent} />
		</ChartContent>
	);
};
