import { Select } from '@material-ui/core';
import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@mui/material/Box';
import { bindActionCreators } from '@reduxjs/toolkit';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { Language } from '../../store/models';
import { addTip, setActiveLanguageCode, _activeLanguageCode } from '../../store/slices/editorSlice';
import { _defaultLanguage, _supportedLanguages } from '../../store/slices/locationSlice';
import { colors } from '../../theme/palette';
import { NEW_LANGUAGE_CONTENT_TIP } from '../../util/constants';
import { LiiingoTooltip } from '../LiiingoTooltip';
import { LANGUAGE_MAP } from '../../store/models/location/Language';

const useStyles = makeStyles({
	menuBox: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'center',
		marginLeft: 'auto',
		marginRight: 20,
	},
	menu: {
		backgroundColor: colors.grayLight5,
		borderStyle: 'solid',
		borderWidth: 0.5,
		borderColor: colors.grayLight20,
		borderRadius: 10,
		padding: 0,
		marginTop: 43,
		width: 120,
		marginLeft: -11,
		'& li': {
			fontSize: 12,
			paddingLeft: 8,
		},
		'& li:hover': {
			backgroundColor: 'linear-gradient(0deg, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.95)), #49525D',
		},
		'& li.Mui-selected': {
			backgroundColor: colors.tealAccent20,
		},
	},
	select: {
		width: 120,
		height: 32,
		backgroundColor: colors.pureWhite,
		borderStyle: 'solid',
		borderWidth: 1,
		borderRadius: 10,
		borderColor: colors.grayLight,
		paddingLeft: 10,
		paddingRight: 10,
		paddingTop: 8,
		paddingBottom: 8,
		fontSize: 12,
		'&:hover': {
			borderColor: colors.blueAccent20,
		},
		'&.Mui-focused': {
			borderColor: colors.blueAccent,
			backgroundColor: colors.pureWhite,
		},
		'& div.MuiSelect-select:focus': {
			backgroundColor: colors.pureWhite,
		},
	},
	whiteColor: {
		color: colors.neutralDark,
	},
	fullWidth: {
		width: '100%',
	},
	root: {
		marginLeft: 300,
	},
});

const StyledBadge = withStyles((theme) => ({
	badge: {
		left: 60,
		top: -5,
		width: 120,
		zIndex: 2000,
		'& .MuiBadge-badge': {
			backgroundColor: colors.pureWhite,
		},
		'&:hover': {
			// cursor: 'pointer',
			// width: 1200,
		},
	},
}))(Badge);

export type LanguageDropdownProps = {
	activeLanguageCode: Language;
	defaultLanguage: Language;
	supportedLanguages: Language[];
	setActiveLanguageCode: (code: Language) => void;
	addTip: (tip: string) => void;
};
//TODO: rework this monster. use css selectors instead of JS for managing the badge and tooltip
export const LanguageDropdown = (props: LanguageDropdownProps) => {
	const classes = useStyles();
	const [hideBadge, setHideBadge] = useState(true);
	const [focusBadge, setFocusBadge] = useState(false);
	const [isOpen, setIsOpen] = useState(false);
	const { activeLanguageCode, defaultLanguage, supportedLanguages, setActiveLanguageCode, addTip } = {
		...props,
	};

	const dispatch = useAppDispatch();
	const setActiveLanguage = bindActionCreators(setActiveLanguageCode, dispatch);

	useEffect(() => {
		//if the activeLanguageCode is no longer a supportedLanguage, set activeLanguageCode to defaultLanguage
		if (!supportedLanguages.includes(activeLanguageCode)) {
			setActiveLanguage(defaultLanguage);
		}
	}, [activeLanguageCode, defaultLanguage, supportedLanguages, setActiveLanguage]);

	const handleChange = (e) => {
		if (defaultLanguage !== e.target.value) {
			addTip(NEW_LANGUAGE_CONTENT_TIP);
		}
		setActiveLanguageCode(e.target.value);
	};

	return supportedLanguages.length > 1 ? (
		<Box className={classes.menuBox}>
			<StyledBadge
				color={focusBadge ? 'primary' : 'default'}
				badgeContent={'Language Menu'}
				anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
				invisible={hideBadge || !focusBadge}
				className={classes.fullWidth}
				overlap={'rectangular'}
			>
				{/* // this isn't good. we need to start using css to manage this stuff */}
				<LiiingoTooltip
					message="Switch between your list of added languages to see translated pages/content"
					placement="bottom"
					show={!isOpen && !hideBadge}
				>
					<div
						className={classes.fullWidth}
						onPointerOver={() => {
							setHideBadge(false);
						}}
						onPointerLeave={() => {
							setHideBadge(true);
							setFocusBadge(false);
						}}
						onFocus={() => {
							setFocusBadge(true);
						}}
						onBlur={() => {
							setFocusBadge(false);
							setHideBadge(true);
						}}
					>
						{/* // this is all way too complex. use css */}
						<Select
							labelId="language-dropdown"
							id="language"
							value={activeLanguageCode}
							onClick={() => {
								setIsOpen(false);
								setFocusBadge(false);
								setHideBadge(true);
							}}
							onChange={handleChange}
							open={isOpen}
							onOpen={() => {
								setIsOpen(true);
							}}
							onClose={() => {
								setIsOpen(false);
								setFocusBadge(false);
								setHideBadge(true);
							}}
							MenuProps={{ classes: { paper: classes.menu } }}
							className={classes.select}
							classes={{
								icon: classes.whiteColor,
							}}
							disableUnderline
						>
							{Object.keys(LANGUAGE_MAP).map(
								(key: Language) =>
									supportedLanguages.includes(key) && (
										<MenuItem
											value={key}
											key={key}
											onBlur={() => {
												setFocusBadge(false);
												// setHideBadge(true);
											}}
										>
											{LANGUAGE_MAP[key]}
										</MenuItem>
									)
							)}
						</Select>
					</div>
				</LiiingoTooltip>
			</StyledBadge>
		</Box>
	) : null;
};

const LanguageDropdownContainer = () => {
	const dispatch = useAppDispatch();

	const activeLanguageCode = useAppSelector(_activeLanguageCode);
	const defaultLanguage = useAppSelector(_defaultLanguage);
	const supportedLanguages = useAppSelector(_supportedLanguages);

	const state = {
		activeLanguageCode,
		defaultLanguage,
		supportedLanguages,
	};

	const actions = bindActionCreators(
		{
			setActiveLanguageCode,
			addTip: addTip,
		},
		dispatch
	);

	return <LanguageDropdown {...state} {...actions} />;
};

export default LanguageDropdownContainer;
