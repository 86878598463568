import React, { ReactNode } from 'react';
import { match, Redirect, Route, Switch } from 'react-router-dom';
import { Organization } from '../store/models';
import { ROUTE } from '../util/constants';
export const OnboardingFlowContext = React.createContext<any>({});

export type OnboardingFlowContextType = {
	route: string;
	organization?: Organization;
	createNewOrganization: boolean;
	logo?: string;
	favicon?: string;
	essentialTemplate?: any;
};

type Props = {
	match: match<{ onboardingRoute?: string }>;
	children: ReactNode;
};

// THIS CONTEXT PROVIDER HAS SEEN ITS DAY.
// IT'S NO LONGER USEFUL FOR ANYTHING.
// JUST GETTING IN THE WAY SHOVING UNNEEDED DATA AROUND EVERYWHERE

export const LookupOnboardingFlowAndInjectItIntoContext = ({ match = null, children }: Props) => {
	localStorage.setItem(ROUTE, match.params.onboardingRoute);

	const context: OnboardingFlowContextType = {
		route: match.params.onboardingRoute,
		organization: null,
		createNewOrganization: true,
		logo: null,
		favicon: null,
		essentialTemplate: null,
	};

	return <OnboardingFlowContext.Provider value={context}>{children}</OnboardingFlowContext.Provider>; // TODO: need a better "Loading" screen instead of null (https://app.clickup.com/t/ph3rrn)
};

export const NoRoute = () => {
	const route = localStorage.getItem(ROUTE);
	if (route !== undefined && route !== 'undefined' && route !== 'null' && route !== null) {
		return <Redirect to={`${route}/dashboard`} />;
	}

	// return <>NO ROUTE FOUND</>;
	return <Redirect to={`essentials/dashboard`} />; //defaut to "essentials" if not found in localstorage
};

export const OnboardingFlowContextProvider = ({ children }) => {
	return (
		<Switch>
			<Route
				path="/:onboardingRoute"
				render={(props) => (
					<LookupOnboardingFlowAndInjectItIntoContext {...props}>
						{children}
					</LookupOnboardingFlowAndInjectItIntoContext>
				)}
			/>
			<Route path="/" component={NoRoute} />
		</Switch>
	);
};
