import { ReactNode, useEffect, useState } from 'react';
import Link from '@material-ui/icons/Link';
import { Option } from './Option';
import { OptionGroup } from './OptionGroup';
import LinkDialog, { LinkDialogMenuOption, LinkDialogOptions } from './Dialog/LinkDialog';

export type ButtonMenuProps = {
	title?: string;
	url?: string;
	children: ReactNode;
	onChange?: (title: string, url: string) => void;
	onMenuChange: (option: LinkDialogMenuOption) => void;
};

export const ButtonMenu = (props: ButtonMenuProps) => {
	const { title, url, children, onChange, onMenuChange } = {
		...props,
	};
	const [titleFormField, setTitleFormField] = useState(title);
	const [urlFormField, setUrlFormField] = useState(url);
	const [openDialog, setOpenDialog] = useState(false);

	useEffect(() => {
		setTitleFormField(title);
		setUrlFormField(url);

		let option: LinkDialogMenuOption;
		if (url?.includes('http')) {
			option = 'web';
		} else if (url?.includes('tel') || url?.includes('sms')) {
			option = 'phone';
		} else if (url?.includes('mailto')) {
			option = 'email';
		} else if (url) {
			option = 'page';
		} else {
			option = 'none';
		}

		if (option) {
			onMenuChange(option);
		}
	}, [title, url, onMenuChange]);

	return (
		<>
			<OptionGroup title="Button">
				<Option icon={Link} label="Link" clickable={true} onClick={() => setOpenDialog(true)} url={url} />
				<LinkDialog
					open={openDialog}
					handleClose={() => setOpenDialog(false)}
					options={LinkDialogOptions}
					value={urlFormField}
					onChange={(url: string) => {
						onChange(titleFormField, url);
					}}
				/>
				{children}
			</OptionGroup>
		</>
	);
};
