import { colors } from '../../../../theme/palette';
import Highcharts, { Options } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { TrafficDailyPayload } from '../../../../store/models';
import { ChartContent } from './ChartContent';

export type TrafficDailyProps = {
	data: TrafficDailyPayload;
};

export const TrafficDaily = (props: TrafficDailyProps) => {
	const { data } = props;

	const chartOptions: Options = {
		title: {
			text: null,
			align: 'left',
			useHTML: true,
			//style copied from the "Typography" genererated by Material UI, proabably should've just stuck with using that method
			style: {
				color: colors.grayDark,
				fontSize: ' 0.875rem',
				display: 'flex',
				fontFamily: '"Roboto", "Helvetica", "Arial", sans- serif',
				fontWeight: '500',
				lineHeight: '1.57',
				letterSpacing: '0.00714em',
			},
		},
		chart: {
			type: 'column',
			height: 213,
			zoomType: 'xy',
			panning: {
				enabled: true,
				type: 'xy',
			},
			panKey: 'shift',
		},
		series: [
			{
				type: 'column',
				data: [data['Sun'], data['Mon'], data['Tue'], data['Wed'], data['Thu'], data['Fri'], data['Sat']],
			},
		],
		xAxis: {
			categories: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
			labels: {
				enabled: true,
				useHTML: true,
				formatter: function () {
					return `${this.value}`;
				},
				style: {
					color: colors.grayDark,
					fontSize: '12px',
					lineHeight: '20px',
				},
			},
		},
		yAxis: {
			title: { text: null },
			labels: {
				style: {
					fontSize: '12px',
					lineHeight: '20px',
				},
			},
		},
		credits: {
			enabled: false, //removing watermark
		},
		tooltip: {
			useHTML: true,
			backgroundColor: colors.grayDark,
			borderWidth: 0,
			borderRadius: 4,
			shadow: false,
			style: {
				padding: '8px 12px',
				color: colors.pureWhite,
				fontSize: '16px',
				fontWeight: '700',
				whiteSpace: 'nowrap',
			},
			formatter: function () {
				return `${this.y}`;
			},
		},
		legend: { enabled: false },
		plotOptions: {
			series: {
				cursor: 'pointer',
				color: colors.tealAccent,
			},
		},
	};
	return (
		<ChartContent
			name={'DAILY TRAFFIC'}
			toolTipMsg={'The number of visits to your Liiingo based on the day of the week.'}
		>
			<HighchartsReact highcharts={Highcharts} options={chartOptions} />;
		</ChartContent>
	);
};
