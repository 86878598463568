import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import { Theme } from '@material-ui/core/styles/createTheme';
import Box from '@mui/material/Box';

import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import { colors } from '../../theme/palette';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		dialogLarge: {
			width: 500,
			minHeight: 575,
		},
		dialogSmall: {
			width: 500,
			height: 'auto',
			// height: 250,
		},
		closeIconDark: {
			color: colors.pureWhite,
		},
		closeIconLight: {
			color: colors.grayLight,
		},
		titleDark: {
			color: colors.pureWhite,
		},
		titleLight: {
			color: colors.grayDark,
		},
		titleContainerDark: {
			// backgroundColor: colors.blueAccent,
			backgroundColor: '#142648',
			paddingTop: 0,
			paddingBottom: 0,
			paddingLeft: 15,
			paddingRight: 15,
			marginBottom: 0,
			display: 'inline-flex',
			width: '100%',
			justifyContent: 'space-between',
		},
		titleContainerLight: {
			paddingTop: 0,
			paddingBottom: 0,
			paddingLeft: 15,
			paddingRight: 15,
			marginBottom: 0,
			display: 'inline-flex',
			width: '100%',
			justifyContent: 'space-between',
			// alignItems: 'center',
		},
		dialogActions: {
			width: '100%',
			maxHeight: 52,
			backgroundColor: colors.grayLight5,
			position: 'absolute',
			bottom: 0,
		},
		content: {
			width: 351,
			height: 300,
			marginTop: 15,
			marginLeft: 5,
			marginRight: 5,
		},
		dialogContent: {
			padding: 20,
			marginBottom: 65,
		},
	})
);

export type LiteModalProps = {
	modalSize?: 'small' | 'large';
	open: boolean;
	title: string;
	titleVariant?: 'light' | 'dark';
	actions?: React.ReactNode;
	handleClose: () => void;
};

export const LiteModal: React.FC<LiteModalProps> = (props) => {
	const { children, title, open, actions, titleVariant, modalSize, handleClose } = {
		...props,
	};
	const classes = useStyles();

	return (
		<Dialog onClose={handleClose} open={open} disableEnforceFocus PaperProps={{ elevation: 0 }}>
			<Box className={modalSize === 'small' ? classes.dialogSmall : classes.dialogLarge}>
				<div className={titleVariant === 'light' ? classes.titleContainerLight : classes.titleContainerDark}>
					<div className={titleVariant === 'light' ? classes.titleLight : classes.titleDark}>
						<h2>{title}</h2>
					</div>
					<IconButton
						className={titleVariant === 'light' ? classes.closeIconLight : classes.closeIconDark}
						onClick={handleClose}
						edge="end"
					>
						<CloseIcon fontSize="small" />
					</IconButton>
				</div>
				<DialogContent className={classes.dialogContent}>{children}</DialogContent>
				<DialogActions className={classes.dialogActions}>{actions ?? null}</DialogActions>
			</Box>
		</Dialog>
	);
};
