import { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { _contents, contentChanged, selectContent } from '../../store/slices/contentSlice';
import { _activeLanguageCode, focusedItemName, setCropImageUrl } from '../../store/slices/editorSlice';
import { changeBackground } from '../../store/slices/topicSlice';
import { ACCEPTABLE_IMAGE_MIME_TYPES, IMAGE_CONTENT } from '../../util/constants';
import { DroppableImage } from './DroppableImage';

export type EditImageProps = {
	src: string;
	contentId: string;
};

export const EditImage = (props: EditImageProps) => {
	const { src, contentId } = { ...props };
	const [previewImage, setPreviewImage] = useState(src);
	const [isDrag, setIsDrag] = useState(false);
	const [isOver, setIsOver] = useState(false);
	const [isValidType, setIsValidType] = useState(false);

	useEffect(() => {
		setPreviewImage(src);
	}, [src, setPreviewImage]);

	const dispatch = useAppDispatch();
	const actions = bindActionCreators(
		{
			onChangeContent: contentChanged,
			onSetCropImageUrl: setCropImageUrl,
			onBackgroundChange: changeBackground,
			onFocusedItemName: focusedItemName,
			onSelectContent: selectContent,
		},
		dispatch
	);

	const contentMap = useAppSelector(_contents);
	const content = contentMap[contentId];
	const activeLanguageCode = useAppSelector(_activeLanguageCode);
	const [translation, setTranslation] = useState(null);

	useEffect(() => {
		setTranslation(content.languages.find((item) => item.language === activeLanguageCode));
	}, [content, activeLanguageCode, setTranslation, contentId]);

	const handleDragEnter = (e) => {
		const fileType = e.dataTransfer.items[0]?.type;
		if (ACCEPTABLE_IMAGE_MIME_TYPES.includes(fileType)) {
			setIsValidType(true);
		} else {
			setIsValidType(false);
		}
	};

	const handleDragOver = () => {
		if (!isDrag) {
			setIsDrag(true);
		}
	};

	const handleDragStop = () => {
		if (isDrag) {
			setIsDrag(false);
		}
	};

	const handleDragIn = () => {
		setIsOver(true);
	};

	const handleDrop = () => {
		setIsOver(false);
	};

	const handleDragOut = () => {
		setIsOver(false);
	};

	const handleDrag = (e) => {
		e.preventDefault();
		e.stopPropagation();
	};

	window.addEventListener('dragenter', handleDragEnter, false);
	window.addEventListener('dragover', handleDragOver, false);
	window.addEventListener('dragleave', handleDragStop, false);
	window.addEventListener('drop', handleDragStop, false);

	return (
		<div
			style={{ width: '100%' }}
			onDragEnter={handleDragIn}
			onDragLeave={handleDragOut}
			onDragOver={handleDrag}
			onDrop={handleDrop}
		>
			<div style={{ animation: 'fade 1s ease .5s both' }}>
				<DroppableImage
					imageUrl={previewImage}
					setImagePreview={(file: File) => {
						const url = URL.createObjectURL(file);
						setPreviewImage(url);
						actions.onSetCropImageUrl(url);
						actions.onChangeContent({
							language: translation.language,
							fileUrl: url,
							fileName: `${translation.language}_${file.name}`,
							id: contentId,
						});
					}}
					isDrag={isDrag}
					isOver={isOver}
					imageType={IMAGE_CONTENT}
					isValidType={isValidType}
				/>
			</div>
		</div>
	);
};
