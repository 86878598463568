import { withStyles, makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles/createTheme';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Box } from '@material-ui/core';
import { colors } from '../../theme/palette';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: 'flex',
			margin: '16px 0px 16px 20px',
		},
		textContent: {
			alignSelf: 'center',
			marginLeft: '20px',
		},
		title: {
			color: '#181B2099',
			fontWeight: 600,
			fontFamily: 'Open Sans',
			textTransform: 'uppercase',
		},
		desc: {
			color: colors.neutralDark,
		},
		divider: {
			display: 'block',
			height: '20px',
		},
		img: {
			width: '60px',
		},
	})
);

export type TitleTipGroupProps = {
	logo: string;
	title: string;
	desc: string;
};

export const TitleTipGroup = (props: TitleTipGroupProps) => {
	const classes = useStyles();
	const { logo, title, desc } = props;

	return (
		<Box className={classes.root}>
			<img src={logo} alt="logo" className={classes.img} />
			<Box className={classes.textContent}>
				<Typography className={classes.title}>{title}</Typography>
				<Typography className={classes.desc}>{desc}</Typography>
			</Box>
		</Box>
	);
};

export const StyledAccordion = withStyles((theme) => ({
	root: {
		boxShadow: 'none',
		padding: '8px 10px 8px 100px',
		'&:before': {
			content: 'none',
		},
	},
	expanded: {
		margin: '0px !important',
	},
}))(Accordion);

export const StyledAccordionSummary = withStyles((theme) => ({
	root: {
		padding: '0px',
		minHeight: '36px!important',
	},
	content: {
		alignItems: 'center',
		margin: '4px 0px !important',
	},
	expandIcon: {
		padding: '4px 12px',
	},
}))(AccordionSummary);

export const StyledAccordionDetails = withStyles((theme) => ({
	root: {
		padding: '0px 20px 0px 0px',
	},
}))(AccordionDetails);

export const EmptyDivider = () => {
	const classes = useStyles();

	return <Box className={classes.divider}></Box>;
};
