import { Badge, makeStyles, Theme } from '@material-ui/core';
import { bindActionCreators } from '@reduxjs/toolkit';
import { ObjectID } from 'bson';
import React, { useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { selectContent } from '../../store/slices/contentSlice';
import { focusedItemName, _focusedItemName } from '../../store/slices/editorSlice';
import { colors } from '../../theme/palette';

const useStyles = makeStyles<Theme, { dim: boolean }>({
	badgeFocusable: (dim) => ({
		display: 'block',
		// badge label, not hovered or selected
		'& .MuiBadge-badge': {
			transition: 'opacity 0.2s',
			opacity: 0,
		},
		'& .MuiBadge-anchorOriginTopLeftRectangular': {
			transform: 'translate(0%, -100%)',
		},
		'& .MuiBadge-anchorOriginBottomLeftRectangular': {
			transform: 'translate(0%, 100%)',
		},
		// hovered
		'&:hover': {
			// badge label, hovered
			'& .MuiBadge-badge': {
				transition: 'opacity 0.2s',
				opacity: 1,
				color: colors.blueAccent,
				backgroundColor: colors.blueAccent20,
			},
		},
	}),
	badgeFocused: (dim) => ({
		display: 'block',
		// badge label, while selected
		'& .MuiBadge-badge': {
			transition: 'opacity 0.2s',
			opacity: 1,
			color: colors.pureWhite,
			backgroundColor: colors.blueDark,
		},
		'& .MuiBadge-anchorOriginTopLeftRectangular': {
			transform: 'translate(0%, -100%)',
		},
		'& .MuiBadge-anchorOriginBottomLeftRectangular': {
			transform: 'translate(0%, 100%)',
		},
	}),
	focusable: (dim) => ({
		// not hovered or selected
		border: `2px solid transparent`,
		opacity: `${dim ? 0.5 : 1}`,
		'&:hover': {
			border: `2px solid ${colors.blueDark}`,
		},
	}),
	focused: (dim) => ({
		border: `2px solid ${colors.blueDark}`,
		opacity: 1,
	}),
});

export interface FocusableProps {
	id: string;
	isFocused: boolean;
	newElement?: boolean;
	onFocus: () => void;
	badgeVertical?: 'top' | 'bottom';
	badgeHorizontal?: 'left' | 'right';
	label?: string;
	dim?: boolean;
}
export const Focusable: React.FC<FocusableProps> = ({
	id,
	isFocused,
	onFocus,
	label = 'Edit',
	badgeVertical = 'top',
	badgeHorizontal = 'left',
	dim = false,
	...rest
}) => {
	const classes = useStyles({ dim });
	const dispatch = useAppDispatch();
	const onFocusedItemName = bindActionCreators(focusedItemName, dispatch);
	const onSelectContent = bindActionCreators(selectContent, dispatch);
	const focusedName = useAppSelector(_focusedItemName);
	const { children, ...passthroughProps } = rest;
	const focusableRef = useRef<HTMLDivElement>(null);
	const [focused, setFocused] = useState(isFocused);

	const setFocusedId = () => {
		if (focusedName === id) {
			return; // no need to dispatch actions if we're clicking content thats already been 'focused'
		} else {
			onFocusedItemName(id);
			ObjectID.isValid(id) ? onSelectContent(id) : onSelectContent(null);
			onFocus();
		}
	};

	useEffect(() => {
		// if control from outside the component changes this variable, use that value to set our component's local state
		if (isFocused) {
			setFocused(true);
		} else {
			setFocused(false);
		}
	}, [isFocused]);

	// useEffect to listen to changes in focusedName and set the local state of focused
	useEffect(() => {
		if (focusedName === id) {
			setFocused(true);
		} else {
			setFocused(false);
		}
	}, [focusedName, id]);

	//useEffect to manually get the children of the focusable element and call .focus() on it
	useEffect(() => {
		if (focused && focusableRef.current) {
			focusableRef.current.focus();
		}
	}, [focused]);

	return label === '' ? (
		/* No label? No badge. Just a Focusable wrapping whatever child was passed in. */
		<div
			ref={focusableRef}
			tabIndex={0}
			style={{ animation: 'fade .75s ease .1s both' }}
			className={`${focused ? classes.focused : classes.focusable}`}
			onClick={setFocusedId}
			onDrop={setFocusedId}
			onFocus={setFocusedId}
			onDoubleClick={setFocusedId}
			{...passthroughProps}
		>
			{children}
		</div>
	) : (
		/* Label? You get a Badge -- that wraps the Focuasable  -- that wraps a child */
		<Badge
			overlap={'rectangular'}
			badgeContent={label}
			anchorOrigin={{ vertical: badgeVertical, horizontal: badgeHorizontal }}
			className={`${focused ? classes.badgeFocused : classes.badgeFocusable}`}
		>
			<div
				ref={focusableRef}
				tabIndex={0}
				style={{ animation: 'fade .75s ease .1s both' }}
				className={`${focused ? classes.focused : classes.focusable}`}
				onClick={setFocusedId}
				onDrop={setFocusedId}
				onFocus={setFocusedId}
				onDoubleClick={setFocusedId}
				{...passthroughProps}
			>
				{children}
			</div>
		</Badge>
	);
};
