import Stripe from 'stripe';
import axiosInstance from '.';
import { PHP_ACCESS_TOKEN } from '../../util/constants';

export interface createSubscriptionParams {
	email: string;
	priceId: string;
	coupon?: string;
	trialDays?: number;
}

export const createCustomerSubscription = async (email, priceId, coupon = null, trialDays = null) => {
	let payload: createSubscriptionParams = {
		email,
		priceId,
	};
	if (coupon) {
		payload.coupon = coupon;
	}
	if (trialDays) {
		payload.trialDays = trialDays;
	}
	let result = await axiosInstance.post('/organization/stripeSignup', payload);
	let { stripeCustomer = null, stripeSubscription = null } = { ...result.data };
	console.log('stripeCustomer', stripeCustomer);
	console.log('stripeSubscription', stripeSubscription);
	return { stripeCustomer, stripeSubscription };
};

//get the Stripe Customer based on thier email contained in the IdToken
export const getCustomer = async () => {
	let phpToken = localStorage.getItem(PHP_ACCESS_TOKEN);
	let result = await axiosInstance.post('/organization/getStripeCustomer', { phpToken });
	let customer = result.data;
	return customer as Stripe.Customer;
};

// get the Stripe Subscription based on the Stripe Customer Id
export const getSubscription = async (stripeCustomerId: string) => {
	let result = await axiosInstance.post('/organization/getStripeSubscription', { stripeCustomerId });
	let subscription = result.data;
	return subscription as Stripe.Subscription;
};

// open a Stripe Customer Billing Portal session for the customer
export const openCustomerPortal = async (stripeCustomerId, returnUrl) => {
	let result = await axiosInstance.post('/organization/getStripeBillingPortal', {
		stripeCustomerId: stripeCustomerId,
		returnUrl: returnUrl,
	});
	let customerPortal = result.data;
	window.location.href = customerPortal.url;
};

// update the Stripe Subscription free trial end date, for testing purposes
export const changeTrialEndDate = async (stripeSubscriptionId: string, trialEnd: number | string) => {
	// if trialEnd is not in the future, or within 10 seconds of the current time, set it to 'now'
	if (trialEnd < Date.now() / 1000) {
		trialEnd = 'now';
	}
	let result = await axiosInstance.post('/organization/updateTrialEndDate', {
		stripeSubscriptionId: stripeSubscriptionId,
		trialEnd: trialEnd,
	});
	let subscription = result.data;
	return subscription as Stripe.Subscription;
};

// update the Stripe Subscription canceled_at date, for testing purposes
export const changeCancelAtDate = async (stripeSubscriptionId: string, cancel_at: number | string) => {
	// cancel_at must be in the future, so we'll force the minimum date to 1 minute from now
	let oneMinuteFromNow = Date.now() / 1000;
	oneMinuteFromNow += 60;
	oneMinuteFromNow = Math.round(oneMinuteFromNow);
	if (cancel_at < oneMinuteFromNow) {
		cancel_at = oneMinuteFromNow;
	}
	let result = await axiosInstance.post('/organization/updateCancelAtDate', {
		stripeSubscriptionId: stripeSubscriptionId,
		cancel_at: cancel_at,
	});
	let subscription = result.data;
	return subscription as Stripe.Subscription;
};
