import { format } from 'date-fns';
import { SessionResponse } from './Analytics';

export type UserTraffic = {
	key: string;
	value: number;
	display: string;
	timestamp: number;
};

export interface TrafficPayload {
	userTraffic: UserTraffic[];
	newTraffic: UserTraffic[];
	returningTraffic: UserTraffic[];
}

// This function aggregates traffic data by the hour of the day.
export const aggregateHourly = (rawData: SessionResponse[]) => {
	// the payload to return to the caller
	let payloadMap = {} as { [key: number]: number };
	// fill the data structure with initial value. Keys 0-23, values all 0
	for (let i = 0; i < 24; i++) {
		payloadMap[i] = 0;
	}
	// iterate over the raw data
	rawData.forEach((data) => {
		const timestamp = parseInt(data.createdDate.$date.$numberLong, 10);
		const hour = new Date(timestamp).getHours();
		// payloadMap[hour] = payloadMap[hour] || 0; // initialize the hour index if it doesn't exist
		payloadMap[hour] += 1; // increment the value for the hour
	});

	//convert payloadMap to ordered array
	let payload = Object.keys(payloadMap)
		.map((hour) => [parseInt(hour), payloadMap[hour]])
		.sort(([a, b]) => a[0] - b[0]);

	return payload;
};

/**
 * This function aggregates traffic data from the raw data.
 *
 * @param rawData - TrafficResponse[] data from the API
 * @returns userTraffic, newTraffic, returningTraffic
 */

export const aggregateTraffic = (rawData: SessionResponse[], locationId: string) => {
	// filter out results that don't have any viewedContent, or that don't have a matching locationId in the viewedContent
	const data = rawData
		.filter((item) => item?.metadata?.viewedContent?.length > 0)
		.filter((item) => {
			if (item.metadata.viewedContent.filter((obj) => locationId === obj.locationId).length) {
				return true;
			}
			return false;
		});

	// prepare the cleaned up data for the charts
	const userTraffic: UserTraffic[] = prepareUserTrafficData(data);

	// const uniqueViews = data?.reverse().reduce(
	// 	(unique, session) => {
	// 		const index = unique?.firstVisit?.findIndex((item) => item.udid === session.udid);
	// 		if (index === -1) {
	// 			unique.firstVisit.push(session);
	// 		} else {
	// 			unique.returnVisit.push(session);
	// 		}
	// 		return unique;
	// 	},
	// 	{ firstVisit: [], returnVisit: [] }
	// );

	//NOTE: newTraffic is the first time that udid has been seen in the last 30 days
	// const newTraffic = prepareUserTrafficData(uniqueViews.firstVisit);
	// const returningTraffic = prepareUserTrafficData(uniqueViews.returnVisit);

	// return { userTraffic, newTraffic, returningTraffic } as TrafficPayload;
	return { userTraffic } as TrafficPayload;
};

const prepareUserTrafficData = (rawData: SessionResponse[]) => {
	const userTraffic: UserTraffic[] = rawData
		.reduce((data, { createdDate }) => {
			const timestamp = parseInt(createdDate.$date.$numberLong, 10);
			const key = format(new Date(timestamp), 'M/d');
			const display = format(new Date(timestamp), 'MMM d, yyyy');
			const element = data.find((value) => value.key === key);

			if (element) {
				element.value++;
			} else {
				data.push({
					key,
					value: 1,
					display,
					timestamp,
				});
			}

			return data;
		}, [])
		.sort((a, b) => (a.timestamp > b.timestamp ? 1 : b.timestamp > a.timestamp ? -1 : 0));

	return userTraffic;
};
