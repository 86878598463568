import { Box, Grid } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, { useContext } from 'react';
import { ErrorBanner, SignInForm } from '../components';
import SvgSignIn from '../components/SvgComponents/SvgSignIn';
import { OnboardingFlowContext } from '../context/OnboardingFlowContext';
import { useQueryStringParams } from '../hooks/useQueryStringParams';
import { TwoPaneLayout } from './TwoPaneLayout';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			flexGrow: 1,
			padding: 'auto',
			justifyContent: 'center',
		},
		pageTitle: {
			paddingBottom: theme.spacing(4),
		},
		quote: {
			padding: '3em',
			color: 'navy',
			fontFamily: 'monospace',
		},
		widthConstrainedContent: {
			minWidth: 300,
			maxWidth: 450,
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			alignItems: 'center',
		},
	})
);

const SignIn = () => {
	const onboardingFlowContext = useContext(OnboardingFlowContext);

	if (onboardingFlowContext.logo) {
		return <TwoPaneLayout heroImageUrl={onboardingFlowContext.logo} component={<FormPanelLayout />} dehero />;
	}

	return <TwoPaneLayout icon={<SvgSignIn />} component={<FormPanelLayout />} />;
};

const FormPanelLayout = () => {
	const params = useQueryStringParams();
	const classes = useStyles();
	const [notifySessionExpired, setNotifySessionExpired] = React.useState(!!params.get('sessionExpired'));

	return (
		<>
			{notifySessionExpired && (
				<ErrorBanner title="" message="Your session expired" onClose={() => setNotifySessionExpired(false)} />
			)}

			<Grid item container direction="column" alignItems="center" className={classes.root}>
				<Box className={classes.widthConstrainedContent}>
					<Typography variant="h5" align="center" className={classes.pageTitle}>
						Welcome Back!
					</Typography>
					<SignInForm />
				</Box>
			</Grid>
		</>
	);
};

export default SignIn;
