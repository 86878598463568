import { Button, createStyles, Dialog, makeStyles, Theme, Typography } from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';
import { lightFormat } from 'date-fns';
import { useState } from 'react';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { bindActionCreators } from 'redux';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { DateRange, newDateRange, _dateRange } from '../../../store/slices/analyticsSlice';
import { colors } from '../../../theme/palette';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		containerWidth: {
			width: '100%',
			padding: 20,
		},
		dashboardTitle: {
			padding: 8,
		},
		fade: {
			animation: 'fade 1s ease .5s both',
		},
		breadcrumbLink: {
			cursor: 'pointer',
			color: colors.tealAccent,
			textDecoration: 'none',
			'&:hover': {
				textDecoration: 'underline',
			},
		},
		dateRangeLabel: {
			color: colors.grayDark,
			fontSize: 'medium',
			display: 'flex',
			alignItems: 'center',
			marginTop: 45,
			cursor: 'pointer',
		},
		dialog: {
			'& .MuiDialog-container': {
				height: 'auto',
				width: 'auto',
			},
		},
	})
);

// DateRange is stored in Redux, however this component also uses local state to keep track of "temp" date range
// This temp date range is used to update the date range in Redux when the user clicks "Apply"
// This is done to prevent the date range in Redux from being updated on every date change
// And provides the ability to "Cancel" the date range change by resetting the temp date range to the current date range in Redux

export const LiiingoDateRangePicker = () => {
	const [open, setOpen] = useState(false);
	const dispatch = useAppDispatch();
	const applyDateRange = bindActionCreators(newDateRange, dispatch);
	const dateRange = useAppSelector<DateRange>(_dateRange);
	const [tempDateRange, setTempDateRange] = useState<DateRange>(dateRange);
	const classes = useStyles();

	const handleDateRangeChange = (ranges: DateRange) => {
		// only using a single range, key value is not guaranted to be 'selection', so using 'reflection' to get the key
		for (const key in ranges) {
			let { startDate, endDate } = ranges[key];
			startDate = new Date(startDate).toLocaleDateString(); // lightFormat(startDate, 'M/D/YY');
			endDate = new Date(endDate).toLocaleDateString(); // lightFormat(endDate, 'M/D/YY');
			console.log(startDate, endDate);
			setTempDateRange({ startDate, endDate });
		}
	};

	const handleApply = () => {
		// apply the tempDateRange to Redux
		applyDateRange(tempDateRange);
		// close the Dialog
		setOpen(false);
	};

	const handleReset = () => {
		// dont apply the tempDateRange to Redux, apply Redux to tempDateRange (reset it)
		setTempDateRange(dateRange);
		// close the Dialog
		setOpen(false);
	};

	const toggleOpen = (event?, reason?) => {
		console.log('toggleOpen', event, reason);
		if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
			// if the user clicks outside the Dialog, then reset the tempDateRange to the current date range in Redux
			setTempDateRange(dateRange);
		}
		setOpen(!open);
	};

	const isDateRangeDirty = () => {
		// if the tempDateRange is different than the dateRange in Redux, then the date range has been changed
		return tempDateRange.startDate !== dateRange.startDate || tempDateRange.endDate !== dateRange.endDate;
	};

	const selectionRange = {
		startDate: new Date(tempDateRange.startDate),
		endDate: new Date(tempDateRange.endDate),
	};

	return (
		<div>
			<Typography className={classes.dateRangeLabel} onClick={toggleOpen} variant={'subtitle1'}>
				{/* <DateRangeIcon fontSize="small" style={{ marginRight: 10 }} /> */}
				<span style={{ marginRight: 10, whiteSpace: 'nowrap' }}>Date Range:</span>
				{lightFormat(new Date(dateRange.startDate), 'M/d/yy')} -{' '}
				{lightFormat(new Date(dateRange.endDate), 'M/d/yy')}
				<ArrowDropDown style={{ marginLeft: 5 }} />
			</Typography>

			<Dialog
				className={classes.dialog}
				open={open}
				onClose={toggleOpen} //do we need this?
				aria-labelledby="date-range-picker-dialog-title"
				aria-describedby="date-range-picker-dialog-description"
				maxWidth={false}
				PaperProps={{ elevation: 0 }}
			>
				<DateRangePicker
					onChange={handleDateRangeChange}
					showSelectionPreview={true}
					moveRangeOnFirstSelection={false}
					months={2}
					ranges={[selectionRange]}
					direction="horizontal"
					preventSnapRefocus={true}
					calendarFocus="backwards"
					elevation={0}
					showDateDisplay={true}
					showMonthAndYearPickers={true}
					scroll={{ enabled: true }}
					maxDate={new Date()}
				/>
				{/* 2 buttons on same row, right aligned */}
				<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
					<Button variant="contained" color="primary" onClick={handleApply} style={{ margin: '10px' }}>
						Apply
					</Button>
					<Button
						variant="contained"
						color="default"
						onClick={isDateRangeDirty ? handleReset : toggleOpen}
						style={{ margin: '10px' }}
					>
						{isDateRangeDirty ? 'Reset' : 'Cancel'}
					</Button>
				</div>
			</Dialog>
		</div>
	);
};
