import { format } from 'date-fns';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useEffect, useState } from 'react';
import { colors } from '../../../../theme/palette';
import { ChartContent } from './ChartContent';

export type HourlyProps = {
	hourly: [number, number][];
};

export const Hourly = (props: HourlyProps) => {
	const { hourly } = { ...props };
	const [categories, setCategories] = useState([]);
	const [maxValue, setMaxValue] = useState(0);

	// effect to calculate the categories
	useEffect(() => {
		if (hourly.length) {
			let tempCategories: number[] = [];
			hourly.forEach(([hour, value]) => {
				// set the max value if the current value is greater than the max value
				if (value > maxValue) {
					setMaxValue(value);
				}
				// push the hour to the tempCategories array
				tempCategories.push(new Date().setHours(hour));
			});

			setCategories(tempCategories);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [hourly]);

	const chartConfig: Highcharts.Options = {
		title: {
			text: null,
			align: 'left',
			useHTML: true,
			//style copied from the "Typography" genererated by Material UI, proabably should've just stuck with using that method
			style: {
				color: colors.grayDark,
				fontSize: ' 0.875rem',
				display: 'flex',
				fontFamily: '"Roboto", "Helvetica", "Arial", sans- serif',
				fontWeight: '500',
				lineHeight: '1.57',
				letterSpacing: '0.00714em',
			},
		},
		legend: {
			enabled: false,
		},
		chart: {
			type: 'area',
			height: 213,
		},
		plotOptions: {
			area: {
				marker: {
					enabled: false,
				},
			},
		},
		xAxis: {
			type: 'category',
			tickInterval: 1,
			categories: categories,
			labels: {
				step: 4,
				style: {
					color: colors.grayDark,
					fontSize: '9px',
					margin: '0px',
					padding: '0px',
				},
				formatter: function () {
					// return the date formatted with date-fns to only show the hour and am or pm
					return format(new Date(this.value), 'h a'); // 1 am, 2 am, 3 am, etc
				},
			},
		},
		yAxis: [
			{
				tickInterval: maxValue,
				title: {
					text: null,
				},
			},
		],
		series: [
			{
				type: 'area',
				name: 'Hourly',
				keys: ['name', 'y'],
				data: hourly,
				fillOpacity: 0.1,
				color: colors.blueAccent,
			},
		],
		credits: {
			enabled: false, //removing watermark
		},
		tooltip: {
			animation: true,
			backgroundColor: colors.grayDark,
			borderColor: colors.grayDark,
			borderRadius: 4,
			borderWidth: 0,
			shadow: true,
			shared: true,
			enabled: true,
			useHTML: true,
			style: {
				color: 'white',
				fontSize: '12px',
				textAlign: 'center',
			},
			formatter() {
				const time = format(new Date(this.x), 'h a');
				const value = this.y;
				return `${time} <br> <hr style="height:1px;border-width:0;color:white;background-color:gray"><b>${value}</b>`;
			},
		},
	};

	return (
		<ChartContent
			name={'HOURLY TRAFFIC'}
			toolTipMsg={'The number of visits to your Liiingo based on the time of day.'}
		>
			<HighchartsReact highcharts={Highcharts} options={chartConfig} immutable={true} />
		</ChartContent>
	);
};
