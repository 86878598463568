import { useEffect, useState } from 'react';
import { UserTraffic } from '../../../../store/models';
import { colors } from '../../../../theme/palette';
import { ChartContent } from './ChartContent';
import { LineGraph } from './LineGraph';

export type TrafficProps = {
	userTraffic: UserTraffic[];
};

export const Traffic = (props: TrafficProps) => {
	const { userTraffic } = { ...props };
	const [total, setTotal] = useState(0);

	useEffect(() => {
		let tempTotal = userTraffic?.reduce((acc, obj) => acc + obj.value, 0) || 0;
		setTotal(tempTotal);
	}, [userTraffic]);

	return (
		<ChartContent name={'TRAFFIC'} total={total} toolTipMsg={'The overall number of visits for your Liiingo.'}>
			<LineGraph data={userTraffic} name="Users" color={colors.purpleAccent} />
		</ChartContent>
	);
};
