import {
	Box,
	Button,
	Card,
	CardContent,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
	Step,
	StepLabel,
	Stepper,
	Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { bindActionCreators } from '@reduxjs/toolkit';
import React, { ReactNode, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { _location, setSelectedLanguages as setSelectedLangs, translate } from '../store/slices/locationSlice';
import { Throbber } from './Throbber';
import { TranslationTree } from './TranslationTree';
import { ContentTree } from './ContentTree';
import { _sections } from '../store/slices/sectionSlice';

const useStyles = makeStyles((theme) => ({
	header: {
		backgroundColor: '#142648',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		color: theme.palette.common.white,
	},
	title: {
		'& h2': {
			color: theme.palette.common.white,
		},
	},
	closeButton: {
		color: theme.palette.common.white,
	},
	body: {
		display: 'flex',
		flexDirection: 'column',
		padding: theme.spacing(2),
	},
	text: {
		marginBottom: theme.spacing(2),
	},
	tree: {
		flexGrow: 1,
		maxWidth: 400,
	},
	footer: {
		width: '100%',
		padding: theme.spacing(2),
	},
	leaf: {
		backgroundColor: '#FFFFFF',
		paddingLeft: 0,
		display: 'block',
	},
	buttonActive: {
		backgroundColor: '#295AFA',
		color: '#fff',
		'&:hover': {
			backgroundColor: '#295AFA',
			opacity: '20%',
		},
	},
	button: {
		height: '2rem',
		backgroundColor: '#295AFA',
		color: theme.palette.common.white,
		fontFamily: 'Roboto',
		fontSize: 14,
		'&:hover': {
			backgroundColor: '#2962ff',
		},
		'&:active': {
			backgroundColor: '#295AFA',
			color: '#295AFA',
		},
		'&.MuiButton-root.Mui-disabled': {
			backgroundColor: '#49525D33',
			color: theme.palette.common.white,
		},
	},
	cancelButton: {
		marginRight: theme.spacing(2),
	},
	root: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		paddingTop: theme.spacing(1), // Don't let content overlap the floating logo button
	},
	card: {
		backgroundColor: theme.palette.background.paper,
		padding: theme.spacing(16),
		// tried to copy styes from Figma, but it didn't look the same. Leaving it as is, in the interest of time
		// width: '450px',
		// display: 'flex',
		// flexDirection: 'column',
		// alignItems: 'flex-start',
	},
	spinner: {
		display: 'flex',
		flex: 1,
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'column',
		margin: 10,
		color: '#49525D',
		fontSize: '20px',
		fontFamily: 'Open Sans',
		fontWeight: 600,
		lineHeight: '32px',
		letterSpacing: '0.15px',
	},
}));

export type TranslationPayload = {
	sections: string[];
	pages: string[];
};
export type TranslateDialogProps = {
	open: boolean;
	handleClose: (event, reason) => void;
};

export const TranslateDialog = (props: TranslateDialogProps) => {
	const { open, handleClose } = props;
	const location = useAppSelector(_location);
	const [selectedLanguages, setSelectedLanguages] = useState<any[]>(location.selectedLanguages);
	const [translating, setTranslating] = useState<boolean>(false);
	const dispatch = useAppDispatch();
	const translateIt = bindActionCreators(translate, dispatch);
	const saveSelectedLangs = bindActionCreators(setSelectedLangs, dispatch);
	const classes = useStyles();
	const sections = useAppSelector(_sections);

	// Stepper Variables and and Functions
	const steps = ['Select Contents', 'Select Languages'];
	const [activeStep, setActiveStep] = useState(0);

	// Back Button  event handler for Stepper
	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	// Next Button event handler for Stepper
	const handleNext = () => {
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
	};

	// Tracking states of selectedItems Items
	const [selectedItems, setSelectedItems] = useState<string[]>([]);
	const [selectedItemsBySxsAPages, setSelectedItemsBySxsAPages] = useState<TranslationPayload>({
		sections: [],
		pages: [],
	});

	// Updating the states of items (whether selectedItems or not) (Sections and Topics )
	const handleToggle = (id: string, isSection: boolean) => () => {
		setSelectedItems((prev) => {
			const newSelectedItems = prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id];

			setSelectedItemsBySxsAPages((prevState) => {
				const key = isSection ? 'sections' : 'pages';
				return {
					...prevState,
					[key]: newSelectedItems.filter((item) =>
						isSection ? Object.keys(sections).includes(item) : !Object.keys(sections).includes(item)
					),
				};
			});

			return newSelectedItems;
		});
	};

	// Event Handler for Select All Checkbox for Sections and Topics
	const handleSelectAllSAP = (check: boolean) => {
		const allSections = Object.keys(sections);
		const allPages = Object.keys(sections).flatMap((sectionId) => sections[sectionId].topicOrder);

		if (check) {
			setSelectedItems([...allSections, ...allPages]);
			setSelectedItemsBySxsAPages({
				sections: allSections,
				pages: allPages,
			});
		} else {
			setSelectedItems([]);
			setSelectedItemsBySxsAPages({
				sections: [],
				pages: [],
			});
		}
	};

	// Handler for select all Languages
	const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.checked) {
			setSelectedLanguages(
				location?.supportedLanguages ? location.supportedLanguages.filter((lang) => lang !== 'en') : []
			);
		} else {
			setSelectedLanguages([]);
		}
	};

	const handleSelectLanguage = (event: React.ChangeEvent<HTMLInputElement>) => {
		const language = event.target.value;
		if (event.target.checked) {
			setSelectedLanguages((prevSelectedLanguages) => [...prevSelectedLanguages, language]);
		} else {
			setSelectedLanguages((prevSelectedLanguages) =>
				prevSelectedLanguages.filter((prevLanguage) => prevLanguage !== language)
			);
		}
	};

	useEffect(() => {
		if (location?.selectedLanguages) {
			setSelectedLanguages(location.selectedLanguages);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Dialog open={open} onClose={handleClose} PaperProps={{ elevation: 0 }}>
			{translating ? (
				<Card className={classes.card}>
					<CardContent className={classes.spinner}>
						<Throbber isVisible={true} />
						<Typography align="center" paragraph style={{ margin: '10px' }}>
							Translating Your Content...
						</Typography>
					</CardContent>
				</Card>
			) : (
				<>
					<div className={classes.header}>
						<DialogTitle className={classes.title}>Select Translations</DialogTitle>
						<IconButton className={classes.closeButton} onClick={() => handleClose(null, null)}>
							<CloseIcon />
						</IconButton>
					</div>
					<DialogContent className={classes.body}>
						<div className={classes.text}>
							Replace existing text with automatic translations. Select the section and/or page with the
							languages you would like to translate:
						</div>
						{/*----------------------- STEPPER BEGIN {HEADER} -----------------------*/}
						<Stepper activeStep={activeStep}>
							{steps.map((label, index) => {
								const stepProps: { completed?: boolean } = {};
								const labelProps: { optional?: ReactNode } = {};

								return (
									<Step key={label} {...stepProps}>
										<StepLabel {...labelProps}>{label}</StepLabel>
									</Step>
								);
							})}
						</Stepper>
						{/*----------------------- STEPPER {END OF HEADER} -----------------------*/}

						{/*----------------------- STEPPER {CONTENTS} -----------------------*/}
						{activeStep === 0 ? (
							<>
								<div className={classes.text}>Select Contents by Section and Pages:</div>
								<ContentTree
									handleToggle={handleToggle}
									selectedItems={selectedItems}
									handleSelectAll={handleSelectAllSAP}
								/>
							</>
						) : (
							<>
								<div className={classes.text}>Select Languages:</div>
								<TranslationTree
									languages={
										location?.supportedLanguages
											? location.supportedLanguages.filter((lang) => lang !== 'en')
											: []
									}
									selectedLanguages={selectedLanguages}
									handleSelectLanguage={handleSelectLanguage}
									handleSelectAll={handleSelectAll}
								/>
							</>
						)}
						{/*----------------------- STEPPER {END OF CONTENTS} -----------------------*/}

						{/*----------------------- STEPPER FOOTER(BUTTONS AND OTHER INFO) -----------------------*/}
						{activeStep === steps.length ? (
							<>
								<Typography variant="h6" style={{ marginTop: 2, marginBottom: 1 }}>
									All steps completed. Thanks
								</Typography>
								<Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
									<Box sx={{ flex: '1 1 auto' }} />
									<Button onClick={() => setActiveStep(0)}>Reset</Button>
								</Box>
							</>
						) : (
							<>
								<Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
									<DialogActions className={classes.footer}>
										<Button
											disabled={activeStep === 0}
											onClick={handleBack}
											style={{ marginRight: 1 }}
											className={activeStep === 0 ? '' : classes.buttonActive}
										>
											Back
										</Button>
										<Box sx={{ flex: '1 1 auto' }} />
										<Button
											onClick={handleNext}
											className={
												selectedItems.length === 0 || activeStep === 1
													? ''
													: classes.buttonActive
											}
											disabled={selectedItems.length === 0 || activeStep === 1}
										>
											Next
										</Button>
										<Button
											className={classes.cancelButton}
											onClick={() => {
												handleClose(null, null);
											}}
										>
											Cancel
										</Button>
										<Button
											color="primary"
											className={classes.button}
											onClick={() => {
												const payload = {
													...selectedItemsBySxsAPages,
													lang: selectedLanguages,
												};
												setTranslating(true);
												translateIt(payload);
												saveSelectedLangs(selectedLanguages);
												// normally we'd want to await the results of the "tranlateIt" call, but I've implemneted it in such a way that it will immediately return true, and when the translations are done, it will update the store and the UI will update accordingly
												// so we're going to cheat, because this isn't a mission critical feature. We're going to use a setTimeout to wait 4 seconds, then close the dialog. This is a hack, but it works for now.
												setTimeout(() => {
													handleClose(null, null);
													setTranslating(false);
												}, 4000);
											}}
											disabled={
												selectedLanguages.length && activeStep === steps.length - 1
													? false
													: true
											}
											disableRipple
											style={activeStep !== steps.length - 1 ? { display: 'none' } : {}}
										>
											Translate
										</Button>
									</DialogActions>
								</Box>
								{/*----------------------- STEPPER END OF FOOTER -----------------------*/}
							</>
						)}
						{/*----------------------- STEPPER END -----------------------*/}
					</DialogContent>
				</>
			)}
		</Dialog>
	);
};
