import { bindActionCreators } from '@reduxjs/toolkit';
import { useEffect, useState } from 'react';
import { liiingoFailure } from '../components/LiiingoFailureSnackbar';
import * as api from '../store/api-client';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import {
	_stripeCustomer,
	_stripeSubscription,
	setStripeSubscription,
	setStripeCustomer,
	_loadingStripeData,
	fetchStripeInfo,
	_redirecting,
	setRedirecting,
} from '../store/slices/editorSlice';

// useStripeState hook. Not to be confused with the useStripe hook
export const useStripeState = () => {
	const dispatch = useAppDispatch();
	// variables that are shared among all instances of this hook are stored in Redux
	const fetchStripeData = bindActionCreators(fetchStripeInfo, dispatch);
	const setSubscription = bindActionCreators(setStripeSubscription, dispatch);
	const setCustomer = bindActionCreators(setStripeCustomer, dispatch);
	const startRedirect = bindActionCreators(setRedirecting, dispatch);
	const redirecting = useAppSelector(_redirecting);
	const stripeCustomer = useAppSelector(_stripeCustomer);
	const stripeSubscription = useAppSelector(_stripeSubscription);
	const loadingStripeData = useAppSelector(_loadingStripeData);
	// these variables are derived from variables stored in Redux. When the Redux variable changes, these are recalculated in a useEffect
	const [billingInfo, setBillingInfo] = useState(false);
	const [daysRemaining, setDaysRemaining] = useState(0);
	// variables that are not in Redux (or derived from) can have different values between each component that uses this hook
	// don't do that in this hook. This needs to behave like a singleton.

	// -------
	// EFFECTS
	// -------

	// effect for determining if a default payment method has been added yet
	useEffect(() => {
		if (!loadingStripeData && stripeCustomer && stripeSubscription) {
			if (stripeCustomer.invoice_settings?.default_payment_method || stripeSubscription.default_payment_method) {
				setBillingInfo(true);
				return;
			}
		}
		setBillingInfo(false);
	}, [stripeCustomer, stripeSubscription, loadingStripeData]);

	// effect for setting days remaining
	useEffect(() => {
		if (!loadingStripeData && stripeSubscription) {
			const trialEndMs = stripeSubscription?.trial_end * 1000;
			const rightNow = Date.now();
			let decimalDays = (trialEndMs - rightNow) / (1000 * 60 * 60 * 24); //diff in ms / divide by ms in a day = days remaining
			setDaysRemaining(Math.ceil(decimalDays)); // make readable for mortals
		}
	}, [loadingStripeData, setDaysRemaining, stripeSubscription]);

	// --------------------------------------------------------------
	// METHODS TO FETCH/PERSIST STRIPE DATA, VIA PHP SERVER ENDPOINTS
	// --------------------------------------------------------------

	// returns a new Stripe Customer and Subscription
	const createCustomerSubscription = async (email, priceId, coupon = null, trialDays = null) => {
		let { stripeCustomer, stripeSubscription } = await api.createCustomerSubscription(
			email,
			priceId,
			coupon,
			trialDays
		);
		setCustomer(stripeCustomer);
		setSubscription(stripeSubscription);
		return { stripeCustomer, stripeSubscription };
	};

	// modifies the trial end date of a subscription
	const changeTrialEndDate = async (trialEnd) => {
		let updatedSubscription = await api.changeTrialEndDate(stripeSubscription.id, trialEnd);
		setSubscription(updatedSubscription);
	};

	// modifies the canceled_at date of a subscription
	const changeCancelAtDate = async (cancel_at) => {
		let updatedSubscription = await api.changeCancelAtDate(stripeSubscription.id, cancel_at);
		setSubscription(updatedSubscription);
	};

	// redirect to the Stripe Customer Billing Portal, modifies the return url based on existing billingInfo
	const openCustomerPortal = () => {
		if (!loadingStripeData && !stripeCustomer) {
			//make sure we have a customer first...
			console.error('no stripe customer');
			liiingoFailure('no stripe customer found');
			return;
		}
		startRedirect(true); // flag to set a page spinner while waiting for the portal to open

		// if no billing info has been added yet, assume that when they return from the portal, they will have added it
		// (another check for billing info is used to ensure this, before showing the success message in the TimeBomb)
		let returnUrl = window.location.href;
		if (!billingInfo) {
			// check if there are query string params already
			if (window.location.search) {
				returnUrl += '&';
			} else {
				returnUrl += '?';
			}
			returnUrl += 'billingAdded=true';
		}
		api.openCustomerPortal(stripeCustomer.id, returnUrl);
	};

	return {
		setCustomer,
		setSubscription,
		stripeCustomer,
		stripeSubscription,
		createCustomerSubscription,
		billingInfo,
		openCustomerPortal,
		changeTrialEndDate,
		changeCancelAtDate,
		fetchStripeData,
		loadingStripeData,
		daysRemaining,
		redirecting,
		// daysRemaining, status, cancelSubscription, changeSubscription, changeTrialEndDate, getCustomer, getSubscription, openCustomerPortal
	};
};
