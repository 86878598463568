import { SvgIcon } from '@material-ui/core';
import * as React from 'react';

export const SvgShareDisabled = (props) => (
	<SvgIcon {...props}>
		<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 281.99 269.33" {...props}>
			<defs>
				<style>{`.cls-1,.cls-2{fill:${props.color};}.cls-1{stroke:#231f20;stroke-miterlimit:10;stroke-width:6.09px;}`}</style>
			</defs>
			<title>{'Liiingo - Share Icon_Final'}</title>
			<path
				className="cls-1"
				d="M191,148.68a41.47,41.47,0,0,0-5.62.57l42.32,61.52A41.14,41.14,0,0,0,191,148.68Z"
			/>
			<path
				className="cls-1"
				d="M156,170.54c-.47.88-.91,1.77-1.32,2.69L94.19,144.71A41,41,0,0,0,93,104.58l12.75-7-5.12-7.45L87.48,97.36a41.21,41.21,0,1,0,1.62,54.89l62.78,29.62a41.08,41.08,0,0,0,41.64,49.2,38.13,38.13,0,0,0,3.86-.31Zm4.12,20.31h0c0-.26,0-.52,0-.79C160.09,190.32,160.06,190.58,160.07,190.85Z"
			/>
			<path className="cls-1" d="M132.69,72.6l5.12,7.45,13.81-7.56A41.63,41.63,0,1,0,148.35,64Z" />
			<polygon className="cls-2" points="241.4 260.43 73.82 16.84 92.8 9.19 260.38 252.78 241.4 260.43" />
		</svg>
	</SvgIcon>
);
