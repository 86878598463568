import toast from 'react-hot-toast';
import axiosInstance from '.';
import { newTopic, SimpleTopic, Status, Topic } from '../models';
import { urlToFile } from './file';

function stripQuotes(res: any): string {
	if (typeof res === 'string' && res !== 'null') {
		return res.replace(/['"]+/g, '');
	} else {
		return '';
	}
}

// GET TOPIC & THINGS
export const getTopicById = (topicId: string) => {
	return axiosInstance
		.post<any>('/exhibit/getExhibitById', { exhibitId: topicId })
		.then((res: any) => {
			return newTopic(res.data.exhibit);
		});
};

export const getAllTopics = () => {
	return axiosInstance
		.post<Topic[]>('/exhibit/getAllExhibits', {})
		.then((res: any) => res.data.map((r) => newTopic(r)));
};

export const getLastViewedTopicId = () => {
	return axiosInstance.post('/exhibit/lastViewed', {}, { responseType: 'text' }).then((res) => stripQuotes(res.data));
};

export const getTopicsBySectionId = (sectionId: string) => {
	return axiosInstance
		.post<Topic[]>('/exhibit/getExhibitsByAreaId', { areaId: sectionId })
		.then((res: any) => res.data.map((r) => newTopic(r)));
};

export const getTopicsByLocationId = async (locationId: string) => {
	let res = await axiosInstance.post<Topic[]>('/exhibit/getExhibitsByLocationId', { locationId: locationId });
	return res.data.map((t) => newTopic(t));
};

export const getMyTopicsList = () => {
	return axiosInstance
		.post<Topic[]>('/exhibit/getExhibitsByOrganizationId', {})
		.then((res: any) => res.data.map((r) => newTopic(r)));
};

export const getMySimpleTopicsList = () => {
	return axiosInstance
		.get<SimpleTopic[]>('/exhibit/getMyExhibitListSimple')
		.then((res: any) => res.data.map((r) => new SimpleTopic(r)));
};

// just a test using GET functionality with PHP api
// not used in the app (yet?)
export const getTopicQr = (topicId: string) => {
	return axiosInstance.get(`/exhibit/getTopicQr?exhibitId=${topicId}`).then((res) => toast.success(res.toString()));
};

export const updateTopicQr = (id: string) => {
	return axiosInstance.post('/exhibit/updateExhibitQr', { exhibitId: id }).then((res) => res.data);
};

// SAVE TOPIC
export const saveTopic = async (topic: Topic) => {
	//set these props to empty string if they don't exist
	if (!topic.hasOwnProperty('backgroundImageName')) {
		topic.backgroundImageName = '';
	}
	if (!topic.hasOwnProperty('exhibitImage')) {
		topic.exhibitImage = '';
	}

	if (topic.exhibitImage?.includes('blob')) {
		// If somehow the createNewEntity flag is set, AND we have an image blob attached, then this isn't really a new topic.
		// Drop the flag and save the topic as normal.
		// (flag is not cleared in the UI until the topic is finished saving to network
		// sometimes the user can make an edit/save before the initial create/save is done
		// the new topic id should exist even if the call for create/save has not returned yet. It's proabably out there churning a QR code still.
		// so dropping the flag and allowing the save to continue should be fine, accorinding to my calculations)
		if (topic.createNewEntity) {
			delete topic.createNewEntity;
		}
		const file = await urlToFile(topic.exhibitImage, topic.backgroundImageName);
		saveTopicImageExpanded(file, topic.backgroundImageName, topic.id);
	}
	const response = await axiosInstance.post('/exhibit/save', topic);
	return response.data;
};

export const saveTopicBackground = async (name, url, id) => {
	const file = await urlToFile(url, name);
	let response = await saveTopicImageExpanded(file, name, id);
	response.name = name;
	response.url = response.path;
	return response;
};

export const saveClonedTopic = async (topic: Topic) => {
	const response = await axiosInstance.post('/exhibit/save', topic);
	return response.data;
};

export const updateExhibitImage = async (id: string, oldId: string) => {
	const response = await axiosInstance.post('/exhibit/cloneExhibitImageById', {
		id,
		oldId,
	});

	return { ...response.data };
};

export const saveTopicOrder = async (sectionId: string, topicOrder: string[]) => {
	const response = await axiosInstance.post('/area/setExhibitOrder', {
		id: sectionId,
		exhibitOrder: topicOrder,
	});
	return response.data;
};

export const saveTopicImage = (topic: Topic) => {
	const { background, backgroundImageName, id } = topic;
	const formData = new FormData();
	formData.append(`background`, background);
	formData.append(`backgroundImageName`, backgroundImageName);
	formData.append(`id`, id);
	return axiosInstance.post('/exhibit/saveBackground', formData).then((res: any) => res.data);
};

export const saveTopicImageExpanded = async (background: File, backgroundImageName: string, id: string) => {
	const formData = new FormData();
	formData.append(`background`, background);
	formData.append(`backgroundImageName`, backgroundImageName);
	formData.append(`id`, id);
	const response = await axiosInstance.post('/exhibit/saveBackground', formData);
	return response.data;
};

//DELTE TOPIC
export const deleteTopic = (topic: Topic) => {
	let deletedTopic = structuredClone(topic);
	deletedTopic.status = Status.Deleted;
	return saveTopic(deletedTopic).then((res: any) => res.data);
};
