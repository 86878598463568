export interface Redirect extends ReturnType<typeof newRedirect> {}

export const newRedirect = (fetchedRedirect: Partial<RedirectConstructorParams>) => {
	const _redirect = new _Redirect(fetchedRedirect); //for data conversions (_id -> id, etc.)
	const redirect = JSON.parse(JSON.stringify(_redirect)); // serialized-converted Redirect
	return redirect;
};

interface RedirectConstructorParams {
	_id?: { $oid: string };
	id?: string;
	name: string;
	url: string;
	createdDate: { $date: { $numberLong: string } };
	pngUrl: string;
	svgUrl: string;
	visits: { [IP: string]: number };
}

export class _Redirect {
	public _id: { $oid: string };
	public id: string = '';
	public name: string = 'New Redirect';
	public url: string = '';
	public createdDate: string;
	public pngUrl: string = '';
	public svgUrl: string = '';
	public visits: { [IP: string]: number } = {};

	constructor(data?: Partial<RedirectConstructorParams>) {
		if (!!data) {
			this._id = data._id;
			this.id = data._id?.$oid || data.id;
			this.name = data.name;
			this.url = data.url;
			this.createdDate = data.createdDate?.$date?.$numberLong || null;
			this.pngUrl = data.pngUrl;
			this.svgUrl = data.svgUrl;
			this.visits = data.visits;
		}
	}
}
