import { Button, createStyles, createTheme, makeStyles, MuiThemeProvider, Theme } from '@material-ui/core';
import InputBase from '@material-ui/core/InputBase';
import { useEffect, useRef, useState } from 'react';
import { bindActionCreators } from 'redux';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { contentChanged, _contents } from '../../store/slices/contentSlice';
import { _activeLanguageCode } from '../../store/slices/editorSlice';
import { colors } from '../../theme/palette';
import { LiiingoTooltip } from '../LiiingoTooltip';

const theme = createTheme({
	props: {
		// Name of the component
		MuiButtonBase: {
			// The properties to apply
			disableRipple: true, // No more ripple, on the whole application!
		},
	},
});

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		buttonArea: {
			border: '1px solid transparent',
			'&:hover': {
				border: '1px solid #D4DEFE',
			},

			'&.Mui-focused': {
				border: '1px solid #4C6CDC',
			},
		},
		button: {
			fontSize: 16,
			fontWeight: 'bold',
			backgroundColor: colors.btnBack,
			color: colors.grayDark,
			padding: 10,
			textAlign: 'left',
			width: '100%',
			height: '100%',
			borderRadius: 10,
			textTransform: 'none',
		},
		textArea: {
			color: colors.grayDark,
			fontSize: 16,
			fontWeight: 'bold',
			textAlign: 'center',
			width: '100%',
		},
	})
);

interface EditButtonProps {
	contentId: string;
	isFocused: boolean;
}

export const EditButton = (props: EditButtonProps) => {
	const { contentId, isFocused } = props;

	const classes = useStyles();
	const editorRef = useRef(null);
	const btnRef = useRef(null);

	const [isTextFocus, setIsTextFocus] = useState(false);
	const [isTextOver, setIsTextOver] = useState(false);
	const contents = useAppSelector(_contents);
	const content = contents[contentId];
	const activeLanguageCode = useAppSelector(_activeLanguageCode);
	const [translation, setTranslation] = useState(
		content.languages.find((l) => l.languageCode === activeLanguageCode)
	);
	const [editorValue, setEditorValue] = useState('no text');

	useEffect(() => {
		setTranslation(content?.languages.find((item) => item.language === activeLanguageCode));
		setEditorValue(translation?.title || translation?.content || 'still missing');
	}, [activeLanguageCode, content, contentId, setTranslation, translation]);

	const dispatch = useAppDispatch();
	const onContentChanged = bindActionCreators(contentChanged, dispatch);

	const handleChange = (e) => {
		if (isFocused) {
			onContentChanged({
				language: translation.language,
				content: e.target.value,
				title: e.target.value,
				id: contentId,
			});
		}
	};

	const handleEditorFocus = () => {
		if (isFocused) {
			editorRef.current.readOnly = false;
			editorRef.current.select();
			setIsTextFocus(true);
		}
	};

	const handleEditorFocusOut = () => {
		if (editorRef.current.value === '') editorRef.current.value = content;
		setIsTextFocus(false);
		editorRef.current.readOnly = true;
	};

	return (
		<MuiThemeProvider theme={theme}>
			<div className={classes.buttonArea}>
				<LiiingoTooltip
					placement="top"
					message={
						isFocused
							? isTextOver
								? 'Click to edit button text.'
								: 'Double-click to edit button text.'
							: 'Double-click to edit button text'
					}
				>
					<Button
						fullWidth
						variant="contained"
						ref={btnRef}
						className={classes.button}
						onDoubleClick={handleEditorFocus}
						disabled={!isFocused}
					>
						<InputBase
							inputProps={{
								className: classes.textArea,
								style: {
									cursor: isTextFocus || isTextOver ? 'text' : 'pointer',
									border: isTextFocus ? '1px solid #4C6CDC' : isTextOver ? '1px solid #D4DEFE' : null,
								},
							}}
							value={editorValue}
							inputRef={editorRef}
							fullWidth
							readOnly
							onMouseOver={(e) => {
								if (isFocused) setIsTextOver(true);
							}}
							onMouseOut={(e) => {
								editorRef.current.blur();
								setIsTextOver(false);
							}}
							onChange={(e) => {
								setEditorValue(e.target.value);
							}}
							onClick={(e) => {
								handleEditorFocus();
							}}
							onFocus={handleEditorFocus}
							onBlur={(e) => {
								handleChange(e);
								handleEditorFocusOut();
							}}
							onKeyPress={(e) => {
								if (e.key === 'Enter') {
									editorRef.current.blur();
									setIsTextFocus(false);
									setIsTextOver(false);
								}
							}}
						/>
					</Button>
				</LiiingoTooltip>
			</div>
		</MuiThemeProvider>
	);
};
