import List from '@material-ui/core/List';
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Box } from '@mui/material';
import { bindActionCreators } from '@reduxjs/toolkit';
import React, { useEffect, useState } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { _plan } from '../../store/slices/editorSlice';
import { _sectionOrder, _selectedLocation } from '../../store/slices/locationSlice';
import { _sections, _selectedSectionId } from '../../store/slices/sectionSlice';
import { _selectedTopicId, _topicIsLoading, _topics, addTopic, renameTopic } from '../../store/slices/topicSlice';
import { DroppableMenuWrapper } from '../ElementMenu/DroppableMenuWrapper';
import { InitialPagePanelWalkthrough } from '../Walkthroughs/InitialPagePanelWalkthrough';
import { drawerCollapsedWidth } from './LiiingoDrawer';
import { PageAdd } from './PageAdd';
import { PageMenu } from './PageMenu';
import { SectionAdd } from './SectionAdd';
import { SectionMenu } from './SectionMenu';

const useStyles = makeStyles(() =>
	createStyles({
		list: {
			paddingLeft: drawerCollapsedWidth,
			paddingTop: 0,
			paddingBottom: 0,
		},
		containerBox: {
			height: '100%',
		},
		pageOrg: {
			width: '100%',
		},
		stickyTop: {
			position: 'sticky',
			zIndex: 1,
			top: 40,
		},
		stickyBottom: {
			position: 'sticky',
			zIndex: 1,
			bottom: 0,
			backgroundColor: 'white',
		},
	})
);

export type AppMenuProps = {
	addSection: (name: string) => void;
	reorderSection: (payload: { oldIndex: number; newIndex: number }) => void;
	reorderTopic: (payload: { oldIndex: number; oldSectionId: string; newIndex: number; newSectionId: string }) => void;
};

export const AppMenu = (props: AppMenuProps) => {
	const { reorderSection, reorderTopic } = {
		...props,
	};
	const classes = useStyles();
	const [showAddSection, setShowAddSection] = useState(true);
	const [showAddPage, setShowAddPage] = useState(false);
	const [sectionDropDisabled, setSectionDropDisabled] = useState(true);
	const [topicDropDisabled, setTopicDropDisabled] = useState(true);

	const [expand, setExpand] = useState(true);
	const [stepKey, setStepKey] = useState('');
	const pageLimit = useAppSelector(_plan)?.restrictions?.pageLimit || 0;

	const topics = useAppSelector(_topics);
	const topicLoading = useAppSelector(_topicIsLoading);
	const sections = useAppSelector(_sections);
	const selectedSectionId = useAppSelector(_selectedSectionId);
	const selectedSection = sections[selectedSectionId];
	const topicsForSection = (section) => section.topicOrder.map((topicId) => topics[topicId]);
	const selectedTopicId = useAppSelector(_selectedTopicId);
	const sectionOrder = useAppSelector(_sectionOrder);
	const location = useAppSelector(_selectedLocation);
	const dispatch = useAppDispatch();
	const addNewTopic = bindActionCreators(addTopic, dispatch);
	const changePageName = bindActionCreators(renameTopic, dispatch);

	const addPage = React.useCallback(
		(pageName: string) => {
			// get position for new page, 1 spot after the currently selected page
			let newIndex = selectedSection.topicOrder.indexOf(selectedTopicId) + 1;
			addNewTopic({ section: selectedSection, index: newIndex, pageName });
			setStepKey('end');
		},
		[selectedTopicId, selectedSection, addNewTopic]
	);

	const renamePage = (topicId, title) => {
		changePageName({ topicId, title });
		setStepKey('end');
	};

	const moveElement = (e) => {
		if (e.destination) {
			const draggableId = e.draggableId;
			// (adding comments)
			// if the item is a section, and it's not from the same index as the destination, then reorder the sections
			if (Object.keys(sections).includes(draggableId)) {
				if (e.source.index !== e.destination.index) {
					reorderSection({ oldIndex: e.source.index, newIndex: e.destination.index });
				}
			}
			// if the item is a topic, and it's not from the same index/section as the destination, reorder the topics
			else if (Object.keys(topics).includes(draggableId)) {
				reorderTopic({
					oldIndex: e.source.index,
					oldSectionId: e.source.droppableId,
					newIndex: e.destination.index,
					newSectionId: e.destination.droppableId,
				});
			}
		}
		setShowAddSection(true);
		setSectionDropDisabled(true);
		setTopicDropDisabled(true);
	};

	const handleDragStart = (e) => {
		setShowAddSection(false);
		const draggableId = e.draggableId;
		// if the item is a section, then set the disabling of the section drop to false?
		// TODO: maybe change these variable names to use the positive version - setSectionDropEnabled(true)
		if (Object.keys(sections).includes(draggableId)) {
			setSectionDropDisabled(false);
		}
		// if the item is a topic, then disable the topic drop?
		else if (Object.keys(topics).includes(draggableId)) {
			setTopicDropDisabled(false);
		}
	};

	//TODO: what's this do?
	useEffect(() => {
		if (topicLoading) setStepKey('section-tab');
		// eslint-disable-next-line
	}, [selectedSection]);

	return (
		<Box className={classes.containerBox} data-tour="page-tab">
			<DragDropContext onDragEnd={moveElement} onDragStart={handleDragStart}>
				<DroppableMenuWrapper id={location.id} dropDisabled={sectionDropDisabled} disableGutters>
					<InitialPagePanelWalkthrough stepKey={stepKey} />
					<List className={classes.list}>
						{/* this doesn't trigger the add page action, it only shows another component which prompts for a page title. That component will actually create the page  */}
						<div className={classes.stickyTop}>
							<PageAdd
								showTheAddPageComponent={() => {
									setExpand(true);
									//TODO: remove this setTimeout
									setTimeout(() => {
										setShowAddPage(true);
										setStepKey('rename-page');
									}, 100); //need to give section a chance to render
								}}
							/>
						</div>

						{/* render sections in order */}
						{sectionOrder.map((sectionId, index) => {
							if (sections[sectionId]) {
								const section = sections[sectionId];
								return (
									<Box
										className={classes.pageOrg}
										data-tour="page-organization"
										key={sectionId + '-menu-item'}
									>
										{/* each section */}
										<SectionMenu
											id={sectionId}
											key={sectionId}
											index={index}
											expand={expand && sectionId === selectedSection?.id}
											last={sectionOrder.length - 1}
										>
											{/* each section's list of pages */}
											<PageMenu
												section={section}
												dropDisabled={topicDropDisabled}
												locationId={location.id}
												disableDelete={topicsForSection(section).length === 1}
												renamePage={renamePage}
												reorderTopic={reorderTopic}
												orderedTopics={topicsForSection(section)}
												showAddPage={showAddPage}
												setShowAddPage={setShowAddPage}
												addTopic={addPage}
											/>
										</SectionMenu>
									</Box>
								);
							} else {
								return null;
							}
						})}

						{showAddSection && Object.keys(topics).length !== pageLimit && (
							<div className={classes.stickyBottom}>
								<SectionAdd setStepKey={setStepKey} key="section-add" />
							</div>
						)}
					</List>
				</DroppableMenuWrapper>
			</DragDropContext>
		</Box>
	);
};
