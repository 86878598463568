import Box from '@mui/material/Box';
import ListItem from '@material-ui/core/ListItem';
import { Theme } from '@material-ui/core/styles/createTheme';

import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import React from 'react';
import { useImageUploadField } from '../../hooks/useImageEditorUploadField';
import { colors } from '../../theme/palette';
import { checkMultipleValidationRules, recommendHighSolution } from '../../util/imageValidators';
import { IMAGE_PREVIEW, MAX_IMAGE_UPLOAD_SIZE } from '../../util/constants';

export const PreviewMinHeight = 133;

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		imageContainer: {
			padding: '0px',
		},
		imageBox: {
			minWidth: '100%',
			minHeight: PreviewMinHeight,
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			backgroundColor: colors.grayLight5,
		},
		image: {
			height: '100%',
			width: '100%',
			backgroundColor: colors.grayMedium,
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'flex-start',
			justifyContent: 'flex-end',
			boxSizing: 'border-box',
			backgroundRepeat: 'no-repeat',
			backgroundPosition: 'center',
			backgroundSize: 'cover',
		},
		imageNew: {
			height: '100%',
			width: 'auto',
		},
	})
);

export type ImageOptionProps = {
	imageUrl?: string;
	setImagePreview: (file: File) => void;
	isOver?: boolean;
	isDrag?: boolean;
	isValidType?: boolean;
};

export const ImageOption = (props: ImageOptionProps) => {
	const { imageUrl, setImagePreview, isOver, isDrag, isValidType } = { ...props };
	const classes = useStyles();

	const [imageUploadFieldProps, ImageUploadField] = useImageUploadField({
		fieldName: `customLogoImageFile`,
		maxFileSizeBytes: MAX_IMAGE_UPLOAD_SIZE,
		initialValue: imageUrl,
		validateImage: checkMultipleValidationRules([recommendHighSolution]),
		setImagePreview: setImagePreview,
		isDrag: isDrag,
		isOver: isOver,
		imageType: IMAGE_PREVIEW,
		isValidType: isValidType,
	});

	return (
		<ListItem key={`image_${imageUrl}`} disableGutters={true} className={classes.imageContainer}>
			<Box className={classes.imageBox}>
				<ImageUploadField {...imageUploadFieldProps} />
			</Box>
		</ListItem>
	);
};
