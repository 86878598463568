import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Redirect } from '../models';

export interface RedirectMap {
	[id: string]: Redirect;
}
export interface RedirectState {
	redirects: RedirectMap;
	selectedRedirectId: string;
	isCreating: boolean;
}

const initRedirectState: RedirectState = {
	redirects: {} as RedirectMap,
	selectedRedirectId: null,
	isCreating: false,
};

//---------------------- Thunks ----------------------

// no thunks yet

//---------------------- Types ----------------------

export interface newRedirectPayload {
	name: string;
	url: string;
	createdDate?: string;
	pngUrl?: string;
	svgUrl?: string;
}

//---------------------- Slice ----------------------

const redirectSlice = createSlice({
	name: 'redirect',
	initialState: initRedirectState,
	reducers: {
		createNewRedirect: (state: RedirectState, action: PayloadAction<newRedirectPayload>) => {
			// this reducer is used to create a new redirect. The api call happens in the LiiingoMiddleware in store.ts, when this action is intercepted
			// the payload will be modified to contain the newly created redirect
			let id = action.payload['id'];
			state.redirects[id] = action.payload;
			state.selectedRedirectId = id;
			state.isCreating = false;
		},
		fetchRedirects: (state: RedirectState, action: PayloadAction<Redirect[]>) => {
			// this reducer is used to fetch redirects. The api call happens in the LiiingoMiddleware in store.ts, when this action is intercepted
			// the payload will be modified to contain the fetched redirect array and the store will be updated with it
			let items = action.payload;
			items.forEach((item) => {
				let id = item['id'];
				state.redirects[id] = item; //populate the redirects map
			});
			let first = Object.keys(state.redirects)[0];
			state.selectedRedirectId = first && state.redirects[first]['id']; // set the first item as the selected redirect
		},
		updateRedirect: (state: RedirectState, action: PayloadAction<Partial<Redirect>>) => {
			// this modifys the selected redirect, accepts a partial redirect object, must include the id of the object to update
			state.redirects[action.payload.id] = {
				...state.redirects[action.payload.id], //spread the current redirect
				...action.payload, //spread the updated values on top
			};
		},
		deleteRedirect: (state: RedirectState, action: PayloadAction<string>) => {
			// delete the redirect matching the id passed in
			delete state.redirects[action.payload];
		},
		setIsCreating: (state: RedirectState, action: PayloadAction<boolean>) => {
			state.isCreating = action.payload;
		},
	},
	extraReducers: (builder) => {
		// builder.addCase(fetchRedirect.fulfilled, (state, action) => {
		//   state.isLoading = false;
		//   let id = action.payload['_id'];
		//   state.redirects[id] = action.payload;
		// });
		// builder.addCase(fetchRedirect.pending, (state) => {
		//   state.isLoading = true;
		// });
		// builder.addCase(fetchRedirect.rejected, (state) => {
		//   state.isLoading = false;
		// });
	},
});

//---------------------- Exports ----------------------

// Export reducer
export default redirectSlice.reducer;

// Export actions
export const {
	createNewRedirect,
	fetchRedirects,
	updateRedirect,
	deleteRedirect,
	setIsCreating,
} = redirectSlice.actions;

// Export selectors
export const _redirects = (state) => state.redirect.redirects;
export const _selectedRedirectId = (state) => state.redirect.selectedRedirectId;
export const _selectedRedirect = (state) => state.redirect.redirects[state.redirect.selectedRedirectId];
export const _isCreating = (state) => state.redirect.isCreating;
