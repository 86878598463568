import Divider from '@material-ui/core/Divider';
import createStyles from '@material-ui/core/styles/createStyles';
import { Theme } from '@material-ui/core/styles/createTheme';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@mui/material/Box';
import { subDays } from 'date-fns';
import { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { Throbber } from '../..';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
	_dateRange,
	_isLoading,
	// _newTraffic,
	_pageViews,
	// _returningTraffic,
	_topContent,
	_userTraffic,
	newDateRange,
} from '../../../store/slices/analyticsSlice';
import { _selectedLocationId } from '../../../store/slices/locationSlice';
import { _topics } from '../../../store/slices/topicSlice';
import { MostViewed, PageViews, Traffic } from './Charts';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		content: {
			marginTop: 10,
			display: 'flex',
			flexDirection: 'column',
			height: '100%',
			justifyContent: 'space-between',
		},
		loadingBox: {
			display: 'flex',
			alignitems: 'center',
			justifyContent: 'center',
		},
	})
);

export type LiiingoAnalyticsProps = {};

export const LiiingoAnalytics = (props: LiiingoAnalyticsProps) => {
	const classes = useStyles();
	const dispatch = useAppDispatch();
	const applyDateRange = bindActionCreators(newDateRange, dispatch);
	const topContent = useAppSelector(_topContent);
	const userTraffic = useAppSelector(_userTraffic);
	// const newTraffic = useAppSelector(_newTraffic);
	// const returningTraffic = useAppSelector(_returningTraffic);
	const pageViews = useAppSelector(_pageViews);
	const locationId = useAppSelector(_selectedLocationId);
	const topics = useAppSelector(_topics);
	const isLoading = useAppSelector(_isLoading);
	const dateRange = useAppSelector(_dateRange);

	// Effect for setting the date range in redux to the last 30 days (applyDateRange action).
	// changing the dateRange will trigger a refetch of all analytics data.
	useEffect(() => {
		// if there's a location loaded, we assume the store is hydrated and we can set the date range
		// also make sure that we're not already loading data
		if (locationId && !isLoading) {
			let ed = new Date().setHours(23, 59, 59, 999); // end of today in local time zone
			let sd = subDays(ed, 30); // 30 days ago
			let endDate = new Date(ed).toISOString();
			let startDate = new Date(sd).toISOString();
			// if the date range is already set to the last 30 days, we don't need to set it again
			// console.log('dateRange.startDate, startDate', dateRange.startDate, startDate);
			if (dateRange.startDate !== startDate || dateRange.endDate !== endDate) {
				applyDateRange({ startDate, endDate });
			}
		}
	}, [locationId, applyDateRange, isLoading, dateRange]);

	return isLoading ? (
		<Box className={classes.loadingBox}>
			<Throbber />
		</Box>
	) : (
		<Box className={classes.content}>
			<Traffic userTraffic={userTraffic} />
			<Divider variant="middle" />
			<MostViewed data={topContent} topics={topics} />
			<Divider variant="middle" />
			<PageViews data={pageViews} topics={topics} />
		</Box>
	);
};
