import Box from '@mui/material/Box';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import { colors } from '../../theme/palette';
import Footer from './IphoneBottomMenu';
import Header from './IphoneStatusbar';

export const IPHONE_RADIUS = 25;

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: 'flex',
			minHeight: 750,
			width: 375,
		},
		contentWrapper: {
			flex: 1,
		},
		phoneScreen: {
			display: 'flex',
			flexDirection: 'column',
			backgroundColor: colors.pureWhite,
			borderRadius: IPHONE_RADIUS,
			border: 'solid',
			borderWidth: 10,
			borderColor: colors.pureBlack,
			width: 400,
		},
		phoneFooter: {
			minWidth: '100%',
			borderBottomLeftRadius: IPHONE_RADIUS,
			borderBottomRightRadius: IPHONE_RADIUS,
		},
	})
);
export type IPhoneViewerProps = {
	onFocusItemName: (id: string) => void;
	isFocused: boolean;
	logoUrl: string;
};
const defaultProps = {};
export const IPhoneViewer: React.FC<IPhoneViewerProps> = (props) => {
	const { children, onFocusItemName, isFocused, logoUrl } = { ...props, ...defaultProps };
	const classes = useStyles();

	return (
		<Box className={classes.root}>
			<Box data-tour="mobile-preview" className={classes.phoneScreen}>
				<Header onFocusItemName={onFocusItemName} isFocused={isFocused} logoUrl={logoUrl} />
				<Box className={classes.contentWrapper}>{children}</Box>
				<Footer className={classes.phoneFooter} />
			</Box>
		</Box>
	);
};
