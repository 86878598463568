import jwt from 'jsonwebtoken';
import * as api from '../store/api-client/index';
import { PHP_ACCESS_TOKEN } from './constants';

/**
// This function is used to refresh the PHP JWT access token if needed
// @param token
// @returns a good token
*/
export async function checkPHPToken(token) {
	// if passed a null token, give it right back
	if (!token) {
		return null;
	}

	try {
		if (notExpired(token)) {
			// if token is good, return it
			return token;
		} else {
			// if the token is expired, refresh it
			const newToken = await api.refreshToken(token);
			localStorage.setItem(PHP_ACCESS_TOKEN, newToken);
			return newToken;
		}
	} catch (error) {
		console.error('Error checking PHP token: ' + error);
		return null;
	}
}

// is the token expired?
// didn't use the opposite of this state ("isExpired") because "exp" could be null which would be "less than" the current time
export function notExpired(token) {
	let { exp }: any = jwt.decode(token);
	const currentTime = Math.floor(Date.now() / 1000);
	return exp > currentTime + 60 * 5; // is expipiration time greater than 5 minutes from now?
}
