import { AnalyticsDate, AnalyticsId } from './Analytics';

export type ViewsByMetadata = {
	areaId?: string;
	exhibitId?: string;
	locationId?: string;
	organizationId?: string;
	viewsBy?: string;
	platform?: string;
	browser?: string;
	appSession?: string;
	orgName?: string;
	locationName?: string;
	areaName?: { name: string; language: string }[];
	exhibitName?: { name: string; language: string }[];
};

export type ViewsByResponse = {
	_id: AnalyticsId;
	type: string;
	uuid: string;
	os: string;
	metadata: ViewsByMetadata;
	createdDate: AnalyticsDate;
	modifiedDate: AnalyticsDate;
	status: number;
};

export type ViewsByPayload = {
	app: number;
	history: number;
	link: number;
	scan: number;
	share: number;
};

// This function takes one object, one representing the top content for the selected period.
// It then returns an array of objects with viewsby.

export const aggregateViewsBy = (selectedTopContent: ViewsByResponse[]) => {
	let viewsByData = { app: 0, history: 0, link: 0, scan: 0, share: 0 };
	selectedTopContent.forEach((topContent) => {
		if (
			topContent.metadata.viewsBy === '' ||
			topContent.metadata.viewsBy === undefined ||
			topContent.metadata.viewsBy === 'menu'
		) {
			viewsByData['app'] = viewsByData['app'] || 0;
			viewsByData['app']++;
		} else {
			viewsByData[topContent.metadata.viewsBy] = viewsByData[topContent.metadata.viewsBy] || 0;
			viewsByData[topContent.metadata.viewsBy]++;
		}
	});

	return viewsByData as ViewsByPayload;
};
