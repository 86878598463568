import { BSONConverter } from 'mongodb-bson-types';

export enum TopicType {
	Exhibit = 'exhibit',
	Area = 'area',
	Location = 'location',
}

export enum ScheduleType {
	Schedule = 'schedule',
	Immediate = 'immediate',
}

export type PushNotification = {
	topicName: string;
	topicType: TopicType;
	topicTypeId: string; // MongoID of topicType
	message: string;
	scheduleTime: string;
	scheduleType: ScheduleType;
	_id?: string;
	id?: string;
	locationId?: string;
	notificationSent?: boolean;
	createdDate?: string;
	modifiedDate?: string;
	status?: number;
	createNewEntity?: boolean;
};

export const newNotification = (notification: Partial<PushNotification>) => {
	if (notification._id) {
		notification._id = BSONConverter.objectId(notification._id);
		notification.id = notification._id;
	}
	if (!!notification.createdDate['$date']) {
		notification.createdDate = new Date(BSONConverter.UTCDateTime(notification.createdDate)).toLocaleString();
	}
	if (!!notification.modifiedDate['$date']) {
		notification.modifiedDate = new Date(BSONConverter.UTCDateTime(notification.modifiedDate)).toLocaleString();
	}
	if (!!notification.scheduleTime['$date']) {
		notification.scheduleTime = new Date(BSONConverter.UTCDateTime(notification.scheduleTime)).toLocaleString();
	}

	return notification;
};
