import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as api from '../api-client';
import { PushNotification } from '../models';
import { AppState } from '../store';

//TODO: this needs refactoring still 3/20
export interface NotificationState {
	notificationsMap: { [id: string]: PushNotification };
	notifications: PushNotification[];
	isLoading: boolean;
}

const initNotificationState: NotificationState = {
	notificationsMap: {},
	notifications: [],
	isLoading: false,
};

export const fetchNotifications = createAsyncThunk<PushNotification[], string, { state: AppState }>(
	'notification/fetchNotifications',
	async (locationId: string) => {
		const notifications = await api.getPushNotificationsByLocationId(locationId);
		return notifications;
	}
);

export const removeNotification = createAsyncThunk<string, string, { state: AppState }>(
	'notification/removeNotification',
	async (id: string) => {
		api.deletePushNotification(id);
		return id;
	}
);

const notificationSlice = createSlice({
	name: 'notification',
	initialState: initNotificationState,
	reducers: {
		// removeNotification(state: NotificationState, action: PayloadAction<string>) {
		//   state.notifications = state.notifications.filter((notification) => notification.id !== action.payload);
		//   delete state.notificationsMap[action.payload];
		// },
	},
	extraReducers: (builder) => {
		builder.addCase(fetchNotifications.fulfilled, (state, action) => {
			state.isLoading = false;
			state.notifications = action.payload;
			state.notificationsMap = {};
			state.notifications.forEach((notification) => {
				state.notificationsMap[notification.id] = notification;
			});
		});
		builder.addCase(fetchNotifications.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(fetchNotifications.rejected, (state) => {
			state.isLoading = false;
		});
		builder.addCase(removeNotification.fulfilled, (state, action) => {
			state.isLoading = false;
			state.notifications = state.notifications.filter((notification) => notification.id !== action.payload);
			delete state.notificationsMap[action.payload];
		});
		builder.addCase(removeNotification.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(removeNotification.rejected, (state) => {
			state.isLoading = false;
		});
	},
});

// Export reducer
export default notificationSlice.reducer;

// Export actions
// export const { removeNotification } = notificationSlice.actions;

// Export selectors
export const _notifications = (state) => state.notification.notifications;
export const _notificationsMap = (state) => state.notification.notificationsMap;
export const _isLoading = (state) => state.notification.isLoading;
