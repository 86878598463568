import { Tooltip } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import createStyles from '@material-ui/core/styles/createStyles';
import { Theme } from '@material-ui/core/styles/createTheme';
import makeStyles from '@material-ui/core/styles/makeStyles';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Box from '@mui/material/Box';
import React from 'react';
import { colors } from '../../../../theme/palette';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			marginTop: 10,
			marginBottom: 10,
		},
		title: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-between',
			alignItems: 'center',
			color: colors.neutralDark,
			fontSize: 16,
			fontFamily: 'Open Sans',
			fontWeight: 600,
		},
		total: {
			display: 'flex',
			color: colors.neutralDark,
			// display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-between',
			alignItems: 'center',
			fontSize: 13,
			fontFamily: 'Roboto',
			fontWeight: 400,
			padding: '10px 0',
		},
		selector: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'flex-end',
		},
		lightGray: {
			color: colors.neutralDark,
		},
		menu: {
			backgroundColor: colors.grayLight5,
			borderStyle: 'solid',
			borderRadius: 10,
			borderColor: colors.grayLight20,
			'& li': {
				fontSize: 14,
			},
			'& li:hover': {
				backgroundColor: colors.tealAccent20,
			},
		},
		icon: {
			marginLeft: 5,
			fontSize: 'inherit',
			verticalAlign: 'middle',
			flex: 'auto',
			color: colors.grayLight,
			width: '11px',
			height: '11px',
		},
		arrow: {
			color: colors.grayDark,
		},
		tooltip: {
			backgroundColor: colors.grayDark,
			textAlign: 'center',
			borderRadius: '4px',
			padding: '8px 12px',
			fontFamily: 'Roboto',
			fontSize: '16px',
			letterSpacing: '0.5px',
			color: '#FFFFFF',
		},
	})
);

export type ChartContentProps = {
	name: string;
	label?: string;
	selected?: string;
	pageNames?: { [id: string]: string };
	options?: string[];
	setSelected?: (selected: string) => void;
	children?: React.ReactNode;
	total?: number;
	avgSession?: string;
	toolTipMsg?: string;
};

export const ChartContent = (props: ChartContentProps) => {
	const { children, name, label, selected, pageNames, setSelected, total, avgSession, toolTipMsg } = props;
	const classes = useStyles();

	const handleChange = (e) => setSelected(e.target.value);

	return (
		<Box className={classes.container}>
			<Box className={classes.title}>
				<Typography variant="subtitle2">
					{name}
					<Tooltip
						title={toolTipMsg}
						placement={'right'}
						enterDelay={300}
						arrow
						classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
					>
						<InfoOutlinedIcon className={classes.icon} style={{ width: '16px', height: '16px' }} />
					</Tooltip>
				</Typography>

				{/* show the page selector if we have some pages (aka for pageview card) */}
				{pageNames &&
					Object.keys(pageNames).length && ( // with all these conditions, this isn't really a "reuseable" component, it's more like a "retrofitted" component - BAD PROGRAMMER, BAD!
						<Select
							onChange={handleChange}
							value={selected}
							MenuProps={{ classes: { paper: classes.menu }, elevation: 0 }} //elevation 0 fixes a material ui error
							classes={{
								root: classes.lightGray,
								icon: classes.lightGray,
							}}
							disableUnderline
						>
							{/* iterate over the pageNames object and create a menu item for each, sorting them alphabetically */}
							{Object.keys(pageNames)
								.sort((a, b) => pageNames[a].localeCompare(pageNames[b]))
								.map((key) => (
									<MenuItem key={key} value={key}>
										<Typography variant="caption">{pageNames[key]}</Typography>
									</MenuItem>
								))}
						</Select>
					)}
				{/* show a label if we have one */}
				{label && (
					<Typography variant="caption" className={classes.lightGray}>
						{label}
					</Typography>
				)}
			</Box>

			{total && (
				<Box style={{ marginBottom: 10 }}>
					{/* this component is designed to be used for two cards  */}
					<Typography variant="caption" className={classes.total}>
						{/* if we have pageNames, we're in the pageview card, otherwise we're in the traffic card */}
						<div style={{ float: 'left' }}>
							{pageNames ? 'Total Views: ' : 'Total Traffic: '}
							<span style={{ color: colors.tealAccent }}>{total}</span>
						</div>

						<div style={{ float: 'right' }}>
							{avgSession ? 'Avg. Session: ' : ''}
							{avgSession ? <span style={{ color: colors.tealAccent }}>{avgSession}</span> : ''}
						</div>
					</Typography>
				</Box>
			)}
			{children}
		</Box>
	);
};
