import { makeStyles, Theme } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { useAppDispatch } from '../../store/hooks';
import { selectContent } from '../../store/slices/contentSlice';
import { focusedItemName, setCropImageUrl } from '../../store/slices/editorSlice';
import { changeBackground } from '../../store/slices/topicSlice';
import { ACCEPTABLE_IMAGE_MIME_TYPES, IMAGE_BANNER } from '../../util/constants';
import { DroppableImage } from './DroppableImage';

export type EditPageImageProps = {
	src?: string;
};
const useStyles = makeStyles((theme: Theme) => ({
	droppableImage: {
		animation: 'fade 1s ease .5s both',
	},
}));
export const EditPageImage = (props: EditPageImageProps) => {
	const { src } = { ...props };
	const [previewImage, setPreviewImage] = useState(src);
	const [isDrag, setIsDrag] = useState(false);
	const [isOver, setIsOver] = useState(false);
	const [isValidType, setIsValidType] = useState(false);
	const classes = useStyles();

	useEffect(() => {
		setPreviewImage(src);
	}, [src, setPreviewImage]);

	const dispatch = useAppDispatch();
	const actions = bindActionCreators(
		{
			onSetCropImageUrl: setCropImageUrl,
			onBackgroundChange: changeBackground,
			onFocusedItemName: focusedItemName,
			onSelectContent: selectContent,
		},
		dispatch
	);

	const handleDragEnter = (e) => {
		const fileType = e.dataTransfer.items[0]?.type;
		if (ACCEPTABLE_IMAGE_MIME_TYPES.includes(fileType)) {
			setIsValidType(true);
		} else {
			setIsValidType(false);
		}
	};

	const handleDragStart = () => {
		if (!isDrag) setIsDrag(true);
	};

	const handleDragStop = () => {
		if (isDrag) {
			setIsDrag(false);
		}
	};

	const handleDragIn = () => {
		setIsOver(true);
	};

	const handleDragOut = () => {
		setIsOver(false);
	};

	const handleDrop = () => {
		setIsOver(false);
	};

	const handleDrag = (e) => {
		e.preventDefault();
		e.stopPropagation();
	};

	window.addEventListener('dragenter', handleDragEnter, false);
	window.addEventListener('dragover', handleDragStart, false);
	window.addEventListener('dragleave', handleDragStop, false);
	window.addEventListener('drop', handleDragStop, false);

	return (
		<div
			style={{ width: '100%' }}
			onDragEnter={handleDragIn}
			onDragLeave={handleDragOut}
			onDragOver={handleDrag}
			onDrop={handleDrop}
			className={classes.droppableImage} //acts upon the actual droppableImage component
		>
			<DroppableImage
				imageUrl={previewImage}
				setImagePreview={(file: File) => {
					const url = URL.createObjectURL(file);
					setPreviewImage(url);
					actions.onSetCropImageUrl(url);
					actions.onBackgroundChange({ name: file.name, url });
				}}
				isDrag={isDrag}
				isOver={isOver}
				imageType={IMAGE_BANNER}
				isValidType={isValidType}
			/>
		</div>
	);
};
