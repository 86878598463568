import axios from 'axios';
import axiosInstance from '.';
import { newOrganization, Organization } from '../models';
import { newOrgPayload } from '../slices/organizationSlice';

export const getOrganizationById = (orgId?: string) => {
	return axiosInstance
		.post<Organization>('/organization/get', { id: orgId })
		.then((res) => newOrganization(res.data));
};

// export const updateOrgEmail = async (org: Organization, email: string, orgName?: string) => {
//   const name = orgName;
//   const updatedOrg = { ...org, email, name, id: org._id, _id: '' }; //TODO: fix this, no longer have _id stored locally on the org, so id will be empty
//   return axiosInstance
//     .post<{ organizationId: string }>('/organization/create', updatedOrg)
//     .then((res: any) => res.data);
// };

// export const updateOrgName = async (org: Organization, orgName: string) => {
//   const name = orgName;
//   const updatedOrg = { ...org, name, id: org._id, _id: '' }; //TODO: fix this, no longer have _id stored locally on the org, so id will be empty
//   return axiosInstance
//     .post<{ organizationId: string }>('/organization/create', updatedOrg)
//     .then((res: any) => res.data);
// };

export const getAllOrganizations = () => {
	return axiosInstance
		.post<Organization[]>('/organization/getAll', {})
		.then((res: any) => res.data.map((r) => newOrganization(r)));
};

export const deleteOrganization = (orgId: string) => {
	return axiosInstance.post('/organization/delete', { id: orgId }).then((res: any) => res.data);
};

export const undoDeleteOrganization = (orgId: string) => {
	return axiosInstance.post('/organization/undoDelete', { id: orgId }).then((res: any) => res.data);
};

export const saveOrganization = (newOrg: Organization) => {
	return axiosInstance.post<{ organizationId: string }>('/organization/create', newOrg).then((res: any) => res.data);
};

export const updateOrganization = (organization: Organization) => {
	return axiosInstance.post('/organization/create', organization).then((res: any) => res.data);
};

export const resetForgottenPassword = (token: string, passwords: Object) => {
	return axios
		.post(process.env.REACT_APP_LIIINGO_URL + '/organization/updateForgottenPassword', passwords, {
			headers: { Authorization: `Bearer ${token}` },
		})
		.then((res: any) => res.data);
};

export const resetPasswordEmail = (email: string) => {
	return axios
		.post(process.env.REACT_APP_LIIINGO_URL + '/organization/resetPasswordEmailV2', { email: email })
		.then((res: any) => res.data);
};

// create new organization, used for onboarding, not gated
export const createNewOrganization = (newOrg: newOrgPayload) => {
	return axios
		.post(process.env.REACT_APP_LIIINGO_URL + '/organization/createNewOrg', newOrg, {
			validateStatus: (status: number): boolean => {
				return status >= 200;
			},
		})
		.then((res: any) => {
			if (res.status === 500) {
				throw res.data.split(' | ')[0];
			}
			return newOrganization(res.data);
		});
};

// used for migrating all cognito account passwords over to php
export const updatePassword = (password: string, cognitoToken: string) => {
	return axios
		.post(
			process.env.REACT_APP_LIIINGO_URL + '/organization/updatePassword',
			{ password },
			{
				headers: {
					Authorization: `Bearer ${cognitoToken}`,
				},
			}
		)
		.then((res: any) => res.data);
};
