import { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { _contents, contentChanged, selectContent } from '../../store/slices/contentSlice';
import { _activeLanguageCode, focusedItemName } from '../../store/slices/editorSlice';
import { ACCEPTABLE_VIDEO_MIME_TYPES } from '../../util/constants';
import { DroppableVideo } from './DroppableVideo';
export type EditVideoProps = {
	contentId: string;
};

export const EditVideo = (props: EditVideoProps) => {
	const { contentId } = { ...props };
	const [previewVideo, setPreviewVideo] = useState(null);
	const [isDrag, setIsDrag] = useState(false);
	const [isOver, setIsOver] = useState(false);
	const [isValidType, setIsValidType] = useState(false);
	const contentMap = useAppSelector(_contents);
	const content = contentMap[contentId];
	const activeLanguageCode = useAppSelector(_activeLanguageCode);
	const [translation, setTranslation] = useState(null);
	const dispatch = useAppDispatch();
	const actions = bindActionCreators(
		{
			onChangeContent: contentChanged,
			onFocusedItemName: focusedItemName,
			onSelectContent: selectContent,
		},
		dispatch
	);

	useEffect(() => {
		setTranslation(content?.languages.find((item) => item.language === activeLanguageCode));
	}, [content, activeLanguageCode, setTranslation, contentId, translation?.content, translation?.fileUrl]);

	useEffect(() => {
		let unmounted = false;
		if (!unmounted) {
			setPreviewVideo(translation?.content || translation?.fileUrl);
		}
		return () => {
			unmounted = true;
		};
	}, [setPreviewVideo, translation?.content, translation?.fileUrl, activeLanguageCode]);

	const handleDragEnter = (e) => {
		const fileType = e.dataTransfer.items[0]?.type;
		if (ACCEPTABLE_VIDEO_MIME_TYPES.includes(fileType)) {
			setIsValidType(true);
		} else {
			setIsValidType(false);
		}
	};

	const handleDragStart = () => {
		if (!isDrag) {
			setIsDrag(true);
		}
	};

	const handleDragStop = () => {
		if (isDrag) {
			setIsDrag(false);
		}
	};

	const handleDragIn = (e) => {
		setIsOver(true);
	};

	const handleDrop = (e) => {
		setIsOver(false);
	};

	const handleDragOut = (e) => {
		setIsOver(false);
	};

	const handleDrag = (e) => {
		e.preventDefault();
		e.stopPropagation();
	};

	window.addEventListener('dragenter', handleDragEnter, false);
	window.addEventListener('dragover', handleDragStart, false);
	window.addEventListener('dragleave', handleDragStop, false);
	window.addEventListener('drop', handleDragStop, false);

	return (
		<div
			style={{ width: '100%' }}
			onDragEnter={handleDragIn}
			onDragLeave={handleDragOut}
			onDragOver={handleDrag}
			onDrop={handleDrop}
		>
			<DroppableVideo
				videoUrl={previewVideo}
				setVideo={(file) => {
					let vid = URL.createObjectURL(file);
					actions.onChangeContent({
						language: translation.language,
						fileUrl: vid,
						fileName: `${translation.language}_${file.name}`,
						id: contentId,
					});
					setPreviewVideo(vid);
				}}
				isDrag={isDrag}
				isOver={isOver}
				isValidType={isValidType}
			/>
		</div>
	);
};
