import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Container from '@material-ui/core/Container';
import createStyles from '@material-ui/core/styles/createStyles';
import { Theme } from '@material-ui/core/styles/createTheme';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import { ComponentType, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Throbber } from '../components';
import { useQueryStringParams } from '../hooks/useQueryStringParams';
import { verifyInvitation } from '../store/api-client';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			paddingTop: theme.spacing(1), // Don't let content overlap the floating logo button
		},
		card: {
			backgroundColor: theme.palette.background.paper,
			padding: theme.spacing(16),
		},
		spinner: {
			display: 'flex',
			flex: 1,
			justifyContent: 'center',
			alignItems: 'center',
			flexDirection: 'column',
		},
	})
);

const InviteAccept: ComponentType<any> = () => {
	const params = useQueryStringParams();
	const invitationId = params.get('invitationId');
	const [invitationInfo, setInvitationInfo] = useState(null);
	const [acceptProgress, setAcceptProgress] = useState(true);
	const classes = useStyles();

	const checkInvitation = async (invitationId) => {
		const invitation = await verifyInvitation(invitationId);
		return invitation;
	};

	useEffect(() => {
		const getInvitation = async () => {
			let invitation = await checkInvitation(invitationId); // get invitationInfo with invitationId calling php backend api
			setInvitationInfo(invitation);
			setAcceptProgress(false);
			console.log('invitation', invitation);
		};
		if (invitationId) getInvitation();
		// eslint-disable-next-line
	}, [invitationId]);

	if (acceptProgress) {
		return (
			<Container className={classes.root}>
				<Card className={classes.card}>
					<CardContent className={classes.spinner}>
						<Throbber />
						<Typography variant="h2" align="center" paragraph>
							Please wait, verifying invitation link
						</Typography>
					</CardContent>
				</Card>
			</Container>
		);
	} else {
		if (invitationInfo === 'no invitation') {
			return (
				<Container className={classes.root}>
					<Card className={classes.card}>
						<CardContent className={classes.spinner}>
							<Typography variant="h2" align="center" paragraph>
								This invitation is not valid
							</Typography>
						</CardContent>
					</Card>
				</Container>
			);
		} else if (invitationInfo !== null) {
			if (invitationInfo.accepted) {
				return (
					<Container className={classes.root}>
						<Card className={classes.card}>
							<CardContent className={classes.spinner}>
								<Typography variant="h2" align="center" paragraph>
									This invitation has already been redeemed
								</Typography>
								<Typography variant="h5" align="center" paragraph>
									Already have an account?
									<a href={'./sign-in'}> Sign in </a>
									<br />
									For questions or concerns, contact{' '}
									<a href="mailto:support@liiingo.com">support@liiingo.com</a>
								</Typography>
							</CardContent>
						</Card>
					</Container>
				);
			} else {
				return (
					<Redirect
						to={`/${invitationInfo.route}/sign-up?invitationId=${invitationInfo._id['$oid']}&billing=${invitationInfo.billing}&plan=${invitationInfo.planType}&sponsored=${invitationInfo.sponsored}&couponId=${invitationInfo.coupon}`}
					/>
				);
			}
		}
	}
};

export default InviteAccept;
