import SyncOutlinedIcon from '@material-ui/icons/SyncOutlined';
import { ReactNode, useState } from 'react';
import { ACCEPTABLE_VIDEO_MIME_TYPES } from '../../util/constants';
import { Option } from './Option';
import { OptionGroup } from './OptionGroup';
import { VideoOption } from './VideoOption';

export type VideoMenuProps = {
	setVideo: (file: File) => void;
	videoUrl?: string;
	name?: string;
	languageCode?: string;
	type?: string;
	size?: string;
	children: ReactNode;
};

export const VideoMenu = (props: VideoMenuProps) => {
	const { setVideo, videoUrl, name, languageCode, type, size, children } = { ...props };

	const [isDrag, setIsDrag] = useState(false);
	const [isOver, setIsOver] = useState(false);
	const [isValidType, setIsValidType] = useState(false);

	const onChange = (e: any) => {
		e.preventDefault();
		let files;
		if (e.dataTransfer) {
			files = e.dataTransfer.files;
		} else if (e.target) {
			files = e.target.files;
		}

		setVideo(files[0]);
	};

	const handleDragEnter = (e) => {
		const fileType = e.dataTransfer.items[0]?.type;
		if (ACCEPTABLE_VIDEO_MIME_TYPES.includes(fileType)) {
			setIsValidType(true);
		} else {
			setIsValidType(false);
		}
	};

	const handleDragStart = () => {
		if (!isDrag) {
			setIsDrag(true);
		}
	};

	const handleDragStop = () => {
		if (isDrag) {
			setIsDrag(false);
		}
	};

	const handleDragIn = (e) => {
		setIsOver(true);
	};

	const handleDrop = (e) => {
		setIsOver(false);
	};

	const handleDragOut = (e) => {
		setIsOver(false);
	};

	const handleDrag = (e) => {
		e.preventDefault();
		e.stopPropagation();
	};

	window.addEventListener('dragenter', handleDragEnter, false);
	window.addEventListener('dragover', handleDragStart, false);
	window.addEventListener('dragleave', handleDragStop, false);
	window.addEventListener('drop', handleDragStop, false);

	return (
		<div>
			<OptionGroup title="Preview">
				<div onDragEnter={handleDragIn} onDragLeave={handleDragOut} onDragOver={handleDrag} onDrop={handleDrop}>
					<VideoOption
						videoUrl={videoUrl}
						setVideo={setVideo}
						isOver={isOver}
						isDrag={isDrag}
						isValidType={isValidType}
					/>
				</div>
			</OptionGroup>
			<OptionGroup title="Actions">
				<label htmlFor="upload-file">
					<Option icon={SyncOutlinedIcon} label="Replace" clickable={true} />
				</label>
				<input
					type="file"
					onChange={onChange}
					id="upload-file"
					style={{ display: 'none' }}
					accept={ACCEPTABLE_VIDEO_MIME_TYPES.join(',')}
				/>
				{children}
			</OptionGroup>
			{(name || languageCode || type || size) && (
				<OptionGroup title="File Details">
					{name && <Option subtitle="Name" label={name.substring(name.indexOf('_') + 1)} />}
					{type && <Option subtitle="Type" label={type} />}
					{size && <Option subtitle="Size" label={size} />}
				</OptionGroup>
			)}
			<OptionGroup title="File Limitations">
				<Option subtitle="MP4, WebM" label="50MB Max" />
			</OptionGroup>
		</div>
	);
};
