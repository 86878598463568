import Crop from '@material-ui/icons/Crop';
import Link from '@material-ui/icons/Link';
import SyncOutlinedIcon from '@material-ui/icons/SyncOutlined';
import { ReactNode, useEffect, useState } from 'react';
import { ACCEPTABLE_IMAGE_MIME_TYPES } from '../../util/constants';
import CropModel from '../AppEditor/CropModel';
import { AltText } from './AltText';
import LinkDialog, { LinkDialogMenuOption, LinkDialogOptions } from './Dialog/LinkDialog';
import { ImageOption } from './ImageOption';
import { Option } from './Option';
import { OptionGroup } from './OptionGroup';

export type ImageBtnMenuProps = {
	setImagePreview: (file: File) => void;
	imageUrl?: string;
	name?: string;
	languageCode?: string;
	type?: string;
	size?: string;
	toCropData: (imageUrl: string, fileName: string) => void;
	title?: string;
	url?: string;
	onChange?: (title: string, url: string) => void;
	onMenuChange: (option: LinkDialogMenuOption) => void;
	children: ReactNode;
};

export const ImageBtnMenu = (props: ImageBtnMenuProps) => {
	const {
		setImagePreview,
		imageUrl,
		name,
		languageCode,
		type,
		size,
		toCropData,
		title,
		url,
		children,
		onChange,
		onMenuChange,
	} = { ...props };
	const [openDrawer, setOpenDrawer] = useState(false);
	const [image, setImage] = useState(imageUrl);

	const [titleFormField, setTitleFormField] = useState(title);
	const [urlFormField, setUrlFormField] = useState(url);
	const [openDialog, setOpenDialog] = useState(false);

	useEffect(() => {
		setImage(imageUrl);
	}, [imageUrl]);

	useEffect(() => {
		setTitleFormField(title);
		setUrlFormField(url);

		let option: LinkDialogMenuOption;

		if (url?.includes('http')) {
			option = 'web';
		} else if (url?.includes('tel') || url?.includes('sms')) {
			option = 'phone';
		} else if (url?.includes('mailto')) {
			option = 'email';
		} else if (url) {
			option = 'page';
		} else {
			option = 'none';
		}

		if (option) {
			onMenuChange(option);
		}
	}, [onMenuChange, title, url]);

	const onImageChange = (e: any) => {
		e.preventDefault();
		let files;
		if (e.dataTransfer) {
			files = e.dataTransfer.files;
		} else if (e.target) {
			files = e.target.files;
		}

		// I don't remember what I was doing here, troubleshooting something. Leaving for now. 4/28/22
		console.log('table of files');
		console.table(files);
		// alert(files)

		const reader = new FileReader();
		reader.onload = () => {
			setImage(reader.result as any);
			setOpenDrawer(true);
		};
		reader.readAsDataURL(files[0]);
	};

	const setCropImage = (url, fileName) => {
		toCropData(url, fileName);
	};

	const [isDrag, setIsDrag] = useState(false);
	const [isOver, setIsOver] = useState(false);
	const [isValidType, setIsValidType] = useState(false);

	const handleDragEnter = (e) => {
		const fileType = e.dataTransfer.items[0]?.type;
		if (ACCEPTABLE_IMAGE_MIME_TYPES.includes(fileType)) setIsValidType(true);
		else setIsValidType(false);
	};

	const handleDragStart = () => {
		if (!isDrag) {
			setIsDrag(true);
		}
	};

	const handleDragStop = () => {
		if (isDrag) {
			setIsDrag(false);
		}
	};

	const handleDragIn = (e) => {
		setIsOver(true);
	};

	const handleDrop = (e) => {
		setIsOver(false);
	};

	const handleDragOut = (e) => {
		setIsOver(false);
	};

	const handleDrag = (e) => {
		e.preventDefault();
		e.stopPropagation();
	};

	window.addEventListener('dragenter', handleDragEnter, false);
	window.addEventListener('dragover', handleDragStart, false);
	window.addEventListener('dragleave', handleDragStop, false);
	window.addEventListener('drop', handleDragStop, false);

	return (
		<div>
			<OptionGroup title="Preview">
				<div onDragEnter={handleDragIn} onDragLeave={handleDragOut} onDragOver={handleDrag} onDrop={handleDrop}>
					<ImageOption
						imageUrl={imageUrl}
						setImagePreview={setImagePreview}
						isOver={isOver}
						isDrag={isDrag}
						isValidType={isValidType}
					/>
				</div>
			</OptionGroup>
			<CropModel open={openDrawer} setOpen={setOpenDrawer} imageUrl={image} sendCropData={setCropImage} />
			<OptionGroup title="Actions">
				<Option icon={Crop} label="Crop" clickable={true} onClick={() => setOpenDrawer(true)} />

				<label htmlFor="upload-file">
					<Option icon={SyncOutlinedIcon} label="Replace" clickable={true} />
				</label>
				<input
					type="file"
					onChange={onImageChange}
					id="upload-file"
					style={{ display: 'none' }}
					accept={ACCEPTABLE_IMAGE_MIME_TYPES.join(',')}
				/>

				<Option icon={Link} label="Link" clickable={true} onClick={() => setOpenDialog(true)} url={url} />
				<LinkDialog
					open={openDialog}
					handleClose={() => setOpenDialog(false)}
					options={LinkDialogOptions}
					value={urlFormField}
					onChange={(url: string) => {
						onChange(titleFormField, url);
					}}
				/>
				{children}
			</OptionGroup>
			<AltText />
			{(name || languageCode || type || size) && (
				<OptionGroup title="File Details">
					{name && <Option subtitle="Name" label={name.substring(name.indexOf('_') + 1)} />}
					{type && <Option subtitle="Type" label={type} />}
					{size && <Option subtitle="Size" label={size} />}
				</OptionGroup>
			)}
			<OptionGroup title="File Limitations">
				<Option subtitle="JPG, PNG, GIF" label="20MB Max" />
			</OptionGroup>
		</div>
	);
};
