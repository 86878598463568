import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import ListItem from '@material-ui/core/ListItem';
import { Theme } from '@material-ui/core/styles/createTheme';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import ArrowDropUp from '@material-ui/icons/ArrowDropUp';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { removeNewLanguageIds, _activeLanguageCode, _newLanguageIds } from '../../store/slices/editorSlice';
import { reorderSection } from '../../store/slices/locationSlice';
import { deleteSection, saveSection, updateSectionName, _sections } from '../../store/slices/sectionSlice';
import { colors } from '../../theme/palette';
import { DISMISSED_SECTION_DELETE } from '../../util/constants';
import { DraggableMenuWrapper } from '../ElementMenu/DraggableMenuWrapper';
import { DroppableMenuWrapper } from '../ElementMenu/DroppableMenuWrapper';
import { LiiingoTooltip } from '../LiiingoTooltip';
import { SectionContextMenu } from './SectionContextMenu';
import { SectionDeleteDialog } from './SectionDeleteDialog';

const useStyles = makeStyles<Theme, { dim: boolean }>({
	subMenu: {
		backgroundColor: colors.grayDark5,
		justifyContent: 'space-between',
	},
	hidden: {
		visibility: 'hidden',
	},
	icon: {
		paddingTop: 13,
	},

	sectionName: (props) => ({
		opacity: props.dim ? 0.5 : 1,
		fontSize: 12,
		fontWeight: 'bold',
		color: colors.pureBlack,
	}),
	sectionNameEditable: (props) => ({
		backgroundColor: colors.pureWhite,
		borderRadius: 5,
		borderStyle: 'solid',
		borderColor: colors.blueAccent20,
		borderWidth: 0.5,
	}),
	rightToolTip: {
		paddingRight: '85px',
		marginRight: '-70px',
		zIndex: 0,
	},
});

export type SectionMenuProps = {
	id: string;
	index: number;
	last: number;
	expand: boolean;
};

export const SectionMenu: React.FC<SectionMenuProps> = (props) => {
	const { children, index, last, id, expand } = {
		...props,
	};
	const [open, setOpen] = useState(true);
	const [openDialog, setOpenDialog] = useState(false);
	const [readOnly, setReadOnly] = useState(true);
	const [showDrag, setShowDrag] = useState(false);

	useEffect(() => {
		setOpen(true);
	}, [expand, setOpen]);

	const dispatch = useAppDispatch();
	const activeLanguageCode = useAppSelector(_activeLanguageCode); //1 line of code to replace 6 lines of code across 3 files
	const newLanguageIds = useAppSelector(_newLanguageIds);
	const sections = useAppSelector(_sections);
	const section = sections[id];
	const thisIsNew = newLanguageIds[activeLanguageCode]?.includes(id);
	const removeLanguageId = bindActionCreators(removeNewLanguageIds, dispatch);
	const onUpdateSectionName = bindActionCreators(updateSectionName, dispatch);
	const saveSectionName = bindActionCreators(saveSection, dispatch); // this only saves to network, not to redux. Used for onBlur
	const onDeleteSection = bindActionCreators(deleteSection, dispatch);
	const onReorderSection = bindActionCreators(reorderSection, dispatch);

	const classes = useStyles({ dim: thisIsNew });

	const onClick = () => {
		setOpen(!open);
	};

	const handleDelete = () => {
		if (localStorage.getItem(DISMISSED_SECTION_DELETE)) {
			onDeleteSection({ sectionId: section.id });
		} else {
			setOpenDialog(true);
		}
	};

	//the input's value is the section name stored in redux - directly. Changing the input value directly modifies the redux state.
	return (
		<DraggableMenuWrapper id={id} index={index} showSnapshot={false}>
			<SectionContextMenu
				id={id}
				index={index}
				last={last}
				deleteSection={handleDelete}
				editSectionName={() => setReadOnly(false)}
				reorderSection={onReorderSection}
			>
				<SectionDeleteDialog
					open={openDialog}
					handleClose={() => setOpenDialog(false)}
					handleDelete={() => onDeleteSection({ sectionId: section.id })}
				/>
				<ListItem
					className={classes.subMenu}
					onMouseEnter={() => setShowDrag(true)}
					onMouseLeave={() => setShowDrag(false)}
					onClick={onClick}
				>
					<LiiingoTooltip
						message="Right-click for section options"
						placement="right"
						className={classes.rightToolTip}
					>
						<>
							{showDrag ? (
								<DragIndicatorIcon fontSize="medium" className={classes.icon} />
							) : (
								<ArrowDropUp fontSize="small" className={classes.hidden} />
							)}
							<InputBase
								onDoubleClick={() => {
									setReadOnly(false);
								}}
								placeholder="Section Name"
								readOnly={readOnly}
								fullWidth={false}
								className={readOnly ? classes.sectionName : classes.sectionNameEditable}
								inputProps={{
									style: {
										textOverflow: 'ellipsis',
										fontSize: 12,
										cursor: readOnly ? 'pointer' : 'text',
									},
								}}
								value={section.name.find((n) => n.language === activeLanguageCode)?.name}
								onFocus={() => {
									thisIsNew && removeLanguageId({ language: activeLanguageCode, ids: [id] });
								}}
								onChange={(e) => {
									onUpdateSectionName({
										sectionId: id,
										name: e.target.value,
										language: activeLanguageCode,
									});
								}}
								onBlur={(e) => {
									setReadOnly(true);
									saveSectionName({ sectionId: id });
								}}
								onKeyPress={(e) => {
									if (e.key === 'Enter') {
										setReadOnly(true);
										saveSectionName({ sectionId: id });
									}
								}}
							/>
						</>
					</LiiingoTooltip>

					<IconButton size="small" onClick={onClick}>
						{open ? <ArrowDropUp /> : <ArrowDropDown />}
					</IconButton>
				</ListItem>
			</SectionContextMenu>
			<Collapse in={open} timeout="auto" unmountOnExit>
				<DroppableMenuWrapper id={id} dropDisabled={false} disableGutters placeholder>
					{children}
				</DroppableMenuWrapper>
			</Collapse>
		</DraggableMenuWrapper>
	);
};
