import { LiiingoWalkthroughStep } from './type';

export const InitialPagePanelSteps: LiiingoWalkthroughStep[] = [
	{
		key: 'start',
		selector: '[data-tour="page-tab"]',
		title: 'Page Toolbox',
		message: "You've discovered your Page Toolbox! We have a few tips to help you manage your pages with ease.",
		buttonText: 'Get Started',
		position: 'right',
		width: 420,
		arrowPosition: 'left',
		arrowPlacement: 15,
	},
	{
		key: 'page-organization',
		selector: '[data-tour="page-organization"]',
		title: 'Page Organization',
		message: 'Pages are grouped into sections to help organize your content into similar topics.',
		buttonText: 'Next',
		position: 'right',
		width: 366,
		arrowPosition: 'left',
		arrowPlacement: 10,
	},
	{
		key: 'add-section',
		selector: '[data-tour="add-section"]',
		title: 'Adding Sections',
		message:
			'To create a new section, click the Add Sections button at the bottom of the pages in the Page Toolbox. Give it a try!',
		buttonText: 'Skip',
		position: 'right',
		width: 459,
		skipTo: 4,
		arrowPosition: 'left',
		arrowPlacement: 10,
	},
	{
		key: 'name-section',
		selector: '[data-tour="name-section"]',
		title: 'Name Your Section',
		message: 'Give your section a name. Then press Enter on your keyboard to save it. Try it out now!',
		buttonText: 'Skip',
		position: 'right',
		width: 380,
		skipTo: 4,
		arrowPosition: 'left',
		arrowPlacement: 10,
	},
	{
		key: 'section-tab',
		selector: '[data-tour="section-tab"]',
		title: 'Section Tabs',
		message:
			'Sections are listed as tabs in your Mobile Preview. This is how your audience will navigate between the sections in your app. Newly created sections and pages appear shortly after they are added.',
		buttonText: 'Next',
		position: 'left',
		width: 380,
		arrowPosition: 'right',
		arrowPlacement: 5,
	},
	{
		key: 'add-page',
		selector: '[data-tour="add-page"]',
		title: 'Adding Pages',
		message: 'To add a new page, click the plus button at the top of the Page Toolbox. Try it now!',
		buttonText: 'Skip',
		position: 'right',
		width: 459,
		skipTo: 7,
		arrowPosition: 'left',
		arrowPlacement: 10,
	},
	{
		key: 'rename-page',
		selector: '[data-tour="rename-page"]',
		title: 'Rename Your Page',
		message:
			'Rename your new page or keep the default name. You can double-click on a page name to rename it at any time.',
		buttonText: 'Skip',
		position: 'right',
		width: 380,
		skipTo: 7,
		arrowPosition: 'left',
		arrowPlacement: 10,
	},
	{
		key: 'end',
		selector: '[data-tour="end"]',
		title: 'Nicely Done!',
		message: 'You’re ready to manage pages on your own. The possibilites are endless!',
		buttonText: 'Done',
		position: [],
	},
];
