import { createSvgIcon } from '@material-ui/core';
import * as React from 'react';

const SvgImageButton = (props) => (
	<svg width={16} height={16} viewBox="-5 -5 45 50" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M19.583 14.75H31.0413L19.583 3.29163V14.75ZM4.99967 0.166626H21.6663L34.1663 12.6666V37.6666C34.1663 38.7717 33.7273 39.8315 32.9459 40.6129C32.1645 41.3943 31.1047 41.8333 29.9997 41.8333H4.99967C3.89461 41.8333 2.8348 41.3943 2.0534 40.6129C1.27199 39.8315 0.833008 38.7717 0.833008 37.6666V4.33329C0.833008 2.02079 2.68717 0.166626 4.99967 0.166626ZM4.99967 37.6666H29.9997V21L21.6663 29.3333L17.4997 25.1666L4.99967 37.6666ZM9.16634 14.75C8.06127 14.75 7.00146 15.1889 6.22006 15.9703C5.43866 16.7518 4.99967 17.8116 4.99967 18.9166C4.99967 20.0217 5.43866 21.0815 6.22006 21.8629C7.00146 22.6443 8.06127 23.0833 9.16634 23.0833C10.2714 23.0833 11.3312 22.6443 12.1126 21.8629C12.894 21.0815 13.333 20.0217 13.333 18.9166C13.333 17.8116 12.894 16.7518 12.1126 15.9703C11.3312 15.1889 10.2714 14.75 9.16634 14.75Z"
			fill="#49525D"
		/>
	</svg>
);

const Icon = createSvgIcon(
	<>
		<path d="M19.583 14.75H31.0413L19.583 3.29163V14.75ZM4.99967 0.166626H21.6663L34.1663 12.6666V37.6666C34.1663 38.7717 33.7273 39.8315 32.9459 40.6129C32.1645 41.3943 31.1047 41.8333 29.9997 41.8333H4.99967C3.89461 41.8333 2.8348 41.3943 2.0534 40.6129C1.27199 39.8315 0.833008 38.7717 0.833008 37.6666V4.33329C0.833008 2.02079 2.68717 0.166626 4.99967 0.166626ZM4.99967 37.6666H29.9997V21L21.6663 29.3333L17.4997 25.1666L4.99967 37.6666ZM9.16634 14.75C8.06127 14.75 7.00146 15.1889 6.22006 15.9703C5.43866 16.7518 4.99967 17.8116 4.99967 18.9166C4.99967 20.0217 5.43866 21.0815 6.22006 21.8629C7.00146 22.6443 8.06127 23.0833 9.16634 23.0833C10.2714 23.0833 11.3312 22.6443 12.1126 21.8629C12.894 21.0815 13.333 20.0217 13.333 18.9166C13.333 17.8116 12.894 16.7518 12.1126 15.9703C11.3312 15.1889 10.2714 14.75 9.16634 14.75Z" />
	</>,
	'ImageButton'
);

export const ImageButtonIcon = (props) => <Icon {...props} viewBox="-5 -5 45 50" />;

export default SvgImageButton;
