import { TopicMap } from '../../slices/topicSlice';
import { AnalyticsDate, AnalyticsId } from './Analytics';

export type TopContentMetadata = {
	areaId?: string;
	exhibitId?: string;
	locationId?: string;
	organizationId?: string;
	viewsBy?: string;
	platform?: string;
	browser?: string;
	appSession?: string;
};

export type TopContentResponse = {
	_id: AnalyticsId;
	type: string;
	uuid: string;
	os: string;
	metadata: TopContentMetadata;
	createdDate: AnalyticsDate;
	modifiedDate: AnalyticsDate;
	status: number;
};

export type TopContentType = {
	label: string;
	value: number;
	change?: number;
};

// This function takes two objects, one representing the top content for the selected period and the other representing the top content for the comparison period.
// It then returns an array of objects with the labels, values, and change for each top content item.
// It sorts the array in descending order according to the value.

export const aggregateTopContent = (selectedTopContent, comparisonTopContent) => {
	let topContent: TopContentType[] = Object.keys(selectedTopContent)
		.map((key) => {
			let nowVal = selectedTopContent[key].value;
			let thenVal = comparisonTopContent[key]?.value;
			let change = thenVal ? Math.round(((nowVal - thenVal) / thenVal) * 100) : null;
			return {
				label: selectedTopContent[key].label,
				value: selectedTopContent[key].value,
				change: change,
			};
		})
		.sort((a, b) => (b.value > a.value ? 1 : a.value > b.value ? -1 : 0));

	return topContent;
};

/**
 * @description This function will filter the data by location, extract the necessary information, and reduce it to an object.
 * @param {Array} data - array of objects
 * @param {Number} locationId - id of the location
 * @returns {Object} - object with the necessary data
 */

export const prepareTopContent = (data: TopContentResponse[], locationId, store) => {
	const topics: TopicMap = store.getState().topic.topics;
	// gets all "top_content" events for the given date range and location
	let preparedData = data
		.filter((obj) => locationId === obj.metadata.locationId)
		.map((obj) => ({
			exhibitId: obj.metadata.exhibitId,
			name:
				topics[obj.metadata.exhibitId]?.name.find((lang) => lang.language === 'en').name ||
				topics[obj.metadata.exhibitId]?.name[0].name,
		}));

	// create a map with the exhibitId as the key and the name and value as the value
	let map = {};
	preparedData.forEach((data) => {
		map[data.exhibitId] = {
			label: data.name,
			value: (map[data.exhibitId]?.value || 0) + 1,
		};
	});

	return map;
};
