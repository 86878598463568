import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { PAGE_TEMPORARY_TIP } from '../../util/constants';
import { LiiingoDismissableTip } from '../LiiingoDismissableTip';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		temporaryTip: {
			width: 211,
		},
	})
);

export type TemporaryTipProps = {
	removeTip: () => void;
};

export const TemporaryTip = (props: TemporaryTipProps) => {
	const classes = useStyles();
	const { removeTip } = { ...props };

	return (
		<LiiingoDismissableTip name={PAGE_TEMPORARY_TIP} fixed onClose={removeTip}>
			<div className={classes.temporaryTip}>
				<Typography variant="caption">
					Temporary pages are only visible through direct links or QR codes. Users lose access once they
					navigate away from the page.
				</Typography>
			</div>
		</LiiingoDismissableTip>
	);
};
