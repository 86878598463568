import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Delete from '@material-ui/icons/DeleteOutline';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import { useState } from 'react';
import { bindActionCreators } from 'redux';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { FileType } from '../../store/models';
import { addDuplicateContent, ContentReorderPayload, _selectedContent } from '../../store/slices/contentSlice';
import { IS_DISMISSED_DELETE, IS_DISMISSED_ML_DELETE } from '../../util/constants';
import { DeleteDialog } from './Dialog/DeleteDialog';
import { Option } from './Option';
import { setIsActionDelete } from '../../store/slices/editorSlice';

export type SubActionsProps = {
	multilanguage: boolean;
	length: number;
	index: number;
	type: FileType;
	handleReorder?: (payload: ContentReorderPayload) => void;
	onDeleteContent?: (id: string) => void;
};

export const SubActions = (props: SubActionsProps) => {
	const { multilanguage, length, index, type, handleReorder, onDeleteContent } = { ...props };
	const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
	const selectedContent = useAppSelector(_selectedContent);
	const dispatch = useAppDispatch();
	const onDuplicateContent = bindActionCreators(addDuplicateContent, dispatch);

	return (
		<>
			<div
				onMouseDown={(e) => {
					// onMousedown event is fired before onBlur event. but the click event fires after the blur
					e.preventDefault();
				}}
			>
				<Option
					icon={ArrowUpward}
					label="Move Up"
					clickable={true}
					disabled={index === 0}
					onClick={() => {
						handleReorder({ oldIndex: index, newIndex: index - 1 });
					}}
				/>
				<Option
					icon={ArrowDownward}
					label="Move Down"
					clickable={true}
					disabled={index === length - 1}
					onClick={() => {
						handleReorder({ oldIndex: index, newIndex: index + 1 });
					}}
				/>
				<Option
					icon={FileCopyOutlinedIcon}
					label="Duplicate"
					clickable={true}
					onClick={() => {
						onDuplicateContent({
							content: selectedContent,
							index: index,
						});
					}}
				/>
				<Option
					icon={Delete}
					label="Delete"
					clickable={true}
					onClick={() => {
						let openDialog: boolean;
						if (multilanguage) {
							openDialog = !localStorage.getItem(IS_DISMISSED_ML_DELETE);
						} else {
							openDialog = !localStorage.getItem(IS_DISMISSED_DELETE);
						}

						if (openDialog) {
							// When we clicked delete button on action menu, it opens dialog, so the onBlur event of text element is fired and the RightDrawer component changed.
							// The other buttons on ActionMenu don't fire onBlur event.
							// To capture and stop onBlur event, this global variable is set as true.
							dispatch(setIsActionDelete(true));
							setOpenDeleteDialog(true);
						} else {
							onDeleteContent(selectedContent._id);
						}
					}}
				/>
			</div>
			<DeleteDialog
				multilanguage={multilanguage}
				open={openDeleteDialog}
				type={type}
				handleDelete={() => {
					dispatch(setIsActionDelete(false));
					onDeleteContent(selectedContent._id);
				}}
				handleClose={() => {
					dispatch(setIsActionDelete(false));
					setOpenDeleteDialog(false);
				}}
			/>
		</>
	);
};
