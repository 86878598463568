import CardMedia from '@material-ui/core/CardMedia';
import CircularProgress from '@material-ui/core/CircularProgress';
import createStyles from '@material-ui/core/styles/createStyles';
import { Theme } from '@material-ui/core/styles/createTheme';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useEffect, useState } from 'react';
import { useAppSelector } from '../../store/hooks';
import { Topic } from '../../store/models';
import { _loadingQrs, _topics } from '../../store/slices/topicSlice';
import { colors } from '../../theme/palette';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		qrCode: {
			position: 'absolute',
			top: 0,
			left: 0,
			opacity: 1,
			height: 224,
			width: 200,
		},
		qrCodeLoading: {
			position: 'absolute',
			top: 0,
			left: 0,
			opacity: 0.2,
			height: 224,
			width: 200,
		},
		title: {
			display: 'flex',
			margin: 10,
			alignItems: 'center',
			justifyContent: 'space-between',
			flexGrow: 1,
			color: colors.grayDark,
		},
		qrProgress: {
			position: 'relative',
			display: 'flex',
			margin: 10,
			'& .MuiCircularProgress-root': {
				position: 'absolute',
				left: 50,
				top: 50,
			},
			'& .MuiCardMedia-root': {},
			'& .MuiTypography-subtitle1': {
				position: 'absolute',
				color: colors.grayDark,
				fontWeight: 'bold',
				whiteSpace: 'nowrap',
				top: 190,
			},
		},
	})
);

export type QRLoaderProps = {
	topicId: string;
};

export const QRLoader = (props: QRLoaderProps) => {
	const { topicId } = props;
	const classes = useStyles();
	const topics = useAppSelector(_topics);
	const loadingQrs: string[] = useAppSelector(_loadingQrs);
	const [topic, setTopic] = useState<Topic>(topics[topicId]);
	const [loading, setLoading] = useState<boolean>(loadingQrs.includes(topicId));

	useEffect(() => {
		setTopic(topics[topicId]);
		setLoading(loadingQrs.includes(topicId));
	}, [topics, topicId, loadingQrs]);

	return (
		<div className={classes.qrProgress}>
			{loading && (
				<>
					<CircularProgress size={100} />
				</>
			)}

			<CardMedia className={loading ? classes.qrCodeLoading : classes.qrCode} src="img" image={topic.qr} />
		</div>
	);
};
