// QRGeneratorCardWrapper >> QRGeneratorTable >> QRGeneratorTableIcons >> LiteEditQRModal >> LiteForm
import { makeStyles } from '@material-ui/core/styles';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import QrCodeOutlinedIcon from '@mui/icons-material/QrCodeOutlined';
import { useState } from 'react';
import LiteDeleteQRModal from './LiteDeleteQRModal';
import LiteEditQRModal from './LiteEditQRModal';
import LiteFeedbackModal from './LiteFeedbackModal';
import LiteQRViewModal from './LiteQRViewModal';
import { TableRowData } from './QRGeneratorTable';

// useStyles

const useStyles = makeStyles({
	iconWrapper: {
		display: 'inline-flex',
		justifyContent: 'space-between',
	},
	icon: {
		cursor: 'pointer',
		margin: 5,
	},
});

// props
export type QRGeneratorTableLinkProps = {
	// rowData is coming from the QRGeneratorTable. This is used to tell the modal/icons which QR code to edit/delete/view/download
	rowData: TableRowData;
};

const QRGeneratorTableIcons = (props: QRGeneratorTableLinkProps) => {
	const { rowData } = { ...props };
	// consts, methods, functions, useEffects, useState ect go here
	const [QRView, setQRView] = useState(false);
	const [QREdit, setQREdit] = useState(false);
	const [QRDelete, setQRDelete] = useState(false);
	const [feedbackModal, setFeedbackModal] = useState(false);
	const classes = useStyles();

	return (
		<div className={classes.iconWrapper}>
			{/* Download Action Icon */}
			<DownloadOutlinedIcon
				className={classes.icon}
				fontSize="small"
				onClick={() => {
					window.location.href = `${process.env.REACT_APP_LIIINGO_URL}/lite/getZip?id=${rowData.id}`;
					setFeedbackModal(true);
				}}
			/>
			{/* View QR Code */}
			<QrCodeOutlinedIcon
				className={classes.icon}
				fontSize="small"
				onClick={() => {
					setQRView(true);
				}}
			/>
			{QRView && (
				<LiteQRViewModal
					open={QRView}
					rowData={rowData}
					handleClose={() => {
						setQRView(false);
						setFeedbackModal(true);
					}}
				/>
			)}

			{/* Edit QR code info */}

			<EditOutlinedIcon
				className={classes.icon}
				fontSize="small"
				onClick={() => {
					setQREdit(true);
				}}
			/>
			{QREdit && (
				<LiteEditQRModal
					open={QREdit}
					rowData={rowData}
					handleClose={() => {
						setFeedbackModal(true);
						setQREdit(false);
					}}
				/>
			)}

			{/* Delete QR code */}

			<DeleteOutlineOutlinedIcon
				className={classes.icon}
				fontSize="small"
				onClick={() => {
					setQRDelete(true);
				}}
			/>
			{QRDelete && (
				<LiteDeleteQRModal
					open={QRDelete}
					rowData={rowData}
					handleClose={() => {
						setQRDelete(false);
					}}
				/>
			)}

			{/* Feedback Modal */}

			{feedbackModal && (
				<LiteFeedbackModal
					open={!localStorage.getItem('feedbackModalClosed') && feedbackModal}
					handleClose={() => {
						setFeedbackModal(false);
						localStorage.setItem('feedbackModalClosed', 'true');
					}}
				/>
			)}
		</div>
	);
};

export default QRGeneratorTableIcons;
