import Box from '@mui/material/Box';
import Checkbox from '@material-ui/core/Checkbox';
import { Theme } from '@material-ui/core/styles/createTheme';

import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import React, { useState } from 'react';
import { DO_NOT_SHOW_SPONSORED_INVITE_DIALOG } from '../../../util/constants';
import { FlatButton } from '../../Buttons/FlatButton';
import { LiiingoDialog } from '../../LiiingoDialog';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		content: {
			width: 351,
			height: 300,
			marginTop: 15,
			marginLeft: 5,
			marginRight: 5,
		},
		box: {
			display: 'flex',
			flexDirection: 'column',
		},
		actionBox: {
			display: 'flex',
			alignItems: 'center',
			marginLeft: 15,
		},
		text: {
			marginLeft: 25,
		},
	})
);

export type SponsorDialogProps = {
	open: boolean;
	onClose: () => void;
	onSure: () => void;
};

export const SponsorDialog = (props: SponsorDialogProps) => {
	const { open, onClose, onSure } = {
		...props,
	};
	const classes = useStyles();
	const [hide, setHide] = useState(false);
	const handleClose = () => {
		if (hide) {
			localStorage.setItem(DO_NOT_SHOW_SPONSORED_INVITE_DIALOG, 'true');
		}
		onClose();
	};

	return (
		<LiiingoDialog
			title="Send Sponsored Invites?"
			titleVariant="light"
			handleClose={handleClose}
			open={open}
			actions={
				<>
					<FlatButton
						onClick={() => {
							handleClose();
						}}
					>
						Cancel
					</FlatButton>
					<FlatButton
						variant="contained"
						color="primary"
						type="submit"
						onClick={(e) => {
							onSure();
							handleClose();
						}}
					>
						Yes, I'm Sure
					</FlatButton>
				</>
			}
		>
			<Box className={classes.box}>
				<Typography className={classes.text} variant="body2">
					Subscriptions for sponsored accounts will be charged to this Group Lead account. Are you sure you
					want to send sponsored invites?
				</Typography>
				<Box className={classes.actionBox}>
					<Checkbox
						color="primary"
						onClick={() => {
							setHide(!hide);
						}}
					/>
					<Typography variant="body2">Don't ask me this again.</Typography>
				</Box>
			</Box>
		</LiiingoDialog>
	);
};
