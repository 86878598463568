import React from 'react';
import { LiiingoContextMenu } from './LiiingoContextMenu';

export type EditContextMenuProps = {
	navigateToApp: () => void;
	appName: React.MutableRefObject<any>;
	setReadOnly: (readOnly: boolean) => void;
};

export const EditContextMenu: React.FC<EditContextMenuProps> = (props) => {
	const { children, navigateToApp, appName, setReadOnly } = { ...props };

	const renameApp = () => {
		setReadOnly(false);
		setTimeout(() => {
			appName.current.focus();
			appName.current.select();
		}, 1);
	};
	return (
		<LiiingoContextMenu
			id="editcontextMenu1"
			options={[
				{
					text: 'Edit',
					handleClick: navigateToApp,
				},
				{
					text: 'Rename',
					handleClick: renameApp,
				},
			]}
		>
			{children}
		</LiiingoContextMenu>
	);
};
