import { addMinutes, differenceInMilliseconds, format } from 'date-fns';
import { SessionResponse } from './Analytics';
import { UserTraffic } from './Traffic';

export type PageViewsType = {
	exhibitId: string;
	views: UserTraffic[];
	sessionLengths?: number[];
};

/**
 * This function aggregates page views from the raw data by day.
 *
 * @param rawData - SessionResponse[] data from the API
 * @returns an array of aggregated page views by day (an array of PageViewsTypes)
 */

export const aggregatePageViews = (rawData: SessionResponse[], locationId) => {
	// Create an array to hold the page view data for each exhibit
	let pageViews: PageViewsType[] = [];

	// Create a Map to keep track of which exhibit has which page views
	let pageViewsMap = {}; //new Map<string, UserTraffic[]>();

	//create another map to keep track of session durations
	let sessionLengthsMap = {}; //new Map<string, number[]>(); //key is exhibitId, value is array of session lengths

	// Loop through each session in the raw data
	rawData.forEach((session) => {
		// Get the viewedContent property from the session metadata
		let viewedContent = session.metadata.viewedContent;

		// Loop through each content item viewed
		viewedContent?.forEach((content) => {
			// Get the openTime and exhibitId properties from the content
			let { openTime, exhibitId, closeTime } = content;
			// console.log('typeof(openTime)', typeof (openTime));
			// console.log('typeof(closeTime)', typeof (closeTime));
			// console.log('openTime', openTime);
			if (typeof openTime === 'string') {
				if (openTime.includes('T')) {
					openTime = new Date(openTime).getTime();
				} else {
					openTime = parseInt(openTime);
				}
			} else if (typeof openTime === 'object') {
				openTime = parseInt(openTime?.$date?.$numberLong);
			} else if (openTime === undefined) {
				openTime = parseInt(session.metadata.startTime?.$date.$numberLong);
			}

			// console.log('closeTime', closeTime);
			if (typeof closeTime === 'string') {
				if (closeTime.includes('T')) {
					closeTime = new Date(closeTime).getTime();
				} else {
					closeTime = parseInt(closeTime);
				}
			} else if (typeof closeTime === 'object') {
				closeTime = parseInt(closeTime?.$date?.$numberLong);
			} else if (closeTime === undefined) {
				if (session.metadata.closeTime?.$date?.$numberLong) {
					closeTime = parseInt(session.metadata.closeTime?.$date?.$numberLong);
				} else {
					closeTime = addMinutes(openTime, 2).valueOf();
				}
			}

			let start = new Date(openTime);
			let end = new Date(closeTime);
			// console.log('start', start);
			// console.log('end', end);
			// console.log('session', session);
			// console.log('closeTime', closeTime);
			// console.log('parseInt(session.metadata.closeTime?.$date?.$numberLong)', parseInt(session.metadata.closeTime?.$date?.$numberLong));
			// console.log('addMinutes(start, 1).valueOf()', addMinutes(start, 1).valueOf());

			//** PAGE VIEW DATA *//

			// Set the key for this page view
			const dateAsKey = format(start, 'M/d');

			// Set the display value for this page view
			const display = format(end, 'MMM d, yyyy');

			// Initialize the pageViewsMap if undefined
			pageViewsMap[exhibitId] = pageViewsMap[exhibitId] || [];
			// Look for an existing page view with the same key
			let existingItem = pageViewsMap[exhibitId].find((view) => view.key === dateAsKey);
			// If a page view with the same date/key exists
			if (existingItem) {
				// Increment its value by 1
				existingItem.value += 1;
			} else {
				// Otherwise, add a new page view with a value of 1
				pageViewsMap[exhibitId].push({
					key: dateAsKey,
					value: 1,
					display,
					timestamp: start.getTime(),
				});
			}

			//** SESSION LENGTH DATA *//

			// Initialize the sessionLengthsMap if undefined
			sessionLengthsMap[exhibitId] = sessionLengthsMap[exhibitId] || [];

			// Get the session length in seconds
			// let sessionLength = end.getTime() - start.getTime();
			let sessionLength = differenceInMilliseconds(end.getTime(), start.getTime());

			if (sessionLength !== 0) {
				//push to map
				sessionLengthsMap[exhibitId].push(sessionLength);
			} else {
				console.log('sessionLength was bad', start.getTime(), end.getTime());
			}
		});
	});

	// Loop through the pageViewsMap
	Object.keys(pageViewsMap).forEach((exhibitId) => {
		// Add an object to the pageViews array for each exhibit
		pageViews.push({
			exhibitId,
			views: pageViewsMap[exhibitId].sort((a, b) =>
				a.timestamp > b.timestamp ? 1 : b.timestamp > a.timestamp ? -1 : 0
			),
			sessionLengths: sessionLengthsMap[exhibitId],
		});
	});

	return pageViews;
};
