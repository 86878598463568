import JSZip from 'jszip';

const convert_svg_to_png = (svg_url: string, size = 1000): Promise<string> => {
	return new Promise((resolve, reject) => {
		const image = new Image();
		image.crossOrigin = 'anonymous';
		image.onload = function () {
			const aspect_ratio = image.naturalHeight / image.naturalWidth;
			const height = size * aspect_ratio;

			const canvas = document.createElement('canvas');
			canvas.width = size;
			canvas.height = height;

			const context = canvas.getContext('2d');
			if (context) {
				context.drawImage(image, 0, 0, size, height);
				const png_data = canvas.toDataURL('image/png', 1);
				resolve(png_data);
			} else {
				reject(new Error('Failed to get canvas context'));
			}
		};
		image.onerror = reject;
		image.src = svg_url;
	});
};

const getZip = async (topicId: string): Promise<void> => {
	const zip = new JSZip();
	const svg_url = `${process.env.REACT_APP_LIIINGO_URL}/exhibit/qr?id=${topicId}`;

	try {
		// Add SVG to zip
		const svg_response = await fetch(svg_url);
		const svg_blob = await svg_response.blob();
		zip.file('qr_code.svg', svg_blob);

		// Convert SVG to PNG and add to zip
		const png_data = await convert_svg_to_png(svg_url);
		const png_blob = await fetch(png_data).then((res) => res.blob());
		zip.file('qr_code.png', png_blob);

		// Generate and download zip file
		const zip_blob = await zip.generateAsync({ type: 'blob' });
		const download_link = document.createElement('a');
		download_link.href = URL.createObjectURL(zip_blob);
		download_link.download = 'qr_codes.zip';
		document.body.appendChild(download_link);
		download_link.click();
		document.body.removeChild(download_link);
	} catch (error) {
		console.error('Error creating zip file:', error);
	}
};

export { getZip };
