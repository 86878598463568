import ListItem from '@material-ui/core/ListItem';
import { Theme } from '@material-ui/core/styles/createTheme';

import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { colors } from '../../theme/palette';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		subtitle: {
			marginRight: theme.spacing(1),
			color: colors.grayLight,
		},
		title: {
			backgroundColor: colors.grayLight20,
			padding: '12px 12px',
		},
		text: {
			color: colors.grayDark,
			fontWeight: 700,
		},
		pb40: {
			paddingBottom: '40px',
		},
	})
);

export type OptionGroupProps = {
	title?: string;
};

const defaultProps = {
	title: 'Title',
};

export const OptionGroup: React.FC<OptionGroupProps> = (props) => {
	const { children, title } = { ...defaultProps, ...props };
	const classes = useStyles();

	return (
		<div className={title !== 'Preview' && title !== 'Text' ? classes.pb40 : ''}>
			<ListItem className={classes.title}>
				<Typography className={classes.text}>{title}</Typography>
			</ListItem>
			{children}
		</div>
	);
};
