import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as lodash from 'lodash';
import * as api from '../api-client';
import { urlToFile } from '../api-client';
import { Language, Location } from '../models';
import { AppState } from './../store';
import { addLanguagesToContent, removeLanguagesFromContent } from './contentSlice';
import {
	addNewLanguageIds,
	makeChange,
	removeNewLanguageIds,
	setNewDefaultLanguage,
	setNewSupportedLanguages,
} from './editorSlice';
import { addLanguagesToSections, removeLanguagesFromSections } from './sectionSlice';
import { addLanguagesToTopics, removeLanguagesFromTopics } from './topicSlice';

export interface LocationMap {
	[id: string]: Location;
}

export interface LocationState {
	locations: LocationMap;
	selectedLocationId: string;
	isLoading: boolean;
}

export const initialLocationState: LocationState = {
	locations: {} as LocationMap,
	selectedLocationId: '',
	isLoading: false,
};

export type AppChangedPayload = {
	key: string;
	value: any;
};

export type LogoChangePayload = {
	name: string;
	url: string;
};

// Called from the UI when Multi Language Drawer is finished. Triggers add/remove languages for all the Things
// Uses newSupportedLanguages/newDefaultLanguage state variables in editorSlice to determine which actions to take
// Those variables are used by the ML Drawer to keep track of desired state.
// If the user clicks Done or Save in the ML Drawer, this action is dispatched
// After we're done adding/removing languages here, we clear the editorSlice variables
// For ML drawer, always use trhe default language contnetn as a template for the new content
export const saveLanguages = createAsyncThunk<void, void, { state: AppState }>(
	'location/saveLanguages',
	async (_, { getState, dispatch }) => {
		const state = getState();
		const location = state.location.locations[state.location.selectedLocationId];
		let defaultLanguage = location.defaultLanguage;
		const supportedLanguages = location.supportedLanguages;
		const newSupportedLanguages = state.editor.newSupportedLanguages;
		const newDefaultLanguage = state.editor.newDefaultLanguage;

		// find all languages in the new supported languages list (from ML drawer) that are not in the location's supported languages
		const add = lodash.difference(newSupportedLanguages, supportedLanguages);
		// find all languages in the location's supported languages that are not in the new supported languages list (from ML drawer)
		const remove = lodash.difference(supportedLanguages, newSupportedLanguages);

		// if the user chose a new default language, and that language isn't being added,
		// dispatch an update to the location's default language
		if (newDefaultLanguage && newDefaultLanguage !== defaultLanguage && !add.includes(newDefaultLanguage)) {
			dispatch(changeDefaultLanguage(newDefaultLanguage as Language));
			defaultLanguage = newDefaultLanguage;
		}

		// The methods down below make a copy of the default language translation to act as filler/placeholder content for newly added languages.

		if (remove.length > 0) {
			console.log('removing languages: ', remove);
			dispatch(removeLanguagesFromLocation(remove));
			dispatch(removeLanguagesFromSections(remove));
			dispatch(removeLanguagesFromTopics(remove));
			dispatch(removeLanguagesFromContent(remove));
		}

		// when adding languages via ML drawrer, we need to create a copy of the default language content
		if (add.length > 0) {
			console.log('adding languages: ', add);
			dispatch(addLanguagesToLocation(add));
			dispatch(addLanguagesToSections({ languages: add, languageToCopy: defaultLanguage }));
			dispatch(addLanguagesToTopics({ languages: add, languageToCopy: defaultLanguage }));
			dispatch(addLanguagesToContent({ languages: add, languageToCopy: defaultLanguage }));
		}

		// if the user chose a new default language, and that language was being added (it didn't exist yet),
		// now, we can dispatch an update to the location's default language. (There would be no default language/translation to clone from if we had set it up above)
		if (newDefaultLanguage && newDefaultLanguage !== defaultLanguage && add.includes(newDefaultLanguage)) {
			dispatch(changeDefaultLanguage(newDefaultLanguage as Language));
			defaultLanguage = newDefaultLanguage;
		}

		// -----------------------------------------------------------------------------------------------
		// Temporary: Check/ensure that we have translations for all supported languages (for all Things)
		// -----------------------------------------------------------------------------------------------

		// Check the section names to ensure that we have translations for all supported languages
		// call getState() again, because the section names have changed, iterate over all sections,
		// for each section, iterate over all supportedLanguages and check if there is an item in the section.name array
		// that has a language that matches the supported language. If not, add a new item to the section.name array
		// that is a copy of the default language translation, but with the language set to the supported language that is currently being checked.
		// thanks github copilot - lol

		// let sections = getState().section.sections;
		// let topics = getState().topic.topics;
		// let contents = getState().content.contents;

		// console.log('supported languages', getState().location.locations[getState().location.selectedLocationId].supportedLanguages);
		// console.log('default language', getState().location.locations[getState().location.selectedLocationId].defaultLanguage);

		// const sectionNames = Object.values(getState().section.sections)
		//   .map((section) => {
		//     let add = lodash.difference(newSupportedLanguages, section.name.map(name => name.language));
		//     let remove = lodash.difference(section.name.map(name => name.language), newSupportedLanguages);
		//     console.dir(add, remove);
		//     console.table(section.name)
		//   });

		// const topicNames = Object.values(getState().topic.topics)
		//   .map((topic) => {
		//     let add = lodash.difference(newSupportedLanguages, topic.name.map(name => name.language));
		//     let remove = lodash.difference(topic.name.map(name => name.language), newSupportedLanguages);
		//     console.dir(add, remove);
		//     console.table(topic.name)
		//   });

		// const contentTranslations = Object.values(getState().content.contents)
		//   .map((content) => {
		//     let add = lodash.difference(newSupportedLanguages, content.languages.map(translation => translation.language));
		//     let remove = lodash.difference(content.languages.map(translation => translation.language), newSupportedLanguages);
		//     console.dir(add, remove);
		//     console.table(content.languages)
		//   });

		// -----------------------------------------------------------------------------------------------
		// End: Temporary: Check/ensure that we have translations for all supported languages (for all Things)
		// -----------------------------------------------------------------------------------------------

		// To make new language items appear 50% opaque, we need to add the ids to an Array in state
		// UI simply references the list of ids to determine if the translation is new or not
		// IDs are removed from the list individually when the user changes that content items translation, or at least focuses on it, as an acknowledgement
		// The list of ids is completely cleared out when save event occurs for the whole store.

		// Since we added translations to EVERYTHING (via ML drawer), let's collect all the id's, no matter the type, and add them to the list of "new" ids
		// Data structure looks like
		// ---------------  { languageCode: [...ids] }
		//   for example:   { 'fr': [id1, id2, id3], 'es': [id1, id2, id3] }
		// where id1, id2, id3 can be the id's for any type of element, as long as it is an unacknowledged translation that needs to be displayed 50% opaque. (is that glass-half-full?)
		// 50% transparency is a nice way to show the user there are items that should be updated.

		// SectionMap
		let sections = getState().section.sections;
		// TopicMap
		let topics = getState().topic.topics;
		// ContentMap
		let contents = getState().content.contents;
		// List of all section ids
		let sectionIds = Object.keys(sections);
		// List of all topic ids
		let topicIds = Object.keys(topics);
		// List of all content ids
		let contentIds = Object.keys(contents);

		// What we're saying here is: for every language that was removed via the ML Drawer,
		// remove any mappings that indicate that ObjectId and that LanguageCode need to be rendered 50% opaque
		// Because those translations no longer exist for any object, we pass a list of all object ids to the removeNewLanguageIds action

		// TODO: make reducer to handle removing the entire key:value[] by simply passing the language (key)
		remove.forEach((lang) => {
			dispatch(removeNewLanguageIds({ language: lang, ids: [...sectionIds, ...topicIds, ...contentIds] }));
		});

		// What we're saying here is: for every language that was added via the ML Drawer,
		// add a mapping for LanguageCode to ObjectId that will determine if that Object/Object.name should be rendered 50% opaque
		// Because those translations did not exist yet, we pass a list of all object ids to the addNewLanguageIds action
		add.forEach((lang) => {
			dispatch(addNewLanguageIds({ language: lang, ids: [...sectionIds, ...topicIds, ...contentIds] }));
		});

		// clear the newSupportedLanguages/newDefaultLanguage vars from ML Drawer
		dispatch(setNewSupportedLanguages([]));
		dispatch(setNewDefaultLanguage(null));
		return;
	}
);

// future proof location saving. Save all locations, even though we only allow one location right now
export const saveLocations = createAsyncThunk<void, void, { state: AppState }>(
	'location/saveLocations',
	async (_, { getState, dispatch }) => {
		let { locations } = getState().location;
		let newLocations = {};

		for await (let location of Object.values(locations)) {
			let headerLogo, customQrLogo;

			// if headerLogo contains a blob type url
			if (location.headerLogo.startsWith('blob')) {
				headerLogo = await urlToFile(location.headerLogo, location.headerLogoImageName);
				await api.saveHeaderLogo(location, headerLogo);
			}

			// if customQrLogo contains a blob type url
			if (location.customQrLogo.startsWith('blob')) {
				customQrLogo = await urlToFile(location.customQrLogo, location.customQrLogoImageName);
				await api.saveQrLogo(location, customQrLogo);
			}

			await api.saveLocation(location);
			newLocations[location.id] = await api.getLocationById(location.id);
		}

		// set the locations
		dispatch(setLocations(newLocations));
		return;
	}
);

export const saveEverything = createAsyncThunk<void, void, { state: AppState }>(
	'location/saveEverything',
	async (_, { getState, dispatch }) => {
		// api calls will be made from the liiingoMiddleware in store.ts, triggered by intercepting this action name
		// set the makeChange varaible to false to disable the publish button.
		dispatch(makeChange(false));
		return;
	}
);

export interface cloneAppPayload {
	templateId: string;
	name: string;
	contactName: string;
	email: string;
	companyName: string;
}

// LOCATION SLICE
// Remember, it's only ok to modify state within these reducers because there's behind-the-scenes stuff going on that allows it.
// Normally, and anywhere else, we don't modify state directly, but rather via dispatching an action
export const locationSlice = createSlice({
	name: 'location',
	initialState: initialLocationState,
	reducers: {
		// this is named fetch because that's what will happen when the middleware intercepts the action. It will return the location to this reducer and forward the other moddels to their respective reducers
		fetchAll: (state: LocationState, action: PayloadAction<Location>) => {
			let location = action.payload;
			state.locations[location.id] = location;
			state.selectedLocationId = location.id;
			state.isLoading = false;
		},
		setLocations: (state: LocationState, action: PayloadAction<LocationMap>) => {
			state.locations = action.payload;
		},
		deleteSectionFromLocation(state: LocationState, action: PayloadAction<string>) {
			const sectionId = action.payload;
			const location = state.locations[state.selectedLocationId];
			location.sectionOrder = location.sectionOrder.filter((id) => id !== sectionId);
		},
		selectLocation: (state: LocationState, action: PayloadAction<string>) => {
			state.selectedLocationId = action.payload;
		},
		removeLanguagesFromLocation(state: LocationState, action: PayloadAction<Language[]>) {
			// Remove languages from selectedLocation's supportedLanguages
			// Realized after making these methods that there is no translation for the name field, so essentially we're only mutating the supported languages array.
			// There's already a method for that, but it takes 1 language at a time. Keeping this for now.
			const location = state.locations[state.selectedLocationId];
			let removeLangs = action.payload;
			location.supportedLanguages = location.supportedLanguages.filter((lang) => !removeLangs.includes(lang));
		},
		addLanguagesToLocation(state: LocationState, action: PayloadAction<Language[]>) {
			// add supported langs
			const location = state.locations[state.selectedLocationId];
			location.supportedLanguages = [...location.supportedLanguages, ...action.payload];
		},
		updateSectionOrder(state: LocationState, action: PayloadAction<string[]>) {
			const location = state.locations[state.selectedLocationId];
			location.sectionOrder = action.payload;
		},
		changeHeaderLogo(state: LocationState, action: PayloadAction<LogoChangePayload>) {
			const location = state.locations[state.selectedLocationId];
			const { name, url } = action.payload;
			location.headerLogoImageName = name;
			location.headerLogo = url;
		},
		changeLocationName(state: LocationState, action: PayloadAction<string>) {
			const location = state.locations[state.selectedLocationId];
			location.name = action.payload;
		},
		changeCompanyName(state: LocationState, action: PayloadAction<string>) {
			const location = state.locations[state.selectedLocationId];
			location.companyName = action.payload;
		},
		changeQrLogo(state: LocationState, action: PayloadAction<LogoChangePayload>) {
			const location = state.locations[state.selectedLocationId];
			const { name, url } = action.payload;
			location.customQrLogo = url;
			location.customQrLogoImageName = name;
			location.qrUpdatedAt = { $date: { $numberLong: Date.now().toString() } };
		},
		changeQrPrimaryColor(state: LocationState, action: PayloadAction<string>) {
			const location = state.locations[state.selectedLocationId];
			location.customQrColorPrimary = action.payload;
			location.qrUpdatedAt = { $date: { $numberLong: Date.now().toString() } };
		},
		changeQrSecondaryColor(state: LocationState, action: PayloadAction<string>) {
			const location = state.locations[state.selectedLocationId];
			location.customQrColorSecondary = action.payload;
			location.qrUpdatedAt = { $date: { $numberLong: Date.now().toString() } };
		},
		addSupportedLanguage(state: LocationState, action: PayloadAction<string>) {
			const location = state.locations[state.selectedLocationId];
			location.supportedLanguages.push(action.payload);
		},
		removeSupportedLanguage(state: LocationState, action: PayloadAction<string>) {
			const location = state.locations[state.selectedLocationId];
			location.supportedLanguages = location.supportedLanguages.filter((lang) => lang !== action.payload);
		},
		changeDefaultLanguage(state: LocationState, action: PayloadAction<Language>) {
			const location = state.locations[state.selectedLocationId];
			location.defaultLanguage = action.payload;
		},
		leaveWithoutSaving(state: LocationState) {
			console.log('Bye bye! Come see us again soon!');
		},
		reorderSection(state: LocationState, action: PayloadAction<{ oldIndex: number; newIndex: number }>) {
			const { oldIndex, newIndex } = action.payload;
			const location = state.locations[state.selectedLocationId];
			const tempSectionId = location.sectionOrder[oldIndex];
			location.sectionOrder.splice(oldIndex, 1);
			location.sectionOrder.splice(newIndex, 0, tempSectionId);
		},
		saveLocation(state: LocationState) {
			// this reducer does abolutely nothing. The action is captured in the LiiingoMiddleware and handled there.
			// this was created for the onBLur event of the location name input in the templateViewer
		},
		updateLocation(state: LocationState, action: PayloadAction<Partial<Location>>) {
			const location = state.locations[state.selectedLocationId];
			const locationUpdates = action.payload;
			state.locations[state.selectedLocationId] = { ...location, ...locationUpdates };
		},
		cloneApp(state: LocationState, action: PayloadAction<cloneAppPayload>) {
			// this reducer does abolutely nothing. The action is captured in the LiiingoMiddleware and the network call is handled there.
		},
		setIsLoading(state: LocationState, action: PayloadAction<boolean>) {
			state.isLoading = action.payload;
		},
		translate(state: LocationState, action: PayloadAction<{ sections: string[]; pages: string[]; lang: any[] }>) {
			// this reducer does abolutely nothing. The action is captured in the LiiingoMiddleware and the network call is handled there.
		},
		setSelectedLanguages(state: LocationState, action: PayloadAction<Language[]>) {
			const location = state.locations[state.selectedLocationId];
			location.selectedLanguages = action.payload;
		},
	},
	// This is where we catch returned values from Thunks.
	// extraReducers: (builder) => {	},
});

// Export reducer
export default locationSlice.reducer;

// Export actions
export const {
	updateSectionOrder,
	changeHeaderLogo,
	changeLocationName,
	changeCompanyName,
	changeQrLogo,
	changeQrPrimaryColor,
	changeQrSecondaryColor,
	changeDefaultLanguage,
	addSupportedLanguage,
	removeSupportedLanguage,
	leaveWithoutSaving,
	removeLanguagesFromLocation,
	addLanguagesToLocation,
	reorderSection,
	setLocations,
	selectLocation,
	deleteSectionFromLocation,
	saveLocation,
	updateLocation,
	cloneApp,
	fetchAll,
	setIsLoading,
	translate,
	setSelectedLanguages,
} = locationSlice.actions;

// Export selectors
//this feels odd, but redux docs recommend keeping selectors very specific
export const _locations = (state) => state.location.locations;
export const _selectedLocationId = (state) => state.location.selectedLocationId;
export const _selectedLocation = (state) => state.location.locations[state.location.selectedLocationId];
export const _location = (state) => state.location.locations[state.location.selectedLocationId];
export const _appName = (state) => state.location.locations[state.location.selectedLocationId]?.name;
export const _headerLogo = (state) => state.location.locations[state.location.selectedLocationId]?.headerLogo;
export const _qrLogo = (state) => state.location.locations[state.location.selectedLocationId]?.customQrLogo;
export const _qrPrimary = (state) => state.location.locations[state.location.selectedLocationId]?.customQrColorPrimary;
export const _qrSecondary = (state) =>
	state.location.locations[state.location.selectedLocationId]?.customQrColorSecondary;
export const _defaultLanguage = (state) => state.location.locations[state.location.selectedLocationId]?.defaultLanguage;
export const _supportedLanguages = (state) =>
	state.location.locations[state.location.selectedLocationId]?.supportedLanguages;
export const _organizationId = (state) => state.location.locations[state.location.selectedLocationId]?.organizationId;
export const _sectionOrder = (state) => state.location.locations[state.location.selectedLocationId]?.sectionOrder;
export const _locationIsLoading = (state) => state.location.isLoading;
export const _selectedLanguages = (state) =>
	state.location.locations[state.location.selectedLocationId]?.selectedLanguages;
