import Bugsnag from '@bugsnag/js';
import { AlertProps } from '@material-ui/lab/Alert';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

interface ErrorContextType {
	errorMessage: string;
	errorTitle: string;
	errorSeverity: AlertProps['severity'];
	clearError: () => void;
	useErrorResetOnNavigate: () => void;
	handleError: (error: string) => void;
}
export const ErrorContext = React.createContext<Partial<ErrorContextType>>({});

export const ErrorContextProvider = ({ children }) => {
	const [errorMessage, setErrorMessage] = useState<string | null>(null);
	const [errorTitle, setErrorTitle] = useState<string | null>(null);
	const [errorSeverity, setErrorSeverity] = useState<AlertProps['severity']>('error');

	const defaultErrorTitle = 'Something went wrong';
	const defaultErrorSeverity = 'error' as AlertProps['severity'];

	const clearError = () => {
		setErrorMessage(null);
		setErrorTitle(null);
		setErrorSeverity(defaultErrorSeverity);
	};

	// This is a custom hook that will clear the error state when the react-router 'location' changes.
	// For this to work properly, the <ErrorContextProvider> needs to be inside the <BrowserRouter> provider.
	const useErrorResetOnNavigate = () => {
		let location = useLocation();
		useEffect(() => {
			clearError();
		}, [location]);
	};

	const handleError = (error: string) => {
		console.error(`NOTIFYING BUGSNAG: ${error}`);
		Bugsnag.notify(error);
		handleStringError(error);
	};

	const handleStringError = (message: string) => {
		setErrorMessage(message);
		setErrorTitle(defaultErrorTitle);
		setErrorSeverity(defaultErrorSeverity);
	};

	const context: ErrorContextType = {
		errorMessage,
		errorTitle,
		errorSeverity,
		clearError,
		useErrorResetOnNavigate,
		handleError,
	};

	return <ErrorContext.Provider value={context}>{children}</ErrorContext.Provider>;
};
