import List from '@material-ui/core/List';
import createStyles from '@material-ui/core/styles/createStyles';
import { Theme } from '@material-ui/core/styles/createTheme';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { Section, Title, Topic } from '../../store/models';
import { _selectedTopicId, selectTopic } from '../../store/slices/topicSlice';
import { DroppableMenuWrapper } from '../ElementMenu/DroppableMenuWrapper';
import { PageMenuItem } from './PageMenuItem';
import { PageMenuItemAdd } from './PageMenuItemAdd';
import { useEffect,  useRef} from 'react';
import { SOCIAL_SHARING } from '../../util/constants';
import { bindActionCreators } from 'redux';
import { selectContent } from '../../store/slices/contentSlice';
import { selectSection } from '../../store/slices/sectionSlice';
import { _activeLanguageCode, focusedItemName } from '../../store/slices/editorSlice';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		list: {
			paddingTop: 0,
			paddingBottom: 0,
		},
		selected: {
			margin: '1px 0px',
			boxShadow: '0 0 0.1em rgb(0 0 0 / 20%)',
		},
	})
);

export type PageMenuOption = {
	topicId: string;
	displayName: string;
};

export type PageMenuProps = {
	section: Section;
	locationId: string;
	orderedTopics: Topic[];
	disableDelete: boolean;
	dropDisabled?: boolean;
	showAddPage: boolean;
	setShowAddPage: (show: boolean) => void;
	renamePage: (topicId: string, title: Title) => void;
	reorderTopic: (payload: { oldIndex: number; oldSectionId: string; newIndex: number; newSectionId: string }) => void;
	addTopic: (pageName: string) => void;
};

const defaultProps = {
	dropDisabled: false,
	showAddPage: false,
};

export const PageMenu = (props: PageMenuProps) => {
	const {
		section,
		locationId,
		orderedTopics,
		disableDelete,
		dropDisabled,
		showAddPage,
		setShowAddPage,
		renamePage,
		reorderTopic,
		addTopic,
	} = {
		...defaultProps,
		...props,
	};

	const classes = useStyles();
	const selectedTopicId = useAppSelector(_selectedTopicId);
	const activeLanguageCode = useAppSelector(_activeLanguageCode);

	const dispatch = useAppDispatch();

	const onFocusedItemName = bindActionCreators(focusedItemName, dispatch);
	

	const selectedRef = useRef(null);
	useEffect(() => {
		if (selectedTopicId && selectedRef.current) {
			selectedRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
		}
	}, [selectedTopicId]);

	
	// For the social Sharing to work we need to make sure that when user select 
	// The menu sharing the topic and become focused on 
	// For this to happen we need to select content, select topic and select section 
	// After setting the this to the new ids based on where the context menu is then we update the 
	// Focused item which should be Social Sharing component  
	const onSelectContent = bindActionCreators(selectContent, dispatch);
	const setTopic = bindActionCreators(selectTopic, dispatch);
	const setSection = bindActionCreators(selectSection, dispatch);
	const handleSocialSharing = ( topicId: string, sectionId: string) => {
		
		console.log('show social');
		onSelectContent('')
		setTopic(topicId);
		setSection(sectionId)
		onFocusedItemName(SOCIAL_SHARING);
	
	};

	return (
		section && (
			<DroppableMenuWrapper id={section?.id} dropDisabled={dropDisabled} disableGutters>
				<List className={classes.list} component="div">
					{/* render topics in order */}
					{orderedTopics.map((topic, index) => {
						return (
							topic && (
								<div
									key={topic.id + '-page-menu-item-div'}
									className={topic.id === selectedTopicId ? classes.selected : null}
									ref={topic.id === selectedTopicId ? selectedRef : null}
								>
									{/* each page */}
									<PageMenuItem
										key={topic.id + '-page-menu-item'}
										locationId={locationId}
										status={topic.status}
										enableSharing={topic.enableSharing}
										topicId={topic.id}
										topicLink={process.env.REACT_APP_LIIINGO_WEBAPP_URL + '/topic/' + topic.id}
										index={index}
										last={orderedTopics.length - 1}
										selected={topic.id === selectedTopicId}
										displayName={
											topic.name.find((n) => n.language === activeLanguageCode)?.name ||
											'Missing translation'
										}
										disableDelete={disableDelete}
										renamePage={renamePage}
										moveUp={() =>
											reorderTopic({
												oldIndex: index,
												oldSectionId: section.id,
												newIndex: index - 1,
												newSectionId: section.id,
											})
										}
										moveDown={() =>
											reorderTopic({
												oldIndex: index,
												oldSectionId: section.id,
												newIndex: index + 1,
												newSectionId: section.id,
											})
										}
										socialSharing={() => {
											handleSocialSharing(topic.id, section.id);
										}}
									/>

									{/* render a hidden addPage item after the topic if the topic is our selected one */}
									{topic.id === selectedTopicId ? (
										<PageMenuItemAdd
											visible={showAddPage}
											setVisible={setShowAddPage}
											addTopic={addTopic}
											key={`add-${topic.id + ' ' + index}`}
										/>
									) : null}
								</div>
							)
						);
					})}
					{!selectedTopicId || section.topicOrder.length === 0 ? (
						<PageMenuItemAdd
							visible={showAddPage}
							setVisible={setShowAddPage}
							addTopic={addTopic}
							key={`add-zero`}
						/>
					) : null}
				</List>
			</DroppableMenuWrapper>
		)
	);
};
