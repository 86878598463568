import { Link, Typography } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { LiiingoDismissableTip } from '../LiiingoDismissableTip';

export const AnalyticsTip = () => {
	const [showTip, setShowTip] = useState(false);

	// effect to show tip after 10 seconds
	useEffect(() => {
		// set a timeout function to show the tip after 10 seconds
		let timeout = setTimeout(() => {
			setShowTip(true);
		}, 10000);
		// a returned function is called the clean up function. Used here to cancel the asynchronous timeout from occuring if the component is unmounted before the timeout is called
		return () => {
			clearTimeout(timeout);
		};
	}, []); // using an empty array as the second param of useEffect ensures that the effect is only called once, on mount

	return (
		showTip && (
			<LiiingoDismissableTip fixedLeft onClose={() => setShowTip(false)}>
				<div>
					<Typography variant="inherit">Want more from your analytics?</Typography>
					<br />
					<Link href="https://form.jotform.com/230474956574063" target="_blank">
						<b>Send Feedback</b>
					</Link>
				</div>
			</LiiingoDismissableTip>
		)
	);
};
