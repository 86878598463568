import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';
import createStyles from '@material-ui/core/styles/createStyles';
import { Theme } from '@material-ui/core/styles/createTheme';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { OnboardingFlowContext } from '../../context/OnboardingFlowContext';
import { useQueryStringParams } from '../../hooks/useQueryStringParams';
import { useAppSelector } from '../../store/hooks';
import { OrganizationType } from '../../store/models';
import {
	_locationIsLoading,
	_qrPrimary,
	_qrSecondary,
	_selectedLocation,
	_selectedLocationId,
} from '../../store/slices/locationSlice';
import { _organization } from '../../store/slices/organizationSlice';
import { _selectedTopic, _selectedTopicId, _topicIsLoading } from '../../store/slices/topicSlice';
import { Keyframes } from '../../theme/Keyframes';
import { AnalyticsCard } from '../Dashboard/AnalyticsCard';
import { EditCard } from '../Dashboard/EditCard';
import { InviteCard } from '../Dashboard/InviteCard';
import { NotifyCard } from '../Dashboard/NotifyCard';
import { QRCard } from '../Dashboard/QRCard';
import { LiiingoSuccessSnackbar } from '../LiiingoSuccessSnackbar';
import { useHydrateStore } from '../../hooks/useHydrateStore';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		loadingCard: {
			position: 'relative',
			minHeight: '80vh',
		},
		containerWidth: {
			width: '100%',
		},
		topGrid: {
			margin: 0,
		},
		dashboardTitle: {
			padding: 8,
		},
		fade: {
			animation: 'fade 1s ease .5s both',
		},
	})
);

export const Dashboard = () => {
	const history = useHistory();
	const classes = useStyles();
	const { route } = useContext(OnboardingFlowContext);
	const urlParams = useQueryStringParams();
	const [passwordReset, setPasswordReset] = useState(!!urlParams.get('passwordReset') ?? false);
	const [snackBarSuccessMessage, setSnackBarSuccessMessage] = useState('');
	const loadingLocation = useAppSelector(_locationIsLoading);
	const loadingTopic = useAppSelector(_topicIsLoading);
	const primaryColor = useAppSelector(_qrPrimary);
	const secondaryColor = useAppSelector(_qrSecondary);
	const selectedTopic = useAppSelector(_selectedTopic);
	const selectedTopicId = useAppSelector(_selectedTopicId);
	const selectedLocationId = useAppSelector(_selectedLocationId);
	const selectedLocation = useAppSelector(_selectedLocation);
	const org = useAppSelector(_organization);
	useHydrateStore();
	const domain_app_url = process.env.REACT_APP_LIIINGO_WEBAPP_URL;
	// TODO: Make a method that will do ALL the things required to close an account.
	// const [closeAccountWithDueDays] = useLazyQuery(SUBSCRIPTION_QUERY, {
	//   onCompleted: (data) => {
	//     if (data.subscription && data.subscription.daysUntilDue) {
	//       const daysUntilDue = Number(data.subscription.daysUntilDue);
	//       if (daysUntilDue < 0) {
	//         localStorage.removeItem(CLOSED_ACCOUNT);
	//         closeAccountMutation({
	//           variables: {
	//             route,
	//           },
	//         });
	//       }
	//     }
	//   },
	//   onError: handleError,
	// });

	// TODO: This is a GraphQL call to the poweredBy API. It probably doesn't do all the things required to actually close an account now.
	// revisit this. It needs to use php endpoint, or maybe only front-end calls if possible.
	// const [closeAccountMutation] = useMutation<closeAccount>(CLOSE_ACCOUNT_MUTATION, {
	//   onCompleted: (data) => {
	//     logout();
	//   },
	//   onError: handleError,
	// });

	// useEffect(() => {
	//   if (localStorage.getItem(CLOSED_ACCOUNT) === 'true') {
	//     closeAccountWithDueDays();
	//   }
	// }, [closeAccountWithDueDays]);

	return (
		<Container maxWidth="md" className={classes.containerWidth}>
			<Keyframes name="fade" _0={{ opacity: 0 }} _100={{ opacity: 1 }} />
			<Typography variant="h5" className={classes.dashboardTitle}>
				Dashboard
			</Typography>
			<Snackbar
				open={!!snackBarSuccessMessage}
				autoHideDuration={6000}
				onClose={() => setSnackBarSuccessMessage('')}
			>
				<Alert severity="success" onClose={() => setSnackBarSuccessMessage('')} elevation={3}>
					{snackBarSuccessMessage}
				</Alert>
			</Snackbar>
			<LiiingoSuccessSnackbar
				open={passwordReset}
				text="Password Reset"
				onClose={() => {
					setPasswordReset(false);
					history.replace(`/${route}/dashboard`);
				}}
			/>
			<Grid container spacing={2} className={classes.topGrid}>
				<Grid item container lg={7} className={classes.fade}>
					<Grid item>
						<EditCard
							loading={loadingLocation || loadingTopic}
							location={selectedLocation}
							topic={selectedTopic}
							orgName={org?.name}
						/>
						<QRCard
							loading={loadingLocation || loadingTopic}
							qrLinkPath={`${domain_app_url}/topic/${selectedTopicId}`}
							primaryColor={primaryColor}
							secondaryColor={secondaryColor}
						/>
						<NotifyCard setSnackBarSuccessMessage={setSnackBarSuccessMessage} />
					</Grid>
				</Grid>
				<Grid item lg={5} className={classes.fade}>
					{org?.type === OrganizationType.GroupLeader && <InviteCard />}
					<AnalyticsCard locationId={selectedLocationId || ''} />
				</Grid>
			</Grid>
		</Container>
	);
};
