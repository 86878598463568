import React, { useState } from 'react';
import { LiteModal } from './LiteModal';
import InsertEmoticonIcon from '@material-ui/icons/InsertEmoticon';
import SentimentNeutralIcon from '@mui/icons-material/SentimentNeutral';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import { Box, IconButton, TextField, Typography, makeStyles } from '@material-ui/core';
import { colors } from '../../theme/palette';
import { FlatButton } from '../Buttons/FlatButton';
import { submitFeedback } from '../../store/api-client/redirect';

type FeedbackType = 'happy' | 'neutral' | 'sad';

const useStyles = makeStyles({
	box: {
		display: 'flex',
		flexDirection: 'column',
		paddingLeft: 36,
		paddingRight: 36,
	},
	heading: {
		fontSize: 20,
		fontWeight: 600,
		textAlign: 'center',
	},
	text: {
		fontSize: 16,
		paddingTop: 8,
		width: '95%',
		textAlign: 'center',
	},
	iconSize: {
		fontSize: '85px !important',
	},
	iconWrapper: {
		textAlign: 'center',
		paddingTop: 15,
		paddingBottom: 15,
	},
});

export type LiteFeedbackModalProps = {
	handleClose: () => void;
	open: boolean;
};

export const LiteFeedbackModal = (props: LiteFeedbackModalProps) => {
	const classes = useStyles();
	const { handleClose, open } = { ...props };
	const [reaction, setReaction] = useState<FeedbackType | null>(null);
	const [feedback, setFeedback] = useState('');

	const handleSendFeedback = async () => {
		// Send email with reaction and feedback
		if (reaction !== null || feedback !== '') {
			await submitFeedback(reaction, feedback);
		}
		handleClose();
	};

	return (
		<LiteModal
			open={open}
			handleClose={handleClose}
			title=""
			titleVariant="light"
			actions={
				<FlatButton variant="contained" color="primary" onClick={handleSendFeedback}>
					Send Feedback
				</FlatButton>
			}
		>
			<div className="BOX1 ">
				<Box className={classes.box}>
					<Typography className={classes.heading} variant="body2">
						{'We want to hear from you!'}
					</Typography>
					<Typography className={classes.text} variant="body2">
						{"Your feedback helps us improve your experience. Please tell us how we're doing."}
					</Typography>
				</Box>
				<div className={classes.iconWrapper}>
					{/* Happy Face */}
					<IconButton
						style={{ color: reaction === 'happy' ? colors.greenHighlighter : colors.grayLight }}
						onClick={() => setReaction('happy')}
					>
						<InsertEmoticonIcon className={classes.iconSize} />
					</IconButton>
					{/* Neutral Face */}
					<IconButton
						style={{ color: reaction === 'neutral' ? colors.orangeHighlighter : colors.grayLight }}
						onClick={() => setReaction('neutral')}
					>
						<SentimentNeutralIcon className={classes.iconSize} />
					</IconButton>
					{/* Sad Face */}
					<IconButton
						style={{ color: reaction === 'sad' ? colors.redDark : colors.grayLight }}
						onClick={() => setReaction('sad')}
					>
						<SentimentVeryDissatisfiedIcon className={classes.iconSize} />
					</IconButton>
				</div>
				<TextField
					className="border border-gray-300 rounded-md p-2"
					inputProps={{
						style: {
							height: '150px',
						},
					}}
					placeholder="Tell us more..."
					variant="outlined"
					fullWidth
					multiline={true}
					value={feedback}
					onChange={(e) => {
						setFeedback(e.target.value);
					}}
				/>
			</div>
		</LiteModal>
	);
};

export default LiteFeedbackModal;
