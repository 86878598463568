import Box from '@mui/material/Box';
import InputBase from '@material-ui/core/InputBase';
import ListItem from '@material-ui/core/ListItem';
import createStyles from '@material-ui/core/styles/createStyles';
import { Theme } from '@material-ui/core/styles/createTheme';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import Add from '@material-ui/icons/Add';
import { bindActionCreators } from '@reduxjs/toolkit';
import React, { useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { _plan } from '../../store/slices/editorSlice';
import { addSection } from '../../store/slices/sectionSlice';
import { _topics } from '../../store/slices/topicSlice';
import { colors } from '../../theme/palette';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		sectionAddLabel: {
			color: colors.grayLight,
			fontSize: 12,
			fontWeight: 'bold',
		},
		sectionAdd: {
			display: 'flex',
			width: '100%',
		},
		icon: {
			marginRight: 5,
		},
		subMenu: {
			justifyContent: 'space-between',
			backgroundColor: colors.grayDark5,
		},
		sectionName: {
			fontSize: 12,
			'&.Mui-focused': {
				backgroundColor: colors.pureWhite,
				borderRadius: 5,
				borderStyle: 'solid',
				borderColor: colors.blueAccent20,
				borderWidth: 0.5,
			},
			'&.Mui-disabled': {
				color: colors.pureBlack,
			},
		},
	})
);

export type SectionAddProps = {
	setStepKey: (stepKey: string) => void;
};

export const SectionAdd = (props: SectionAddProps) => {
	const { setStepKey } = { ...props };
	const classes = useStyles();

	const [visible, setVisible] = useState(false);
	const [disabled, setDisabled] = useState(true);
	const sectionName = useRef(null);
	const dispatch = useAppDispatch();
	const addNewSection = bindActionCreators(addSection, dispatch);

	const renameSection = () => {
		setDisabled(false);
		setTimeout(() => {
			sectionName.current.focus();
			sectionName.current.select();
		}, 1);
	};

	const handleClick = () => {
		setVisible(true);
		renameSection();
		setStepKey('name-section');
	};

	const topics = useAppSelector(_topics);
	const plan = useAppSelector(_plan);
	const [disableAddSection, setDisableAddSection] = useState(false);

	useEffect(() => {
		if (plan?.restrictions && plan.restrictions.pageLimit) {
			setDisableAddSection(Object.keys(topics).length >= plan.restrictions.pageLimit);
		}
	}, [plan, topics]);

	return (
		<>
			{visible && (
				<ListItem className={classes.subMenu}>
					<InputBase
						placeholder="Section Name"
						autoFocus
						data-tour="name-section"
						onDoubleClick={renameSection}
						disabled={disabled}
						inputRef={sectionName}
						fullWidth={true}
						className={classes.sectionName}
						defaultValue=""
						onBlur={(e) => {
							const name = e.target.value === '' ? 'New Section' : e.target.value;
							addNewSection(name);
							setVisible(false);
						}}
						onKeyPress={(e) => {
							if (e.key === 'Enter') {
								sectionName.current.blur();
							}
						}}
					/>
				</ListItem>
			)}
			<ListItem button disabled={disableAddSection} onClick={handleClick} className={classes.sectionAddLabel}>
				<Box className={classes.sectionAdd} data-tour="add-section">
					<Add fontSize="small" className={classes.icon} />
					<Typography className={classes.sectionAddLabel}>Add Section</Typography>
				</Box>
			</ListItem>
		</>
	);
};
