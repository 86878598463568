import { createSvgIcon } from '@material-ui/core';
import * as React from 'react';

const SvgQr = (props) => (
	<svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M0.5 7.16667H2.16667V8.83333H0.5V7.16667ZM7.16667 2.16667H8.83333V5.5H7.16667V2.16667ZM5.5 7.16667H8.83333V10.5H7.16667V8.83333H5.5V7.16667ZM10.5 7.16667H12.1667V8.83333H13.8333V7.16667H15.5V8.83333H13.8333V10.5H15.5V13.8333H13.8333V15.5H12.1667V13.8333H8.83333V15.5H7.16667V12.1667H10.5V10.5H12.1667V8.83333H10.5V7.16667ZM13.8333 13.8333V10.5H12.1667V13.8333H13.8333ZM10.5 0.5H15.5V5.5H10.5V0.5ZM12.1667 2.16667V3.83333H13.8333V2.16667H12.1667ZM0.5 0.5H5.5V5.5H0.5V0.5ZM2.16667 2.16667V3.83333H3.83333V2.16667H2.16667ZM0.5 10.5H5.5V15.5H0.5V10.5ZM2.16667 12.1667V13.8333H3.83333V12.1667H2.16667Z"
			fill="#92A0AC"
		/>
	</svg>
);

const Icon = createSvgIcon(
	<path d="M0.5 7.16667H2.16667V8.83333H0.5V7.16667ZM7.16667 2.16667H8.83333V5.5H7.16667V2.16667ZM5.5 7.16667H8.83333V10.5H7.16667V8.83333H5.5V7.16667ZM10.5 7.16667H12.1667V8.83333H13.8333V7.16667H15.5V8.83333H13.8333V10.5H15.5V13.8333H13.8333V15.5H12.1667V13.8333H8.83333V15.5H7.16667V12.1667H10.5V10.5H12.1667V8.83333H10.5V7.16667ZM13.8333 13.8333V10.5H12.1667V13.8333H13.8333ZM10.5 0.5H15.5V5.5H10.5V0.5ZM12.1667 2.16667V3.83333H13.8333V2.16667H12.1667ZM0.5 0.5H5.5V5.5H0.5V0.5ZM2.16667 2.16667V3.83333H3.83333V2.16667H2.16667ZM0.5 10.5H5.5V15.5H0.5V10.5ZM2.16667 12.1667V13.8333H3.83333V12.1667H2.16667Z" />,
	'Qr'
);

export const QrIcon = (props) => <Icon {...props} viewBox="-4 -4 24 24" />;

export default SvgQr;
