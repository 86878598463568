import { useEffect, useState } from 'react';

export const useIsDev = () => {
	const [isDev, setIsDev] = useState(false);

	useEffect(() => {
		setIsDev(
			window.location.hostname.includes('dev-start.liiingo.com') || window.location.hostname.includes('localhost')
		);
	}, []);

	return isDev;
};
