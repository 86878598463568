import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ObjectID } from 'bson';
import toast from 'react-hot-toast';
import { TextInitialValue } from '../../components/ElementMenu/InsertTypographyOption';
import { colors } from '../../theme/palette';
import * as api from '../api-client';
import { Content, FileLanguage, FileType, Language } from '../models';
import { newContent as _newContent } from '../models/file/FileContent';
import { newFileLanguage } from '../models/file/FileLanguage';
import { AppState } from '../store';
import { addSingleLanguageElement, focusedItemName } from './editorSlice';
import { topicContentAdd } from './topicSlice';
export interface ContentMap {
	[id: string]: Content;
}
export interface ContentState {
	contents: ContentMap;
	activeContentIds: string[]; //for maintaining order and subsets per section
	selectedContentId: string;
	isLoading: boolean;
}

const initContentState: ContentState = {
	contents: {},
	activeContentIds: [],
	selectedContentId: null,
	isLoading: false,
};

export type ContentReorderPayload = {
	oldIndex: number;
	newIndex: number;
};

// Function to delete contents.
// Varaible input param type, either an id or id array
export const contentDelete = createAsyncThunk<string[], string[] | string, { state: AppState }>(
	'content/contentDelete',
	async (deleteContentIds: string[] | string, { getState, dispatch }) => {
		if (typeof deleteContentIds == 'string') {
			deleteContentIds = [deleteContentIds];
		}
		deleteContentIds.forEach((contentId) => api.deleteContent(contentId));
		// To reduce code complexity, I'm leaving any deleted ids in the "newLanguageIds" list located in the editor slice.
		// That list is used as a lookup table, leaving them won't affect anything. Not persisted across refresh.
		const newList: string[] = [...deleteContentIds]; // for typescript
		return newList;
	}
);
export interface duplicateContentPayload {
	index: number;
	content: Content;
}

// make a clone of the content, assign a new contentId, add it to the store, save everything
export const addDuplicateContent = createAsyncThunk<Content, duplicateContentPayload, { state: AppState }>(
	'content/addDuplicateContent',
	async (payload: duplicateContentPayload, { getState, dispatch }) => {
		let { content, index } = payload;
		let clonedContent = structuredClone(content);
		clonedContent._id = new ObjectID().toHexString();
		clonedContent.id = clonedContent._id;
		clonedContent.createNewEntity = true;
		clonedContent = addContentHelper(getState, dispatch, clonedContent, index);
		return clonedContent;
	}
);

export interface addContentPayload {
	index: number;
	languages: Language[];
	contentType: FileType;
	tag?: string;
	contentLanguage: Partial<FileLanguage>;
}

// This action can be used for adding new content
export const addNewContent = createAsyncThunk<Content, addContentPayload, { state: AppState }>(
	'content/addNewContent',
	async (payload: addContentPayload, { getState, dispatch }) => {
		let { contentType, tag, languages, index, contentLanguage } = payload;
		const { locations, selectedLocationId } = getState().location;
		const organizationId = locations[selectedLocationId].organizationId;
		let content = createNewContent(languages, contentType, organizationId, tag, contentLanguage);
		content = addContentHelper(getState, dispatch, content, index);
		return content;
	}
);

//This method is used for the addNewContent and addDuplicateContent actions. Shared logic was extracted to this helper method.
const addContentHelper = async (getState, dispatch, content, index) => {
	const { selectedTopicId } = getState().topic; // This means that we are always adding new content to the selected Topic. This is correct today, but may not be in the future.

	if (!content.assignedTopics?.includes(selectedTopicId)) {
		content.assignedTopics.push(selectedTopicId); //this isn't modifying state, so it's okay to do this here
	}
	let languages = content.languages.map((lang) => lang.language);
	const activeLanguageCode = getState().editor.activeLanguageCode;
	// filter out the activelanguage code
	languages = languages.filter((lang) => lang !== activeLanguageCode);
	languages.forEach((language) => {
		// each new translation 50% opaque
		dispatch(addSingleLanguageElement({ language: language, id: content._id }));
	});

	dispatch(addContentToMap(content));
	await dispatch(topicContentAdd({ id: content._id, index: index })); //add to the topic's ordered content list
	dispatch(selectContentIds(getState().topic.topics[selectedTopicId].content)); //activeContentIds = topic.content (ordered content id list)
	dispatch(selectContent(content._id));
	dispatch(focusedItemName(content._id)); //TODO: do we really need this as another state variable? Could it be replaced with selectedContentId?
	return content;
};

// NETWORK CALLS

export interface SaveContentPayload extends FileLanguage {
	id: string;
}

const contentSlice = createSlice({
	name: 'content',
	initialState: initContentState,
	reducers: {
		selectContentIds(state: ContentState, action: PayloadAction<string[]>) {
			state.activeContentIds = action.payload;
		},
		removeLanguagesFromContent(state: ContentState, action: PayloadAction<Language[]>) {
			const languagesToRemove = action.payload;
			//do work across all content
			Object.values(state.contents).forEach((content) => {
				// filter out languages that are in the list of languages to remove
				content.languages = content.languages.filter((item) => !languagesToRemove.includes(item.language));
			});
		},
		updateContent(state: ContentState, action: PayloadAction<{ id: string; content: Content }>) {
			state.contents[action.payload?.id] = action.payload.content;
		},
		addLanguagesToContent(
			state: ContentState,
			action: PayloadAction<{ languages: Language[]; languageToCopy: Language }>
		) {
			const { languages, languageToCopy } = action.payload;
			//add languages to all content items
			Object.values(state.contents).forEach((content) => {
				// If the language we're adding already exists, remove it. Happens when data is corrupt.
				content.languages = content.languages.filter((item) => !languages.includes(item.language));
				// Clone the default language for each new language that's been added.
				const translationToCopy = content.languages.find(
					(translation) => translation.language === languageToCopy
				); // I'm calling them translations here. The items in the content.languages array are "translations" here.
				languages.forEach((lang) => {
					let newTranslation;
					// if the default translation has a file name, create a unique file name for the new translation
					// TODO: seems like we also need to include a Blob when saving to backend, in order to make a unique file to match the name
					if (!!translationToCopy.fileName) {
						newTranslation = {
							...translationToCopy,
							language: lang,
							fileName: `${lang}+${translationToCopy.fileName}`,
						};
					} else {
						newTranslation = { ...translationToCopy, language: lang };
					}
					content.languages.push(newTranslation);
				});
			});
		},
		contentReorder(state: ContentState, action: PayloadAction<ContentReorderPayload>) {
			const contentToReorder = state.activeContentIds[action.payload.oldIndex];
			state.activeContentIds.splice(action.payload.oldIndex, 1);
			state.activeContentIds.splice(action.payload.newIndex, 0, contentToReorder);
		},
		selectContent(state: ContentState, action: PayloadAction<string>) {
			state.selectedContentId = action.payload;
		},
		addContentToMap(state: ContentState, action: PayloadAction<Content>) {
			state.contents[action.payload._id] = action.payload;
		},
		contentChanged(state: ContentState, action: PayloadAction<Partial<SaveContentPayload>>) {
			const { contents } = state;
			let { id, ...updates } = action.payload;
			console.log(action.payload);
			if (!updates.language) {
				//if language code isn't included in the Partial<FileLanguage> object, we don't know what language it is.
				toast.error('Language-code not provided. Content has not been changed');
				return; //don't do anything
			}
			if (!id) {
				//if no content id was provided, we can't be sure what to change.
				toast.error('No content id provided during update. No changes applied');
				return; //don't do anything
			}

			let content: Content = contents[id];
			let contentType = content.type;
			let translations: FileLanguage[] = content.languages;

			// helper function to change file name
			// e.g fr_video.mp4 -> sp_video.mp4, or video.mp4 -> sp_video.mp4
			// TODO: what about edge case: video_1.mp4 -> sp_1.mp4?
			const changeName = (name: string, language: string) => {
				let namePart = name.substring(name.indexOf('_') + 1);
				return `${language}_${namePart}`;
			};

			// When a new image/video content item is added and an initial image/video is selected, all other translations need to have the same image/video applied.
			// So... lets loop over all translations, update the one that needs updated, and look for any others that don't have a fileUrl.
			// If we find one, we need to assign it a fileUrl. We'll use the fileUrl that was just picked in the UI, passed in as "updates.fileUrl", a bolb url.
			// Only do this for images and image buttons, right? No, videos with an empty 'content' property AND an empty 'fileUrl' will need a copy of the blob url in the fileUrl
			// Videos with a JWplayer Id in the 'content' field should not have a 'fileUrl'.
			// (for videos, during the call to save to network, the 'fileUrl' blob will be uploaded to JWPlayer, a JWPlayer Id will be assigned to the 'content" prop, and the 'fileUrl' will be nullified.)
			let newTranslations = translations.map((t) => {
				// merge updates in with the existing translation that matches on language. Short circuit the iteration
				if (t.language === updates.language) {
					return { ...t, ...updates };
				}

				//if the translation has a fileUrl, we don't need to do anything. Short circuit the iteration
				if (!!t.fileUrl) {
					return t;
				}

				// after this line, a missing 'fileUrl' is implied.

				// if the content type is a webview, it could be a button or an image button. Image buttons have a fileUrl, regular buttons do not.
				// So, although we want to autofill for image buttons, we can't distinguish between an image button with a missing fileUrl and a regular button.
				// update: new image button content is given a type of 'imagebtn', but it will not be persisted in the backend as such (doeesn't work with mobile app).
				// so, we can safely say that an 'imagebtn' is new, and if it's missing the fileUrl for any translations, it should be populated.
				// we're going to use the fileUrl passed in with this update. Scenario: when new content is made and an image is chosen for the first time
				if (contentType === 'webview') {
					// <- remember, this is a regular button or an image button already saved/retrieved from the backend. No need to check for missing fileUrl here. Short circuit the iteration
					return t;
				}

				// if it's missing the fileUrl and the content type is video but has 'content' (JWPlayer ID), we don't need to do anything. Short circuit the iteration
				if (contentType === 'video' && !!t.content) {
					return t;
				}

				// if it's missing the fileUrl and the content type is video (w/ no content field) or image, we need to assign it a fileUrl.
				if (contentType === 'video' || contentType === 'image' || contentType === 'imageBtn') {
					if (updates.fileUrl?.startsWith('blob:')) {
						t.fileUrl = updates.fileUrl;
						t.fileName = changeName(updates.fileName, t.language);
					}
				}
				return t;
			});
			state.contents[id].languages = newTranslations;
		},
		fetchAllContents(state: ContentState, action: PayloadAction<Content[]>) {
			const contents = action.payload;
			contents.forEach((content) => (state.contents[content._id] = content));
		},
	},
	extraReducers: (builder) => {
		builder
			// .addCase(fetchAllContents.fulfilled, (state, action) => {
			// 	state.isLoading = false;
			// 	const contents = action.payload;
			// 	contents.forEach((content) => (state.contents[content._id] = content));
			// })
			.addCase(addNewContent.fulfilled, (state, action: PayloadAction<Content>) => {
				return; // not using the returned payload, already set this content in the ContentMap via another dispatch event, in order to make things work in the proper order
			})
			.addCase(contentDelete.fulfilled, (state, action) => {
				const deleteContents = action.payload;
				//set selected to null if in delete list
				if (deleteContents.includes(state.selectedContentId)) state.selectedContentId = null;
				// filter out items in delete list form the active list
				state.activeContentIds = state.activeContentIds.filter((active) => !deleteContents.includes(active));
				// delete from ContentMap
				deleteContents.forEach((contentId) => delete state.contents[contentId]);
			});
	},
});

// Export reducer
export default contentSlice.reducer;

// Export actions
export const {
	addContentToMap,
	contentReorder,
	selectContent,
	contentChanged,
	selectContentIds,
	addLanguagesToContent,
	removeLanguagesFromContent,
	updateContent,
	fetchAllContents,
} = contentSlice.actions;

// Export selectors
export const _contents = (state) => state.content.contents;
export const _selectedContentId = (state) => state.content.selectedContentId;
export const _activeContentIds = (state) => state.content.activeContentIds;
export const _selectedContent = (state) => state.content.contents[state.content.selectedContentId];
export const _contentIsLoading = (state) => state.content.isLoading;

// Helper method for addNewContent Thunk
export const createNewContent = (
	supportedLanguages: string[],
	contentType: FileType,
	organizationId: string,
	tag?: string,
	contentLanguage?: Partial<FileLanguage>
) => {
	let languages: FileLanguage[] = [];

	supportedLanguages.forEach((code) => {
		let newLanguage = newFileLanguage({
			language: code as Language,
			title: contentType === 'webview' ? `Button` : `New ${contentType}`,
			content: !!tag ? `<${tag}>${TextInitialValue[tag]}</${tag}>` : contentType === 'webview' ? `Button` : null,
			description: '',
			fileName: `${code}_placeholder`,
			fileUrl:
				contentType === 'imageBtn'
					? 'https://storage.googleapis.com/liii/contentImages/7feb83f6318742501897322fa7ab212aMwce72KtHRrrJ3SAMNsA.png'
					: '',
			fileData: null,
			url: contentType === 'webview' || contentType === 'imageBtn' ? `https://www.liiingo.com` : ``,
			background: colors.btnBack,
			justify: 'center',
		});
		languages.push(newFileLanguage({ ...newLanguage, ...contentLanguage })); //overwrite with passed in params
	});

	const newContent = _newContent({
		_id: new ObjectID().toHexString(),
		createdDate: null,
		modifiedDate: null,
		status: 1,
		type: contentType,
		languages: languages,
		assignedTopics: [],
		folderId: '',
		organizationId: organizationId,
		isShared: false,
		readOnly: false,
	});

	newContent.createNewEntity = true;

	return newContent;
};
