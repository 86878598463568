import { LiiingoWalkthroughStep } from './type';

export const ManagingElementsSteps: LiiingoWalkthroughStep[] = [
	{
		key: 'right-drawer',
		selector: '[data-tour="right-drawer"]',
		title: 'Design Toolbox',
		message:
			'When an element in your Mobile Preview is selected, the Design Toolbox appears with tools to help you customize it further.',
		buttonText: 'Next',
		position: 'left',
		width: 376,
		arrowPosition: 'right',
		arrowPlacement: 10,
	},
	{
		key: 'organize',
		selector: '[data-tour="mobile-preview"]',
		title: 'Organizing Your Elements',
		message: 'Click & drag content in your Mobile Preview to rearrange the layout. Give it a try!',
		buttonText: 'Skip',
		position: 'left',
		width: 374,
		arrowPosition: 'right',
		arrowPlacement: 15,
		skipTo: 2,
	},
	{
		key: 'end',
		selector: '[data-tour="end"]',
		title: 'Mission Accomplished!',
		message: "Time to start creating your masterpiece. We can't wait to see how you design your app!",
		buttonText: 'Done',
		width: 379,
		position: [],
	},
];
