import { createSvgIcon } from '@material-ui/core';
import * as React from 'react';

const SvgLink = () => (
	<svg width={50} height={45} viewBox="0 0 50 45" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M38 10.2H28V14.95H38C42.275 14.95 45.75 18.425 45.75 22.7C45.75 26.275 43.3 29.275 39.975 30.2L43.625 33.8C47.7 31.725 50.5 27.575 50.5 22.7C50.5 19.3847 49.183 16.2053 46.8388 13.8611C44.4946 11.5169 41.3152 10.2 38 10.2V10.2ZM35.5 20.2H30.025L35.025 25.2H35.5V20.2ZM0.5 3.37495L8.275 11.15C5.97797 12.0846 4.01154 13.6825 2.62672 15.7397C1.24189 17.7969 0.50148 20.2201 0.5 22.7C0.5 26.0152 1.81696 29.1946 4.16117 31.5388C6.50537 33.883 9.68479 35.2 13 35.2H23V30.45H13C8.725 30.45 5.25 26.975 5.25 22.7C5.25 18.725 8.275 15.45 12.15 15.025L17.325 20.2H15.5V25.2H22.325L28 30.875V35.2H32.325L42.35 45.2L45.5 42.05L3.675 0.199951L0.5 3.37495Z"
			fill="#92A0AC"
		/>
	</svg>
);

const Icon = createSvgIcon(
	<path
		d="M38 10.2H28V14.95H38C42.275 14.95 45.75 18.425 45.75 22.7C45.75 26.275 43.3 29.275 39.975 30.2L43.625 33.8C47.7 31.725 50.5 27.575 50.5 22.7C50.5 19.3847 49.183 16.2053 46.8388 13.8611C44.4946 11.5169 41.3152 10.2 38 10.2V10.2ZM35.5 20.2H30.025L35.025 25.2H35.5V20.2ZM0.5 3.37495L8.275 11.15C5.97797 12.0846 4.01154 13.6825 2.62672 15.7397C1.24189 17.7969 0.50148 20.2201 0.5 22.7C0.5 26.0152 1.81696 29.1946 4.16117 31.5388C6.50537 33.883 9.68479 35.2 13 35.2H23V30.45H13C8.725 30.45 5.25 26.975 5.25 22.7C5.25 18.725 8.275 15.45 12.15 15.025L17.325 20.2H15.5V25.2H22.325L28 30.875V35.2H32.325L42.35 45.2L45.5 42.05L3.675 0.199951L0.5 3.37495Z"
		fill="#92A0AC"
	/>,
	'Link'
);

export const LinkIcon = () => <Icon viewBox="0 0 50 45" />;

export default SvgLink;
