import Crop from '@material-ui/icons/Crop';
import SyncOutlinedIcon from '@material-ui/icons/SyncOutlined';
import { ReactNode, useEffect, useState } from 'react';
import { ACCEPTABLE_IMAGE_MIME_TYPES } from '../../util/constants';
import CropModel from '../AppEditor/CropModel';
import { AltText } from './AltText';
import { ImageOption } from './ImageOption';
import { Option } from './Option';
import { OptionGroup } from './OptionGroup';

export type ImageMenuProps = {
	setImagePreview: (file: File) => void;
	imageUrl?: string;
	name?: string;
	languageCode?: string;
	type?: string;
	size?: string;
	children: ReactNode;
	toCropData: (imageUrl: string, fileName: string) => void;
};

export const ImageMenu = (props: ImageMenuProps) => {
	const { setImagePreview, imageUrl, name, languageCode, type, size, children, toCropData } = { ...props };
	const [openDrawer, setOpenDrawer] = useState(false);
	const [image, setImage] = useState(imageUrl);
	const [imgType, setImgType] = useState('');

	useEffect(() => {
		setImage(imageUrl);

		fetch(imageUrl)
			.then((res) => res.blob()) // Gets the response and returns it as a blob
			.then((blob) => {
				setImgType(blob.type);
			});
	}, [imageUrl, name]);

	const onChange = (e: any) => {
		e.preventDefault();
		let files;
		if (e.dataTransfer) {
			files = e.dataTransfer.files;
		} else if (e.target) {
			files = e.target.files;
		}

		const reader = new FileReader();
		reader.onload = () => {
			setImage(reader.result as any);

			if (files[0].type !== 'image/gif') setOpenDrawer(true);
			else toCropData(URL.createObjectURL(files[0]), files[0].name);
		};
		reader.readAsDataURL(files[0]);
	};

	const setCropImage = (url, fileName) => {
		toCropData(url, fileName);
	};

	const [isDrag, setIsDrag] = useState(false);
	const [isOver, setIsOver] = useState(false);
	const [isValidType, setIsValidType] = useState(false);

	const handleDragEnter = (e) => {
		const fileType = e.dataTransfer.items[0]?.type;
		if (ACCEPTABLE_IMAGE_MIME_TYPES.includes(fileType)) {
			setIsValidType(true);
		} else {
			setIsValidType(false);
		}
	};

	const handleDragStart = () => {
		if (!isDrag) {
			setIsDrag(true);
		}
	};

	const handleDragStop = () => {
		if (isDrag) {
			setIsDrag(false);
		}
	};

	const handleDragIn = (e) => {
		setIsOver(true);
	};

	const handleDrop = (e) => {
		setIsOver(false);
	};

	const handleDragOut = (e) => {
		setIsOver(false);
	};

	const handleDrag = (e) => {
		e.preventDefault();
		e.stopPropagation();
	};

	window.addEventListener('dragenter', handleDragEnter, false);
	window.addEventListener('dragover', handleDragStart, false);
	window.addEventListener('dragleave', handleDragStop, false);
	window.addEventListener('drop', handleDragStop, false);

	return (
		<div>
			<OptionGroup title="Preview">
				<div onDragEnter={handleDragIn} onDragLeave={handleDragOut} onDragOver={handleDrag} onDrop={handleDrop}>
					<ImageOption
						imageUrl={imageUrl}
						setImagePreview={setImagePreview}
						isOver={isOver}
						isDrag={isDrag}
						isValidType={isValidType}
					/>
				</div>
			</OptionGroup>
			<CropModel open={openDrawer} setOpen={setOpenDrawer} imageUrl={image} sendCropData={setCropImage} />
			<OptionGroup title="Actions">
				<Option
					icon={Crop}
					label="Crop"
					disabled={imgType === 'image/gif' || imgType === 'gif' ? true : false}
					clickable={true}
					onClick={() => setOpenDrawer(true)}
				/>
				<label htmlFor="upload-file">
					<Option icon={SyncOutlinedIcon} label="Replace" clickable={true} />
				</label>
				<input
					type="file"
					onChange={onChange}
					id="upload-file"
					style={{ display: 'none' }}
					accept={ACCEPTABLE_IMAGE_MIME_TYPES.join(',')}
				/>
				{children}
			</OptionGroup>
			<AltText />
			{(name || languageCode || type || size) && (
				<OptionGroup title="File Details">
					{name && <Option subtitle="Name" label={name.substring(name.indexOf('_') + 1)} />}
					{/* {languageCode && <Option subtitle="Language" label={LANGUAGE_MAP[languageCode]} />} */}
					{type && <Option subtitle="Type" label={type} />}
					{size && <Option subtitle="Size" label={size} />}
				</OptionGroup>
			)}
			<OptionGroup title="File Limitations">
				<Option subtitle="JPG, PNG, GIF" label="20MB Max" />
			</OptionGroup>
		</div>
	);
};
