import { useState, useEffect } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Theme } from '@material-ui/core/styles/createTheme';
import { colors } from '../../../theme/palette';
import { MAIL_VALIDATION_REGEX } from '../../../util/constants';

const useStyles = makeStyles<Theme, { backgroundColor?: string; color?: string }>({
	emailItem: (props) => ({
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		padding: '2px 10px',
		position: 'static',
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
		background: props.backgroundColor,
		borderRadius: 100,
		flex: 'none',
		order: 0,
		flexGrow: 0,
		margin: '0px 0px',
		color: props.color,
		height: 'fit-content',
		cursor: 'pointer',
	}),
});

interface EmailItemProps {
	email: string;
	selected: boolean;
	backCnt: number;
}
export const EmailItem = (props: EmailItemProps) => {
	const { email, selected, backCnt } = props;
	const [status, setStatus] = useState(0);
	let styling: { backgroundColor: string; color: string };

	useEffect(() => {
		if (selected) {
			if (backCnt % 2 === 1) {
				setStatus(3);
			} else {
				setStatus(2);
			}
		} else {
			if (email.match(MAIL_VALIDATION_REGEX)) {
				setStatus(0);
			} else {
				setStatus(1);
			}
		}
	}, [selected, backCnt, email]);

	switch (
		status // 0:normal ,1: invalid, 2: selected, 3: del Selected
	) {
		case 0:
			styling = {
				backgroundColor: 'linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), #92A0AC',
				color: colors.navyAccent,
			};
			break;
		case 1:
			styling = {
				backgroundColor: 'linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), #DC4C4C',
				color: colors.redDark,
			};
			break;
		case 2:
			styling = {
				backgroundColor: colors.navyAccent,
				color: colors.pureWhite,
			};
			break;
		case 3:
			styling = {
				backgroundColor: colors.redDark,
				color: colors.pureWhite,
			};
			break;
		default:
			break;
	}

	const classes = useStyles(styling);

	return <span className={classes.emailItem}>{email}</span>;
};
