// QRGeneratorCardWrapper >> QRGeneratorTable >> QRGeneratorTableIcons >> LiteEditQRModal >> LiteForm
// Parent to this component is LiteDashboard.tsx
// This will hold the entire QR Code Generator Card
// needs top content container & QRGeneratorTable
import { Box, Card, CardContent, Theme, Typography, createStyles, makeStyles } from '@material-ui/core';
import { useState } from 'react';
import { colors } from '../../theme/palette';
import { FlatButton } from '../Buttons';
import LiteCreateQRModal from './LiteCreateQRModal';
import QRGeneratorTable from './QRGeneratorTable';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		formCard: {
			marginBottom: 20,
			width: 700,
			height: 'auto',
			backgroundColor: theme.palette.background.paper,
			position: 'relative',
		},
		titleBox: {
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'baseline',
			marginBottom: 20,
		},
		headerText: {
			color: colors.grayDark,
			display: 'grid',
			fontSize: 20,
		},
		descriptionText: {
			fontSize: 16,
			color: colors.grayLight,
			justifyContent: 'center',
			paddingTop: 8,
		},
	})
);

export type QRGeneratorCardWrapperProps = {
	// Props go here
};

const QRGeneratorCardWrapper = (props: QRGeneratorCardWrapperProps) => {
	const [open, setOpen] = useState(false);
	const classes = useStyles();

	// Methods, functions, useEffects, useState ect go here

	return (
		<div>
			<Card variant="outlined" className={classes.formCard}>
				<CardContent>
					<Box className={classes.titleBox}>
						<Typography className={classes.headerText} variant="h5">
							{'QR Code Generator'}
							<div className={classes.descriptionText}>
								{'Effortlessly generate and manage your dynamic QR codes.'}
							</div>
						</Typography>
						<FlatButton variant="contained" onClick={() => setOpen(true)} color="secondary">
							{'Create'}
						</FlatButton>
					</Box>
					{open && <LiteCreateQRModal open={open} handleClose={() => setOpen(false)} />}
					<QRGeneratorTable />
				</CardContent>
			</Card>
		</div>
	);
};

export default QRGeneratorCardWrapper;
