import Box from '@material-ui/core/Box';
import Drawer from '@material-ui/core/Drawer';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import createStyles from '@material-ui/core/styles/createStyles';
import { Theme } from '@material-ui/core/styles/createTheme';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';
import CallSplitOutlinedIcon from '@material-ui/icons/CallSplitOutlined';
import ControlPointOutlinedIcon from '@material-ui/icons/ControlPointOutlined';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import LinkOutlinedIcon from '@material-ui/icons/LinkOutlined';
import PaletteOutlinedIcon from '@material-ui/icons/PaletteOutlined';
import ShareOutlinedIcon from '@material-ui/icons/ShareOutlined';
import React, { useEffect } from 'react';
import CreateLogo from '../../theme/getstarted_create.svg';
import ImproveLogo from '../../theme/getstarted_improve.svg';
import ShareLogo from '../../theme/getstarted_share.svg';
import { MultiLingIcon } from '../SvgComponents/SvgMultiLing';
import { QrIcon } from '../SvgComponents/SvgQr';
import {
	EmptyDivider,
	StyledAccordion,
	StyledAccordionDetails,
	StyledAccordionSummary,
	TitleTipGroup,
} from './Accordions';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { colors } from '../../theme/palette';
import { FlatButton } from '../Buttons/FlatButton';
// import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			'& .MuiDrawer-paperAnchorRight': {
				padding: '20px 5px 20px 0px',
			},
			'& ::-webkit-scrollbar': {
				width: '5px !important',
				height: '6px',
				background: '#dbdcdf',
			},
			'& ::-webkit-scrollbar-track': {
				borderRadius: '0px',
				background: 'white',
			},
			'& ::-webkit-scrollbar-thumb': {
				background: '#dbdcdf',
				borderRadius: '3px',
			},
		},
		content: {
			display: 'flex',
			justifyContent: 'space-between',
			flexDirection: 'column',
			width: 590,
			height: '100%',
			overflowX: 'hidden',
		},
		accordionHeading: {
			fontSize: theme.typography.pxToRem(15),
			fontWeight: 400,
			color: colors.neutralDark,
			paddingLeft: '10px',
		},
		accordionText: {
			fontSize: theme.typography.pxToRem(15),
			fontWeight: 500,
			paddingLeft: '30px',
			lineHeight: 1.5,
			color: colors.neutralDark,
		},
		linkText: {
			color: '#295AFA',
			textDecoration: 'underline',
			fontWeight: 600,
		},
		strongText: {
			fontWeight: 700,
		},
		titleRoot: {
			paddingRight: '20px',
		},
		titleBox: {
			width: '100%',
			display: 'flex',
			justifyContent: 'space-between',
			margin: '0px 0px 5px 20px',
		},
		title: {
			color: colors.grayDark,
			fontWeight: 600,
		},
		closeButton: {
			color: colors.grayLight,
			marginRight: '15px',
		},
		buttonBox: {
			display: 'flex',
			justifyContent: 'flex-end',
			alignItems: 'center',
			height: 80,
		},
		button: {
			height: 40,
			marginRight: 10,
		},
		headerText: {
			margin: '0px 0px 10px 20px',
			color: colors.neutralDark,
			fontSize: '16px',
			width: '550px',
		},
		iconColor: {
			color: colors.grayDark,
		},
		highlightRow: {
			background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.95)), #92A0AC',
		},
	})
);

type AnchorOptions = 'left' | 'right' | 'top' | 'bottom';

const defaultProps = {
	anchor: 'right' as AnchorOptions,
	onClose: () => {},
};

export type GetStartedPanelDrawerProps = {
	open: boolean;
	setOpen: (open: boolean) => void;
	title: string;
	anchor?: AnchorOptions;
	onClose?: () => void;
};

const GetStartedPanelDrawer: React.FC<GetStartedPanelDrawerProps> = (props) => {
	const { children, anchor, title, open, onClose, setOpen } = { ...defaultProps, ...props };
	const classes = useStyles();

	const handleClose = () => {
		onClose();
		setOpen(false);
	};

	return (
		<Drawer
			variant="temporary"
			elevation={0}
			anchor={anchor}
			open={open}
			onClose={handleClose}
			transitionDuration={{ appear: 500, enter: 500, exit: 500 }}
			disableEnforceFocus
			className={classes.root}
		>
			<Box className={classes.titleRoot}>
				<Box className={classes.titleBox}>
					<Typography className={classes.title} variant="h5">
						{title}
					</Typography>
					<IconButton className={classes.closeButton} size="small" onClick={handleClose}>
						<CloseIcon />
					</IconButton>
				</Box>
				<Box>
					<Typography variant="body2" className={classes.headerText}>
						Check out some quick tips on how to get started using Liiingo. Find additional information in
						our{' '}
						<Link href="https://support.liiingo.com/" target="_blank" className={classes.linkText}>
							Help Center
						</Link>
						.
					</Typography>
				</Box>
			</Box>
			{children}
			<Box className={classes.buttonBox}>
				<FlatButton
					className={classes.button}
					variant="contained"
					color="primary"
					onClick={() => {
						setOpen(false);
					}}
				>
					Done
				</FlatButton>
			</Box>
		</Drawer>
	);
};

export type GetStartedPanelProps = {
	open: boolean;
	setOpen: (open: boolean) => void;
};

export const GetStartedPanel = (props: GetStartedPanelProps) => {
	const { open, setOpen } = { ...props };
	const classes = useStyles();
	const [expanded, setExpanded] = React.useState<string | false>(false);

	const handleHelpLinks = (url: string) => {
		// GOOGLE ANALYTICS
		// ReactGA.event({
		// 	category: 'help_link',
		// 	action: 'help_link_clicked',
		// 	label: url, // optional
		// });
	};

	//run once on load
	useEffect(() => {
		// GOOGLE ANALYTICS
		// ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search });
	}, []);

	const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, expand: boolean) => {
		if (expand) {
			setExpanded(panel);
			// Send a custom event
			// ReactGA.event({
			// 	category: 'accordian',
			// 	action: 'panel_expanded',
			// 	label: panel, // optional
			// });
		} else {
			setExpanded(false);
		}
	};

	return (
		<GetStartedPanelDrawer open={open} setOpen={setOpen} title={'Get Started with Liiingo'}>
			<Box className={classes.content}>
				<Box>
					<Box>
						<TitleTipGroup
							logo={CreateLogo}
							title="Create"
							desc="Create content tailored to your audience"
						/>
						<StyledAccordion
							expanded={expanded === 'panel1'}
							onChange={handleChange('panel1')}
							className={classes.highlightRow}
						>
							<StyledAccordionSummary
								expandIcon={<ArrowDropDownIcon />}
								aria-controls="panel1a-content"
								id="panel1a-header"
							>
								<InsertDriveFileOutlinedIcon fontSize="small" className={classes.iconColor} />
								<Typography className={classes.accordionHeading}>
									Organize the overall layout with{' '}
									<span className={classes.strongText}>sections & pages</span>.
								</Typography>
							</StyledAccordionSummary>
							<StyledAccordionDetails>
								<Typography className={classes.accordionText}>
									Group similar pages in sections to help customers find what they need. Learn more
									about about{' '}
									<Link
										href="https://support.liiingo.com/question/pages-and-sections"
										target="_blank"
										className={classes.linkText}
										onClick={() => {
											handleHelpLinks('https://support.liiingo.com/question/pages-and-sections');
										}}
									>
										managing sections and pages
									</Link>{' '}
									in our Help Center.
								</Typography>
							</StyledAccordionDetails>
						</StyledAccordion>
						<StyledAccordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
							<StyledAccordionSummary
								expandIcon={<ArrowDropDownIcon />}
								aria-controls="panel2a-content"
								id="panel2a-header"
							>
								<ControlPointOutlinedIcon fontSize="small" className={classes.iconColor} />
								<Typography className={classes.accordionHeading}>
									<span className={classes.strongText}>Add content</span> by dragging elements in from
									the sidebar.
								</Typography>
							</StyledAccordionSummary>
							<StyledAccordionDetails>
								<Typography className={classes.accordionText}>
									Elements are the building blocks used to create content. Create content for your
									audience using text, images, videos and more! Learn more about{' '}
									<Link
										href="https://support.liiingo.com/question-category/content"
										target="_blank"
										className={classes.linkText}
										onClick={() => {
											handleHelpLinks('https://support.liiingo.com/question-category/content');
										}}
									>
										managing content
									</Link>{' '}
									in our Help Center.
								</Typography>
							</StyledAccordionDetails>
						</StyledAccordion>
						<StyledAccordion
							expanded={expanded === 'panel3'}
							onChange={handleChange('panel3')}
							className={classes.highlightRow}
						>
							<StyledAccordionSummary
								expandIcon={<ArrowDropDownIcon />}
								aria-controls="panel2a-content"
								id="panel2a-header"
							>
								<MultiLingIcon fontSize="small" className={classes.iconColor} />
								<Typography className={classes.accordionHeading}>
									Translate your content into{' '}
									<span className={classes.strongText}>multiple languages</span>.
								</Typography>
							</StyledAccordionSummary>
							<StyledAccordionDetails>
								<Typography className={classes.accordionText}>
									Reach new audiences by creating content in your customer’s preferred language. Learn
									more about{' '}
									<Link
										href="https://support.liiingo.com/question/add-content-in-multiple-languages"
										target="_blank"
										className={classes.linkText}
										onClick={() => {
											handleHelpLinks(
												'https://support.liiingo.com/question/add-content-in-multiple-languages'
											);
										}}
									>
										providing content in multiple languages
									</Link>{' '}
									in our Help Center.
								</Typography>
							</StyledAccordionDetails>
						</StyledAccordion>
						<EmptyDivider />
						<TitleTipGroup
							logo={ShareLogo}
							title="Share"
							desc="Share content using unique QR codes and links."
						/>
						<StyledAccordion
							expanded={expanded === 'panel4'}
							onChange={handleChange('panel4')}
							className={classes.highlightRow}
						>
							<StyledAccordionSummary
								expandIcon={<ArrowDropDownIcon />}
								aria-controls="panel2a-content"
								id="panel2a-header"
							>
								<PaletteOutlinedIcon fontSize="small" className={classes.iconColor} />
								<Typography className={classes.accordionHeading}>
									<span className={classes.strongText}>Customize your QR code</span> with your logo
									and brand colors.
								</Typography>
							</StyledAccordionSummary>
							<StyledAccordionDetails>
								<Typography className={classes.accordionText}>
									Create a custom QR code with your company’s colors and logo to encourage brand
									recognition. Learn more about{' '}
									<Link
										href="https://support.liiingo.com/question/customize-qr-codes"
										target="_blank"
										className={classes.linkText}
										onClick={() => {
											handleHelpLinks('https://support.liiingo.com/question/customize-qr-codes');
										}}
									>
										customizing your QR code
									</Link>{' '}
									in our Help Center.
								</Typography>
							</StyledAccordionDetails>
						</StyledAccordion>
						<StyledAccordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
							<StyledAccordionSummary
								expandIcon={<ArrowDropDownIcon />}
								aria-controls="panel2a-content"
								id="panel2a-header"
							>
								<QrIcon fontSize="small" className={classes.iconColor} />
								<Typography className={classes.accordionHeading}>
									<span className={classes.strongText}>Download QR codes</span> for your audience to
									scan.
								</Typography>
							</StyledAccordionSummary>
							<StyledAccordionDetails>
								<Typography className={classes.accordionText}>
									Each page has a unique QR code. Download and share your QR codes to provide your
									audience easy access to information they need. Learn more about how to{' '}
									<Link
										href="https://support.liiingo.com/question/download-qr-codes"
										target="_blank"
										className={classes.linkText}
										onClick={() => {
											handleHelpLinks('https://support.liiingo.com/question/download-qr-codes');
										}}
									>
										download QR codes
									</Link>{' '}
									in our Help Center.
								</Typography>
							</StyledAccordionDetails>
						</StyledAccordion>
						<StyledAccordion
							expanded={expanded === 'panel6'}
							onChange={handleChange('panel6')}
							className={classes.highlightRow}
						>
							<StyledAccordionSummary
								expandIcon={<ArrowDropDownIcon />}
								aria-controls="panel2a-content"
								id="panel2a-header"
							>
								<LinkOutlinedIcon fontSize="small" className={classes.iconColor} />
								<Typography className={classes.accordionHeading}>
									<span className={classes.strongText}>Use unique links</span> to share content
									digitally.
								</Typography>
							</StyledAccordionSummary>
							<StyledAccordionDetails>
								<Typography className={classes.accordionText}>
									Links are a great way to share your content electronically. Each page has a unique
									link that can be shared to provide your audience easy access to information they
									need. Learn more about{' '}
									<Link
										href="https://support.liiingo.com/question/copy-page-links"
										target="_blank"
										className={classes.linkText}
										onClick={() => {
											handleHelpLinks('https://support.liiingo.com/question/copy-page-links');
										}}
									>
										copying page links
									</Link>{' '}
									in our Help Center.
								</Typography>
							</StyledAccordionDetails>
						</StyledAccordion>
						<StyledAccordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
							<StyledAccordionSummary
								expandIcon={<ArrowDropDownIcon />}
								aria-controls="panel2a-content"
								id="panel2a-header"
							>
								<ShareOutlinedIcon fontSize="small" className={classes.iconColor} />
								<Typography className={classes.accordionHeading}>
									<span className={classes.strongText}>Promote your content</span> on social media.
								</Typography>
							</StyledAccordionSummary>
							<StyledAccordionDetails>
								<Typography className={classes.accordionText}>
									Reach more people by sharing your content on social media. Unique page links and QR
									codes are a great way to spread the word about what you have to offer. Learn more
									about{' '}
									<Link
										href="https://support.liiingo.com/question/share-via-social-media"
										target="_blank"
										className={classes.linkText}
										onClick={() => {
											handleHelpLinks(
												'https://support.liiingo.com/question/share-via-social-media'
											);
										}}
									>
										sharing via social media
									</Link>{' '}
									in our Help Center.
								</Typography>
							</StyledAccordionDetails>
						</StyledAccordion>
						<EmptyDivider />
						<TitleTipGroup
							logo={ImproveLogo}
							title="Improve"
							desc="Make improvements to your content based on data."
						/>
						<StyledAccordion
							expanded={expanded === 'panel8'}
							onChange={handleChange('panel8')}
							className={classes.highlightRow}
						>
							<StyledAccordionSummary
								expandIcon={<ArrowDropDownIcon />}
								aria-controls="panel2a-content"
								id="panel2a-header"
							>
								<AssessmentOutlinedIcon fontSize="small" className={classes.iconColor} />
								<Typography className={classes.accordionHeading}>
									Track trends and popular pages using{' '}
									<span className={classes.strongText}>Liiingo Analytics</span>.
								</Typography>
							</StyledAccordionSummary>
							<StyledAccordionDetails>
								<Typography className={classes.accordionText}>
									Analytics provide valuable data and insight into how users engage with your content.
									Learn more about how to{' '}
									<Link
										href="https://support.liiingo.com/question-category/improve-content"
										target="_blank"
										className={classes.linkText}
										onClick={() => {
											handleHelpLinks(
												'https://support.liiingo.com/question-category/improve-content'
											);
										}}
									>
										improve your content
									</Link>{' '}
									in our Help Center.
								</Typography>
							</StyledAccordionDetails>
						</StyledAccordion>
						<StyledAccordion expanded={expanded === 'panel9'} onChange={handleChange('panel9')}>
							<StyledAccordionSummary
								expandIcon={<ArrowDropDownIcon />}
								aria-controls="panel2a-content"
								id="panel2a-header"
							>
								<CallSplitOutlinedIcon fontSize="small" className={classes.iconColor} />
								<Typography className={classes.accordionHeading}>
									<span className={classes.strongText}>Expand your reach</span> with customers.
								</Typography>
							</StyledAccordionSummary>
							<StyledAccordionDetails>
								<Typography className={classes.accordionText}>
									Be creative in reaching your audience to make the most of Liiingo. Learn more about{' '}
									<Link
										href="https://support.liiingo.com/question-category/ways-to-use-liiingo"
										target="_blank"
										className={classes.linkText}
										onClick={() => {
											handleHelpLinks(
												'https://support.liiingo.com/question-category/ways-to-use-liiingo'
											);
										}}
									>
										ways to use Liiingo
									</Link>{' '}
									in our Help Center.
								</Typography>
							</StyledAccordionDetails>
						</StyledAccordion>
					</Box>
				</Box>
			</Box>
		</GetStartedPanelDrawer>
	);
};
export default GetStartedPanel;
