import { format } from 'date-fns';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useEffect, useState } from 'react';
import { colors } from '../../../../theme/palette';
import { ChartContent } from './ChartContent';

export type DownloadsProps = {
	downloads: [string, number][];
};

export const Downloads = (props: DownloadsProps) => {
	const { downloads } = { ...props };
	const [total, setTotal] = useState(0);

	// effect to calculate the total downloads
	useEffect(() => {
		let tempTotal = 0;
		downloads.forEach((download) => {
			tempTotal += download[1]; // add the value that exists in position 1 of the download array
		});
		setTotal(tempTotal);
	}, [downloads, total]);

	const chartConfig: Highcharts.Options = {
		title: {
			text: null,
			align: 'left',
			useHTML: true,
			//style copied from the "Typography" genererated by Material UI, proabably should've just stuck with using that method
			style: {
				color: colors.grayDark,
				fontSize: ' 0.875rem',
				display: 'flex',
				fontFamily: '"Roboto", "Helvetica", "Arial", sans- serif',
				fontWeight: '500',
				lineHeight: '1.57',
				letterSpacing: '0.00714em',
			},
		},
		subtitle: {
			text: `Total Downloads: <div style="color: blue; font-size: 16px; line-height: 16px;"><b>&nbsp; ${total} </b><div>`,
			align: 'left',
			useHTML: true,
			//style copied from the "Typography" genererated by Material UI, proabably should've just stuck with using that method
			style: {
				color: colors.grayDark,
				fontSize: '14px',
				display: 'inline-flex',
				fontFamily: '"Roboto", sans- serif',
				fontWeight: '400',
				letterSpacing: '0.5px',
			},
		},
		legend: {
			enabled: false,
		},
		chart: {
			type: downloads.length > 1 ? 'area' : 'column',
			height: 213,
		},
		plotOptions: {
			area: {
				marker: {
					enabled: false,
				},
			},
		},
		xAxis: {
			type: 'category',
			labels: {
				step: Math.floor(downloads.length / 5),
				style: {
					color: colors.grayDark,
					fontSize: '9px',
					margin: '0px',
					padding: '0px',
				},
				formatter: function () {
					// return the date formatted with date-fns to only show month and day
					return format(new Date(this.value), 'MMM d');
				},
			},
		},
		yAxis: [
			{
				tickInterval: 5,
				title: {
					text: null,
				},
			},
		],
		series: [
			{
				type: downloads.length > 1 ? 'area' : 'column',
				name: 'Downloads',
				keys: ['name', 'y'],
				data: downloads,
				// opacity: 0.5,
				fillOpacity: 0.1,
				color: colors.blueAccent,
				// lineWidth: 3,
			},
		],
		credits: {
			enabled: false, //removing watermark
		},
		tooltip: {
			animation: true,
			backgroundColor: colors.grayDark,
			borderColor: colors.grayDark,
			borderRadius: 4,
			borderWidth: 0,
			shadow: true,
			shared: true,
			// dateTimeLabelFormats: {
			// 	day: '%b %e, %Y',
			// },
			enabled: true,
			useHTML: true,
			style: {
				color: 'white',
				fontSize: '12px',
				textAlign: 'center',
			},

			formatter() {
				const display = this.points[0].key;
				const value = this.y;
				return `${display} <br> <hr style="height:1px;border-width:0;color:white;background-color:gray"><b>${value}</b>`;
			},
		},
	};

	return (
		<ChartContent
			name={'DOWNLOADS'}
			toolTipMsg={'The number of times a user has downloaded your Liiingo to their mobile device.'}
		>
			<HighchartsReact highcharts={Highcharts} options={chartConfig} immutable={true} />
		</ChartContent>
	);
};
