import { useLogoUploadField } from '../../hooks/useLogoUploadField';
import { checkMultipleValidationRules, recommendHighSolution } from '../../util/imageValidators';

export const PreviewMinHeight = 133;

export type DroppableLogoProps = {
	isValidType?: boolean;
	isDrag?: boolean;
	imageUrl?: string;
	setImagePreview: (file: File) => void;
};

export const DroppableLogo = (props: DroppableLogoProps) => {
	const { imageUrl, isDrag, isValidType, setImagePreview } = { ...props };
	const [logoUploadFieldProps, LogoUploadField] = useLogoUploadField({
		fieldName: `customLogoImageFile`,
		maxFileSizeBytes: 3000000,
		initialValue: imageUrl,
		validateImage: checkMultipleValidationRules([recommendHighSolution]),
		setImagePreview: setImagePreview,
		isDrag: isDrag,
		isValidType: isValidType,
	});

	return <LogoUploadField {...logoUploadFieldProps} />;
};
