import { combineReducers } from '@reduxjs/toolkit';
import analyticsReducer from './slices/analyticsSlice';
import contentReducer from './slices/contentSlice';
import editorReducer from './slices/editorSlice';
import locationReducer from './slices/locationSlice';
import notificationReducer from './slices/notificationSlice';
import organizationReducer from './slices/organizationSlice';
import redirectReducer from './slices/redirectSlice';
import sectionReducer from './slices/sectionSlice';
import topicReducer from './slices/topicSlice';
// bring these in as needed
// import authReducer
// import parentTopicReducer
// import userReducer
import { connectRouter } from 'connected-react-router';

const rootReducer = (history) =>
	combineReducers({
		router: connectRouter(history),
		editor: editorReducer,
		analytics: analyticsReducer,
		location: locationReducer,
		section: sectionReducer,
		topic: topicReducer,
		content: contentReducer,
		notification: notificationReducer,
		organization: organizationReducer,
		redirect: redirectReducer,
		// app: appReducer,
		// auth: authReducer,
		// user: userReducer,
		// parentTopics: parentTopicReducer,
	});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
