import { useState, useRef, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import TextField from '@mui/material/TextField';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { Option } from './Option';
import { OptionGroup } from './OptionGroup';
import { contentChanged, _contents, _selectedContentId } from '../../store/slices/contentSlice';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { _activeLanguageCode } from '../../store/slices/editorSlice';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			'& .MuiTextField-root': {
				padding: '5px 10px',
			},
			'& .MuiOutlinedInput-root': {
				padding: '10px 5px',
				'& fieldset': {
					borderColor: 'white',
				},
				'&:hover fieldset': {
					border: '0.5px solid #59C9E6',
				},
				'&.Mui-focused fieldset': {
					border: '0.5px solid #59C9E6',
				},
			},
		},
	})
);

export const AltText = () => {
	const classes = useStyles();
	const editorRef = useRef(null);
	const [editorValue, setEditorValue] = useState('');
	const dispatch = useAppDispatch();
	const onContentChanged = bindActionCreators(contentChanged, dispatch);
	const contents = useAppSelector(_contents);
	const contentId = useAppSelector(_selectedContentId);
	const content = contents[contentId];
	const [translation, setTranslation] = useState(null);
	const activeLanguageCode = useAppSelector(_activeLanguageCode);
	const languageRef = useRef(null);
	const valueRef = useRef(null);
	const idRef = useRef(null);
	const [isDirty, setIsDirty] = useState(false);

	useEffect(() => {
		languageRef.current = translation?.language;
		valueRef.current = editorValue;
		idRef.current = contentId;
	}, [contentId, editorValue, translation?.language]);

	useEffect(() => {
		return () => {
			// if the content has been changed and component is unmounted, save the content
			if (isDirty) {
				onContentChanged({
					language: languageRef.current,
					description: valueRef.current,
					id: idRef.current,
				});
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isDirty]);

	useEffect(() => {
		setTranslation(content?.languages.find((item) => item.language === activeLanguageCode));

		// &#34; is equal to the character "
		// &#39; is equal to the character '
		var value = translation?.description;
		if (value) {
			value = value.replace('&#39;', "'");
			value = value.replace('&#34;', '"');
		}
		setEditorValue(value);
	}, [activeLanguageCode, content, translation, setTranslation]);

	const handleAltText = (e) => {
		onContentChanged({
			language: translation.language,
			description: editorValue,
			id: contentId,
		});
	};

	return (
		<div className={classes.root}>
			<OptionGroup title="Accessibility">
				<Option subtitle="Alt Text" />
				<TextField
					multiline
					fullWidth
					minRows={5}
					maxRows={5}
					value={editorValue}
					inputRef={editorRef}
					onChange={(e) => {
						setEditorValue(e.target.value);
						setIsDirty(true);
					}}
					onBlur={(e) => {
						handleAltText(e);
						setIsDirty(false);
					}}
				/>
			</OptionGroup>
		</div>
	);
};
