import InputBase from '@material-ui/core/InputBase';
import ListItem from '@material-ui/core/ListItem';
import createStyles from '@material-ui/core/styles/createStyles';
import { Theme } from '@material-ui/core/styles/createTheme';
import makeStyles from '@material-ui/core/styles/makeStyles';
import React, { useLayoutEffect, useRef } from 'react';
import { colors } from '../../theme/palette';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		item: {
			width: '100%',
			'&.Mui-selected': {
				backgroundColor: theme.palette.background.default,
			},
		},
		pageName: {
			fontSize: 12,
			'&.Mui-focused': {
				borderRadius: 5,
				borderStyle: 'solid',
				borderColor: colors.blueAccent20,
				borderWidth: 0.5,
			},
			'&.Mui-disabled': {
				color: colors.pureBlack,
			},
		},
		spinner: {
			marginRight: 5,
		},
		spinnerText: {
			fontSize: 12,
		},
	})
);

export type PageMenuItemAddProps = {
	visible: boolean;
	addTopic: (pageName: string) => void;
	setVisible: (visible: boolean) => void;
};

// This component is a usually invisible item in the page list. It's shown after clicking the "Add Page" button.
// It serves as a temporary placeholder that allows you to choose a page name.
// After Enter is pressed, or the item loses focus, it will fire off the action to create a new page (addTopic).
export const PageMenuItemAdd = (props: PageMenuItemAddProps) => {
	const { visible, addTopic, setVisible } = {
		...props,
	};
	const pageName = useRef(null);
	const classes = useStyles();

	useLayoutEffect(() => {
		if (visible && pageName.current !== null) {
			pageName.current.focus();
			pageName.current.select();
		}
	}, [visible]);

	return (
		visible && (
			<ListItem key="add_page" classes={{ root: classes.item }} component="div">
				<InputBase
					autoFocus={true}
					placeholder="Page Name"
					inputRef={pageName}
					fullWidth={true}
					className={classes.pageName}
					data-tour="rename-page"
					defaultValue="New Page Name"
					onKeyPress={(e) => {
						if (e.key === 'Enter') {
							pageName.current.blur();
						}
					}}
					onBlur={(e) => {
						if (e.target.value === '') {
							e.target.value = 'New Page';
						}
						let pageTitle = e.target.value;
						addTopic(pageTitle);
						setVisible(false);
					}}
				/>
			</ListItem>
		)
	);
};
