import { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { _plan, getPlanByPriceId } from '../store/slices/editorSlice';
import {
	_location,
	_locationIsLoading,
	_selectedLocation,
	fetchAll,
	setIsLoading,
} from '../store/slices/locationSlice';
import { _sections } from '../store/slices/sectionSlice';
import { selectTopic } from '../store/slices/topicSlice';
import { useStripeState } from './useStripeState';

// hydrates the Store from network.
export const useHydrateStore = () => {
	const dispatch = useAppDispatch();
	const selectedLocation = useAppSelector(_selectedLocation);
	const locationLoading = useAppSelector(_locationIsLoading);
	const { stripeSubscription, fetchStripeData, loadingStripeData } = useStripeState();
	// const [planId, setPlanId] = useState('');
	const plan = useAppSelector(_plan);
	const fetchPlanById = bindActionCreators(getPlanByPriceId, dispatch);
	// const fetchLocations = bindActionCreators(fetchAllLocations, dispatch);
	const fetchEverything = bindActionCreators(fetchAll, dispatch);
	const isLoading = bindActionCreators(setIsLoading, dispatch);
	const [fetchingPlan, setFetchingPlan] = useState(false);

	//Getting and setting the key environment to set the firstTopic to the primary topic
	const locationPulled = useAppSelector(_location);
	const sectionsPulled = useAppSelector(_sections);
	const firstSectionID = locationPulled?.sectionOrder[0];
	const firstSection = sectionsPulled[firstSectionID];
	const firstTopicID: string = firstSection?.topicOrder[0];
	const setTopicStatus = bindActionCreators(selectTopic, dispatch);

	// effect to get the Stripe Subscription
	useEffect(() => {
		// if we dont have a subscription, and we are not loading the subscription, fetch the subscription
		if (!stripeSubscription && !loadingStripeData) {
			fetchStripeData();
		}
	}, [fetchStripeData, loadingStripeData, stripeSubscription]);

	// effect to get the plan by id
	useEffect(() => {
		// if we have a subscription.plan.id and we don't have a plan, fetch the plan
		if (stripeSubscription?.plan?.id && !plan && !fetchingPlan) {
			setFetchingPlan(true);
			// use debounce on this api call?

			fetchPlanById(stripeSubscription.plan.id);
		}
	}, [fetchPlanById, fetchingPlan, plan, stripeSubscription]);

	// effect for fetching location if the store does not have a selected location
	useEffect(() => {
		if (!selectedLocation && !locationLoading) {
			console.log('hydrating store');
			isLoading(true); // set location slice's variable isLoading to true
			fetchEverything(null); //passing null as an arg because typescript is complaining about the arg being required. The middleware doesn't need the arg, but the action does. The middleware will provide the location as an arg after it's fetched.
		} else {
			console.log('Store already hydrated. The location is being fetched or has already been selected.');

			//Updating selected Topic to be the firstTopic
			setTopicStatus(firstTopicID);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
};
