import { useMsal } from '@azure/msal-react';
import { faWindows } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Alert from '@material-ui/lab/Alert';
import IconButton from '@mui/material/IconButton';
import MD5 from 'crypto-js/md5';
import { useContext, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { loginRequest } from '../authConfig';
import { OnboardingFlowContext } from '../context/OnboardingFlowContext';
import { useAuth } from '../hooks/useAuth';
import { useIsDev } from '../hooks/useIsDev';
import { colors } from '../theme/palette';
import { InfoLink } from './InfoLink';
import { liiingoFailure } from './LiiingoFailureSnackbar';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		form: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'center',
		},
		centerMyContentPlease: {
			textAlign: 'center',
		},
		topOfForm: {
			height: 'min-content',
		},
		bottomOfForm: {
			display: 'block',
			textAlign: 'center',
			padding: 'auto',
		},
		horizontalLine: {
			border: `1px solid ${colors.graySecondaryLight}`,
		},
		orSignInWithText: {
			lineHeight: 1,
		},
		planLink: {
			textDecoration: 'none',
			fontWeight: 'bold',
			color: colors.tealAccent,
		},
		verifyButton: {
			marginTop: 20,
		},
		breathingRoom: {
			margin: '30px 10px',
		},
	})
);

type FormValues = {
	email: string;
	password: string;
};

export const SignInForm = () => {
	const [errorMessage, setErrorMessage] = useState('');
	const onboardingFlowContext = useContext(OnboardingFlowContext);
	const { register, errors, handleSubmit } = useForm({
		mode: 'onChange',
		defaultValues: {
			email: '',
			password: '',
		},
	});
	const history = useHistory();
	const classes = useStyles();
	const { login } = useAuth();
	const isDev = useIsDev();
	const { instance } = useMsal();

	const onSubmit: SubmitHandler<FormValues> = async (data: FormValues) => {
		try {
			await login(data.email, data.password);
			history.push(`/${onboardingFlowContext.route}/dashboard`);
		} catch (error) {
			setErrorMessage(error.message);
		}
	};

	const validationMessages = {
		requiredMessage: 'This field is required',
	};

	const handleMicrosoftLogin = (event) => {
		return instance
			.loginPopup(loginRequest)
			.then((response) => {
				let email = response.account.username;
				let password = MD5(response.account.localAccountId).toString();
				return login(email, password);
			})
			.then(() => {
				history.push(`/${onboardingFlowContext.route}/dashboard`);
			})
			.catch((e) => {
				liiingoFailure('Microsoft login failed: Please check Partner Center subscription status.');
				console.error(e);
			});
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
			<Grid container direction="row">
				<Grid container item direction="row" xs={12} spacing={2} className={classes.topOfForm}>
					{errorMessage && (
						<Alert severity="error" onClose={() => setErrorMessage(null)}>
							Invalid email/password
						</Alert>
					)}
					<Grid item xs={12}>
						<TextField
							autoFocus
							fullWidth
							variant="outlined"
							label="Email"
							type="email"
							name="email"
							error={!!errors.email}
							helperText={errors?.email?.message}
							margin="normal"
							inputRef={register({ required: validationMessages.requiredMessage })}
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							fullWidth
							variant="outlined"
							label="Password"
							type="password"
							name="password"
							error={!!errors?.password}
							helperText={errors?.password?.message}
							margin="normal"
							inputRef={register({ required: validationMessages.requiredMessage })}
						/>
					</Grid>
					<Grid item xs={12}>
						<InfoLink to="forgot-password">Forgot Your Password?</InfoLink>
					</Grid>
					<Grid item xs={12}>
						<Button type="submit" variant="contained" color="primary" data-cy="loginButton" fullWidth>
							Sign In
						</Button>
					</Grid>
				</Grid>
				<Grid item container direction="row" xs={12} className={classes.bottomOfForm}>
					<Typography variant="body2" className={classes.breathingRoom}>
						Need to create an account?{' '}
						{onboardingFlowContext.route === 'essentials' ? (
							<a className={classes.planLink} href="https://www.liiingo.com/pricing">
								View Plans and Pricing
							</a>
						) : (
							<InfoLink to="sign-up">Sign Up</InfoLink>
						)}
					</Typography>
					{isDev && onboardingFlowContext.route === 'essentials' && (
						<Typography variant="body2" className={classes.breathingRoom}>
							<InfoLink to="sign-up?billing=monthly&plan=Essentials">Dev Sign Up</InfoLink>
						</Typography>
					)}
				</Grid>
				<Grid item container direction="row" xs={12} className={classes.bottomOfForm}>
					<Typography variant="body2" className={classes.breathingRoom}>
						<IconButton
							type="button"
							disableRipple
							color="primary"
							data-cy="loginButton"
							className={classes.verifyButton}
							onClick={handleMicrosoftLogin}
						>
							Microsoft&nbsp;
							<FontAwesomeIcon icon={faWindows} />
						</IconButton>
						<br />
						Subscriber Sign-On
					</Typography>
				</Grid>
			</Grid>
		</form>
	);
};
