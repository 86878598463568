import { Breadcrumbs, Card, Container, createStyles, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import HomeIcon from '@mui/icons-material/Home';
import { Skeleton } from '@mui/material';
import {
	Downloads,
	Hourly,
	MostViewed,
	PageViews,
	Traffic,
	TrafficDaily,
	UserCard,
	ViewsBy,
} from '../components/Dashboard/Analytics/Charts';
import { LiiingoDateRangePicker } from '../components/Dashboard/Analytics/LiiingoDateRangePicker';
import { AnalyticsTip } from '../components/Tips';
import { useChangeRoute } from '../hooks/useChangeRoute';
import { useHydrateStore } from '../hooks/useHydrateStore';
import { useAppSelector } from '../store/hooks';
import {
	_downloadsData,
	_hourly,
	_isLoading,
	// _newTraffic,
	_pageViews,
	// _returningTraffic,
	_sessionData,
	_topContent,
	_trafficDaily,
	_userTraffic,
	_viewsBy,
} from '../store/slices/analyticsSlice';
import { _topics } from '../store/slices/topicSlice';
import { Keyframes } from '../theme/Keyframes';
import { colors } from '../theme/palette';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		containerWidth: {
			width: '100%',
			margin: 30,
		},
		dashboardTitle: {
			fontFamily: 'Open Sans',
			fontWeight: 400,
			fontSize: '24px',
			lineHeight: '32px',
			letterSpacing: '0.18px',
			color: '#49525D',
			padding: 3,
			marginBottom: 10,
		},
		fade: {
			animation: 'fade 1s ease .5s both',
			// width: 300,
			// height: '550px',
		},
		breadcrumbLink: {
			display: 'flex',
			alignItems: 'center',
			cursor: 'pointer',
			color: colors.tealAccent,
			textDecoration: 'none',
			'&:hover': {
				textDecoration: 'underline',
			},
			'& .MuiSvgIcon-root': {
				marginRight: 5,
			},
		},
		dialog: {
			'& .MuiDialog-container': {
				height: 'auto',
				width: 'auto',
			},
		},
		card: {
			padding: 20,
			margin: '40px 20px 20px 20px',
			border: '1px solid #e0e0e0',
			borderRadius: 6,
			animation: 'fade 1s ease .5s both',
			height: 'auto',
		},
	})
);

export type AnalyticsProps = {};

export const Analytics = (props: AnalyticsProps) => {
	const classes = useStyles();
	const toDashboard = useChangeRoute('dashboard');
	const topics = useAppSelector(_topics);
	useHydrateStore(); //this will only hydrate if we access this page with an empty store
	const isLoading = useAppSelector(_isLoading);

	//DATA
	const pageViews = useAppSelector(_pageViews);
	const userTraffic = useAppSelector(_userTraffic);
	// const newTraffic = useAppSelector(_newTraffic);
	// const returningTraffic = useAppSelector(_returningTraffic);
	const topContent = useAppSelector(_topContent);
	const sessionData = useAppSelector(_sessionData);
	const viewsBy = useAppSelector(_viewsBy);
	const downloadsData = useAppSelector(_downloadsData);
	const trafficDaily = useAppSelector(_trafficDaily);
	const hourly = useAppSelector(_hourly);

	return (
		<Container className={classes.containerWidth}>
			<Keyframes name="fade" _0={{ opacity: 0 }} _100={{ opacity: 1 }} />
			{/* MAIN GRID CONTAINER */}
			<Grid container spacing={1} direction="row" justifyContent="flex-start" alignItems="stretch">
				{/* page title */}
				<Grid item xs={4}>
					<Typography variant="h5" className={classes.dashboardTitle}>
						Analytics
					</Typography>
					{/* breadcrumbs */}
					<Breadcrumbs aria-label="breadcrumb">
						{/* app dashboard */}
						<Typography className={classes.breadcrumbLink} onClick={toDashboard}>
							<HomeIcon fontSize="inherit" />
							Dashboard
						</Typography>
						{/* analytics */}
						<Typography>Analytics</Typography>
					</Breadcrumbs>
				</Grid>

				{/* empty */}
				<Grid item xs={4}></Grid>

				{/* date range selector */}
				<Grid item xs={4}>
					<LiiingoDateRangePicker />
				</Grid>

				{/* START ANALYTICS CARDS */}

				{/* LEFT SIDE, 2 rows of cards */}
				<Grid item xs={8}>
					<Grid container>
						{/* row 1, 2 cards */}
						<Grid item xs={6}>
							<Card elevation={1} className={classes.card}>
								{isLoading ? (
									<Skeleton variant="text" width={300} height={211} className={classes.fade} />
								) : (
									<div className={classes.fade} style={{ width: '300px', height: '211px' }}>
										<PageViews data={pageViews} topics={topics} />
									</div>
								)}
							</Card>
						</Grid>

						<Grid item xs={6}>
							<Card elevation={1} className={classes.card}>
								{isLoading ? (
									<Skeleton variant="text" width={300} height={211} className={classes.fade} />
								) : (
									<div className={classes.fade} style={{ width: '300px', height: '211px' }}>
										<Traffic
											userTraffic={userTraffic}
											// newTraffic={newTraffic}
											// returningTraffic={returningTraffic}
										/>
									</div>
								)}
							</Card>
						</Grid>

						{/* row 2, one card */}
						<Grid item xs={12}>
							<Card elevation={1} className={classes.card}>
								{isLoading ? (
									<Skeleton variant="text" width={620} height={189} className={classes.fade} />
								) : (
									<div className={classes.fade} style={{ width: '100%', height: '189px' }}>
										<ViewsBy data={viewsBy} />
									</div>
								)}
							</Card>
						</Grid>
					</Grid>
				</Grid>
				{/* END LEFT SIDE */}

				{/* RIGHT SIDE, 1 card spanning 2 rows */}
				<Grid item xs={4}>
					<Grid container direction="column">
						<Grid item xs={12}>
							<Card elevation={1} className={classes.card}>
								{isLoading ? (
									<Skeleton variant="text" width={300} height={512} className={classes.fade} />
								) : (
									<div className={classes.fade} style={{ width: 'auto', height: '512px' }}>
										<MostViewed data={topContent} topics={topics} />
									</div>
								)}
							</Card>
						</Grid>
					</Grid>
				</Grid>
				{/* END RIGHT SIDE */}

				{/* THIRD ROW, full width 2 cards */}
				<Grid item xs={6}>
					<Card elevation={1} className={classes.card}>
						{isLoading ? (
							<Skeleton variant="text" width={446} height={200} className={classes.fade} />
						) : (
							<div className={classes.fade} style={{ width: '446px', height: 'auto' }}>
								<Downloads downloads={downloadsData} />
							</div>
						)}
					</Card>
				</Grid>

				<Grid item xs={6}>
					<Card elevation={1} className={classes.card}>
						{isLoading ? (
							<Skeleton variant="text" width={446} height={200} className={classes.fade} />
						) : (
							<div className={classes.fade} style={{ width: '100%', height: 'auto' }}>
								<UserCard session={sessionData.session} sessionDuration={sessionData.sessionDuration} />
							</div>
						)}
					</Card>
				</Grid>
				{/* END THIRD ROW */}

				{/* FOURTH ROW, full width, 2 cards wide */}
				<Grid item xs={6}>
					<Card elevation={1} className={classes.card}>
						{isLoading ? (
							<Skeleton variant="text" width={446} height={200} className={classes.fade} />
						) : (
							<div className={classes.fade} style={{ width: '100%', height: 'auto' }}>
								<TrafficDaily data={trafficDaily} />
							</div>
						)}
					</Card>
				</Grid>

				<Grid item xs={6}>
					<Card elevation={1} className={classes.card}>
						{isLoading ? (
							<Skeleton variant="text" width={446} height={200} className={classes.fade} />
						) : (
							<div className={classes.fade} style={{ width: '446px', height: 'auto' }}>
								<Hourly hourly={hourly} />
							</div>
						)}
					</Card>
				</Grid>
				{/* END FOURTH ROW */}

				{/* // grid just to create a bottom empty footer space so the tooltip doesn;t cover the last card */}
				<Grid item xs={12}>
					{/* // span with opaque font color and tiny font size to create a bottom footer space */}
					<span style={{ color: 'rgba(0, 0, 0, .00)', fontSize: '.1rem' }}>
						Hello! I'm a footer space!
						<br />
						This text is hidden. I'm just putting it here to add some space.
						<br />
						But you're not supposed to see it, so I made the font color invisible.
						<br />
					</span>
				</Grid>
			</Grid>
			<AnalyticsTip />
		</Container>
	);
};
