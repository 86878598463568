import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Skeleton } from '@mui/material';
import { useEffect, useState } from 'react';
import { useStripeState } from '../../hooks/useStripeState';
import { FlatButton } from '../Buttons/FlatButton';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		skeletonLine: {
			height: 20,
			width: '100%',
			marginBottom: 10,
			borderRadius: 5,
		},
		skeletonButton: {
			height: 40,
			width: 100,
			marginBottom: 10,
			borderRadius: 5,
		},
		spinner: {
			margin: 'auto',
		},
		formActions: {
			display: 'flex',
			justifyContent: 'space-between',
			[theme.breakpoints.up('md')]: {
				flexDirection: 'row-reverse',
			},
			[theme.breakpoints.down('sm')]: {
				marginTop: theme.spacing(1),
				flexDirection: 'row',
			},
		},
		formCard: {
			marginBottom: 20,
			backgroundColor: theme.palette.background.paper,
		},
	})
);

export const BillingInformationCard = () => {
	const classes = useStyles();
	const [cardText, setCardText] = useState('');
	const [buttonText, setButtonText] = useState('');
	const { billingInfo, openCustomerPortal, loadingStripeData } = useStripeState();

	useEffect(() => {
		if (billingInfo) {
			setCardText(`Manage your billing information through our Stripe portal.`);
			setButtonText(`Manage Billing`);
		} else if (!loadingStripeData) {
			setCardText(
				`Add billing information to your Liiingo account to keep everything running without interruption after your free trial ends.`
			);
			setButtonText(`Add Billing`);
		}
	}, [billingInfo, loadingStripeData, setCardText]);

	return (
		<Card variant="outlined" className={classes.formCard}>
			<CardHeader title="Billing Information" />
			<CardContent>
				<Grid container>
					<Grid container item xs={12} md={12}>
						<Grid item xs container direction="row" spacing={2}>
							<Grid item xs={12} md={12}>
								<Typography gutterBottom variant="subtitle1">
									{loadingStripeData ? (
										<>
											<Skeleton
												animation="wave"
												variant="rectangular"
												className={classes.skeletonLine}
											/>
											<Skeleton
												animation="wave"
												variant="rectangular"
												className={classes.skeletonLine}
												width="30%"
											/>
										</>
									) : (
										cardText
									)}
								</Typography>
							</Grid>
							<Grid item xs={12} sm={12} className={classes.formActions}>
								{loadingStripeData ? (
									<Skeleton
										animation="wave"
										variant="rectangular"
										className={classes.skeletonButton}
									/>
								) : (
									<FlatButton onClick={openCustomerPortal} variant="contained" color="secondary">
										{buttonText}
									</FlatButton>
								)}
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
};
