import { createSvgIcon } from '@material-ui/core';
import * as React from 'react';

const SvgMultiLing = (props) => (
	<svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M20.667 3H10v3.333h1.333v-2h9.334c.4 0 .666.267.666.667v7.333c0 .4-.266.667-.666.667H12v4.667H8.467L6 19.6v-1.933H4c-.4 0-.667-.267-.667-.667V9.667C3.333 9.267 3.6 9 4 9h8.667V7.667H4c-1.133 0-2 .866-2 2V17c0 1.133.867 2 2 2h.667v3.4l4.2-3.4h4.466v-4.667h7.334c1.133 0 2-.866 2-2V5c0-1.133-.867-2-2-2z"
			fill="#49525D"
		/>
		<path
			d="M4.8 16.266h1.6l.4-1.066h2.067l.4 1.066h1.6L8.6 10.333H7l-2.2 5.933zM7.8 12l.667 2.066H7.133L7.8 12zM14 12.333c.733 0 1.733-.2 2.667-.666.933.466 2 .666 2.666.666V11s-.666 0-1.4-.267c.8-.8 1.4-2 1.4-3.733v-.667h-2V5H16v1.333h-2v1.334h3.933c-.133 1.2-.666 1.933-1.266 2.4-.4-.334-.8-.8-1.067-1.4h-1.4c.267.866.667 1.533 1.2 2.066C14.733 11 14.133 11 14 11v1.333z"
			fill="#49525D"
		/>
	</svg>
);

const Icon = createSvgIcon(
	<>
		<path d="M20.667 3H10v3.333h1.333v-2h9.334c.4 0 .666.267.666.667v7.333c0 .4-.266.667-.666.667H12v4.667H8.467L6 19.6v-1.933H4c-.4 0-.667-.267-.667-.667V9.667C3.333 9.267 3.6 9 4 9h8.667V7.667H4c-1.133 0-2 .866-2 2V17c0 1.133.867 2 2 2h.667v3.4l4.2-3.4h4.466v-4.667h7.334c1.133 0 2-.866 2-2V5c0-1.133-.867-2-2-2z" />
		<path d="M4.8 16.266h1.6l.4-1.066h2.067l.4 1.066h1.6L8.6 10.333H7l-2.2 5.933zM7.8 12l.667 2.066H7.133L7.8 12zM14 12.333c.733 0 1.733-.2 2.667-.666.933.466 2 .666 2.666.666V11s-.666 0-1.4-.267c.8-.8 1.4-2 1.4-3.733v-.667h-2V5H16v1.333h-2v1.334h3.933c-.133 1.2-.666 1.933-1.266 2.4-.4-.334-.8-.8-1.067-1.4h-1.4c.267.866.667 1.533 1.2 2.066C14.733 11 14.133 11 14 11v1.333z" />
	</>,
	'Multilingual'
);

export const MultiLingIcon = (props) => <Icon {...props} viewBox="0 0 24 24" />;

export default SvgMultiLing;
