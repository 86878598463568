import * as React from 'react';

const SvgSignUp = (props) => {
	return (
		<svg width={500} height={500} viewBox="0 0 300 300" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<g id="Layer_1"></g>
			<g id="Layer_2">
				<g>
					<g>
						<path
							fill="none"
							stroke="#304AF4"
							strokeWidth={2.7667}
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeMiterlimit={10}
							strokeDasharray={10.5846}
							d="M176.59,54.23c-7.93-1.56-16.17-2.21-24.6-1.85c-4.37,0.19-17.74,2.71-25.57,6.33"
						/>
						<path
							fill="none"
							stroke="#304AF4"
							strokeWidth={2.7667}
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeMiterlimit={10}
							strokeDasharray={10.5846}
							d="M225.4,231.16c17.47-15.66,31.32-41.56,32.29-75.07"
						/>
						<path
							fill="none"
							stroke="#304AF4"
							strokeWidth={2.7667}
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeMiterlimit={10}
							strokeDasharray={10.5846}
							d="M64.79,109.5c-7.23,9.24-15.03,33.57-15.03,46.6c0,29.3,12.12,55.76,31.62,74.66"
						/>
					</g>
					<g>
						<g>
							<g>
								<path
									fill="#FFFFFF"
									stroke="#304AF4"
									strokeWidth={2.6461}
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeMiterlimit={10}
									d="M214.12,238.94l11.46,16.12c1.04,1.04,0.31,2.8-1.15,2.8H83.02c-1.45,0-2.19-1.76-1.15-2.8l11.48-16.12"
								/>
								<path
									fill="#FFFFFF"
									stroke="#304AF4"
									strokeWidth={2.6461}
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeMiterlimit={10}
									d="M214.12,238.94H93.34v-67.02c0-4.01,3.26-7.28,7.27-7.28h106.23c4.02,0,7.28,3.27,7.28,7.28V238.94z"
								/>
								<path
									fill="#FFFFFF"
									stroke="#304AF4"
									strokeWidth={2.6461}
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeMiterlimit={10}
									d="M224.43,257.85H83.02c-1.45,0-2.19-1.76-1.15-2.8l11.48-16.12h120.78l11.46,16.12
						C226.62,256.09,225.89,257.85,224.43,257.85z"
								/>
								<path
									fill="#FFFFFF"
									stroke="#304AF4"
									strokeWidth={2.6461}
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeMiterlimit={10}
									d="M214.12,171.92v67.02H93.34v-67.02c0-4.01,3.26-7.28,7.27-7.28h106.23
						C210.86,164.63,214.12,167.9,214.12,171.92z"
								/>
								<path
									fill="#B5C3F4"
									d="M169.65,257.85l-1.87-5.6c-0.1-0.16-0.28-0.26-0.48-0.26h-27c-0.19,0-0.37,0.1-0.48,0.26l-1.87,5.6
						H169.65z"
								/>
								<path
									fill="#B5C3F4"
									d="M206.22,233.25h-105c-1.08,0-1.95-0.87-1.95-1.95v-57.87c0-1.08,0.87-1.95,1.95-1.95h105
						c1.08,0,1.95,0.87,1.95,1.95v57.87C208.18,232.37,207.3,233.25,206.22,233.25z"
								/>
								<g>
									<line
										fill="#FFFFFF"
										stroke="#304AF4"
										strokeWidth={2.6461}
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeMiterlimit={10}
										x1="97.21"
										y1="243.55"
										x2="210.4"
										y2="243.55"
									/>
									<line
										fill="#FFFFFF"
										stroke="#304AF4"
										strokeWidth={2.6461}
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeMiterlimit={10}
										x1="95.26"
										y1="247.86"
										x2="212.35"
										y2="247.86"
									/>
								</g>
								<path
									fill="#B5C3F4"
									stroke="#304AF4"
									strokeWidth={1.8112}
									strokeMiterlimit={10}
									d="M169.65,257.85l-1.87-5.6c-0.1-0.16-0.28-0.26-0.48-0.26h-27c-0.19,0-0.37,0.1-0.48,0.26l-1.87,5.6
						H169.65z"
								/>
							</g>
							<g>
								<path
									fill="#FFFFFF"
									stroke="#304AF4"
									strokeWidth={2.6461}
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeMiterlimit={10}
									d="M197.67,150.54c0-0.93-0.75-1.69-1.69-1.69h-84.36c-0.93,0-1.69,0.75-1.69,1.69v8.17h87.74V150.54z"
								/>
								<rect
									x="109.94"
									y="158.7"
									fill="#FFFFFF"
									stroke="#304AF4"
									strokeWidth={2.6461}
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeMiterlimit={10}
									width="87.74"
									height="74.54"
								/>
							</g>
							<line
								fill="#FFFFFF"
								stroke="#304AF4"
								strokeWidth={2.6461}
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeMiterlimit={10}
								x1="110.29"
								y1="180.22"
								x2="197.67"
								y2="180.22"
							/>
							<g>
								<path
									fill="#FFFFFF"
									stroke="#304AF4"
									strokeWidth={2.6461}
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeMiterlimit={10}
									d="M132.86,183.04c-0.52-0.15-0.87-0.62-0.87-1.16v-0.92c0-0.12,0.06-0.24,0.15-0.31
						c0.59-0.48,1.04-1.22,1.3-1.93c0.06-0.17,0.11-0.33,0.13-0.48c0,0,0.47,0,0.54-0.43c0.21-1.34,0.37-1.4,0.37-1.99
						c0-0.59-0.21-0.73-0.47-0.58c0.04-0.15,0.12-0.47,0.13-0.9c0.06-2.71-1.96-4.34-4.37-4.34c-2.4,0-4.38,1.69-4.37,4.34
						c0,0.44,0.09,0.75,0.13,0.9c-0.26-0.15-0.47-0.01-0.47,0.58c0,0.59,0.15,0.65,0.37,1.99c0.07,0.44,0.54,0.43,0.54,0.43
						c0.01,0.06,0.02,0.12,0.03,0.18c0.16,0.82,0.61,1.7,1.26,2.22c0.1,0.08,0.16,0.19,0.16,0.32v0.91c0,0.53-0.35,1.01-0.86,1.16
						c-0.97,0.28-2.46,0.57-3.54,0.85c-0.27,0.07-0.97,0.19-1.58,0.35c2.01,2.49,5.07,4.08,8.51,4.08c3.43,0,6.48-1.58,8.49-4.04
						c-0.66-0.22-1.48-0.38-1.81-0.46C135.56,183.53,133.83,183.32,132.86,183.04z"
								/>
								<path
									fill="#4BCFE3"
									stroke="#304AF4"
									strokeWidth={2.6461}
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeMiterlimit={10}
									d="M129.96,166.42c-6.04,0-10.94,4.9-10.94,10.95c0,2.6,0.91,4.99,2.43,6.87c0.61-0.16,1.31-0.28,1.58-0.35
						c1.08-0.28,2.57-0.57,3.54-0.85c0.51-0.15,0.86-0.62,0.86-1.16v-0.91c0-0.13-0.06-0.24-0.16-0.32
						c-0.65-0.52-1.1-1.41-1.26-2.22c-0.01-0.06-0.02-0.12-0.03-0.18c0,0-0.47,0-0.54-0.43c-0.21-1.34-0.37-1.4-0.37-1.99
						c0-0.59,0.21-0.73,0.47-0.58c-0.04-0.15-0.13-0.47-0.13-0.9c-0.02-2.65,1.96-4.34,4.37-4.34c2.4,0,4.42,1.63,4.37,4.34
						c-0.01,0.44-0.09,0.75-0.13,0.9c0.26-0.15,0.47-0.01,0.47,0.58c0,0.59-0.15,0.65-0.37,1.99c-0.07,0.44-0.54,0.43-0.54,0.43
						c-0.02,0.14-0.07,0.31-0.13,0.48c-0.27,0.71-0.72,1.45-1.3,1.93c-0.09,0.08-0.15,0.19-0.15,0.31v0.92
						c0,0.54,0.35,1.01,0.87,1.16c0.97,0.28,2.7,0.49,3.78,0.77c0.33,0.08,1.15,0.25,1.81,0.46c1.53-1.88,2.45-4.29,2.45-6.9
						C140.9,171.32,136,166.42,129.96,166.42z"
								/>
							</g>
							<g>
								<line
									fill="#FFFFFF"
									stroke="#304AF4"
									strokeWidth={2.6461}
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeMiterlimit={10}
									x1="121.61"
									y1="194.83"
									x2="139.26"
									y2="194.83"
								/>
								<line
									fill="#FFFFFF"
									stroke="#304AF4"
									strokeWidth={2.6461}
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeMiterlimit={10}
									x1="121.61"
									y1="202.06"
									x2="139.26"
									y2="202.06"
								/>
								<line
									fill="#FFFFFF"
									stroke="#304AF4"
									strokeWidth={2.6461}
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeMiterlimit={10}
									x1="121.61"
									y1="209.3"
									x2="139.26"
									y2="209.3"
								/>
								<line
									fill="#FFFFFF"
									stroke="#304AF4"
									strokeWidth={2.6461}
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeMiterlimit={10}
									x1="121.61"
									y1="216.54"
									x2="139.26"
									y2="216.54"
								/>
								<line
									fill="#FFFFFF"
									stroke="#304AF4"
									strokeWidth={2.6461}
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeMiterlimit={10}
									x1="121.61"
									y1="223.78"
									x2="139.26"
									y2="223.78"
								/>
							</g>
							<circle
								fill="#4BCFE3"
								stroke="#304AF4"
								strokeWidth={2.6461}
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeMiterlimit={10}
								cx="153.6"
								cy="189.65"
								r="3.42"
							/>
							<line
								fill="#FFFFFF"
								stroke="#304AF4"
								strokeWidth={2.6461}
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeMiterlimit={10}
								x1="161.6"
								y1="187.35"
								x2="186.42"
								y2="187.35"
							/>
							<line
								fill="#FFFFFF"
								stroke="#304AF4"
								strokeWidth={2.6461}
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeMiterlimit={10}
								x1="161.6"
								y1="192.28"
								x2="169.64"
								y2="192.28"
							/>
							<rect
								x="151.66"
								y="199.31"
								fill="none"
								stroke="#304AF4"
								strokeWidth={2.6461}
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeMiterlimit={10}
								width="39.39"
								height="26.02"
							/>
							<g>
								<ellipse
									transform="matrix(1 -1.075783e-03 1.075783e-03 1 -0.1654 0.1247)"
									fill="#304AF4"
									cx="115.84"
									cy="153.77"
									rx="1.75"
									ry="1.75"
								/>

								<ellipse
									transform="matrix(1 -1.075783e-03 1.075783e-03 1 -0.1654 0.132)"
									fill="#304AF4"
									cx="122.6"
									cy="153.77"
									rx="1.75"
									ry="1.75"
								/>

								<ellipse
									transform="matrix(1 -1.073471e-03 1.073471e-03 1 -0.165 0.1387)"
									fill="#304AF4"
									cx="129.15"
									cy="153.77"
									rx="1.75"
									ry="1.75"
								/>
							</g>
						</g>
					</g>
					<g>
						<g>
							<path
								fill="#304AF4"
								stroke="#304AF4"
								strokeWidth={1.3917}
								strokeMiterlimit={10}
								d="M46.8,103.07c-0.57,0-1.21-0.08-1.88-0.31c-0.09-0.03-0.16-0.1-0.18-0.2l-0.51-2.11
					c-0.15-0.06-0.3-0.13-0.45-0.19L42,101.32c-0.08,0.05-0.17,0.05-0.26,0.01c-0.07-0.03-1.81-0.85-2.71-2.71
					c-0.04-0.08-0.04-0.18,0.01-0.26l1.13-1.85c-0.06-0.15-0.12-0.3-0.18-0.45l-2.01-0.52c-0.09-0.02-0.16-0.09-0.19-0.17
					c-0.03-0.08-0.68-1.88,0-3.83c0.03-0.09,0.1-0.16,0.2-0.18l2.11-0.51c0.06-0.15,0.13-0.3,0.2-0.45l-1.05-1.79
					c-0.05-0.08-0.05-0.17-0.01-0.26c0.03-0.07,0.85-1.81,2.71-2.71c0.08-0.04,0.18-0.04,0.26,0.01l1.85,1.13
					c0.15-0.06,0.3-0.12,0.45-0.18l0.52-2.01c0.02-0.09,0.09-0.16,0.17-0.19c0.08-0.03,1.88-0.68,3.83,0
					c0.09,0.03,0.16,0.1,0.18,0.2l0.51,2.11c0.15,0.06,0.3,0.13,0.45,0.2l1.79-1.05c0.08-0.05,0.17-0.05,0.26-0.01
					c0.07,0.03,1.81,0.85,2.71,2.71c0.04,0.08,0.04,0.18-0.01,0.26l-1.13,1.85c0.06,0.15,0.12,0.3,0.18,0.45l2.01,0.52
					c0.09,0.02,0.16,0.09,0.19,0.17c0.03,0.08,0.68,1.88,0,3.83c-0.03,0.09-0.1,0.16-0.2,0.18l-2.11,0.51
					c-0.06,0.15-0.13,0.3-0.2,0.45l1.05,1.79c0.05,0.08,0.05,0.17,0.01,0.26c-0.03,0.07-0.85,1.81-2.71,2.71
					c-0.08,0.04-0.18,0.04-0.26-0.01l-1.85-1.13c-0.15,0.06-0.3,0.12-0.45,0.18l-0.52,2.01c-0.02,0.09-0.09,0.16-0.17,0.19
					C48.71,102.78,47.91,103.07,46.8,103.07z M45.25,102.28c1.42,0.44,2.77,0.12,3.19,0l0.46-1.8c-0.01-0.01-0.01-0.03-0.02-0.04
					c-0.04-0.14,0.04-0.3,0.18-0.34c0.25-0.08,0.51-0.18,0.75-0.29c0.08-0.04,0.18-0.03,0.26,0.02l1.84,1.12
					c1.32-0.69,2.04-1.87,2.25-2.25l-0.94-1.59c-0.02-0.01-0.03-0.01-0.05-0.02c-0.13-0.07-0.18-0.24-0.11-0.37
					c0.12-0.24,0.23-0.48,0.33-0.74c0.03-0.09,0.1-0.15,0.19-0.17l2.1-0.51c0.44-1.42,0.12-2.77,0-3.19l-1.8-0.47
					c-0.01,0.01-0.03,0.01-0.04,0.02c-0.14,0.04-0.3-0.04-0.34-0.18c-0.08-0.25-0.18-0.51-0.29-0.75c-0.04-0.08-0.03-0.18,0.02-0.26
					l1.12-1.84c-0.69-1.32-1.87-2.04-2.25-2.25l-1.59,0.94c-0.01,0.02-0.01,0.03-0.02,0.05c-0.07,0.13-0.24,0.18-0.37,0.11
					c-0.24-0.12-0.48-0.23-0.74-0.33c-0.09-0.03-0.15-0.1-0.17-0.19l-0.51-2.1c-1.42-0.44-2.77-0.12-3.19,0l-0.46,1.8
					c0.01,0.01,0.01,0.03,0.02,0.04c0.04,0.14-0.04,0.3-0.18,0.34c-0.25,0.08-0.51,0.18-0.75,0.29c-0.08,0.04-0.18,0.03-0.26-0.02
					l-1.84-1.12c-1.32,0.69-2.04,1.87-2.25,2.25l0.94,1.59c0.02,0.01,0.03,0.01,0.05,0.02c0.13,0.07,0.18,0.24,0.11,0.37
					c-0.12,0.24-0.23,0.48-0.33,0.74c-0.03,0.09-0.1,0.15-0.19,0.17l-2.1,0.51c-0.44,1.42-0.12,2.77,0,3.19l1.8,0.47
					c0.01-0.01,0.03-0.01,0.04-0.02c0.14-0.04,0.3,0.04,0.34,0.18c0.08,0.25,0.18,0.51,0.29,0.75c0.04,0.08,0.03,0.18-0.02,0.26
					l-1.12,1.84c0.69,1.32,1.87,2.04,2.25,2.25l1.59-0.94c0.01-0.02,0.01-0.03,0.02-0.05c0.07-0.13,0.24-0.18,0.37-0.11
					c0.24,0.12,0.48,0.23,0.74,0.33c0.09,0.03,0.15,0.1,0.17,0.19L45.25,102.28z M55.9,95.53L55.9,95.53L55.9,95.53z M46.98,97.19
					c-1.99,0-3.62-1.62-3.62-3.62s1.62-3.62,3.62-3.62s3.62,1.62,3.62,3.62S48.97,97.19,46.98,97.19z M46.98,90.51
					c-1.69,0-3.06,1.37-3.06,3.06c0,1.69,1.37,3.06,3.06,3.06s3.06-1.37,3.06-3.06C50.04,91.88,48.67,90.51,46.98,90.51z"
							/>
						</g>
						<g>
							<path
								fill="#4BCFE3"
								d="M54.54,71.24c0.34-0.98,0-1.91,0-1.91l-1.13-0.29c0,0.01,0.01,0.02,0.01,0.04
					c-0.04-0.14-0.1-0.27-0.16-0.41l0.63-1.03c-0.45-0.94-1.35-1.35-1.35-1.35l-1,0.59c0.01,0.01,0.02,0.01,0.04,0.02
					c-0.13-0.07-0.26-0.13-0.4-0.18l-0.29-1.18c-0.98-0.34-1.91,0-1.91,0l-0.29,1.13c0.01,0,0.02-0.01,0.04-0.01
					c-0.14,0.04-0.27,0.1-0.41,0.16l-1.03-0.63c-0.94,0.45-1.35,1.35-1.35,1.35l0.59,1c0.01-0.01,0.01-0.02,0.02-0.04
					c-0.07,0.13-0.13,0.26-0.18,0.4l-1.18,0.29c-0.34,0.98,0,1.91,0,1.91l1.13,0.29c0-0.01-0.01-0.02-0.01-0.04
					c0.04,0.14,0.1,0.27,0.16,0.41l-0.63,1.03c0.45,0.94,1.35,1.35,1.35,1.35l1-0.59c-0.01-0.01-0.02-0.01-0.04-0.02
					c0.13,0.07,0.26,0.13,0.4,0.18l0.29,1.18c0.98,0.34,1.91,0,1.91,0l0.29-1.13c-0.01,0-0.02,0.01-0.04,0.01
					c0.14-0.04,0.27-0.1,0.41-0.16l1.03,0.63c0.94-0.45,1.35-1.35,1.35-1.35l-0.59-1c-0.01,0.01-0.01,0.02-0.02,0.04
					c0.07-0.13,0.13-0.26,0.18-0.4L54.54,71.24z M49.87,71.96c-0.97,0-1.75-0.78-1.75-1.75c0-0.97,0.78-1.75,1.75-1.75
					c0.97,0,1.75,0.78,1.75,1.75C51.62,71.18,50.84,71.96,49.87,71.96z"
							/>
						</g>
						<circle fill="#B5C3F4" cx="91.91" cy="81.08" r="29.3" />
						<g>
							<path
								fill="#FFFFFF"
								stroke="#304AF4"
								strokeWidth={2.4309}
								strokeMiterlimit={10}
								d="M101.12,95.22H83.1c-2.32,0-4.22-1.9-4.22-4.22v-2.1c0-6.29,5.14-11.43,11.43-11.43h3.59
					c6.29,0,11.43,5.14,11.43,11.43V91C105.34,93.32,103.44,95.22,101.12,95.22z"
							/>
							<circle
								fill="#FFFFFF"
								stroke="#304AF4"
								strokeWidth={2.4309}
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeMiterlimit={10}
								cx="92.11"
								cy="70.82"
								r="7.7"
							/>
						</g>
						<path
							fill="none"
							stroke="#304AF4"
							strokeWidth={2.7434}
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeMiterlimit={10}
							d="M113.45,102.21c-9.91,10.01-25.82,12.03-38.07,4.03c-14.01-9.15-17.95-27.92-8.8-41.93
				s27.92-17.95,41.93-8.8c12.06,7.88,16.66,22.89,11.87,35.85"
						/>
						<g>
							<path
								fill="#304AF4"
								stroke="#304AF4"
								strokeWidth={1.9447}
								strokeMiterlimit={10}
								d="M126.05,116.09c-0.79,0-1.7-0.11-2.62-0.43c-0.12-0.04-0.22-0.15-0.25-0.27l-0.72-2.94
					c-0.21-0.08-0.42-0.18-0.62-0.27l-2.5,1.47c-0.11,0.06-0.24,0.07-0.36,0.02c-0.1-0.05-2.53-1.19-3.79-3.79
					c-0.06-0.12-0.05-0.26,0.02-0.37l1.57-2.59c-0.09-0.21-0.17-0.42-0.25-0.63l-2.81-0.73c-0.12-0.03-0.22-0.12-0.27-0.24
					c-0.04-0.11-0.95-2.63,0-5.36c0.04-0.12,0.15-0.22,0.27-0.25l2.94-0.72c0.08-0.21,0.18-0.42,0.27-0.62l-1.47-2.5
					c-0.06-0.11-0.07-0.24-0.02-0.36c0.05-0.1,1.19-2.53,3.79-3.79c0.12-0.06,0.26-0.05,0.37,0.02l2.59,1.57
					c0.21-0.09,0.42-0.17,0.63-0.25l0.73-2.81c0.03-0.12,0.12-0.22,0.24-0.27c0.11-0.04,2.63-0.95,5.36,0
					c0.12,0.04,0.22,0.15,0.25,0.27l0.72,2.94c0.21,0.08,0.42,0.18,0.62,0.27l2.5-1.47c0.11-0.06,0.24-0.07,0.36-0.02
					c0.1,0.05,2.53,1.19,3.79,3.79c0.06,0.12,0.05,0.26-0.02,0.37l-1.57,2.59c0.09,0.21,0.17,0.42,0.25,0.63l2.81,0.73
					c0.12,0.03,0.22,0.12,0.27,0.24c0.04,0.11,0.95,2.63,0,5.36c-0.04,0.12-0.15,0.22-0.27,0.25l-2.94,0.72
					c-0.08,0.21-0.18,0.42-0.27,0.62l1.47,2.5c0.06,0.11,0.07,0.24,0.02,0.36c-0.05,0.1-1.19,2.53-3.79,3.79
					c-0.12,0.06-0.26,0.05-0.37-0.02l-2.59-1.57c-0.21,0.09-0.42,0.17-0.63,0.25l-0.73,2.81c-0.03,0.12-0.12,0.22-0.24,0.27
					C128.71,115.68,127.59,116.09,126.05,116.09z M123.88,115c1.99,0.62,3.87,0.17,4.45,0l0.65-2.51c-0.01-0.02-0.02-0.04-0.02-0.06
					c-0.06-0.2,0.05-0.41,0.25-0.48c0.36-0.11,0.71-0.25,1.04-0.4c0.12-0.05,0.25-0.04,0.36,0.02l2.58,1.57
					c1.84-0.97,2.85-2.61,3.15-3.15l-1.31-2.22c-0.02-0.01-0.04-0.02-0.06-0.03c-0.18-0.1-0.25-0.33-0.16-0.52
					c0.17-0.33,0.32-0.68,0.46-1.03c0.04-0.12,0.15-0.21,0.27-0.24l2.93-0.72c0.62-1.99,0.17-3.87,0-4.45l-2.51-0.65
					c-0.02,0.01-0.04,0.02-0.06,0.02c-0.2,0.06-0.41-0.05-0.48-0.25c-0.11-0.36-0.25-0.71-0.4-1.04c-0.05-0.12-0.04-0.25,0.02-0.36
					l1.57-2.58c-0.97-1.85-2.61-2.85-3.15-3.15l-2.23,1.31c-0.01,0.02-0.02,0.04-0.03,0.06c-0.1,0.18-0.33,0.25-0.52,0.16
					c-0.33-0.17-0.68-0.32-1.03-0.46c-0.12-0.04-0.21-0.15-0.24-0.27l-0.72-2.93c-1.99-0.62-3.87-0.17-4.45,0l-0.65,2.51
					c0.01,0.02,0.02,0.04,0.02,0.06c0.06,0.2-0.05,0.41-0.25,0.48c-0.36,0.11-0.71,0.25-1.04,0.4c-0.12,0.05-0.25,0.04-0.36-0.02
					l-2.58-1.57c-1.85,0.97-2.85,2.61-3.15,3.15l1.31,2.23c0.02,0.01,0.04,0.02,0.06,0.03c0.18,0.1,0.25,0.33,0.16,0.52
					c-0.17,0.33-0.32,0.68-0.46,1.03c-0.04,0.12-0.15,0.21-0.27,0.24l-2.93,0.72c-0.62,1.99-0.17,3.87,0,4.45l2.51,0.65
					c0.02-0.01,0.04-0.02,0.06-0.02c0.2-0.06,0.41,0.05,0.48,0.25c0.11,0.36,0.25,0.71,0.4,1.04c0.05,0.12,0.04,0.25-0.02,0.36
					l-1.57,2.58c0.97,1.85,2.61,2.85,3.15,3.15l2.23-1.31c0.01-0.02,0.02-0.04,0.03-0.06c0.1-0.18,0.33-0.25,0.52-0.16
					c0.33,0.17,0.68,0.32,1.03,0.46c0.12,0.04,0.21,0.15,0.24,0.27L123.88,115z M138.76,105.56L138.76,105.56L138.76,105.56z
					 M126.29,107.88c-2.79,0-5.06-2.27-5.06-5.06s2.27-5.06,5.06-5.06c2.79,0,5.06,2.27,5.06,5.06S129.08,107.88,126.29,107.88z
					 M126.29,98.54c-2.36,0-4.28,1.92-4.28,4.28s1.92,4.28,4.28,4.28c2.36,0,4.28-1.92,4.28-4.28S128.65,98.54,126.29,98.54z"
							/>
						</g>
					</g>
					<g>
						<path
							fill="#FFFFFF"
							d="M213.77,47.56c0.38,1.16,1.49,1.95,2.74,1.95l14.4,0.06c1.19-0.04,2.25-0.76,2.68-1.83l2.17-5.48h-23.71
				L213.77,47.56z"
						/>
						<path
							fill="#B5C3F4"
							d="M242.1,131.63h-37.43c-3.06,0-5.56-2.5-5.56-5.56V49.12c0-3.06,2.5-5.56,5.56-5.56h37.43
				c3.06,0,5.56,2.5,5.56,5.56v76.96C247.66,129.13,245.16,131.63,242.1,131.63z"
						/>
						<rect x="204.5" y="58.45" fill="#FFFFFF" width="38.3" height="40.3" />
						<line
							fill="none"
							stroke="#FFFFFF"
							strokeWidth={2.4576}
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeMiterlimit={10}
							x1="216.6"
							y1="125.47"
							x2="230.18"
							y2="125.47"
						/>
						<line
							fill="none"
							stroke="#FFFFFF"
							strokeWidth={2.4576}
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeMiterlimit={10}
							x1="228.35"
							y1="53.45"
							x2="239.08"
							y2="53.45"
						/>
						<path
							fill="#FFFFFF"
							d="M218.31,111.66h-10.8c-1.91,0-3.48-1.57-3.48-3.48v-1.3c0-1.91,1.57-3.48,3.48-3.48h10.8
				c1.91,0,3.48,1.57,3.48,3.48v1.3C221.8,110.1,220.23,111.66,218.31,111.66z"
						/>
						<path
							fill="#FFFFFF"
							d="M239.83,111.66h-10.8c-1.91,0-3.48-1.57-3.48-3.48v-1.3c0-1.91,1.57-3.48,3.48-3.48h10.8
				c1.91,0,3.48,1.57,3.48,3.48v1.3C243.31,110.1,241.75,111.66,239.83,111.66z"
						/>
						<circle fill="#FFFFFF" cx="207.43" cy="53.44" r="1.21" />
						<circle fill="#FFFFFF" cx="210.89" cy="53.44" r="1.21" />
						<circle fill="#FFFFFF" cx="214.22" cy="53.44" r="1.21" />
						<path
							fill="#FFFFFF"
							d="M254.24,142.75c-0.05-0.27-0.08-0.54-0.09-0.81c-0.02-1.71-0.04-3.42,0-5.14
				c0.04-1.53,0.12-3.05,0.25-4.58c0.11-1.3,0.57-2.5,1.29-3.59c1.31-2,1.87-4.21,1.86-6.59c-0.01-4.46,0-8.92-0.01-13.37
				c0-0.46-0.03-0.93-0.15-1.37c-0.29-1.06-1.19-1.69-2.22-1.64c-1.05,0.05-1.86,0.76-2.09,1.82c-0.24,1.1-0.59,1.51-1.22,1.43
				c-0.65-0.09-0.9-0.56-0.93-1.68c-0.01-0.39-0.01-0.79-0.11-1.16c-0.27-1.05-1.17-1.74-2.18-1.72c-1.02,0.02-1.88,0.72-2.15,1.77
				c-0.05,0.21-0.04,0.43-0.09,0.63c-0.14,0.55-0.5,0.89-1.07,0.86c-0.56-0.03-0.9-0.4-0.99-0.96c-0.04-0.28-0.01-0.57-0.05-0.85
				c-0.14-1.11-0.98-1.94-2.03-2.01c-1.15-0.08-2.05,0.58-2.36,1.74c-0.05,0.17-0.07,0.35-0.1,0.53c-0.1,0.62-0.46,0.98-1.08,0.95
				c-0.63-0.03-0.93-0.48-0.99-1.07c-0.03-0.32-0.02-0.64-0.02-0.96c0-3.71,0-7.42,0-11.13c0-0.29,0.01-0.57,0-0.86
				c-0.05-1.45-0.9-2.39-2.17-2.42c-1.25-0.03-2.17,0.89-2.3,2.33c-0.03,0.39-0.02,0.78-0.02,1.18c0,6.24,0,12.48,0,18.72
				c0,0.39,0,0.79-0.07,1.17c-0.11,0.53-0.53,0.85-1.01,0.73c-0.35-0.08-0.69-0.43-0.89-0.76c-0.15-0.24-0.08-0.62-0.08-0.94
				c-0.01-1.5,0-3-0.01-4.49c0-0.35-0.03-0.69-0.04-1.03c-2.01,0.05-3.1,1.04-3.09,2.84c0.01,2.53-0.07,5.07,0.2,7.58
				c0.28,2.65,1.78,4.72,4.06,6.12c4.04,2.48,5.88,6.16,5.99,10.8c0.06,2.46,0.07,4.92,0.1,7.38c0,0.19,0,0.39-0.02,0.59H254.24z"
						/>
						<rect x="237.24" y="134.95" fill="#B5C3F4" width="18.21" height="7.8" />
						<g>
							<path
								fill="#304AF4"
								d="M231.42,107.42c-2.01,0.05-3.1,1.04-3.09,2.84c0.01,2.53-0.07,5.07,0.2,7.58
					c0.28,2.65,1.78,4.72,4.06,6.12c4.04,2.48,5.88,6.16,5.99,10.8c0.06,2.46,0.07,4.92,0.1,7.38c0,0.35-0.01,0.72-0.09,1.06
					c-0.14,0.59-0.52,0.93-1.16,0.87c-0.58-0.06-0.88-0.43-0.95-0.98c-0.03-0.28-0.02-0.57-0.02-0.85c-0.01-2.53,0-5.06-0.02-7.6
					c0-0.53-0.08-1.05-0.13-1.72c-0.51,0-0.93,0-1.34,0c-6.03-0.01-12.05-0.01-18.08-0.02c-1.39,0-1.99-0.37-1.99-1.23
					c-0.01-0.72,0.64-1.1,2.01-1.1c5.71-0.01,11.41,0,17.12,0c0.42,0,0.84,0,1.55,0c-0.51-0.86-0.81-1.6-1.31-2.14
					c-0.76-0.83-1.65-1.56-2.51-2.3c-0.97-0.83-2.08-1.53-2.92-2.47c-1.66-1.86-2.61-4.1-2.69-6.6c-0.07-2.38,0.01-4.78,0.09-7.16
					c0.07-2.09,1.46-3.77,3.5-4.37c0.54-0.16,1.09-0.3,1.76-0.49c-0.1-1.57-0.19-3.12-0.29-4.76c-0.51-0.03-0.96-0.09-1.41-0.09
					c-7.81-0.01-15.62,0-23.43,0c-0.36,0-0.71,0.01-1.07-0.01c-1.87-0.11-2.7-0.98-2.7-2.85c0-12.48,0-24.97,0-37.45
					c0-1.95,0.85-2.79,2.81-2.79c11.88,0,23.75,0,35.63,0c1.95,0,2.79,0.86,2.79,2.82c0,12.38,0,24.75,0,37.13
					c0,2.25-0.55,2.86-2.84,3.16c-0.14,0.02-0.27,0.06-0.52,0.12c0,0.46,0,0.92,0,1.53c2.13-0.74,3.94-0.36,5.45,1.32
					c1.18-0.79,2.42-1.1,3.78-0.9c1.36,0.21,2.33,1,3.08,2.06c0.67-0.26,1.27-0.59,1.92-0.72c2.92-0.57,5.27,1.48,5.29,4.63
					c0.03,4.74,0.01,9.49,0,14.23c0,0.5-0.07,0.99-0.12,1.49c-0.19,2.03-0.85,3.9-1.94,5.61c-0.77,1.21-1.13,2.51-1.12,3.95
					c0.02,2.89,0,5.78-0.01,8.67c0,0.29,0.01,0.57-0.02,0.85c-0.07,0.59-0.37,1-1.01,1.05c-0.58,0.04-0.97-0.25-1.1-0.8
					c-0.11-0.44-0.2-0.91-0.21-1.36c-0.02-1.71-0.04-3.42,0-5.14c0.04-1.53,0.12-3.05,0.25-4.58c0.11-1.3,0.57-2.5,1.29-3.59
					c1.31-2,1.87-4.21,1.86-6.59c-0.01-4.46,0-8.92-0.01-13.37c0-0.46-0.03-0.93-0.15-1.37c-0.29-1.06-1.19-1.69-2.22-1.64
					c-1.05,0.05-1.86,0.76-2.09,1.82c-0.24,1.1-0.59,1.51-1.22,1.43c-0.65-0.09-0.9-0.56-0.93-1.68c-0.01-0.39-0.01-0.79-0.11-1.16
					c-0.27-1.05-1.17-1.74-2.18-1.72c-1.02,0.02-1.88,0.72-2.15,1.77c-0.05,0.21-0.04,0.43-0.09,0.63c-0.14,0.55-0.5,0.89-1.07,0.86
					c-0.56-0.03-0.9-0.4-0.99-0.96c-0.04-0.28-0.01-0.57-0.05-0.85c-0.14-1.11-0.98-1.94-2.03-2.01c-1.15-0.08-2.05,0.58-2.36,1.74
					c-0.05,0.17-0.07,0.35-0.1,0.53c-0.1,0.62-0.46,0.98-1.08,0.95c-0.63-0.03-0.93-0.48-0.99-1.07c-0.03-0.32-0.02-0.64-0.02-0.96
					c0-3.71,0-7.42,0-11.13c0-0.29,0.01-0.57,0-0.86c-0.05-1.45-0.9-2.39-2.17-2.42c-1.25-0.03-2.17,0.89-2.3,2.33
					c-0.03,0.39-0.02,0.78-0.02,1.18c0,6.24,0,12.48,0,18.72c0,0.39,0,0.79-0.07,1.17c-0.11,0.53-0.52,0.85-1.01,0.73
					c-0.35-0.08-0.69-0.43-0.89-0.76c-0.15-0.24-0.08-0.62-0.08-0.94c-0.01-1.5,0-3-0.01-4.49
					C231.47,108.11,231.44,107.77,231.42,107.42z M204.79,59.38c0,0.6,0,1.05,0,1.51c0,10.41-0.01,20.83,0.01,31.24
					c0,1.7,0.15,3.41,0.24,5.11c0.01,0.13,0.09,0.26,0.15,0.4c8.68,0,17.32,0,26.01,0c0.02-0.41,0.05-0.72,0.06-1.04
					c0.08-1.42,0.12-2.84,0.24-4.26c0.25-2.78,3.05-4.58,5.69-3.69c1.7,0.57,2.82,2.12,2.91,3.96c0.08,1.66,0.19,3.32,0.29,5.07
					c0.35-0.03,0.6-0.05,0.82-0.06c0.38-2.3,0.51-37.05,0.15-38.24C229.25,59.38,217.11,59.38,204.79,59.38z"
							/>
							<path
								fill="#304AF4"
								d="M197.74,87.52c0-12.52,0-25.04,0-37.56c0-2.72,1.03-4.92,3.28-6.5c1.29-0.91,2.75-1.31,4.33-1.3
					c6.71,0.01,13.41,0.01,20.12,0c5.14,0,10.27-0.02,15.41-0.01c4.61,0.01,7.79,3.19,7.79,7.82c0.01,15.62,0,31.25,0,46.87
					c0,0.28,0.02,0.58-0.04,0.85c-0.11,0.56-0.73,1.04-1.26,0.95c-0.55-0.1-0.88-0.46-0.91-1.03c-0.02-0.39-0.02-0.78-0.02-1.18
					c0-15.3,0.01-30.6-0.02-45.91c0-0.88-0.12-1.79-0.38-2.63c-0.66-2.12-2.63-3.49-4.99-3.51c-9.24-0.05-18.47-0.09-27.71-0.11
					c-2.6-0.01-5.2,0.07-7.8,0.1c-3.24,0.04-5.44,2.14-5.6,5.38c-0.02,0.32-0.01,0.64-0.01,0.96c0,24.54,0,49.08,0.01,73.62
					c0,0.78,0.06,1.57,0.23,2.33c0.46,2.12,2.42,3.75,4.58,3.84c1.28,0.05,2.57,0.02,3.85,0.04c0.36,0,0.72,0,1.07,0.05
					c0.6,0.1,1.11,0.37,1.12,1.08c0.01,0.65-0.51,1.17-1.27,1.19c-1.35,0.04-2.71,0.03-4.07,0.01c-0.5-0.01-1-0.06-1.49-0.14
					c-3.6-0.61-6.17-3.57-6.23-7.23c-0.05-2.67-0.02-5.35-0.02-8.03C197.73,107.49,197.73,97.5,197.74,87.52
					C197.73,87.52,197.73,87.52,197.74,87.52z"
							/>
						</g>
						<path
							fill="none"
							stroke="#304AF4"
							strokeWidth={2.2309}
							strokeMiterlimit={10}
							d="M259.05,154.07l-2.23-2.23c-0.02-0.02-0.02-0.05,0-0.07l2.23-2.23c0.02-0.02,0.05-0.02,0.07,0l2.23,2.23
				c0.02,0.02,0.02,0.05,0,0.07l-2.23,2.23C259.1,154.09,259.07,154.09,259.05,154.07z"
						/>
						<path
							fill="none"
							stroke="#304AF4"
							strokeWidth={2.2309}
							strokeMiterlimit={10}
							d="M260.2,97.49l-2.23-2.23c-0.02-0.02-0.02-0.05,0-0.07l2.23-2.23c0.02-0.02,0.05-0.02,0.07,0l2.23,2.23
				c0.02,0.02,0.02,0.05,0,0.07l-2.23,2.23C260.25,97.51,260.22,97.51,260.2,97.49z"
						/>
						<path
							fill="none"
							stroke="#304AF4"
							strokeWidth={2.2309}
							strokeMiterlimit={10}
							d="M191.53,64.49l-2.23-2.23c-0.02-0.02-0.02-0.05,0-0.07l2.23-2.23c0.02-0.02,0.05-0.02,0.07,0l2.23,2.23
				c0.02,0.02,0.02,0.05,0,0.07l-2.23,2.23C191.59,64.51,191.55,64.51,191.53,64.49z"
						/>
						<path
							fill="#304AF4"
							d="M258.13,80.92l0.66,1.55c0.07,0.17,0.21,0.3,0.38,0.36l1.37,0.51c0.49,0.36,0.48,1.1-0.03,1.44l-1.39,0.58
				c-0.17,0.07-0.31,0.21-0.38,0.38l-0.56,1.45c-0.29,0.47-0.97,0.49-1.29,0.04l-0.67-1.43c-0.08-0.18-0.23-0.32-0.41-0.4
				l-1.59-0.69c-0.48-0.3-0.47-0.99,0.01-1.28l1.57-0.75c0.19-0.09,0.33-0.24,0.42-0.43l0.56-1.3
				C257.1,80.46,257.8,80.44,258.13,80.92z"
						/>
						<path
							fill="#304AF4"
							d="M192.31,110.48l0.66,1.55c0.07,0.17,0.21,0.3,0.38,0.36l1.37,0.51c0.49,0.36,0.48,1.1-0.03,1.44
				l-1.39,0.58c-0.17,0.07-0.31,0.21-0.38,0.38l-0.56,1.45c-0.29,0.47-0.97,0.49-1.29,0.04l-0.67-1.43
				c-0.08-0.18-0.23-0.32-0.41-0.4l-1.59-0.69c-0.48-0.3-0.47-0.99,0.01-1.28l1.57-0.75c0.19-0.09,0.33-0.24,0.42-0.43l0.56-1.3
				C191.27,110.02,191.98,110,192.31,110.48z"
						/>
						<g transform="translate(450,0) scale(1.05, 1.05)">
							<g transform="">
								<g>
									<path
										fill="none"
										d="M-206.4,60.7l0,2.78h2.8c0.56,0,1.02-0.47,1.02-1.04v-2.78h-2.8C-205.94,59.65-206.4,60.12-206.4,60.7z
							"
									/>
									<path
										fill="#5E3388"
										d="M-203.6,58.83h-1.77c-1.02,0-1.84,0.84-1.84,1.86l0,3.59h3.62c1.02,0,1.84-0.84,1.84-1.86V60.7v-1.86
							H-203.6z M-202.58,62.43c0,0.58-0.46,1.04-1.02,1.04h-2.8l0-2.78c0-0.58,0.46-1.04,1.02-1.04h2.8V62.43z"
									/>
								</g>
							</g>
						</g>
						<g transform="translate(0,450) scale(1.05, 1.05)">
							<g transform="">
								<g>
									<path
										fill="none"
										d="M198.78-344.49l0,2.78h2.8c0.56,0,1.02-0.47,1.02-1.04v-2.78h-2.8
							C199.24-345.53,198.78-345.06,198.78-344.49z"
									/>
									<path
										fill="#5E3388"
										d="M201.58-346.35h-1.77c-1.02,0-1.84,0.84-1.84,1.86l0,3.59h3.62c1.02,0,1.84-0.84,1.84-1.86v-1.73v-1.86
							H201.58z M202.6-342.75c0,0.58-0.46,1.04-1.02,1.04h-2.8l0-2.78c0-0.58,0.46-1.04,1.02-1.04h2.8V-342.75z"
									/>
								</g>
							</g>
						</g>
						<g transform="translate(480,30) scale(0.45, 0.45)">
							<g transform="">
								<path
									fill="#7BC6E2"
									d="M-542.57,74.25h-2.48c-0.13,0-0.25,0.02-0.37,0.05c-0.26,0.07-0.49,0.21-0.68,0.39
						c-0.03,0.03-0.07,0.07-0.1,0.11c-0.21,0.26-0.34,0.6-0.34,0.96l0,3.95h3.97c0.82,0,1.48-0.68,1.48-1.51v-2.44v0l0.01-1.51
						C-541.08,74.25-542.05,74.25-542.57,74.25z"
								/>
							</g>
						</g>
						<g transform="translate(30,480) scale(0.45, 0.45)">
							<g transform="">
								<path
									fill="#7BC6E2"
									d="M402.86-871.18h-2.48c-0.13,0-0.25,0.02-0.37,0.05c-0.26,0.07-0.49,0.21-0.68,0.39
						c-0.03,0.03-0.07,0.07-0.1,0.11c-0.21,0.26-0.34,0.6-0.34,0.96l0,3.95h3.97c0.82,0,1.48-0.68,1.48-1.51v-2.44v0l0.01-1.51
						C404.35-871.18,403.37-871.18,402.86-871.18z"
								/>
							</g>
						</g>
						<g>
							<g transform="translate(0,0) scale(1.05, 1.05)">
								<g transform=" translate(0,100) scale(1,-1) ">
									<g>
										<path
											fill="none"
											stroke="#304AF4"
											strokeWidth={1.5747}
											strokeMiterlimit={10}
											d="M201.58,35.71h-1.77c-1.02,0-1.84,0.84-1.84,1.86l0,3.59h3.62c1.02,0,1.84-0.84,1.84-1.86v-1.73v-1.86
								H201.58z"
										/>
									</g>
								</g>
							</g>
							<g transform="translate(30,30) scale(0.45, 0.45)">
								<g transform=" translate(0,100) scale(1,-1) ">
									<path
										fill="#4BCFE3"
										d="M402.86,20.3h-2.48c-0.13,0-0.25,0.02-0.37,0.05c-0.26,0.07-0.49,0.21-0.68,0.39
							c-0.03,0.03-0.07,0.07-0.1,0.11c-0.21,0.26-0.34,0.6-0.34,0.96l0,3.95h3.97c0.82,0,1.48-0.68,1.48-1.51V21.8v0l0.01-1.51
							C404.35,20.3,403.37,20.3,402.86,20.3z"
									/>
								</g>
							</g>
						</g>
						<g>
							<g transform="translate(0,0) scale(1.05, 1.05)">
								<g transform=" translate(0,100) scale(1,-1) ">
									<g>
										<path
											fill="none"
											stroke="#304AF4"
											strokeWidth={1.5747}
											strokeMiterlimit={10}
											d="M203.42,15.94v-1.77c0-1.02-0.84-1.84-1.86-1.84l-3.59,0v3.62c0,1.02,0.84,1.84,1.86,1.84h1.73h1.86
								V15.94z"
										/>
									</g>
								</g>
							</g>
							<g transform="translate(30,30) scale(0.45, 0.45)">
								<g transform=" translate(0,100) scale(1,-1) ">
									<path
										fill="#4BCFE3"
										d="M404.35-30.31v-2.48c0-0.13-0.02-0.25-0.05-0.37c-0.07-0.26-0.21-0.49-0.39-0.68
							c-0.03-0.03-0.07-0.07-0.11-0.1c-0.26-0.21-0.6-0.34-0.96-0.34l-3.95,0v3.97c0,0.82,0.68,1.48,1.51,1.48h2.44h0l1.51,0.01
							C404.35-28.82,404.35-29.8,404.35-30.31z"
									/>
								</g>
							</g>
						</g>
						<g>
							<g transform="translate(0,0) scale(1.05, 1.05)">
								<g transform=" translate(0,100) scale(1,-1) ">
									<g>
										<path
											fill="none"
											stroke="#304AF4"
											strokeWidth={1.5747}
											strokeMiterlimit={10}
											d="M221.36,37.63v1.77c0,1.02,0.84,1.84,1.86,1.84l3.59,0v-3.62c0-1.02-0.84-1.84-1.86-1.84h-1.73h-1.86
								V37.63z"
										/>
									</g>
								</g>
							</g>
							<g transform="translate(30,30) scale(0.45, 0.45)">
								<g transform=" translate(0,100) scale(1,-1) ">
									<path
										fill="#4BCFE3"
										d="M453.47,21.96v2.48c0,0.13,0.02,0.25,0.05,0.37c0.07,0.26,0.21,0.49,0.39,0.68
							c0.03,0.03,0.07,0.07,0.11,0.1c0.26,0.21,0.6,0.34,0.96,0.34l3.95,0v-3.97c0-0.82-0.68-1.48-1.51-1.48h-2.44h0l-1.51-0.01
							C453.47,20.47,453.47,21.44,453.47,21.96z"
									/>
								</g>
							</g>
						</g>
						<path
							fill="#304AF4"
							d="M225.57,80.98h-3.84c-1.22,0-2.2-0.99-2.2-2.2v-3.84c0-1.22,0.99-2.2,2.2-2.2h3.84
				c1.22,0,2.2,0.99,2.2,2.2v3.84C227.77,80,226.78,80.98,225.57,80.98z"
						/>
						<g>
							<circle fill="#304AF4" cx="230.67" cy="72.14" r="0.95" />
							<circle fill="#304AF4" cx="230.67" cy="81.59" r="0.95" />
							<circle fill="#304AF4" cx="234.32" cy="76.85" r="0.95" />
							<circle fill="#304AF4" cx="234.32" cy="81.59" r="0.95" />
							<circle fill="#304AF4" cx="237.97" cy="72.14" r="0.95" />
							<circle fill="#304AF4" cx="237.97" cy="76.85" r="0.95" />
							<circle fill="#304AF4" cx="237.97" cy="81.59" r="0.95" />
							<circle fill="#304AF4" cx="216.08" cy="72.14" r="0.95" />
							<circle fill="#304AF4" cx="216.08" cy="76.85" r="0.95" />
							<circle fill="#304AF4" cx="216.08" cy="81.59" r="0.95" />
							<circle fill="#304AF4" cx="219.73" cy="62.74" r="0.95" />
							<circle fill="#304AF4" cx="219.73" cy="67.44" r="0.95" />
							<circle fill="#304AF4" cx="219.73" cy="90.99" r="0.95" />
							<circle fill="#304AF4" cx="223.38" cy="62.74" r="0.95" />
							<circle fill="#304AF4" cx="223.38" cy="86.29" r="0.95" />
							<circle fill="#304AF4" cx="227.02" cy="62.74" r="0.95" />
							<circle fill="#304AF4" cx="227.02" cy="67.44" r="0.95" />
							<circle fill="#304AF4" cx="227.02" cy="86.29" r="0.95" />
							<circle fill="#304AF4" cx="227.02" cy="90.99" r="0.95" />
							<circle fill="#304AF4" cx="208.81" cy="72.14" r="0.95" />
							<circle fill="#304AF4" cx="208.81" cy="81.59" r="0.95" />
							<circle fill="#304AF4" cx="212.46" cy="72.14" r="0.95" />
							<circle fill="#304AF4" cx="212.46" cy="76.85" r="0.95" />
						</g>
					</g>
				</g>
			</g>{' '}
		</svg>
	);
};

export default SvgSignUp;
