import { useEffect, useState } from 'react';
import { useStripeState } from '../../hooks/useStripeState';
import { IS_CLOSED, IS_CLOSED_THREE_DAY } from '../../util/constants';
import { DaysView } from './DaysView';
import { ExpandedView } from './ExpandedView';

const TrialDaysWidget = () => {
	const [visible, setVisible] = useState(false);
	const [expanded, setExpanded] = useState(false);
	const isClosedThreeDay = localStorage.getItem(IS_CLOSED_THREE_DAY);
	const isClosed = localStorage.getItem(IS_CLOSED);
	const todaysDate = new Date().getUTCDate().toString();
	const { daysRemaining, billingInfo, stripeSubscription } = useStripeState();

	// effect for setting visibilty
	// if in trial, show widget
	// if in trial and billging added, show widget with relevant message
	useEffect(() => {
		//one off condition to hide this while on the sign-up page
		if (!window.location.href.includes('sign-up')) {
			if (stripeSubscription?.status === 'trialing') {
				setVisible(true);
			} else {
				setVisible(false);
			}
		}
	}, [stripeSubscription]);

	// effect to determine if the expanded view should be shown
	useEffect(() => {
		if (visible) {
			if (daysRemaining > 3 && !isClosed) {
				// if more than 3 days remain and not closed already, show expanded view
				setExpanded(true);
			} else {
				// if no billing info, 3 or less days remaining, and the user has not closed the expanded view today, show expanded view
				if (daysRemaining <= 3 && isClosedThreeDay !== todaysDate) {
					setExpanded(true);
				}
			}
		}
	}, [daysRemaining, visible, isClosedThreeDay, isClosed, todaysDate, billingInfo]);

	// if not visible
	if (!visible) {
		return null;
	}

	// if expanded, show expanded view, else show days view
	return expanded ? <ExpandedView expanded setExpanded={setExpanded} /> : <DaysView />;
};

export default TrialDaysWidget;
